import React from 'react'

function InfoFIllIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_2)">
                <path d="M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56823 3.03617 8.69181 1.52242 12.3463C0.00866572 16.0009 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9992 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C39.9987 14.6961 37.8911 9.60977 34.1407 5.85934C30.3902 2.1089 25.3039 0.00133398 20 0V0ZM20 8.87156C20.6698 8.87156 21.3246 9.07017 21.8815 9.4423C22.4384 9.81442 22.8725 10.3433 23.1288 10.9622C23.3851 11.581 23.4522 12.2619 23.3215 12.9188C23.1908 13.5758 22.8683 14.1792 22.3947 14.6528C21.9211 15.1264 21.3176 15.449 20.6607 15.5797C20.0038 15.7103 19.3228 15.6433 18.704 15.3869C18.0852 15.1306 17.5563 14.6966 17.1842 14.1396C16.812 13.5827 16.6134 12.9279 16.6134 12.2581C16.6131 11.8132 16.7004 11.3725 16.8705 10.9614C17.0405 10.5502 17.2899 10.1766 17.6044 9.86184C17.9189 9.5471 18.2924 9.29742 18.7034 9.12707C19.1145 8.95672 19.5551 8.86904 20 8.86904V8.87156ZM24.5289 29.3571C24.5289 29.6141 24.4268 29.8604 24.2452 30.0421C24.0635 30.2238 23.8171 30.3258 23.5602 30.3258H16.4474C16.1904 30.3258 15.9441 30.2238 15.7624 30.0421C15.5807 29.8604 15.4787 29.6141 15.4787 29.3571V27.4223C15.4787 27.1654 15.5807 26.919 15.7624 26.7374C15.9441 26.5557 16.1904 26.4536 16.4474 26.4536H17.416V21.2907H16.4474C16.1904 21.2907 15.9441 21.1887 15.7624 21.007C15.5807 20.8253 15.4787 20.579 15.4787 20.3221V18.3872C15.4787 18.1303 15.5807 17.8839 15.7624 17.7023C15.9441 17.5206 16.1904 17.4185 16.4474 17.4185H21.6128C21.8697 17.4185 22.1161 17.5206 22.2977 17.7023C22.4794 17.8839 22.5815 18.1303 22.5815 18.3872V26.4511H23.5501C23.807 26.4511 24.0534 26.5532 24.2351 26.7348C24.4168 26.9165 24.5188 27.1629 24.5188 27.4198L24.5289 29.3571Z" fill={props.currentColor ? "currentColor" :"#0DEFDA"} />
            </g>
            <defs>
                <clipPath id="clip0_3_2">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default InfoFIllIcon