import React from 'react'

function TimerClockIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_65)">
                <path d="M0.0107038 18.6345C0.17126 18.2164 0.160557 17.7554 0.224779 17.3266C1.1346 11.5158 4.02462 6.92723 8.85202 3.62516C12.5448 1.09501 16.7086 0.022912 21.1721 0.280216C24.608 0.473193 27.787 1.53457 30.677 3.42146C30.9339 3.58228 31.0623 3.61444 31.2336 3.32498C31.544 2.82109 31.8972 2.33865 32.2397 1.84548C32.3789 1.6525 32.518 1.4488 32.7963 1.49169C33.0746 1.54529 33.1389 1.79187 33.2031 2.01702C33.6848 3.72165 34.1557 5.43701 34.616 7.14165C34.8194 7.8814 34.4768 8.35312 33.7062 8.41745C31.9936 8.57826 30.2703 8.72835 28.5577 8.87845C28.472 8.88917 28.3757 8.88917 28.2901 8.88917C27.8084 8.87845 27.6264 8.57826 27.8833 8.16014C28.2151 7.62409 28.5684 7.08804 28.9644 6.59488C29.1892 6.30541 29.1142 6.20892 28.836 6.03739C26.0637 4.30059 23.0345 3.43219 19.7806 3.46435C16.1841 3.50723 12.898 4.57933 10.008 6.73425C6.70056 9.20008 4.57051 12.4485 3.63928 16.4689C3.17902 18.463 3.1148 20.4786 3.4038 22.4941C4.04602 26.8683 6.06904 30.4705 9.49424 33.2473C11.6564 34.9948 14.129 36.099 16.8584 36.6351C18.0787 36.8709 18.646 38.0931 18.0251 39.0795C17.6826 39.637 17.0083 39.9264 16.3447 39.7978C11.528 38.865 7.55686 36.5064 4.45277 32.7112C1.88386 29.57 0.492373 25.9463 0.0856301 21.9259C0.0749264 21.8508 0.107038 21.7651 0 21.7329C0 21.5507 0 21.3684 0 21.1861C0.128445 20.5322 0.128445 19.8889 0 19.2349C0.0107038 19.0527 0.0107038 18.8382 0.0107038 18.6345Z" fill="#00FDB4" />
                <path d="M20.2418 14.0699C20.2418 15.8603 20.2525 17.6507 20.2311 19.4411C20.2311 19.7413 20.3061 19.9128 20.5951 20.0629C23.8918 21.7569 27.1886 23.4722 30.4853 25.1769C31.1918 25.5414 31.4594 26.0667 31.2988 26.7207C31.1169 27.4497 30.3569 27.8785 29.6397 27.632C29.5113 27.5891 29.3721 27.5248 29.2544 27.4604C25.4867 25.5092 21.7297 23.558 17.9512 21.6175C17.7479 21.5103 17.6515 21.4031 17.6515 21.1565C17.6622 16.986 17.6515 12.8263 17.6622 8.65581C17.6622 7.86246 18.1225 7.31569 18.8182 7.25136C19.621 7.18704 20.2311 7.74453 20.2418 8.58076C20.2418 10.4033 20.2418 12.2366 20.2418 14.0699Z" fill="#00FDB4" />
                <path d="M25.9178 37.9622C25.9178 38.6913 25.4897 39.3023 24.7832 39.506C24.3337 39.6347 23.8841 39.7419 23.4239 39.8062C22.6104 39.9242 21.8611 39.3667 21.6791 38.5412C21.5079 37.7585 21.9681 36.9437 22.7495 36.7079C23.1777 36.5792 23.6272 36.472 24.0768 36.4077C25.0722 36.2576 25.9178 36.9759 25.9178 37.9622Z" fill="#3BDFFD" />
                <path d="M39.8189 20.0673C39.8082 20.3782 39.851 20.6892 39.7654 21.0001C39.562 21.7291 38.8555 22.2651 38.1491 22.1901C37.2928 22.1043 36.6827 21.504 36.6399 20.6892C36.6184 20.2925 36.6184 19.8851 36.6291 19.4884C36.6506 18.5771 37.3142 17.891 38.1812 17.8695C39.0482 17.8374 39.7654 18.5342 39.8189 19.4455C39.8296 19.6492 39.8189 19.8529 39.8189 20.0673Z" fill="#3BDFFD" />
                <path d="M38.9911 25.7765C38.8734 26.3661 38.7021 27.0844 38.274 27.6955C37.8672 28.2745 37.0644 28.4567 36.4222 28.178C35.7265 27.8778 35.3304 27.1809 35.4696 26.4519C35.5766 25.9051 35.7586 25.3798 36.0155 24.8759C36.3473 24.2219 37.1287 23.911 37.8351 24.104C38.5094 24.2862 38.9911 24.9188 38.9911 25.7765Z" fill="#3BDFFD" />
                <path d="M32.283 32.0566C32.283 32.0459 32.283 32.0352 32.283 32.0137C32.3044 31.006 33.6317 29.6015 34.5629 29.6015C35.7939 29.5908 36.5645 30.9202 35.9437 31.9816C35.6654 32.4533 35.3229 32.8821 34.9268 33.2466C34.4345 33.6969 33.8458 33.7827 33.2357 33.5254C32.6255 33.2788 32.283 32.7213 32.283 32.0566Z" fill="#3BDFFD" />
                <path d="M37.1657 16.0021C36.6198 15.97 36.1167 15.6698 35.8491 15.0372C35.6779 14.6191 35.5173 14.1903 35.3996 13.7614C35.1748 12.9895 35.5815 12.1962 36.3201 11.8853C37.0586 11.5744 37.915 11.8531 38.3003 12.55C38.5786 13.0646 38.782 13.6114 38.8997 14.1796C39.071 15.1337 38.2682 16.0236 37.1657 16.0021Z" fill="#3BDFFD" />
                <path d="M29.2994 37.6406C28.4324 37.662 27.8116 37.2439 27.5761 36.6113C27.3407 35.9788 27.5226 35.2283 28.0685 34.8317C28.486 34.5207 28.9248 34.2527 29.3851 34.0169C29.9845 33.7167 30.7338 33.8989 31.194 34.3921C31.6543 34.896 31.7934 35.6679 31.4188 36.2254C30.8836 37.0402 30.038 37.4154 29.2994 37.6406Z" fill="#3BDFFD" />
            </g>
            <defs>
                <clipPath id="clip0_3_65">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TimerClockIcon