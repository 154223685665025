export const ENCRYPTION_KEY = process.env.REACT_APP_API_KEY_ENC;
export const DECRYPTION_KEY = process.env.REACT_APP_API_KEY_DEC;
export const API_ENCRYPTION_KEY = process.env.REACT_APP_API_ENCRYPT_VI_KEY;
export const API_DECRYPTION_KEY = process.env.REACT_APP_API_DECRYPT_VI_KEY;

export const UserType = {
    WORKER: 1,
    OWNER: 2,
    ISMANAGER: 3,
    BOTH: 4
};