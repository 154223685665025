import { ReactSVG } from 'react-svg'
import { cn } from '../../../../lib/utils';
const SvgRender = ({ filePath, iconClassName }) => {
    var imageSplitExt = filePath?.split('.')?.pop();
    // console.log('filePath', filePath);
    
    return (
        <>
            {imageSplitExt == 'svg'?
                <ReactSVG className={cn("icon size-16 [&_.injected-svg]:size-full", iconClassName)} src={filePath} />
                :
                <img src={filePath} className={cn("icon", iconClassName)} alt={"Image"} />
            }
        </>
    )
}

export default SvgRender;