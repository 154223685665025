import React from 'react';

const HappyIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_629_2" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                <path d="M40 0.987915H0V39.6976H40V0.987915Z" fill="white" />
            </mask>
            <g mask="url(#mask0_629_2)">
                <path d="M20 35.504C28.6525 35.504 35.6667 28.7161 35.6667 20.3427C35.6667 11.9694 28.6525 5.18146 20 5.18146C11.3475 5.18146 4.33333 11.9694 4.33333 20.3427C4.33333 28.7161 11.3475 35.504 20 35.504Z" fill="#FFEB3A" stroke="#FFEB3A" strokeLinecap="round" />
                <path d="M8.9756 19.5801C10.0526 22.6892 11.6141 25.3094 13.53 27.153C15.4497 29.0003 17.6799 30.0201 20 30.0201C22.3201 30.0201 24.5503 29.0003 26.47 27.153C28.386 25.3094 29.9473 22.6892 31.0244 19.5801" stroke="#222222" strokeLinecap="round" />
                <path d="M22.2769 15.8497L25.5657 18.8395C25.6768 18.9404 25.7323 18.9909 25.8 18.9909C25.8677 18.9909 25.9232 18.9404 26.0343 18.8395L29.3231 15.8497C30.2359 15.0198 30.3468 13.6541 29.5789 12.6965L29.4347 12.5164C28.5161 11.3708 26.6725 11.5629 26.0271 12.8715C25.936 13.0564 25.664 13.0564 25.5729 12.8715C24.9275 11.5629 23.0839 11.3708 22.1653 12.5164L22.0211 12.6965C21.2532 13.6541 21.3641 15.0198 22.2769 15.8497Z" fill="#FF0000" stroke="#FF0000" stroke-width="0.51321" />
                <path d="M10.4098 15.8497L13.6985 18.8395C13.8096 18.9404 13.8651 18.9909 13.9328 18.9909C14.0005 18.9909 14.056 18.9404 14.1671 18.8395L17.4559 15.8497C18.3687 15.0198 18.4796 13.6541 17.7117 12.6965L17.5675 12.5164C16.6489 11.3708 14.8053 11.5629 14.1599 12.8715C14.0688 13.0564 13.7968 13.0564 13.7057 12.8715C13.0603 11.5629 11.2167 11.3708 10.2982 12.5164L10.1538 12.6965C9.38605 13.6541 9.49692 15.0198 10.4098 15.8497Z" fill="#FF0000" stroke="#FF0000" stroke-width="0.51321" />
            </g>
        </svg>

    );
}

export default HappyIcon;
