import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { isEmpty } from "lodash";
import CloseFillIcon from './icons/CloseFillIcon';
import GuidelinesListIcon from './icons/GuidelinesListIcon';
import { cn } from '../../../lib/utils';
import PlusLineIcon from './icons/PlusLineIcon';
import AddSalonGuidelinesModal from '../modals/AddSalonGuidelinesModal';

function AddSalonGuidelines({ t, guidelinesSelectData, setGuidelinesSelectData, setGuidelines, databaseGuidList, setDatabaseGuidList, apiResponse, setDeleteGuidLineArray, deleteGuidLineArray, ...props }) {

    const [modalOpen, setModalOpen] = useState(false);
    const [guidelinesData, setGuidelinesData] = useState([]);

    useEffect(() => {
        if (!isEmpty(guidelinesData)) {
            setGuidelinesSelectData([...guidelinesSelectData, guidelinesData[0]]);
        }
    }, [guidelinesData]);

    useEffect(() => {
        setGuidelines(guidelinesSelectData);
    }, [guidelinesSelectData]);

    const handleRemoveGuidLines = (index) => {
        setGuidelinesSelectData((prev) => prev.filter((_, i) => i !== index));
    };

    const handleDatabseRemoveGuidLines = (guidData) => {
        setDeleteGuidLineArray([...deleteGuidLineArray, guidData.id]);
        setDatabaseGuidList(prev => prev.filter(guidLine => guidLine.id !== guidData.id));
    };

    useEffect(() => {
        if (apiResponse) {
            setGuidelinesSelectData([]);
        }
    }, [apiResponse])

    const handleClickUpload = (data) => {
        if (data?.fileList?.length > 0) {
            const newTab = window.open(URL.createObjectURL(data.fileList[0]), '_blank');
            if (newTab) {
                newTab.focus();
            } else {
                console.error('Popup blocked. Please allow pop-ups and try again.');
            }
        } else if (typeof data === "object") {
            const newTab = window.open(data.file, '_blank');
            if (newTab) {
                newTab.focus();
            } else {
                console.error('Popup blocked. Please allow pop-ups and try again.');
            }
        } else {
            console.error('Invalid data type. Expected a File object or a string (URL).');
        }

    };

    return (
        <div className='guidelines-list'>
            <ul className="flex items-stretch content-stretch gap-3.5 flex-wrap">
                {
                    guidelinesSelectData.length > 0 &&
                    guidelinesSelectData.map((data, ind) => (
                        <li className="border border-success-100 rounded-[14px] pt-3.5 pb-2 px-1 flex-grow w-full max-w-[100px] text-center flex flex-col gap-2 h-full min-h-[148px] max-h-[148px]" key={ind}>
                            <div className="icon">
                                <GuidelinesListIcon className="w-14 h-14 text-blue-950" onClick={() => handleClickUpload(data)} />
                            </div>
                            <p className={cn("text-[15px] font-medium leading-none text-center break-all text-blue-950")}>{data.title}</p>
                            <button type="button" role='button'><CloseFillIcon className="w-5 h-5" onClick={() => handleRemoveGuidLines(ind)} /></button>
                        </li>
                    ))
                }

                {databaseGuidList?.length > 0 &&
                    databaseGuidList.map((data, ind) => (
                        <li className="border border-success-100 rounded-[14px] pt-3.5 pb-2 px-1 flex-grow w-full max-w-[100px] text-center flex flex-col gap-2 h-full min-h-[148px] max-h-[148px]" key={ind}>
                            <div className="icon">
                                <GuidelinesListIcon className="w-14 h-14 text-blue-950" onClick={() => handleClickUpload(data)} />

                            </div>
                            <p className={cn("text-[15px] font-medium leading-none text-center break-all text-blue-950")}>{data?.title?.length > 20 ? data?.title?.slice(0, 20) + '...' : data?.title}</p>
                            <button type="button" role='button'><CloseFillIcon className="w-5 h-5" onClick={() => handleDatabseRemoveGuidLines(data)} /></button>
                        </li>
                    ))}

                <li className="border-[2.5px] border-success-950 rounded-[14px] pt-3.5 pb-2 px-1 flex-shrink-0 max-w-[100px] w-full text-center flex flex-col justify-center gap-2 h-full min-h-[148px] max-h-[148px] cursor-pointer" onClick={() => setModalOpen(true)}>
                    <div className="icon">
                        <PlusLineIcon className="w-6 h-6 text-success-950" />
                    </div>
                    <p className={cn("text-lg font-bold leading-none text-center break-all text-success-950")}>{t("button.add")}</p>
                </li>
            </ul>
            <AddSalonGuidelinesModal setModalOpen={setModalOpen} setGuidelines={setGuidelinesData} modalOpen={modalOpen} />
        </div>
    )
}

export default withTranslation()(AddSalonGuidelines)