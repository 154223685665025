import React from 'react'
import { cn } from '../../../lib/utils';

function Congratulation({ icon, title, text, cardButton, className, iconWrapperClassName, BtnWrapperClassName, titleClassName, desClassName }) {

  return (
    <div className={cn(`custom_card w-full flex justify-center items-center`, className)}>
      <div className="custom_card_body ptb60 max-w-[549px]">
        <div className='row justify-content-center'>
          <div className="col-lg-8">
            <div className={cn(`icon_wrapper mb-6 text-center`, iconWrapperClassName)}>
              {icon ? icon : ""}
            </div>
            <h6 className={cn(`text-center text-lg font-semibold text-blue-950 mb-4`, titleClassName)}>{title ? title : ""}</h6>
            <p className={cn(`text-base font-semibold text-blue-950/40`, desClassName)}>{text ? text : ""}</p>
            <div className={cn(`text-center flex justify-center items-center gap-4`, BtnWrapperClassName)}>
              {cardButton ? cardButton : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Congratulation;
