import React from 'react'
import { cn } from "../../../../lib/utils";

export default function Table({ children, size, className, ...props }) {
  return (
    <table className={cn("w-full table-auto", className, size)} {...props}>
      {children}
    </table>
  )
}
