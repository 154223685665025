import React from 'react'

function BagIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1149 9.32924C31.972 9.33316 34.8291 9.3586 37.6852 9.32826C38.7873 9.31652 39.5977 9.66007 40 10.7377C40 12.9507 40 15.1648 40 17.3778C39.7553 17.8192 39.493 18.2469 39.0711 18.5484C38.5445 18.6796 38.0199 18.8235 37.4894 18.938C34.8242 19.5135 32.158 20.0851 29.4898 20.6508C27.2396 21.1285 24.9884 21.5963 22.7372 22.0681C22.7342 21.7559 22.7391 21.4427 22.7264 21.1314C22.7049 20.6117 22.3789 20.2779 21.8641 20.274C20.6406 20.2632 19.4162 20.2623 18.1927 20.2691C17.6847 20.2721 17.3813 20.5128 17.3 21.0189C17.2452 21.3624 17.2697 21.7187 17.2589 22.0691C15.9542 21.8019 14.6456 21.5484 13.3448 21.2645C9.20059 20.3621 5.02509 19.6065 0.896579 18.6277C0.836873 18.5602 0.784997 18.4848 0.717461 18.427C0.236878 18.0111 -0.00194563 17.4913 1.19363e-05 16.8512C0.00490586 15.0033 -0.000966851 13.1563 0.00294829 11.3083C0.00588464 10.1015 0.768358 9.34294 1.98792 9.34099C4.79801 9.33609 7.6081 9.34001 10.4192 9.33903C10.5748 9.33903 10.7314 9.33218 10.887 9.32924C12.7213 9.32924 14.5555 9.32924 16.3898 9.32826C18.7976 9.32826 21.2044 9.32826 23.6122 9.32826C25.4465 9.32924 27.2807 9.32924 29.1149 9.32924Z" fill="#3BDFFD" />
            <path d="M0.92314 32.0668C0.921182 27.9118 0.919224 23.7569 0.917267 19.6019C0.917267 19.5638 0.927055 19.5246 0.931949 19.4865C1.19524 19.551 1.45658 19.6225 1.72183 19.6803C3.72834 20.1119 5.73582 20.5426 7.74331 20.9683C10.2568 21.5018 12.7694 22.0362 15.2848 22.5559C15.9406 22.691 16.5817 22.9142 17.261 22.923C17.264 23.4427 17.2688 23.9615 17.2698 24.4812C17.2718 25.367 17.3657 25.4942 18.2055 25.7389C18.2437 26.291 18.5579 26.5944 19.1031 26.5963C20.0143 26.5993 20.9266 26.568 21.8368 26.6061C22.6062 26.6384 23.5859 25.9132 23.6104 24.9853C23.6172 24.7083 23.6358 24.4146 23.7014 24.1396C23.95 23.0854 24.6636 22.5187 25.6884 22.3034C28.5728 21.6975 31.4612 21.1132 34.3418 20.4907C35.9666 20.1393 37.2977 21.167 37.2772 22.919C37.241 25.965 37.2684 29.012 37.2684 32.058C36.9835 32.0609 36.6987 32.0638 36.4139 32.0677C35.5036 32.0814 34.7959 32.6687 34.5875 33.5624C34.4974 33.948 34.5708 34.3376 34.5268 34.7222C24.8437 34.7222 15.1605 34.7222 5.47743 34.7222C5.47351 34.4756 5.47057 34.2279 5.46666 33.9813C5.44904 32.8625 4.6572 32.0736 3.5365 32.0677C2.66538 32.0629 1.79426 32.0668 0.92314 32.0668ZM2.73781 24.8042C2.73781 24.0642 2.73487 23.3233 2.73976 22.5833C2.74172 22.2936 2.61742 22.092 2.32672 22.0734C2.00176 22.0538 1.80796 22.2496 1.806 22.5726C1.79719 24.0535 1.79817 25.5334 1.80502 27.0143C1.806 27.3363 1.99002 27.5389 2.31595 27.5242C2.63112 27.5095 2.74368 27.2893 2.74074 26.9859C2.73291 26.2587 2.73781 25.5314 2.73781 24.8042Z" fill="#00FDB4" />
            <path d="M5.47605 34.7233C15.1592 34.7233 24.8423 34.7233 34.5254 34.7233C34.5264 35.3517 34.5274 35.98 34.5274 36.6084C24.8433 36.6084 15.1582 36.6084 5.47409 36.6084C5.47507 35.98 5.47605 35.3517 5.47605 34.7233Z" fill="#00FDB4" />
            <path d="M29.1141 9.32921C27.2798 9.32921 25.4456 9.32921 23.6113 9.32823C23.6113 9.0943 23.5996 8.85939 23.6123 8.62644C23.6515 7.8992 23.9226 7.59969 24.6224 7.55369C24.8759 7.53705 24.9053 7.44896 24.827 7.24244C24.5862 6.6121 24.3542 5.97882 24.1125 5.34947C23.9715 4.98144 23.6877 4.79841 23.3011 4.7896C22.9507 4.78177 22.5993 4.79939 22.2489 4.79939C20.4293 4.80037 18.6097 4.8033 16.7902 4.79743C16.384 4.79645 16.016 4.89237 15.8496 5.28584C15.5422 6.01112 15.276 6.755 14.9804 7.52237C15.139 7.53216 15.2408 7.54194 15.3416 7.54488C15.9631 7.56446 16.1814 7.7563 16.3409 8.35923C16.4271 8.68321 16.3683 9.00523 16.3908 9.32627C14.5566 9.32627 12.7224 9.32627 10.8881 9.32725C10.8999 8.9788 10.8558 8.63035 10.9488 8.2819C11.0731 7.81992 11.3119 7.59284 11.7827 7.54977C11.8346 7.54488 11.8865 7.5488 11.9383 7.54586C12.325 7.52237 12.8055 7.70051 13.0747 7.47343C13.338 7.25222 13.3938 6.78339 13.5387 6.42221C13.7902 5.79579 14.0046 5.15273 14.2972 4.54686C14.7798 3.54654 15.6039 3.01898 16.7226 3.01311C18.8936 3.00136 21.0655 3.03366 23.2365 3.00038C24.6606 2.97885 25.5963 3.86954 26.0113 4.93152C26.3138 5.70379 26.6172 6.47605 26.903 7.25516C26.9823 7.47147 27.087 7.57229 27.3248 7.55075C27.5441 7.5302 27.7663 7.54586 27.9875 7.54684C28.7451 7.55075 29.0739 7.86201 29.116 8.62644C29.1268 8.86135 29.115 9.09528 29.1141 9.32921Z" fill="#00FDB4" />
            <path d="M37.2664 32.0584C37.2654 29.0115 37.239 25.9655 37.2752 22.9195C37.2958 21.1675 35.9646 20.1397 34.3398 20.4911C31.4593 21.1136 28.5709 21.698 25.6864 22.3038C24.6626 22.5192 23.949 23.0859 23.6995 24.14C23.6349 24.4161 23.6163 24.7087 23.6084 24.9857C23.584 25.9136 22.6042 26.6379 21.8349 26.6066C20.9256 26.5684 20.0124 26.5997 19.1011 26.5968C18.555 26.5948 18.2408 26.2914 18.2036 25.7394C19.3331 25.7355 20.4636 25.7345 21.5931 25.7286C22.4133 25.7247 22.7256 25.4115 22.7275 24.602C22.7295 23.7583 22.7334 22.9136 22.7363 22.0699C24.9875 21.5981 27.2397 21.1293 29.489 20.6526C32.1562 20.0869 34.8224 19.5153 37.4886 18.9398C38.0191 18.8252 38.5427 18.6804 39.0703 18.5502C39.0752 19.2657 39.083 19.9812 39.084 20.6977C39.086 24.4856 39.086 28.2744 39.0869 32.0633C38.4801 32.0604 37.8732 32.0594 37.2664 32.0584Z" fill="#00FDB4" />
            <path d="M5.47618 34.7238C5.4752 35.3521 5.47422 35.9805 5.47422 36.6089C5.19918 36.7244 4.91142 36.6539 4.63051 36.6608C4.07162 36.6735 3.51176 36.6667 2.95189 36.6637C1.80574 36.6578 0.985517 35.9756 0.914066 34.943C0.847508 33.9858 0.83772 33.0275 0.920917 32.0703C1.79204 32.0703 2.66413 32.0654 3.53525 32.0703C4.65498 32.0761 5.4478 32.8641 5.46541 33.9838C5.46933 34.2295 5.47324 34.4761 5.47618 34.7238Z" fill="#00FDB4" />
            <path d="M37.266 32.061C37.8728 32.062 38.4797 32.0629 39.0865 32.0649C39.1619 33.1367 39.2157 34.2124 39.0425 35.2743C38.9289 35.9712 38.2595 36.5282 37.544 36.6035C36.5397 36.7092 35.5326 36.7043 34.5274 36.6104C34.5264 35.982 34.5254 35.3536 34.5244 34.7252C34.5685 34.3396 34.4951 33.951 34.5851 33.5654C34.7936 32.6718 35.5012 32.0845 36.4115 32.0708C36.6963 32.0659 36.9812 32.0639 37.266 32.061Z" fill="#00FDB4" />
            <path d="M17.2598 22.9243C16.5805 22.9155 15.9394 22.6923 15.2837 22.5572C12.7682 22.0385 10.2547 21.5031 7.74212 20.9696C5.73464 20.5439 3.72715 20.1132 1.72064 19.6816C1.45539 19.6248 1.19405 19.5533 0.930762 19.4878C0.837777 19.2049 0.890632 18.9152 0.896504 18.6284C5.02502 19.6072 9.20051 20.3638 13.3447 21.2652C14.6465 21.5481 15.9541 21.8026 17.2588 22.0698C17.2598 22.3556 17.2598 22.6394 17.2598 22.9243Z" fill="#00FDB4" />
            <path d="M16.3886 9.32938C16.3661 9.00736 16.4249 8.68534 16.3387 8.36234C16.1792 7.75941 15.9599 7.56757 15.3394 7.54799C15.2386 7.54506 15.1368 7.53527 14.9782 7.52548C15.2738 6.75714 15.54 6.01424 15.8474 5.28896C16.0147 4.89451 16.3818 4.79859 16.788 4.80055C18.6075 4.80544 20.4271 4.80348 22.2467 4.8025C22.5971 4.8025 22.9485 4.78489 23.2989 4.79272C23.6855 4.80153 23.9693 4.98456 24.1103 5.35258C24.352 5.98194 24.585 6.61521 24.8248 7.24555C24.9041 7.45207 24.8747 7.54016 24.6202 7.5568C23.9204 7.60281 23.6493 7.90231 23.6101 8.62955C23.5974 8.8625 23.6091 9.09741 23.6091 9.33134C21.2043 9.32938 18.7964 9.32938 16.3886 9.32938Z" fill="#FFFFFE" />
            <path d="M17.5585 22.9229C17.5585 22.638 17.5585 22.3542 17.5585 22.0694C17.5692 21.719 17.5448 21.3627 17.5996 21.0191C17.6808 20.5131 17.9842 20.2713 18.4922 20.2694C19.7157 20.2635 20.9402 20.2645 22.1637 20.2743C22.6785 20.2782 23.0044 20.612 23.026 21.1317C23.0387 21.4439 23.0338 21.7562 23.0367 22.0684C23.0338 22.9121 23.0299 23.7568 23.0279 24.6005C23.026 25.41 22.7137 25.7222 21.8935 25.7271C20.764 25.733 19.6335 25.7349 18.504 25.7379C17.6642 25.4941 17.5702 25.3669 17.5683 24.4811C17.5663 23.9614 17.5614 23.4426 17.5585 22.9229Z" fill="#FDFCFA" />
            <path d="M2.73666 24.8058C2.73666 25.533 2.73177 26.2602 2.73862 26.9875C2.74155 27.2909 2.62899 27.5111 2.31382 27.5258C1.98887 27.5405 1.80486 27.3379 1.8029 27.0159C1.79605 25.535 1.79507 24.055 1.80388 22.5741C1.80584 22.2521 1.99963 22.0554 2.32459 22.0749C2.61529 22.0926 2.7396 22.2952 2.73764 22.5849C2.73372 23.3249 2.73666 24.0648 2.73666 24.8058Z" fill="#00FDB4" />
        </svg>
    )
}

export default BagIcon