import React from 'react';
import { cn } from '../../../../lib/utils';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_CUSTOMER } from '../../../routes/path';
import { authStore } from '../../../contexts/AuthProvider';

const AboutUsDeatils = ({ colored, Data, groupClassName, workerData, textClassName, data, salonData, handelClickEvent }) => {
    const navigate = useNavigate();
    const { isAuthenticated,profileType } = authStore((state) => state)
    return (
        <div className={cn(`border-[1.3px] border-success-100 rounded-[10px] h-full p-0 flex items-center justify-center ${colored && "bg-light-800"}`, groupClassName, handelClickEvent === false ? "" : "cursor-pointer")}
            onClick={() => handelClickEvent === false ? "" : navigate((isAuthenticated && profileType == "") ? PATH_CUSTOMER.findSalonProfile : PATH_AUTH.findSalonProfile, {
                state: { id: data?.salon_id || data?.id || salonData.id, data: salonData || data }
            })}>
            <p className={cn('text-blue-950 text-sm text-center', textClassName)}>{Data ? Data : salonData ? salonData : ""}</p>
        </div>
    );
}

export default AboutUsDeatils;
