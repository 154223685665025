import React from 'react';
import { cn } from '../../../../lib/utils';
import ButtonPrimary from '../buttons/ButtonPrimary';

const CustomeTabsUpdated = ({ tabs, className, onTabChange, groupClassName, wrapperClassName, defaultTabId, selectedTabId }) => {
  return (
    <div className={cn("", wrapperClassName)}>
      <ul className={cn('flex items-center gap-4', groupClassName)}>
        {
          tabs?.map(({ tabId, tabName }, index) => (
            <li key={tabId}><ButtonPrimary variant={selectedTabId === tabId ? "fill" : "outline"} size={"sm"} onClick={() => onTabChange(tabId)} className={cn(`rounded-full duration-150  !text-base py-[5px] font-medium !leading-[1] ${selectedTabId === tabId ? "border-primary1 " : "border-borderColor hover:border-primary1 text-success-900"}`, className)}>{tabName}</ButtonPrimary></li>
          ))
        }
      </ul>
    </div>
  )
}

export default CustomeTabsUpdated