import React from 'react'

function ImageGalleryIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2591 28.9811C19.0011 28.9811 14.7458 28.9921 10.4878 28.9756C7.93025 28.9674 6.41934 27.44 6.41384 24.8604C6.40285 18.6822 6.40011 12.5012 6.41384 6.32293C6.41934 3.5044 7.80663 2.03744 10.6169 2.02645C19.0835 1.99074 27.5529 1.99074 36.0195 2.0292C38.6622 2.04019 39.9863 3.43846 39.9918 6.03723C40.0028 12.3528 40.0028 18.6712 39.9918 24.9868C39.9863 27.6213 38.6484 28.9674 36.0277 28.9784C31.7724 28.9921 27.5171 28.9811 23.2591 28.9811ZM8.96041 23.5198C10.4411 22.0089 11.8339 20.5667 13.2487 19.1492C16.2842 16.1081 16.5095 16.0752 19.6824 18.836C20.5065 19.553 21.034 19.5118 21.696 18.6932C23.3608 16.6328 25.0612 14.5972 26.7672 12.5699C28.1325 10.9436 29.7808 10.8859 31.2312 12.3968C31.7367 12.9242 32.1982 13.4956 32.6927 14.0341C34.4124 15.9049 35.8821 17.9981 37.7227 19.7645C38.0908 19.4679 37.9726 19.1245 37.9726 18.825C37.9809 14.6632 37.9919 10.5013 37.9726 6.34216C37.9644 4.49336 37.4727 4.03459 35.5607 4.03184C27.3743 4.0236 19.1907 4.0236 11.0043 4.03184C9.01261 4.03459 8.61428 4.44391 8.61153 6.46853C8.60329 11.6825 8.60604 16.8938 8.61428 22.1078C8.61428 22.4979 8.4989 22.9237 8.96041 23.5198ZM23.2317 26.7669C27.4869 26.7669 31.7422 26.7697 35.9975 26.7642C36.7392 26.7642 37.6155 26.7944 37.8216 25.9098C38.088 24.7643 38.2144 23.6105 37.2831 22.5584C34.8574 19.8195 32.5059 17.0174 30.1296 14.2374C29.1709 13.1165 28.9759 13.1138 28.0528 14.2181C26.0337 16.6383 24.0338 19.0805 21.9735 21.465C20.957 22.6435 20.5148 22.5776 19.4297 21.4293C18.8967 20.8661 18.2814 20.3799 17.7622 19.803C16.9792 18.9294 16.2815 18.9322 15.4573 19.7755C13.7019 21.5776 11.8888 23.3248 10.1224 25.1159C9.75707 25.4868 9.04557 25.8109 9.25435 26.3906C9.47412 26.9922 10.2186 26.7559 10.7378 26.7559C14.9052 26.7752 19.0698 26.7669 23.2317 26.7669Z" fill="currentColor" />
            <path d="M28.6456 38.8805C27.7308 38.8613 26.5578 38.4465 25.3683 38.1251C18.1297 36.1774 10.8993 34.1994 3.65513 32.2655C0.347613 31.3836 -0.545198 29.9991 0.298165 26.6476C1.17724 23.1451 2.13049 19.6617 3.05626 16.1701C3.24032 15.4779 3.48207 14.7444 4.4051 15.0081C5.30065 15.2636 5.17978 16.0548 5.00397 16.7251C4.11665 20.1315 3.17714 23.5242 2.29531 26.9333C1.67172 29.348 1.96291 29.7354 4.37762 30.3755C12.2426 32.4578 20.1021 34.5621 27.9616 36.6718C29.4175 37.0619 30.0027 36.8394 30.4807 35.4137C30.9175 34.117 31.2196 32.7737 31.596 31.4551C31.7855 30.7903 32.1289 30.1887 32.9283 30.4029C33.7525 30.6227 33.6728 31.3177 33.4997 31.9825C33.1344 33.4 32.8157 34.8313 32.4064 36.235C31.9174 37.9135 30.629 38.8668 28.6456 38.8805Z" fill="currentColor" />
            <path d="M14.9328 13.9319C12.9878 13.9209 11.3725 12.3276 11.4357 10.4815C11.4961 8.74533 13.1883 7.0641 14.9053 7.03663C16.8283 7.00366 18.5232 8.68214 18.4958 10.5886C18.4655 12.5528 16.9849 13.9429 14.9328 13.9319ZM14.8751 11.8853C15.7377 11.8743 16.3338 11.4622 16.386 10.6298C16.4437 9.73429 15.8283 9.14366 14.9959 9.08597C14.1581 9.02553 13.6966 9.66286 13.6251 10.4705C13.551 11.3441 14.0235 11.8111 14.8751 11.8853Z" fill="currentColor" />
        </svg>
    )
}

export default ImageGalleryIcon