import React from 'react'
import { withTranslation } from 'react-i18next'
import Rating from '../../../uiComponents/common/Rating'
import RowWrap from '../../../uiComponents/RowWrap'

const RatingAndReviewsCard = ({ colored, t }) => {
    return (
        <>
            <RowWrap className={`hidden xsm:grid grid-cols-3 p-2 border-[1.5px] border-borderColor rounded-2xl max-w-[500px] mx-auto [&>div:nth-child(odd)]:after:!h-[calc(100%-24px)] [&>div:nth-child(odd)]:after:!top-3 ${colored && "bg-light-800"}`} sm={2}>
                <div className="col-span-1 ">
                    <Rating starClass={"w-5 h-5 md:w-6 md:h-6"} rate={4.5} wrapperClass="border-0" selected={5} defaultColor="text-white" textClassName="font-medium" />
                </div>
                <div className="col-span-2 ps-14 text-[15px] text-blue-950">
                    <div className="grid grid-cols-2 gap-x-7 items-center">
                        <Rating starClass={"w-5 h-5 md:w-6 md:h-6"} selected={1} wrapperClass="border-0 py-0.5" defaultColor="text-white" />
                        <div className='pt-1'>{t("check_box_label.quality_price")}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-7 items-center">
                        <Rating starClass={"w-5 h-5 md:w-6 md:h-6"} selected={2} wrapperClass="border-0 py-0.5" defaultColor="text-white" />
                        <div className='pt-1'>{t("comman_text.salon")}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-7 items-center">
                        <Rating starClass={"w-5 h-5 md:w-6 md:h-6"} selected={3} wrapperClass="border-0 py-0.5" defaultColor="text-white" />
                        <div className='pt-1'>{t("check_box_label.atmosphere")}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-7 items-center">
                        <Rating starClass={"w-5 h-5 md:w-6 md:h-6"} selected={4} wrapperClass="border-0 py-0.5" defaultColor="text-white" />
                        <div className='pt-1'>{t("check_box_label.service")}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-7 items-center">
                        <Rating starClass={"w-5 h-5 md:w-6 md:h-6"} selected={5} wrapperClass="border-0 py-0.5" defaultColor="text-white" />
                        <div className='pt-1'>{t("check_box_label.workers")}</div>
                    </div>
                </div>
            </RowWrap>
            <div className={`xsm:hidden flex items-center justify-center gap-5 p-1 border border-borderColor rounded-lg ${colored && "bg-light-800"}`}>
                <div className="">
                    <Rating rate={4.5} wrapperClass="border-0" groupClass="gap-1" starClass="w-5 h-5" selected={5} defaultColor="text-white" />
                </div>
                <div className="">
                    <div className="flex gap-x-1 items-center">
                        <div className="pt-1 w-5 text-center text-sm">4</div>
                        <Rating selected={1} wrapperClass="border-0 py-0.5" starCount={1} defaultColor="text-white" starClass="w-5 h-5" />
                        <div className='pt-1 text-blue-950 text-sm'>{t("check_box_label.quality_price")}</div>
                    </div>
                    <div className="flex gap-x-1 items-center">
                        <div className="pt-1 w-5 text-center text-sm">3.4</div>
                        <Rating selected={2} wrapperClass="border-0 py-0.5" starCount={1} defaultColor="text-white" starClass="w-5 h-5" />
                        <div className='pt-1 text-blue-950 text-sm'>{t("comman_text.salon")}</div>
                    </div>
                    <div className="flex gap-x-1 items-center">
                        <div className="pt-1 w-5 text-center text-sm">3</div>
                        <Rating selected={3} wrapperClass="border-0 py-0.5" starCount={1} defaultColor="text-white" starClass="w-5 h-5" />
                        <div className='pt-1 text-blue-950 text-sm'>{t("check_box_label.atmosphere")}</div>
                    </div>
                    <div className="flex gap-x-1 items-center">
                        <div className="pt-1 w-5 text-center text-sm">5</div>
                        <Rating selected={4} wrapperClass="border-0 py-0.5" starCount={1} defaultColor="text-white" starClass="w-5 h-5" />
                        <div className='pt-1 text-blue-950 text-sm'>{t("check_box_label.service")}</div>
                    </div>
                    <div className="flex gap-x-1 items-center">
                        <div className="pt-1 w-5 text-center text-sm">4</div>
                        <Rating selected={5} wrapperClass="border-0 py-0.5" starCount={1} defaultColor="text-white" starClass="w-5 h-5" />
                        <div className='pt-1 text-blue-950 text-sm'>{t("check_box_label.workers")}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTranslation()(RatingAndReviewsCard)