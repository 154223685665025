
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Card from '../../../../uiComponents/Card';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import RowWrap from '../../../../uiComponents/RowWrap';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import PlusLineIcon from '../../../../uiComponents/icons/PlusLineIcon';
import EducationIcon from '../../../../uiComponents/icons/EducationIcon';
import EducationCard from './EducationCard';
import CustomTabs from '../../../../uiComponents/tabs/CustomTabs';
import CommonSliderWrapper from '../../../../uiComponents/common/CommonSliderWrapper';
import Http from '../../../../../../Http';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PATH_WORKER } from '../../../../../routes/path';

const EducationListCard = ({ t, error, setCount, ...props }) => {

    const navigate = useNavigate();
    const sliderRef = useRef(null);
    const [educations, setEducations] = useState([]);
    const [selectedEducationStatus, setSelectedEducationStatus] = useState(1)
    const [pending, setPending] = useState(0);
    const [approved, setApproved] = useState(0);
    const [declined, setDeclined] = useState(0);
    const { data: educationAllData } = useQuery(
        ["userEducationAllData"],
        async () => {
            const response = await Http.get(PROFILE_COMPLETE_URL.getEducation);
            const { pending, approved, declined, data } = response?.data?.data || {};
            setPending(pending);
            setApproved(approved);
            setDeclined(declined);
            return data;
        },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            retry: false,
        });


    useEffect(() => {
        if (educationAllData?.length > 0) {
            let status = selectedEducationStatus === 1 ? 0 : selectedEducationStatus === 0 ? 1 : selectedEducationStatus
            setEducations(educationAllData?.filter((item) => item.status === status));
            setCount(1)
        } else {
            setEducations([]);
        }
    }, [educationAllData])

    const handleTabChange = (key) => {
        setSelectedEducationStatus(key);
        let status = key === 1 ? 0 : key === 0 ? 1 : key
        let data = educationAllData;
        setEducations(data?.filter((item) => item.status === status));
    }
    return (
        <>
            <Card className={"sm:py-3 mb-3"}>
                <RowWrap className={"grid grid-cols-2 md:grid-cols-4"}>
                    <LabelWrap
                        labelClassName="font-semibold mb-0"
                        label={t("comman_text.education")}
                        prefixIcon={<EducationIcon className="w-6 h-6" />}
                    />
                    <div className='flex justify-center items-center w-full order-3 col-span-2 md:order-2 mt-3 md:mt-0 '>
                        {
                            educationAllData?.length > 0 ?
                                <CustomTabs tabGroupClass="w-full flex justify-center" tabListClass="border-2 rounded-[10px] border-primary1 grid-cols-3 mb-0 max-w-[453px] w-full" tabButtonClass="py-2 sm:p-2 text-sm sm:text-sm xl:text-lg rounded-lg sm:px-4 lg:px-4 !font-medium" tabsData={[
                                    {
                                        id: "1",
                                        title: `Approved (${approved})`,
                                    },
                                    {
                                        id: "0",
                                        title: `Pending (${pending})`,
                                    },
                                    {
                                        id: "2",
                                        title: `Declined (${declined})`,
                                    }
                                ]}
                                    selectedIndex={selectedEducationStatus}
                                    onChange={handleTabChange}
                                />
                                :
                                <LabelWrap
                                    labelClassName="font-semibold mb-0"
                                    label={`${t("comman_text.uploaded")} (${0})`}
                                />

                        }
                    </div>
                    <div className='flex justify-end items-center w-full order-2 md:order-3'>
                        <ButtonPrimary
                            onClick={() => navigate(PATH_WORKER.addEducation)}
                            size={"sm"} type={"button"}
                            icon={<PlusLineIcon className="w-5 h-5" />}
                            className={"text-[15px] lg:text-[15px] font-bold rounded-[5px] py-0.5 px-2.5 max-w-[82px] w-full border"} >Add</ButtonPrimary>
                    </div>
                </RowWrap>
            </Card>
            {
                educations?.length > 0 &&
                <Card className={"mb-3 sm:p-3.5"}>

                    <CommonSliderWrapper
                        ref={sliderRef}
                        defaultArrows={false}
                        // sildNoShow={true}
                        nextArrowClassName="border-[1.6px] w-9 h-9 bg-primary1 border-primary1 text-white"
                        prevArrowClassName="border-[1.6px] w-9 h-9 bg-primary1 border-primary1 text-white"
                        infinite={false}
                        slidesToShow={3}
                        xsSlidesToShow={1}
                        mdSlidesToShow={2}
                        lgSlidesToShow={2}
                        xlSlidesToShow={3}
                        SliderClassName="gap_14"
                    >
                        {
                            educations
                                ?.map((item, index) => (
                                    <EducationCard key={index} educationData={item} />
                                ))
                        }
                    </CommonSliderWrapper>
                </Card>
            }
        </>
    )
}

export default withTranslation()(EducationListCard);