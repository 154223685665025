import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Card from '../../../uiComponents/Card'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import Badge from '../../../uiComponents/common/Badge'
import FilterIcon from '../../../uiComponents/icons/FilterIcon'
import SortIcon from '../../../uiComponents/icons/SortIcon'
import RatingAndReviewsCard from './RatingAndReviewsCard'
import FindSalonProfileFilterModal from '../../../modals/FindSalonProfileFilterModal'
import SortByModal from '../../../modals/SortByModal'
import ReviewCardFull from '../../../uiComponents/common/ReviewCardFull'

const SalonRatingAndReviews = ({ t, showReportReviewBtn,salonData,handelClickEvent }) => {

    const [FindSalonProfileFilterModalOpen, setFindSalonProfileFilterModalOpen] = useState(false)
    const [SortByModalOpen, setSortByModalOpen] = useState(false)
    const [selectedSortBy, setselectedSortBy] = useState([])


    const SortByOption = [
        {
            id: 'latest',
            label: 'Latest'
        },
        {
            id: 'oldest',
            label: 'Oldest'
        },
        {
            id: "review_asc",
            label: t("comman_text.review_asc"),
        },
        {
            id: "review_desc",
            label: t("comman_text.review_des"),
        },
    ]


    const ratingAndReviews = [
        {
            id: 0,
            rating: "4.5/5",
            text: "Overall"
        },
        {
            id: 1,
            rating: "4.5/5",

            text: "Quality/price"
        },
        {
            id: 2,
            rating: "4/5",
            text: "Salon"
        },
        {
            id: 3,
            rating: "4/5",
            text: "Atmosphere"
        },
        {
            id: 4,
            rating: "4.3/5",
            text: "Service"
        },
        {
            id: 5,
            rating: "5/5",
            text: "Workers"
        },]

    return (
        <>
            <Card className="mb-2.5 md:mb-4">
                <div className="flex justify-between items-center">
                    <Badge text={t('comman_text.rating_reviews')} groupClassName="mx-auto" />
                </div>
                <RatingAndReviewsCard colored />
            </Card>
            <Card className={"mb-2.5 md:mb-4 sm:px-2.5 sm:py-[6.5px] py-[6.5px] px-2.5"}>
                <div className="flex items-center justify-between">
                    <h6 className='text-sm text-blue-950 font-medium leading-[1]'> 123 Portfolios</h6>
                    <div className="flex items-center gap-3">
                        <ButtonPrimary size={"sm"} className={"py-1 px-2 md:px-3 rounded-md "} onClick={() => setFindSalonProfileFilterModalOpen(true)}>
                            <span><FilterIcon className="w-5 h-5" /></span>
                            <span className='hidden md:block'>{t("button_label.filter")}</span>
                        </ButtonPrimary>
                        <ButtonPrimary size={"sm"} className={"py-1 px-2 md:px-3 rounded-md "} onClick={() => setSortByModalOpen(true)}>
                            <span><SortIcon className="w-5 h-5" /></span>
                            <span className='hidden md:block'>{t("comman_text.sort_by")}</span>
                        </ButtonPrimary>
                    </div>
                </div>
            </Card>

            {
                [...Array(3)].map((item, index) => (
                    <Card className={"mb-2.5 md:mb-4 bg-light-800 lg:bg-white"}>
                        <ReviewCardFull salonReviwes={true} dataList={ratingAndReviews} colored showReportReviewBtn={showReportReviewBtn} salonData={salonData} handelClickEvent={handelClickEvent}/>
                    </Card>
                ))
            }

            <FindSalonProfileFilterModal
                modalOpen={FindSalonProfileFilterModalOpen}
                setModalOpen={setFindSalonProfileFilterModalOpen}
            />

            <SortByModal
                modalOpen={SortByModalOpen}
                setModalOpen={setSortByModalOpen}
                SortByOption={SortByOption}
                selectedSortBy={selectedSortBy}
                setselectedSortBy={setselectedSortBy}

            />
        </>
    )
}

export default withTranslation()(SalonRatingAndReviews)