import React from 'react'
import { cn } from '../../../../lib/utils'
import { cva } from 'class-variance-authority'

export const checkVariance = cva(
    `flex items-start gap-2 cursor-pointer relative text-start radio-label font-normal leading-[20px] text-blue-950 
    before:border before:border-success-100 before:block before:relative before:align-middle before:cursor-pointer before:rounded before:bg-transparent peer-checked:before:bg-success-950 peer-checked:before:border-success-950 before:ease-in before:duration-150 before:flex-shrink-0 
    after:absolute after:peer-checked:after:block peer-checked:after:absolute peer-checked:after:rounded-[1.5px] peer-checked:after:border-r-2 peer-checked:after:border-b-2 peer-checked:after:border-white peer-checked:after:rotate-45 peer-checked:after:ease-in peer-checked:after:duration-150
    `,
    {
        variants: {
            size: {
                default: 'before:mt-[2px] text-sm before:w-[18px] before:h-[18px] after:mt-[2px] peer-checked:after:w-1.5 peer-checked:after:h-2.5 peer-checked:after:top-[3px] peer-checked:after:start-[6px]',
                md: 'before:mt-0.5 text-sm before:w-5 before:h-5 peer-checked:after:w-1.5 peer-checked:after:h-2.5 peer-checked:after:top-[5.5px] peer-checked:after:end-[6.5px]',
                lg: 'before:mt-[0px] text-[15px] lg:text-[17px]  before:w-[18px] before:h-[18px] md:before:w-[22px] md:before:h-[22px] peer-checked:after:w-1.5 peer-checked:after:h-2.5 md:peer-checked:after:w-2 md:peer-checked:after:h-3 peer-checked:after:top-[2.5px] peer-checked:after:end-[6px] md:peer-checked:after:top-[3px] md:peer-checked:after:end-[7px]',
                xl: 'before:mt-[0px] text-[15px] lg:text-[17px]  before:w-[18px] before:h-[18px] md:before:w-[25px] md:before:h-[25px] after:mt-[2px] peer-checked:after:w-1.5 peer-checked:after:h-2.5 md:peer-checked:after:w-2 md:peer-checked:after:h-4  peer-checked:after:top-[2px] peer-checked:after:end-[6px] md:peer-checked:after:top-[1px] md:peer-checked:after:end-[8px]',
                xxl: 'text-[15px] lg:text-[17px]  before:rounded-full before:w-7 before:h-7 md:before:w-8 md:before:h-8  peer-checked:after:w-2.5 peer-checked:after:h-4 peer-checked:after:top-[5px] peer-checked:before:bg-success-800 peer-checked:after:bg-success-800 peer-checked:before:border-success-800 md:peer-checked:after:w-[10px] md:peer-checked:after:h-[18px]  peer-checked:after:start-[10px] md:peer-checked:after:start-[12px] peer-checked:after:border-r-4 peer-checked:after:border-b-4',
            }
        },
        defaultVariants: {
            size: "default",
        }
    },
)


const InputCheckbox = ({ id, errorType, size, errorData, errorClassName, groupClassName, name, mainClassName, text, checked, value, className, mainLabel, icon, prefixIcon, suffixIcon, titleClass, ...props }) => {
    function generateUniqueId() {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    }
    const uniqueId = generateUniqueId();
    return (
        <div className={cn("meetime-form-group", groupClassName)}>
            <div className={cn("flex items-center custom-radio relative w-full", mainClassName)}>
                <input id={id ? id : uniqueId} type="checkbox" name={name ? name : "walletType"} value={value ? value : ""} checked={checked} className="sr-only radio-input peer" {...props} />
                <label htmlFor={id ? id : uniqueId} className={cn(checkVariance({ size, className }))}><span className={cn('flex items-center gap-2.5', titleClass)}>{prefixIcon}{text}{suffixIcon}</span></label>
            </div>
            {errorType && <p className={cn("error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium", errorClassName)}>{errorData}</p>}
        </div>
    )
}

export default InputCheckbox;