import React from 'react'
import { withTranslation } from 'react-i18next';
import { cn } from '../../../../lib/utils';
import MaleIcon from '../../uiComponents/icons/MaleIcon'
import FeMaleIcon from '../../uiComponents/icons/FeMaleIcon'
import BabyBoyIcon from '../icons/BabyBoyIcon';
import BabyGirlIcon from '../icons/BabyGirlIcon';

const SpecialitiesListCard = ({ services, t, listClassName, itemClassName }) => {
    return (
        <ul className={cn("time-list-card !border border-success-100 bg-light-850 rounded-[5px] !px-4 !py-2 [&>li:last-child]:border-0", listClassName)}>
            {
                services?.length ? services?.map((service,key) => {
                    return (
                        <li className={cn("py-2", services?.length - 1 ? "border-b border-success-100" : "", itemClassName)} key={key}>
                            <p className="text-sm font-normal leading-[1.5] text-blue-950 mb-0 flex items-center !gap-2 justify-between">
                                <span>{service?.category} - {service?.service_title}</span>
                                <ul className="flex items-end text-center justify-center gap-1">
                                    {service?.service_for?.includes('1') ? <li className="flex-shrink-0 leading-none"><MaleIcon className=" h-5 text-[#3BDFFD]" /></li> : <></>}
                                    {service?.service_for?.includes('2') ? <li className="flex-shrink-0 leading-none"><FeMaleIcon className=" h-5 text-[#FE7AB6]" /></li> : <></>}
                                    {service?.service_for?.includes('4') ? <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="h-3.5 text-[#3BDFFD]" /></li> : <></>}
                                    {service?.service_for?.includes('3') ? <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="h-3.5 text-[#FE7AB6]" /></li> : <></>}
                                </ul>


                            </p>
                        </li>
                    )
                }) : <li><p className="text-[15px] leading-[18px] font-normal text-blue-950 mb-0 text-center">{t("comman_text.no_data_available")}</p></li>
            }
        </ul>
    )
}

export default withTranslation()(SpecialitiesListCard)
