import React, { useRef } from 'react';
// import Slider from "react-slick"
// import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
// import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
// import Card from '../Card';
// import HeartIcon from '../icons/line-icon/HeartIcon';
// import ShareIcon from '../icons/line-icon/ShareIcon';
// import ServiceFacility from './ServiceFacility';
import CommonSliderWrapper from './CommonSliderWrapper';
// import InputRadioBox from '../inputs/InputRadioBox';
// import HairdresserIcon from '../icons/service-type-icon/HairdresserIcon';
// import HairstylingIcon from '../icons/service-type-icon/HairstylingIcon';
// import BarberIcon from '../icons/service-type-icon/BarberIcon';
// import PedicureIcon from '../icons/service-type-icon/PedicureIcon';
// import HairRemovalIcon from '../icons/service-type-icon/HairRemovalIcon';
// import WellnessSPAIcon from '../icons/service-type-icon/WellnessSPAIcon';
import { withTranslation } from 'react-i18next';
import InputCheckbox from '../inputs/InputCheckbox';
import { cn } from '../../../../lib/utils';
// import { useMutation, useQuery } from 'react-query';
// import { FRONT_API_URL } from '../../../api/axios';
// import Http from '../../../../Http';
// import { enqueueSnackbar } from 'notistack';
import SvgRender from './SvgRender';



const SalonGuidelinesSlider = ({ t, slidesToShow, selectedList, serviceTypeData, setSelectedList, itemClassName, ...props }) => {
    const sliderRef = useRef(null)
    const language = localStorage.getItem('i18nextLng') || 'dk'

    const handleSelect = (option) => {
        if (selectedList?.includes(option)) {
            setSelectedList(selectedList?.filter(item => item !== option))
        } else {
            setSelectedList([...selectedList, option]);
        }
    };

    // const { data: badgetAllData } = useQuery(
    //     ["userRefetchBudgeData"],
    //     async () => {
    //         return await Http.get(FRONT_API_URL.badgeFilter).then((res) => res.data.data);
    //     },
    //     { refetchOnWindowFocus: false }
    // );


    // const serviceTypeData = [
    //     {
    //         id: 1,
    //         name: "Hairdresser",
    //         icon: <HairdresserIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 2,
    //         name: "Hair Styling",
    //         icon: <HairstylingIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 3,
    //         name: "Barber dasxz",
    //         icon: <BarberIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 4,
    //         name: "Mani/PediCure",
    //         icon: <PedicureIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 5,
    //         name: "Hair removal",
    //         icon: <HairRemovalIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 6,
    //         name: "Wellness & SPA",
    //         icon: <WellnessSPAIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 7,
    //         name: "Hairdresser",
    //         icon: <HairdresserIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 8,
    //         name: "Hair Styling",
    //         icon: <HairstylingIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 10,
    //         name: "Barber",
    //         icon: <BarberIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 11,
    //         name: "Mani/PediCure",
    //         icon: <PedicureIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 13,
    //         name: "Hair removal",
    //         icon: <HairRemovalIcon className="h-16 w-16" />
    //     },
    //     {
    //         id: 14,
    //         name: "Wellness & SPA",
    //         icon: <WellnessSPAIcon className="h-16 w-16" />
    //     },
    // ]

    return (
        <div className='w-full'>
            {/* <p className='mb-0 w-fit justify-start bg-white p-2 py-1'>Service</p>
            <br /> */}
            {
                serviceTypeData?.length
                > 0 &&
                <CommonSliderWrapper
                    ref={sliderRef}
                    defaultArrows={false}
                    nextArrowClassName="-end-2 h-[35px] w-[35px] sm:top-10 sm:-end-10 border-[1.6px] text-success-300"
                    prevArrowClassName="-end-2 h-[35px] w-[35px] sm:top-10 sm:-end-10 border-[1.6px] text-success-300"
                    arrowButton="sm:top-20 h-[35px] w-[35px] sm:!start-auto sm:!-end-10 border-[1.6px] text-success-300 bg-white border-text-blue-950"
                    // groupClassName="sm:max-w-[calc(100%-40px)] max-w-full"
                    groupClassName="w-full sm:max-w-[calc(100%-40px)] sm:flex sm:gap-2 sm:items-center"
                    slidesToShow={1}
                    xxsSlidesToShow={2}
                    xsSlidesToShow={3}
                    smSlidesToShow={4}
                    mdSlidesToShow={4}
                    lgSlidesToShow={5}
                    xlSlidesToShow={5}
                    xxlSlidesToShow={slidesToShow}
                    arrowBtnGroupClassName={"flex flex-row sm:flex-col ps-3 mt-3 sm:mt-0"}
                    infinite={serviceTypeData?.length < 6 ? false : true}
                >
                    {serviceTypeData?.length ? (
                        serviceTypeData.map((item) => (
                            <InputCheckbox
                                key={item.id} // Place the key here
                                name="service-type"
                                id={item.id}
                                checked={selectedList.includes(item.id)}
                                groupClassName="flex-grow max-w-[165px] w-full h-full"
                                className={cn(
                                    `bg-light-800 text-success-750 rounded-[13px] lg:rounded-[15px] border-[1.5px] w-full max-w-[100px] text-center  h-full min-h-[120px] max-h-[100px] items-center gap-1 before:hidden after:hidden [&_.icon_svg]:hover:brightness-[50] peer-checked:[&_.icon_svg]:brightness-[50] peer-checked:after:hidden border-success-100 text-[15px] font-medium peer-checked:border-success-950 peer-checked:bg-success-950 peer-checked:text-white hover:border-success-950 hover:bg-success-950 hover:text-white transition-all duration-300 leading-none`,
                                    itemClassName
                                )}
                                titleClass="justify-center mx-auto"
                                text={
                                    <div className="rounded-[13px] pt-2 pb-3 flex-grow w-full max-w-[100px] text-center  h-full min-h-[120px] max-h-[100px]">
                                        {/* {item.icon} */}
                                        <SvgRender filePath={item.filter_image} iconClassName="size-16 mx-auto" />
                                        {/* <img className="icon mx-auto size-16" src={item.image}></img> */}
                                        <p className="text-sm font-medium leading-none text-center break-words mt-2 px-3">
                                            {language === "en" ? item.name : item.dk_name}
                                        </p>
                                    </div>
                                }
                                onChange={() => handleSelect(item.id)}
                            />
                        ))
                    ) : (
                        <></>
                    )}

                </CommonSliderWrapper>
            }
        </div>
    );
}

export default withTranslation()(SalonGuidelinesSlider)
