import React from 'react'

const RightArrowIcon = (props) => {
    return (
        <svg width={40} height={35} viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_463_12)">
                <path d="M38.4847 15.4608C38.8912 15.9843 39.1227 16.7241 39.1227 17.5C39.1227 18.276 38.8912 19.0157 38.4847 19.5393L27.0407 34.276C26.2412 35.3059 25.0002 35.2277 24.2692 34.1014C23.538 32.9751 23.5934 31.2273 24.3932 30.1975L32.1077 20.2631H3.15603C2.07256 20.2631 1.19421 19.026 1.19421 17.5C1.19421 15.974 2.07256 14.7368 3.15603 14.7368H32.1077L24.3932 4.80237C23.5934 3.77263 23.538 2.02486 24.2692 0.898589C25.0002 -0.227671 26.2412 -0.305777 27.0407 0.72396L38.4847 15.4608Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_463_12">
                    <rect width={40} height={35} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default RightArrowIcon