import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next';
import InputWrap from '../uiComponents/inputs/InputWrap';


const ShareModal = ({ t, copyLink, modalOpen, setModalOpen }) => {

    const handleModalClose = () => {
        setModalOpen(false);
    }

    const [tooltipTitle, setTooltipTitle] = useState(t("share_modal.button_text"))

    const handleCopyClick = (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(copyLink);
        setTooltipTitle('copied')
        setTimeout(() => { setTooltipTitle('copy') }, 2000)
    };

    return (
        <>
            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog as="div" className={`relative z-[999]`} onClose={handleModalClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-dark-950 bg-opacity-70"
                        />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto" >
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="w-full max-w-[557px]  transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                    <div className="modal-header mb-4">
                                        <h2 className="font-semibold text-xl text-center text-blue-950">{t("share_modal.title")}</h2>
                                        <button
                                            type={"button"}
                                            aria-label="button"
                                            className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                            onClick={handleModalClose}>
                                            <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='share_content mb-6'>
                                            <p className='font-500 text-center text-[15px] text-blue-950'>{t("share_modal.text")}</p>
                                        </div>
                                        <div className="copy_content mt-5 flex gap-3 flex-col  xsm:flex-row">
                                            <InputWrap
                                                size={"sm"}
                                                placeholder={t('input_filed_placeholder.first_name')}
                                                className="form-control"
                                                readOnly
                                                value={copyLink}
                                                disabled
                                                groupClassName={"w-full"}
                                            />
                                            <ButtonPrimary size={"sm"} className='btn-theme btn_copy w-auto py-1.5 md:py-1.5 font-medium min-w-[90px] text-lg lg:text-lg' onClick={handleCopyClick}>{tooltipTitle}</ButtonPrimary>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default withTranslation()(ShareModal)