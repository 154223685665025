import React from 'react'

function LocationPin3(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5711 34.7302C21.1092 34.7302 22.8366 33.9581 25.3081 31.4988C29.2346 27.6097 33.8188 20.7752 33.8188 14.341C33.8188 10.5375 32.4147 6.88985 29.9153 4.20039C27.4159 1.51093 24.026 0 20.4914 0C16.9567 0 13.5668 1.51093 11.0674 4.20039C8.56802 6.88985 7.16388 10.5375 7.16388 14.341C7.16388 24.4498 18.1062 34.5157 20.5711 34.7302ZM20.4914 5.79789C21.7476 5.79789 22.9755 6.19873 24.02 6.94971C25.0645 7.7007 25.8786 8.7681 26.3594 10.0169C26.8401 11.2658 26.9659 12.64 26.7208 13.9657C26.4757 15.2915 25.8708 16.5093 24.9825 17.4651C24.0943 18.4209 22.9625 19.0719 21.7305 19.3356C20.4984 19.5993 19.2213 19.4639 18.0607 18.9467C16.9002 18.4294 15.9082 17.5534 15.2103 16.4294C14.5124 15.3055 14.1399 13.9841 14.1399 12.6324C14.1416 10.8204 14.8114 9.08307 16.0021 7.80176C17.1929 6.52045 18.8074 5.79978 20.4914 5.79789Z" fill="#00FDB4" />
            <path d="M20.4835 40C29.8579 40 34.967 37.4049 34.967 36.068C34.967 35.1744 32.5752 33.5158 27.2469 32.6436C27.1952 32.6326 27.1442 32.6183 27.0941 32.6007L27.094 32.6008C26.9479 32.758 26.8017 32.9153 26.6556 33.0583C25.4863 34.2164 22.5829 36.8544 20.5898 36.8544H20.4038C18.9288 36.7329 16.4839 35.1029 13.9859 32.565C13.9024 32.6108 13.8127 32.6422 13.7201 32.6579C8.39177 33.5158 6 35.1744 6 36.068C6 37.4049 11.1091 40 20.4835 40Z" fill="#3BDFFD" />
        </svg>
    )
}

export default LocationPin3