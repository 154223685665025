import React, { useState } from 'react'
import ImageIcon from '../../../uiComponents/icons/ImageIcon'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import InputCheckbox from '../../../uiComponents/inputs/InputCheckbox'
import QuntityHandler from '../../../uiComponents/common/QuntityHandler'
import MaleIcon from '../../../uiComponents/icons/MaleIcon'
import FeMaleIcon from '../../../uiComponents/icons/FeMaleIcon'
import ImageIconSingle from '../../../uiComponents/icons/ImageIconSingle'
import ServiceMoreInfoModal from '../../../modals/ServiceMoreInfoModal'
import { withTranslation } from 'react-i18next'
import Badge from '../../../uiComponents/common/Badge'
import BabyBoyIcon from '../../../uiComponents/icons/BabyBoyIcon'
import BabyGirlIcon from '../../../uiComponents/icons/BabyGirlIcon'

const ServiceSelectableCard = ({ t, id, setCount, data, handleChangePlusCounter, handleCheckBoxChange, count, selectedIds, handleChangeMinusCounter }) => {
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false)

    const disacountAvailable = false

    const iconMap = {
        "1": <MaleIcon className="w-3.5 xl:w-4 text-[#3BDFFD]" />,
        "2": <FeMaleIcon className="w-3.5 xl:w-4 text-blue-950/10" />,
        "3": <BabyGirlIcon className="w-4 xl:w-5 text-[#FE7AB6]" />,
        "4": <BabyBoyIcon className="w-4 xl:w-5 text-[#3BDFFD]" />,
    };

    return (
        <>

            {/* ---------------------------------------------------large screen------------------------ */}
            <div className='border border-borderColor p-[10px] lg:flex hidden items-center gap-3 rounded-2xl mb-2.5'>
                <div className="flex-shrink-0">
                    <InputCheckbox
                        id={id}
                        size={"xxl"}
                        className={"before:rounded-full  before:w-[30px]  before:h-[30px]"}
                        value={id}
                        // checked={selectedCard.includes(id)}
                        onChange={(e) => handleCheckBoxChange(data, count[data?.id] ? count[data?.id]?.count : 0)} checked={selectedIds?.includes(data?.id)}
                    />
                </div>
                <div className="grid grid-cols-12 flex-grow">
                    <div className="col-span-6 xl:col-span-7 pe-2">
                        <h2 className='text-[15px] font-medium text-success-900 mb-2'>{data?.title}</h2>
                        <h4 className='text-blue-950/80 text-sm line-clamp-2'>{data?.description ? data?.description : "No description"}</h4>
                    </div>
                    <div className="col-span-6 xl:col-span-5">
                        <div className="grid grid-cols-3">
                            <div className="border-e border-borderColor last:border-e-0 first:border-s flex items-center justify-center flex-col gap-1 px-2">
                                {data?.images.length > 0 && <ImageIcon className="w-6 h-6 xl:w-8 xl:h-8 text-blue-950/40" />}
                                <span className='text-sm text-blue-950/40 font-medium'>{data?.duration} min.</span>
                                <ButtonPrimary
                                    onClick={() => setMoreInfoModalOpen(true)}
                                    size={"sm"}
                                    className={"!text-sm py-0.5 !leading-[1] rounded-md px-2"}
                                // count={count}
                                // handleChangePlusCounter={handleChangePlusCounter}
                                // handleChangeMinusCounter
                                >
                                    {t('front_footer.more_info')}
                                </ButtonPrimary>
                            </div>
                            <div className="border-e border-borderColor last:border-e-0 first:border-s px-2 flex items-center justify-center">
                                <div className="flex items-end justify-end">
                                    {data?.service_for?.map((service,index) => (
                                        <div key={index}>
                                            {iconMap[service] || null}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="border-e  border-borderColor last:border-e-0 first:border-s  flex items-center flex-col gap-1 px-2 justify-between">
                            {!Object.keys(count).length && !count[data?.id]?.discountMinusData && ( <Badge text={"-20%"} groupClassName={"border-danger-950 text-[15px] bg-danger-950 mb-0 text-white py-[0.5px] px-2 mb-0"} />)}
                                {/* < p className={`text-base font-500 py-0 ${disacountAvailable ? "text-danger-950 " : "text-blue-950"}`}>{data?.price} Dkk.</p> */}
                                {!count[data.id]?.discountMinusData ?
                                    <p className='text-base font-500 py-0 ${disacountAvailable ? "text-danger-950 " : "text-blue-950"'>{data.price} Dkk.</p>
                                    :
                                    <>
                                        < Badge text={`${t("comman_text.offer")}-${count[data.id]?.discount}${count[data.id]?.symbol == 1 ? '%' : "Dkk."}`} groupClassName={"border-danger-950 text-[15px] bg-danger-950 mb-0 text-white py-[0.5px] px-2 mb-0"} />
                                        {/* <div className='offer_lable mb-1'><p className='fs-14 fw-500 text-danger-950'>{t("comman_text.offer")} - {count[data.id]?.discount}  {count[data.id]?.symbol == 1 ? '%' : "Dkk."}</p></div> */}
                                        <p className='text-danger fs-15 fw-800 price_text mb-1 text-danger-950'>
                                            {count[data.id]?.discountMinusData} Dkk.
                                        </p>
                                    </>
                                }

                                <QuntityHandler count={count} setCount={setCount} data={data} handleChangePlusCounter={handleChangePlusCounter} handleChangeMinusCounter={handleChangeMinusCounter} />
                                {/* {disacountAvailable && <p className='text-12 text-blue-950/40'>Original {count[data?.id]?.originaldbPrice ? count[data?.id]?.originaldbPrice : count[data?.id]?.price} kr.</p>} */}
                                {
                                    count[data.id]?.discountMinusData ? <p className='text-12 text-blue-950/40'>Original {count[data.id]?.originaldbPrice ? count[data.id]?.originaldbPrice : count[data.id]?.price} Dkk.</p> : ""
                                }

                                {/* {count == {} && !count[data?.id]?.discountMinusData && < Badge text={"-20%"} groupClassName={"border-danger-950 text-[15px] bg-danger-950 mb-0 text-white py-[0.5px] px-2 mb-0"} />}
                                {/* < p className={`text-base font-500 py-0 ${disacountAvailable ? "text-danger-950 " : "text-blue-950"}`}>{data?.price} Dkk.</p>
                                <QuntityHandler count={count} setCount={setCount} data={data} handleChangePlusCounter={handleChangePlusCounter} handleChangeMinusCounter={handleChangeMinusCounter} />
                                {disacountAvailable && <p className='text-12 text-blue-950/40'>Original {count[data?.id]?.originaldbPrice ? count[data?.id]?.originaldbPrice : count[data?.id]?.price} kr.</p>} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div >


            {/* ---------------------------------------------------small screen------------------------ */}
            {id === data?.category_id && (
                <div className='border border-borderColor p-[10px] lg:hidden rounded-[10px] mb-2.5'>
                    <div className="grid grid-cols-12">
                        <div className="pe-2 border-e border-borderColor col-span-9 flex items-center flex-col gap-2">
                            <div className="">
                                <h2 className='text-[15px] font-semibold text-success-900 mb-2'>{data?.title}</h2>
                                <h4 className='text-blue-950/80 text-12 sm:text-sm line-clamp-2'>{data?.description ? data?.description : "No description"}</h4>
                            </div>
                            <div className="flex items-center gap-2 justify-between w-full">
                                <div className="flex items-center gap-1">
                                    <ImageIconSingle className="w-5 h-5 sm:w-6 sm:h-6 xl:w-8 xl:h-8" />
                                    <span className='text-12 sm:text-sm text-blue-950/80 font-medium'>{data?.duration} min.</span>
                                </div>
                                <div className='flex justify-end items-center gap-2'>
                                    {disacountAvailable && <span className='text-12 sm:text-sm text-danger-950 font-medium'>-30%</span>}
                                    <ButtonPrimary onClick={() => setMoreInfoModalOpen(true)} size={"sm"} className={"!text-12 xsm:!text-sm py-0.5 !leading-[1] rounded-md px-1 xsm:px-2"}>
                                        {t('front_footer.more_info')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3 flex items-center flex-col justify-between ">
                            <div className="mb-1">
                                <InputCheckbox
                                    id={id}
                                    size={"lg"}
                                    className={"before:rounded-full before:h-[23px] before:w-[23px] peer-checked:after:top-[5px] peer-checked:after:start-[9px] peer-checked:before:bg-success-800 gap-0"}
                                    value={id}
                                    onChange={(e) => handleCheckBoxChange(data, count[data?.id] ? count[data?.id]?.count : 0)}
                                    checked={selectedIds?.includes(data?.id)}
                                />
                            </div>
                            {disacountAvailable && <Badge text={"-20%"} className={"text-12"} groupClassName={"border-danger-950 sm:px-2 text-12 bg-danger-950 mb-0 text-white py-0 px-2 mb-0"} />}
                            {/* <p className={`text-[13px] font-500 text-danger-950 py-0 ${disacountAvailable ? "text-danger-950 " : "text-blue-950"}`}>{data?.price} Dkk</p> */}
                            {!count[data.id]?.discountMinusData ?
                                <p className='price_text mb-1 text_black80'>{data.price} Dkk.</p>
                                // <p className={`text-[13px] font-500 text-danger-950 py-0 ${disacountAvailable ? "text-danger-950 " : "text-blue-950"}`}>{data?.price} Dkk</p>
                                :
                                <>
                                    < Badge text={`${t("comman_text.offer")}-${count[data.id]?.discount}${count[data.id]?.symbol == 1 ? '%' : "Dkk."}`} groupClassName={"border-danger-950 text-[15px] bg-danger-950 mb-0 text-white py-[0.5px] px-2 sm:px-2 mb-0"} />
                                    {/* <div className='offer_lable mb-1 d-block'><p className='fs-14 fw-700 '>{t("comman_text.offer")} - {count[data.id]?.discount}  {count[data.id]?.symbol == 1 ? '%' : "Dkk."}</p></div> */}
                                    <p className='text-danger fs-15 fw-600 price_text mb-1 text-danger-950'>
                                        {count[data.id]?.discountMinusData} Dkk.
                                    </p>
                                </>
                            }
                            <QuntityHandler className={"w-[60px] md:w-[80px]"} count={count} setCount={setCount} data={data} handleChangePlusCounter={handleChangePlusCounter} handleChangeMinusCounter={handleChangeMinusCounter} />
                            {/* {disacountAvailable && <p className='text-12 text-blue-950/40'>Original {count[data?.id]?.originaldbPrice ? count[data?.id]?.originaldbPrice : count[data?.id]?.price} Kr.</p>} */}
                            {
                                count[data.id]?.discountMinusData ? <p className='text-12 text-blue-950/40'>Original {count[data.id]?.originaldbPrice ? count[data.id]?.originaldbPrice : count[data.id]?.price} Dkk.</p> : ""
                            }
                            {/* <p className={`text-[13px] font-500 text-danger-950 py-0 ${disacountAvailable ? "text-danger-950 " : "text-blue-950"}`}>{data?.price} Dkk</p>
                            <QuntityHandler className={"w-[60px] md:w-[80px]"} count={count} setCount={setCount} data={data} handleChangePlusCounter={handleChangePlusCounter} handleChangeMinusCounter={handleChangeMinusCounter} />
                            {disacountAvailable && <p className='text-12 text-blue-950/40'>Original {count[data?.id]?.originaldbPrice ? count[data?.id]?.originaldbPrice : count[data?.id]?.price} Kr.</p>} */}
                        </div>
                    </div>
                </div>
            )}

            {moreInfoModalOpen && <ServiceMoreInfoModal
                data={data}
                modalOpen={moreInfoModalOpen}
                setModalOpen={setMoreInfoModalOpen}
                count={count}
                setCount={setCount}
                handleChangePlusCounter={handleChangePlusCounter}
                handleChangeMinusCounter={handleChangeMinusCounter}
            />}
        </>
    )
}

export default withTranslation()(ServiceSelectableCard)