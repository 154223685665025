import React from 'react'

function RoundFillCheckIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM30.4257 14.6126C31.1328 13.764 31.0182 12.5029 30.1696 11.7958C29.3211 11.0886 28.06 11.2033 27.3528 12.0518L17.6438 23.7027L12.5257 18.5847C11.7447 17.8036 10.4783 17.8036 9.69728 18.5847C8.91623 19.3657 8.91623 20.632 9.69728 21.4131L14.8153 26.5311L14.875 26.5908C15.1827 26.8989 15.5431 27.2598 15.893 27.5243C16.3116 27.8409 16.9684 28.2209 17.8469 28.181C18.7254 28.1412 19.3451 27.7033 19.7334 27.3501C20.0578 27.055 20.3841 26.663 20.6626 26.3283L20.6627 26.3283L20.7166 26.2634L30.4257 14.6126Z" fill="currentColor" />
        </svg>
    )
}

export default RoundFillCheckIcon