import { Badge, Calendar } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon'
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon'
import dayjs from "dayjs"
import moment from "moment"
import "dayjs/locale/zh-cn"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utcPlugin from 'dayjs/plugin/utc'
import ButtonPrimary from '../buttons/ButtonPrimary'
import Table from '../table/Table'
import THead from '../table/THead'
import TR from '../table/TR'
import TH from '../table/TH'
import TBody from '../table/TBody'
import TD from '../table/TD'
import InputCheckbox from '../inputs/InputCheckbox'
import TrashIcon from '../icons/TrashIcon'
import { cloneDeep } from 'lodash'
import { authStore } from '../../../contexts/AuthProvider'
dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);

const AvailabilityCalendar = ({ t, salonUnAvailability, setSalonUnAvailability, setRemovedUnAvailability, salonUnAvailabilityData, location, props }) => {
    const [selectedUnvDate, setSelectedUnvDate] = useState([]);
    const [currentDate, setCurrentDate] = useState(null);
    const [currentMonthUnAvilability, setCurrentMonthUnAvilability] = useState([]);
    const serviceLocation = location ?? authStore((state) => state.service_location);

    useEffect(() => {
        if (salonUnAvailabilityData && salonUnAvailabilityData?.length > 0) {

           let data =  salonUnAvailabilityData?.filter((item)=>{
                if(serviceLocation == 1){
                    return item.salon == true
                }
                else if(serviceLocation == 2){
                    return item.onTheGo == true
                }else{
                    return true
                }
            })

            const getUnAvailibility = data.map(item => {
                return {
                    date: item.holiday_date,
                    salon: serviceLocation === 1 ||  serviceLocation === 3 ?  item.salon : false,
                    onTheGo: serviceLocation === 2 ||  serviceLocation === 3 ?  item.onTheGo : false,
                    id: item.id
                }
            })
            const receivedDates = data.map(item => {
                return {
                    date: dayjs(item.date),
                    salon: serviceLocation === 1 ||  serviceLocation === 3 ? item.salon: false,
                    onTheGo: serviceLocation === 2 ||  serviceLocation === 3 ? item.onTheGo : false,
                }
            })
            setSalonUnAvailability(getUnAvailibility)
            setSelectedUnvDate(receivedDates)
        }
    }, [salonUnAvailabilityData])

    const onSelect = (date, source) => {
        if (source !== "date") return

        const selectDate = dayjs(date).format("YYYY-MM-DD");
        const exitsDate = salonUnAvailability.filter((data) => data.date === selectDate)?.length > 0;
        const dates = {
            date: dayjs(date).format("YYYY-MM-DD"),
            salon: serviceLocation === 1 ||  serviceLocation === 3 ? true  : false,
            onTheGo:serviceLocation === 2 ||  serviceLocation === 3 ? true  : false,
            id: "",
        };
        if (exitsDate) {
            setSalonUnAvailability((prev) =>
                prev.filter((data) => data.date !== selectDate)
            );
            setSalonUnAvailability((prev) =>
                prev.filter((data) => {
                    if (data.date === selectDate) {
                        if (data.id) {
                            setRemovedUnAvailability((prev) => [...prev, data.id]);
                        }
                        return false;
                    }
                    return true;
                })
            );
        } else {
            setSalonUnAvailability((prev) => [...prev, dates]);
        }
        const unvDates = {
            date: date,
            salon: serviceLocation === 1 ||  serviceLocation === 3 ? true  : false,
            onTheGo:serviceLocation === 2 ||  serviceLocation === 3 ? true  : false,
        };
        const index = selectedUnvDate.filter((data) => data.date?.format("YYYY-MM-DD") === selectDate)?.length > 0;
        if (index) {
            setSelectedUnvDate((prev) =>
                prev.filter((data) => data.date?.format("YYYY-MM-DD") !== selectDate)
            );
        } else {
            setSelectedUnvDate((prev) => [...prev, unvDates]);
        }
    };
    const customRenderDate = useCallback(
        (current) => {
            if (selectedUnvDate.some((e) => current.isSame(e.date, 'day'))) {
                let both = selectedUnvDate.some((e) => current.isSame(e.date, 'day') && e.salon && e.onTheGo);
                let salon = selectedUnvDate.some((e) => current.isSame(e.date, 'day') && e.salon);
                let onTheGo = selectedUnvDate.some((e) => current.isSame(e.date, 'day') && e.onTheGo);
                return <div className="custom_selected_date">
                    <div className="custom_selected_date_container relative z-[1] flex flex-col text-white before:w-[26px] before:h-[26px] before:absolute  before:top-1/2 before:start-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:-z-[1] before:bg-success-950 before:rounded">
                        <span className="text-sm !leading-[17px]">{current.format("DD")}</span>
                        {both ?
                            <div className="unavailability_badge leading-[1.5] flex items-center gap-1 absolute start-1/2 top-full -translate-x-1/2">
                                <Badge color="#5bcaf8" />
                                <Badge color="#fd8c8c" />
                            </div>
                            : salon ?
                                <div className="unavailability_badge leading-[1.5] flex items-center gap-1 absolute start-1/2 top-full -translate-x-1/2">
                                    <Badge color="#fd8c8c" />
                                </div>
                                : onTheGo ?
                                    <div className="unavailability_badge leading-[1.5] flex items-center gap-1 absolute start-1/2 top-full -translate-x-1/2">
                                        <Badge color="#5bcaf8" />
                                    </div>
                                    : <></>
                        }
                    </div>
                </div>;
            }

            return <div className="text-sm font-medium text-blue-950 !leading-[17px]">{current.format("DD")}</div>;
        },
        [selectedUnvDate]
    );

    const onChangeSalon = (changeVal) => {
        const cloneSelectData = cloneDeep(salonUnAvailability);
        const cloneSelectUnvData = cloneDeep(selectedUnvDate);
        const selectedData = cloneSelectData.filter(
            (data) => data.date === changeVal.date
        )[0];
        const selectedUnvData = cloneSelectUnvData.filter((data) => data.date?.format("YYYY-MM-DD") === changeVal.date)[0];
        selectedData.salon = !changeVal.salon;
        selectedUnvData.salon = !changeVal.salon;
        setSalonUnAvailability(cloneSelectData);
        setSelectedUnvDate(cloneSelectUnvData)
    };
    const onChangeOnTheGo = (changeVal) => {
        const cloneSelectData = cloneDeep(salonUnAvailability);
        const cloneSelectUnvData = cloneDeep(selectedUnvDate);
        const selectedData = cloneSelectData.filter(
            (data) => data.date === changeVal.date
        )[0];
        const selectedUnvData = cloneSelectUnvData.filter((data) => data.date?.format("YYYY-MM-DD") === changeVal.date)[0];
        selectedData.onTheGo = !changeVal.onTheGo;
        selectedUnvData.onTheGo = !changeVal.onTheGo;
        setSalonUnAvailability(cloneSelectData);
        setSelectedUnvDate(cloneSelectUnvData)
    };
    const handleDeleteUnavailabiliti = (deleteDate) => {
        if (deleteDate.id) {
            setRemovedUnAvailability((prev) => [...prev, deleteDate.id])
        }
        setSalonUnAvailability((prev) =>
            prev.filter((data) => data.date !== deleteDate.date)
        );
        setSelectedUnvDate((prev) =>
            prev.filter((data) => data.date?.format("YYYY-MM-DD") !== deleteDate.date)
        );
    };
    useEffect(() => {
        if (currentDate) {
            let newcurrentDate = salonUnAvailability.filter(data => moment(data?.date).isSame(`${currentDate.year()}-${currentDate.month() + 1}`, 'month'))
            setCurrentMonthUnAvilability(newcurrentDate);
        }
    }, [currentDate, salonUnAvailability])

    return (
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-6 xl:gap-10 pt-3.5">
            <div className="salon-card xl:col-span-3">
                <div className='shadow-120 rounded-[10px] px-3.5 py-4'>
                    <Calendar
                        className='font-primary'
                        fullscreen={false}
                        {...(selectedUnvDate?.length > 0 && { defaultValue: dayjs(selectedUnvDate[0]?.date) })}
                        onSelect={(date, { source }) => onSelect(date, source)}
                        fullCellRender={customRenderDate}
                        disabledDate={(date) => {
                            if (date.endOf("d").valueOf() < dayjs()) {
                                return true;
                            }
                            return false;
                        }}
                        headerRender={({
                            value,
                            type,
                            onChange,
                            onTypeChange,
                        }) => {
                            const start = 0;
                            const end = 12;
                            const monthOptions = [];
                            let current = value.clone();
                            const localeData = value.localeData();
                            const months = [];

                            for (let i = 0; i < 12; i++) {
                                current = current.month(i);
                                months.push(localeData.monthsShort(current));
                            }
                            for (let i = start; i < end; i++) {
                                monthOptions.push(months[i]);
                            }

                            const month = value.month();

                            const cMonth = months.filter(
                                (item, ind) => ind === month
                            );

                            let currentMonths = cMonth;
                            const handlerPrevClick = () => {
                                const now = value.clone().month(month - 1);
                                onChange(now);
                            };
                            const handlerNextClick = () => {
                                const now = value.clone().month(month + 1);
                                onChange(now);
                            };

                            setCurrentDate(value)
                            return (
                                <div className="flex items-center justify-center gap-6 lg:gap-9 pb-2.5 border-b-[1.3px] border-success-100">
                                    <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2" onClick={() => handlerPrevClick()}>
                                        <LeftSmallArrowIcon className="w-3 h-3" />
                                    </ButtonPrimary>
                                    <h6 className="mb-0 text-[15px] font-semibold text-blue-950">
                                        {currentMonths[0]}{" "}
                                    </h6>
                                    <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2" onClick={() => handlerNextClick()}>
                                        <RightSmallArrowIcon className="w-3 h-3" />
                                    </ButtonPrimary>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
            <div className="salon-card xl:col-span-2">
                <div className="selected_date_table overflow-x-auto">
                    <Table className={"text-[15px] text-blue-950 border-separate border border-spacing-0 border-success-100 rounded-[10px] min-w-[480px]"}>
                        <THead>
                            <TR className='bg-success-100/10'>
                                <TH className={"font-medium py-1.5 px-3 md:px-5 text-start"}><span>{t("form_input_label.unavailability")}</span></TH>
                                {serviceLocation === 1 || serviceLocation === 3 ? 
                                <TH className={"font-medium py-1.5 px-3 md:px-5"}><span className='before:w-1.5 before:h-1.5 before:rounded-full before:block flex items-center justify-center gap-1 before:bg-danger-900/70'>{t("check_box_label.salon")}</span></TH> 
                                : ''}
                                {serviceLocation === 2 || serviceLocation === 3 ?
                                 <TH className={"font-medium py-1.5 px-3 md:px-5"}><span className='before:w-1.5 before:h-1.5 before:rounded-full before:block flex items-center justify-center gap-1 before:bg-blue-850/70'>{t("check_box_label.on_the_go")}</span></TH>
                                   : ''} 
                                <TH className={"font-medium py-1.5 px-3 md:px-5 text-start"}></TH>
                            </TR>
                        </THead>
                        <TBody>
                            {
                                currentMonthUnAvilability?.length > 0 ?
                                    currentMonthUnAvilability.map((data, index) => (
                                        <TR key={index}>
                                            <TD className={"px-3 py-2 md:py-2 md:px-5"}><p className="text-blue-950 font-normal text-[15px]">{data?.date ? dayjs(data?.date).format("D MMMM YYYY") : "no date"}</p></TD>
                                            {serviceLocation === 1 || serviceLocation === 3 ?
                                            <TD className={"px-3 py-2 md:py-2 md:px-5"}>
                                                <InputCheckbox
                                                    checked={data?.salon}
                                                    mainClassName={"justify-center"}
                                                    id={"salon" + index}
                                                    onChange={() => onChangeSalon(data)}
                                                    disabled={!data?.onTheGo}
                                                    size={"xl"}
                                                />
                                            </TD>
                                             : ''} 
                                            {serviceLocation === 2 || serviceLocation === 3 ?
                                            <TD className={"px-3 py-2 md:py-2 md:px-5"}>
                                                <InputCheckbox
                                                    mainClassName={"justify-center"}
                                                    id={"onTheGo" + index}
                                                    checked={data?.onTheGo}
                                                    onChange={() => onChangeOnTheGo(data)}
                                                    disabled={!data?.salon}
                                                    size={"xl"}
                                                />
                                            </TD>
                                              : ''} 
                                            <TD className={"px-3 py-2 md:py-2 md:px-5"}><ButtonPrimary onClick={() => handleDeleteUnavailabiliti(data)} size={"sm"} className={"p-1 md:p-1 border rounded-[5px] ms-auto"} icon={<TrashIcon className="w-5 h-5" />} type="button" /></TD>
                                        </TR>
                                    ))
                                    : <></>
                            }

                        </TBody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(AvailabilityCalendar)