import React from 'react'

function LocationPin2(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.8481 39.195C19.5047 39.6941 20.3916 39.6941 21.0482 39.195C24.6555 36.4523 35.8963 27.0487 35.8963 16.3636C35.8963 12.0237 34.216 7.86157 31.2252 4.7928C28.2343 1.72402 24.1778 0 19.9481 0C15.7184 0 11.662 1.72402 8.6711 4.7928C5.68024 7.86157 4 12.0237 4 16.3636C4 27.0487 15.2408 36.4523 18.8481 39.195ZM25.2642 16.3636C25.2642 19.3761 22.8841 21.8182 19.9481 21.8182C17.0122 21.8182 14.6321 19.3761 14.6321 16.3636C14.6321 13.3512 17.0122 10.9091 19.9481 10.9091C22.8841 10.9091 25.2642 13.3512 25.2642 16.3636Z" fill="#00FDB4" />
            <path d="M19.9379 22.996C23.7962 22.996 26.9185 19.859 26.9185 15.996C26.9185 12.1331 23.7962 8.99603 19.9379 8.99603C16.0796 8.99603 12.9574 12.1331 12.9574 15.996C12.9574 19.859 16.0796 22.996 19.9379 22.996Z" fill="#3BDFFD" stroke="white" strokeWidth={3} />
        </svg>
    )
}

export default LocationPin2