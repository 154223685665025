import React from 'react'

function UserLineIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_576_2)">
                <path d="M4.10424 31.2353C4.16908 30.6441 4.2015 30.3486 4.23722 30.1445C4.79935 26.934 7.40196 24.7677 10.6612 24.7976C10.8683 24.7995 11.2112 24.8245 11.897 24.8749C14.3835 25.0574 17.1846 25.2068 19.6893 25.2068C22.3035 25.2068 25.2025 25.0441 27.7229 24.8508C28.3874 24.7998 28.7197 24.7744 28.9238 24.7716C32.0113 24.7304 34.5181 26.6557 35.2747 29.6492C35.3247 29.8472 35.3773 30.1292 35.4825 30.6931C35.5946 31.2939 35.6507 31.5944 35.6771 31.838C36.073 35.4681 33.3841 38.7049 29.7428 38.9814C29.4986 39 29.1929 39 28.5817 39H19.6893H11.0639C10.5113 39 10.235 39 10.0087 38.984C6.52688 38.7393 3.8687 35.7737 4.00502 32.286C4.01387 32.0594 4.04399 31.7846 4.10424 31.2353Z" stroke="currentColor" strokeWidth={3} />
                <path d="M11.7017 8.96223C11.7017 4.56481 15.2665 1 19.664 1C24.0614 1 27.6262 4.56481 27.6262 8.96224V10.0997C27.6262 13.8689 24.5706 16.9245 20.8013 16.9245H18.5264C14.7572 16.9245 11.7017 13.8689 11.7017 10.0997V8.96223Z" stroke="currentColor" strokeWidth={3} />
            </g>
            <defs>
                <clipPath id="clip0_576_2">
                    <rect width={40} height={40} fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default UserLineIcon