import React from 'react'

const ContactSupportIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.3909 17.6207C35.3909 13.743 33.8739 10.0242 31.1734 7.28229C28.4729 4.54039 24.81 3 20.991 3C17.1719 3 13.5092 4.54039 10.8087 7.28229C8.10815 10.0242 6.591 13.743 6.591 17.6207" stroke="#0DEFDA" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.3999 30.4133V31.3271C35.3999 32.2965 35.0206 33.2262 34.3455 33.9117C33.6703 34.5972 32.7547 34.9822 31.8 34.9822H25.5M3 25.731V22.3024C3.00012 21.4874 3.26857 20.6958 3.76267 20.0533C4.25677 19.4108 4.94818 18.9546 5.72699 18.7569L8.85898 17.9601C9.01813 17.9199 9.18423 17.9169 9.34466 17.9516C9.50507 17.9862 9.65561 18.0575 9.78486 18.16C9.91412 18.2625 10.0187 18.3936 10.0906 18.5432C10.1625 18.6928 10.1999 18.8572 10.2 19.0237V29.0078C10.2003 29.1747 10.163 29.3395 10.0911 29.4895C10.0192 29.6395 9.91451 29.771 9.78502 29.8737C9.65552 29.9765 9.50462 30.0479 9.34384 30.0824C9.18305 30.1171 9.01661 30.1139 8.85719 30.0733L5.72519 29.2784C4.94671 29.0803 4.2557 28.6238 3.76196 27.9815C3.2682 27.3392 3.00002 26.5476 3 25.7329V25.731ZM39 25.731V22.3024C38.9998 21.4874 38.7315 20.6958 38.2373 20.0533C37.7432 19.4108 37.0519 18.9546 36.2729 18.7569L33.1409 17.9601C32.9817 17.9199 32.8157 17.9169 32.6553 17.9516C32.495 17.9862 32.3443 18.0575 32.215 18.16C32.0858 18.2625 31.9812 18.3936 31.9093 18.5432C31.8375 18.6928 31.8 18.8572 31.8 19.0237V29.0078C31.7998 29.1745 31.8369 29.339 31.9088 29.489C31.9805 29.6388 32.0851 29.7702 32.2143 29.8728C32.3436 29.9756 32.4943 30.047 32.6548 30.0817C32.8155 30.1165 32.9817 30.1137 33.1409 30.0733L36.2729 29.2784C37.0519 29.0807 37.7432 28.6243 38.2373 27.982C38.7315 27.3396 38.9998 26.548 39 25.7329V25.731Z" stroke="currentcolor" strokeWidth="2.5" />
            <path d="M23.6955 37.7253H18.2955C17.5794 37.7253 16.8926 37.4366 16.3863 36.9225C15.88 36.4084 15.5955 35.711 15.5955 34.984C15.5955 34.2569 15.88 33.5597 16.3863 33.0456C16.8926 32.5315 17.5794 32.2426 18.2955 32.2426H23.6955C24.4116 32.2426 25.0983 32.5315 25.6047 33.0456C26.111 33.5597 26.3955 34.2569 26.3955 34.984C26.3955 35.711 26.111 36.4084 25.6047 36.9225C25.0983 37.4366 24.4116 37.7253 23.6955 37.7253Z" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ContactSupportIcon