import React from 'react'

const RegisterIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3431 2H14C10.2287 2 8.34315 2 7.17157 3.17157C6 4.34314 6 6.22876 6 9.99995V30C6 33.7712 6 35.6569 7.17157 36.8284C8.34315 38 10.2287 38 14 38H26C29.7713 38 31.6569 38 32.8285 36.8284C34 35.6569 34 33.7712 34 30V13.6569C34 12.8394 34 12.4305 33.8477 12.063C33.6955 11.6955 33.4065 11.4065 32.8285 10.8284L25.1715 3.17157C24.5935 2.59351 24.3045 2.30448 23.937 2.15224C23.5694 2 23.1606 2 22.3431 2Z" stroke="#00FDB4" strokeWidth="2.9" />
            <path d="M13.8322 22.0571H25.8322" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M14.0036 30.0018H22.0035" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M22.0071 2V9.99995C22.0071 11.8857 22.0071 12.8284 22.593 13.4143C23.1787 14 24.1216 14 26.0072 14H34.0071" stroke="#00FDB4" strokeWidth="2.3" />
        </svg>
    )
}

export default RegisterIcon