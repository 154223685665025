import React from 'react';

const StarIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_527_119)">
                <path d="M38.4739 13.8511H25.3436L21.3518 1.0187C21.2493 0.719816 21.0643 0.461934 20.8219 0.279782C20.5792 0.0976459 20.2906 0 19.995 0C19.6993 0 19.4106 0.0976459 19.1681 0.279782C18.9255 0.461934 18.7405 0.719816 18.6381 1.0187L14.649 13.8511H1.42774C1.04952 13.8526 0.687187 14.0152 0.419741 14.3034C0.152294 14.5916 0.00142081 14.9821 1.21433e-05 15.3897C-0.000377143 15.4768 0.00859456 15.5639 0.0267486 15.6489C0.0458763 15.8664 0.109508 16.0766 0.213067 16.2645C0.316624 16.4523 0.457531 16.6129 0.625646 16.7351L11.4165 24.9318L7.27503 37.8968C7.17803 38.2041 7.17337 38.5365 7.26167 38.8469C7.34998 39.1571 7.52679 39.4296 7.76698 39.6255C7.9897 39.8405 8.27115 39.9719 8.56907 40C8.89059 39.9755 9.1996 39.8559 9.46208 39.6542L19.9962 31.5641L30.5305 39.6542C30.7909 39.8598 31.101 39.98 31.4234 40C31.7233 39.9802 32.0072 39.8476 32.2256 39.6255C32.4681 39.4316 32.6466 39.1592 32.7351 38.8482C32.8236 38.5371 32.8175 38.2038 32.7175 37.8968L28.576 24.9318L39.2707 16.6602L39.538 16.421C39.7555 16.2141 39.9063 15.9378 39.9684 15.6323C40.0305 15.3269 40.0006 15.0081 39.8832 14.722C39.7659 14.436 39.5671 14.1977 39.3156 14.0417C39.0641 13.8856 38.7731 13.8199 38.4846 13.854L38.4739 13.8511Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_527_119">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default StarIcon;
