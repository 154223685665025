import React from 'react'

const AlertIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.00035 20.0052C0.937101 9.5803 9.54902 1 20.0056 1C30.4517 1.01054 39.0109 9.60138 38.9898 20.0369C38.9687 30.4618 30.4095 39.0106 19.974 39C9.54902 38.9894 0.937101 30.4408 1.00035 20.0052ZM22.8516 11.7623C22.841 11.7623 22.841 11.7623 22.8306 11.7623C22.8306 11.6674 22.8306 11.5831 22.8306 11.4882C22.8094 9.69625 21.1967 8.37863 19.4365 8.71595C18.0766 8.97947 17.1279 10.0968 17.1384 11.4777C17.149 12.9007 17.3388 14.3132 17.4546 15.7257C17.6234 17.7601 17.771 19.7944 17.9502 21.8183C18.045 22.9567 18.8777 23.7579 19.9634 23.7789C21.0281 23.7999 21.8819 23.0094 22.0189 21.8604C22.0611 21.4705 22.0821 21.0699 22.1139 20.6799C22.2297 19.278 22.3351 17.8759 22.4616 16.474C22.5881 14.9035 22.7252 13.3329 22.8516 11.7623ZM19.9528 26.3509C18.6247 26.3615 17.4652 27.542 17.4758 28.8807C17.4864 30.2299 18.6353 31.3684 19.9844 31.3789C21.3443 31.3789 22.5143 30.2088 22.5038 28.8491C22.4722 27.4788 21.3232 26.3403 19.9528 26.3509Z" fill="currentcolor" />
        </svg>
    )
}

export default AlertIcon