import { Listbox, Transition } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { cn } from '../../../../lib/utils'
import BottomArrowIcon from '../icons/BottomArrowIcon'
import InputWithIcon from './InputWithIcon'
import SearchIcon from '../icons/bottombar-icon/SearchIcon'
import { cva } from 'class-variance-authority'

const selectVariance = cva(
    "w-full text-start outline-0 cursor-default bg-light-800 border-[1.5px] border-success-100 rounded-lg text-[15px] font-medium text-success-300 flex items-center",
    {
        variants: {
            size: {
                default: 'px-4 py-1 h-[45px] pe-8',
                sm: 'px-3.5 h-[40px] pe-8',
            }
        },
        defaultVariants: {
            size: "default",
        }
    },
)

const SelectDropdown = ({ selectedOption, categorySelectItems, placeholder, size, data, searchBox, groupClassName, className, disabledList, dropMenuWrapperClass, buttonIconClass, selectedData, setSelectedData, buttonClass, buttonTextClass, prefixIcon, dropArrowIcon, buttonText, dropMenuClass, dropItemClass, dropTextClass, ...props }) => {
    const [selectedItem, setSelectedItem] = useState(selectedData);
    const language = localStorage.getItem('i18nextLng') || 'dk';

    const listItems = Array.isArray(data) && data.length > 0
        ? data.map(item => ({
            id: item.id,
            text: language === 'en' ? item.name ? item.name : item.code : item.dk_name
        }))
        : [{ text: "Data not Found" }];


    useEffect(() => {
        setSelectedItem(selectedData);
    }, [selectedData]);

    useEffect(() => {
        if (selectedData === null && data?.length > 0) {
            setSelectedData({ id: data[0].id, text: language === 'en' ? data?.[0].name : data?.[0].dk_name });
        }
    }, [selectedData, data]);

    const handleSelectChange = (id) => {
        const selectedItem = listItems.find((item, ind) => (
            item.id === id
        ))
        setSelectedData ? setSelectedData(selectedItem) : setSelectedItem(selectedItem)
    }

    return (
        <Listbox value={selectedOption} onChange={(e) => handleSelectChange(e)} className={cn(groupClassName)} disabled={props.disabled ?? false}>
            <div className={cn("category-dropdown relative ", className)}>
                <Listbox.Button
                    aria-label="button"
                    role="button"
                    disabled={disabledList ? true : false}
                    className={cn(selectVariance({ size }), buttonClass)}>
                    <div className="flex items-center text-start gap-2 justify-start">
                        {prefixIcon ? prefixIcon : <></>}
                        <span className={cn("flex-grow", buttonTextClass)}>{selectedData ? selectedData?.text : selectedItem ? selectedItem.text : placeholder}</span>
                    </div>
                    <span className="pointer-events-none absolute inset-y-0 end-3 flex items-center">
                        {dropArrowIcon ? dropArrowIcon : <BottomArrowIcon className="h-3 w-3 md:h-4 md:w-4 text-success-300/90" />}
                    </span>
                </Listbox.Button>
                <Transition
                    as={"div"}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className={cn("absolute min-w-[200px] max-h-60 w-full overflow-y-auto theme-scrollbar rounded-md text-base shadow-110 bg-white z-[99]", dropMenuWrapperClass)}>
                    <Listbox.Options onClick={(e) => e.stopPropagation()} className={cn("px-3 py-2", dropMenuClass)}>
                        {
                            searchBox && <InputWithIcon className="h-9" size={"sm"} suffixIcon={<SearchIcon className="w-4 h-4" />} />
                        }
                        {listItems.length && listItems.map((item, ind) =>
                            <Listbox.Option
                                key={"index" + ind}
                                value={item.id}
                                className={cn("relative cursor-default select-none py-1 px-3 transition-all duration-300 rounded-lg text-[15px] font-medium group hover:bg-success-900", dropItemClass)}>
                                <p className={cn("text-blue-950 group-hover:text-white transition-all duration-300", dropTextClass)}>{item.text}</p>
                            </Listbox.Option>
                        )}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default SelectDropdown