import React from 'react'
import { cn } from '../../../../lib/utils'
import { withTranslation } from "react-i18next";

const FileUploadButton = ({ t,id, onChange, labelTitleClass, FileText, className, icon, labelClassName, ...props }) => {
    return (
        <label className={cn("bg-primary1 rounded-lg h-10 flex items-center p-2.5 cursor-pointer", labelClassName)}>
            <div className={cn("flex items-center justify-center gap-1.5 text-white", className)}>
                {icon ? icon : <></>}
                <h6 className={cn("text-sm font-bold leading-4", labelTitleClass)}>
                    {FileText ? FileText : <>{t('form_input_label.upload_profile_photo')}</>}
                </h6>
            </div>
            <input id={id || "dropzone-file"} type="file" className="hidden"  onChange={onChange} {...props} multiple />
        </label>
    )
}

export default withTranslation()(FileUploadButton)