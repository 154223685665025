import { Dialog, Transition } from '@headlessui/react';
import { FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as Yup from "yup";
import Http from '../../../Http';
import { PROFILE_COMPLETE_URL } from '../../api/axios';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import InputAutocompleteWrap from '../uiComponents/inputs/InputAutocompleteWrap';
import LocationPin1 from '../uiComponents/icons/LocationPin1';

const RequestForNewOnTheGoCityModal = ({ t, modalOpen, setModalOpen, workerProfileData, salonDataId }) => {
  const [workFromAddressLat,setWorkFromAddressLat] = useState('');
  const [address, setAddress] = useState('');
  const [workFromAddressLong,setWorkFromAddressLong] = useState('');

  let cityRequestValidationSchema = Yup.object().shape({
    address: Yup.string()
    .required(t("validation_message.address_required"))
    .test('valid-location', 'Invalid address. Please select a valid address from the dropdown.', function () {
      const { work_from_address_lat, work_from_address_long, address } = this.parent;
      if (!work_from_address_lat && !work_from_address_long && address) {
        return this.createError({ message: 'Please select a proper value from the dropdown.' });
      }
      return true;
    }),
  });
  const { mutateAsync: workerCityRequest } = useMutation(
    async (datas) => {
      try {
        const { data } = await Http.post(PROFILE_COMPLETE_URL.workerCityRequest, datas);
        if (data) {
          setModalOpen(false);
          setAddress("");  
          setWorkFromAddressLat("");
          setWorkFromAddressLong("");
          enqueueSnackbar(t('snackbar_message.on_the_go_area_is_requested'), {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }

      }
    }
  );

  const handleClose = () => {
    setModalOpen(false);
    setAddress("");  
    setWorkFromAddressLat("");
    setWorkFromAddressLong("");
  }

  const handleAddressChange = (city, location) => {
    console.log(city,location);
    setAddress(city);  
    setWorkFromAddressLat(location?.lat());
    setWorkFromAddressLong(location?.lng());
    setFieldValue("address", city);
    setFieldValue("work_from_address_lat", location?.lat());
    setFieldValue("work_from_address_long", location?.lng());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: address ?? '',
      work_from_address_lat:  workFromAddressLat ?? '',
      work_from_address_long: workFromAddressLong ?? '',
    },
    validationSchema: cityRequestValidationSchema,
    onSubmit: async (values) => {
      try {
        const address = values.address;
        const salon_id = salonDataId;
        const work_from_address_lat = values.work_from_address_lat;
        const work_from_address_long = values.work_from_address_long;
        const data = { address, salon_id,work_from_address_lat,work_from_address_long };
        await workerCityRequest(data);
      } catch (error) {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else {
          enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
  });

  const {
    errors,
    handleSubmit,
    touched,
    getFieldProps,
    resetForm,
    setFieldValue
  } = formik;


  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Transition appear show={modalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[99]" onClose={handleClose}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <form id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95">
                  <Dialog.Panel className="w-full max-w-[800px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                    <div className="modal-header pe-7 mb-6">
                      <h2 className="font-semibold text-xl text-center text-blue-950">{t("on_the_go_city_request_modal.main_title")}</h2>
                      <button
                        type={"button"}
                        aria-label="button"
                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                        onClick={handleClose}>
                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                      </button>
                    </div>
                    <div className="modal-body">
                      <InputAutocompleteWrap
                        label={t("on_the_go_city_request_modal.title1")}
                        labelIcon={<LocationPin1 className="w-5 h-5" />}
                        placeholder={t('form_input_label.salon_address')}
                        value={address}
                        groupClassName={"w-full mb-4"}
                        tooltipText={t("on_the_go_city_request_modal.tooltip")}
                        handleChange= {handleAddressChange} 
                        // onChange={(e) => handleAddressChange(e.target.value, location)}
                        errorType={Boolean(touched.address && errors.address) && "error"}
                        errorData={touched.address && errors.address}
                      />
                      <h5 className='text-center text-base text-blue-950 mb-4'>{t("on_the_go_city_request_modal.title2")}</h5>
                      <div className='w-full flex flex-col sm:flex-row justify-between gap-x-5 gap-y-3'>
                        <ButtonPrimary size="sm" type="button" onClick={handleClose} className="w-full bg-danger-950 hover:bg-danger-900 hover:border-danger-900 border-danger-950">{t("on_the_go_city_request_modal.cancel")}</ButtonPrimary>
                        <ButtonPrimary size="sm" type="submit" className="w-full" >{t("on_the_go_city_request_modal.send_request")}</ButtonPrimary>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </form>
            </div>
          </Dialog>
        </Transition>
      </form>
    </FormikProvider>
  )
}

export default withTranslation()(RequestForNewOnTheGoCityModal);