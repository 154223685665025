import React from 'react'

function SalonChairIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6414 34.4001V36.2439C25.6414 36.5251 25.4924 36.8063 25.2541 36.9313C24.2113 37.4938 23.079 37.7751 21.917 37.7751C20.7848 37.7751 19.6228 37.4938 18.58 36.9313C18.3416 36.8063 18.1926 36.5251 18.1926 36.2439V34.4001C16.1666 34.9001 15.0344 35.7439 15.0344 36.8376C15.0344 38.7751 18.6098 39.6813 21.917 39.6813C25.2243 39.6813 28.7997 38.8063 28.7997 36.8376C28.7997 35.7439 27.6973 34.9001 25.6414 34.4001ZM16.5241 27.3064V26.2751H11.3398V27.3064C11.3398 27.7126 11.6675 28.0564 12.0548 28.0564H15.7494C16.1964 28.0564 16.5241 27.7126 16.5241 27.3064Z" fill="#3BDFFD" />
            <path d="M24.1649 35.8106V35.0606V26.3106H19.6361V35.0606V35.8106C21.096 36.4669 22.7348 36.4669 24.1649 35.8106ZM31.4647 19.2481H30.0644L27.502 11.6856C27.4126 11.3731 27.1147 11.1856 26.8167 11.1856H13.4089L12.0681 2.68554C11.8596 1.18554 10.489 0.154287 9.05881 0.373038C7.62865 0.591788 6.64541 2.02929 6.85398 3.52929L9.50574 20.1231V23.0606C9.50574 23.9981 10.2506 24.7793 11.1445 24.7793H32.5374C32.9247 24.7793 33.2524 24.4356 33.2524 24.0293V21.1856C33.2822 20.1231 32.448 19.2481 31.4647 19.2481ZM14.6901 19.0918L13.6771 12.6856H26.3102L28.515 19.2481H14.8689C14.7795 19.2481 14.6901 19.1856 14.6901 19.0918Z" fill="#00FDB4" />
        </svg>
    )
}

export default SalonChairIcon