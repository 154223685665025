import React from 'react';

const ChairWithManIcon = (props) => {
    return (
        <svg width={31} height={29} viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="15.5164" cy="14.4907" rx="15.0945" ry="14.4907" fill="#5AE6FF" />
            <g clipPath="url(#clip0_9_2152)">
                <path d="M18.1383 18.6049L18.143 18.6079L18.1477 18.6107C18.3948 18.759 18.5124 18.9387 18.537 19.1507L18.5394 19.1723L18.5443 19.1935C18.6001 19.4345 18.5448 19.6731 18.4057 19.8601L18.4001 19.8676L18.3949 19.8754C17.2797 21.5367 15.4332 22.5001 13.3834 22.5001C10.0543 22.5001 7.37109 19.902 7.37109 16.7437C7.37109 15.2055 8.01715 13.7352 9.20763 12.6249C9.42388 12.4498 9.66271 12.3655 9.93795 12.3655C10.1997 12.3655 10.4681 12.4782 10.6443 12.6417C10.8231 12.8466 10.9051 13.0675 10.9051 13.3179C10.9051 13.5143 10.7942 13.7716 10.6137 13.9448C9.84634 14.6815 9.38746 15.6856 9.38746 16.7437C9.38746 18.9013 11.2162 20.6347 13.4244 20.6347C14.776 20.6347 16.0489 19.986 16.7813 18.8875L16.7836 18.884C17.0724 18.4404 17.693 18.3199 18.1383 18.6049Z" fill="white" />
                <path d="M22.1611 15.4516L23.047 18.6407H24.5223C25.0929 18.6407 25.5591 19.099 25.5948 19.5887C25.5889 20.1364 25.0981 20.5955 24.5223 20.5955H22.2253C21.7501 20.5955 21.3471 20.2938 21.223 19.8469L20.3371 16.6578H15.3343C14.8278 16.6578 14.419 16.3264 14.2918 15.8724L12.817 10.17C12.6949 9.63296 13.0405 9.06552 13.5968 8.94686C14.1897 8.82036 14.7789 9.17656 14.8975 9.68898L15.1645 10.7653H19.19C19.804 10.7653 20.2626 11.224 20.2626 11.7821C20.2626 12.3342 19.7693 12.7989 19.19 12.7989H15.656L16.1519 14.703H21.1588C21.6341 14.703 22.037 15.0047 22.1611 15.4516Z" fill="white" />
                <path d="M14.4791 6.19056C14.4791 6.86675 13.8975 7.44362 13.1604 7.44362C12.4234 7.44362 11.8417 6.86675 11.8417 6.19056C11.8417 5.51438 12.4234 4.9375 13.1604 4.9375C13.8975 4.9375 14.4791 5.51438 14.4791 6.19056Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_9_2152">
                    <rect width="19.6885" height="18.9009" fill="white" transform="translate(6.71875 3.78906)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ChairWithManIcon;
