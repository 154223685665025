"use client";
import React, { useState } from "react";
import { Provider, Root, Trigger, Content, Portal, Arrow, TooltipArrow } from "@radix-ui/react-tooltip";
import { cn } from "../../../lib/utils";
import InfoFIllIcon from "./icons/InfoFIllIcon";

const Tooltip = ({ alwaysOpen, title, children, position, tooltipIcon, tooltipIconClass, tooltipContentClass, tooltipButtonClass, ...props }) => {
    const [open, setOpen] = useState(false);

    return (
        <Provider>
            <Root open={alwaysOpen || open} onOpenChange={setOpen}>
                <Trigger asChild>
                    <span
                        {...props}
                        className={cn(`IconButton block leading-none`, tooltipButtonClass)}
                        onMouseLeave={() => setOpen(false)}
                        onClick={() => setOpen(true)}>
                        {tooltipIcon ? tooltipIcon : <InfoFIllIcon className={cn("h-5 w-5 text-success-950", tooltipIconClass)} currentColor />}
                    </span>
                </Trigger>
                <Portal>
                    <Content className={cn("TooltipContent bg-white rounded max-w-[250px] px-3 py-1.5 shadow-110 z-[999]", tooltipContentClass)} sideOffset={5}>
                        <p className="flex-shrink-0 block text-sm font-medium text-black/80 font-primary">{title ?? ""}</p>
                        <TooltipArrow className="TooltipArrow" />
                    </Content>
                </Portal>
            </Root>
        </Provider>
    );
};

export default Tooltip;
