import React from 'react'
import DataNotFound from '../DataNotFound';
import { cn } from '../../../../lib/utils';
import TR from './TR';
import TD from './TD';

const NoDataFound = ({className, colSpan, ...props}) => {
    return (
        <TR className={cn(className)}>
            <TD colSpan={colSpan}>
                <DataNotFound {...props} />
            </TD>
        </TR>
    )
}
export default NoDataFound;