import React from 'react'

const LinkIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1244_23)">
                <path d="M37.1464 10.182C37.0127 12.3456 36.292 14.2854 34.7478 15.8516C32.2657 18.3674 29.7763 20.8762 27.2344 23.3301C24.1005 26.3579 19.2196 26.5291 16.0596 23.7943C14.7278 22.6399 14.4557 21.276 15.3316 20.1478C16.2147 19.0125 17.7065 18.9458 19.1361 19.9812C20.7495 21.1475 22.4919 21.0666 23.8857 19.698C26.2939 17.3345 28.6854 14.9543 31.0626 12.5574C32.4612 11.1484 32.4923 9.05855 31.1868 7.7566C29.8621 6.43797 27.7953 6.44512 26.3657 7.81611C25.0767 9.05142 23.8071 10.3105 22.5635 11.5934C21.9978 12.1766 21.4274 12.3432 20.6422 12.06C19.7089 11.722 18.7256 11.5506 17.7208 11.5363C17.3126 11.5292 16.9069 11.4602 16.747 11.0079C16.6086 10.6104 16.8424 10.3153 17.1002 10.0606C19.3079 7.8899 21.4035 5.58825 23.764 3.59604C26.3704 1.39436 29.4588 1.20395 32.4899 2.68204C35.5473 4.17204 36.9866 6.81166 37.1464 10.182Z" stroke="currentcolor" strokeWidth="1.8" />
                <path d="M1.90375 28.6882C1.86556 26.3531 2.77253 24.4087 4.35493 22.7901C6.79418 20.2909 9.26924 17.825 11.7777 15.3925C14.89 12.372 19.6874 12.2221 22.9572 14.9831C24.2819 16.1018 24.5468 17.4989 23.6421 18.6271C22.7042 19.7958 21.382 19.8791 19.9475 18.8604C18.0501 17.5108 16.5774 17.5941 14.9497 19.1984C12.6584 21.4548 10.3815 23.7256 8.11644 26.0056C6.53404 27.5981 6.40754 29.6021 7.76084 30.9637C9.21197 32.4228 11.2693 32.2967 12.9281 30.647C14.1096 29.4712 15.3101 28.3145 16.4677 27.1148C16.9999 26.5651 17.5369 26.3771 18.2744 26.6602C19.1694 27.003 20.1098 27.1649 21.0741 27.1625C21.5156 27.1602 21.9787 27.1887 22.2078 27.6552C22.4512 28.1527 22.1028 28.4858 21.7949 28.7929C19.7733 30.8136 17.8305 32.9225 15.6944 34.8148C13.0928 37.1164 10.0521 37.6804 6.82521 36.1976C3.64606 34.7268 2.03502 32.1679 1.90375 28.6882Z" stroke="currentcolor" strokeWidth="1.8" />
            </g>
            <defs>
                <clipPath id="clip0_1244_23">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LinkIcon