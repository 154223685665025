import { Disclosure, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import BottomArrowIcon from '../../../uiComponents/icons/BottomArrowIcon'
import CheckboxList from '../../../uiComponents/common/CheckboxList'
import SvgRender from '../../../uiComponents/common/SvgRender'

const ServiceAccordianList = ({ serviceTypeData, subCategoryData, setCategoryIds, setserviceTypeIds, serviceTypeIds, setServiceTypeChoose, serviceList, categoryChoose, setCategoryChoose, SalonDetailsFilterModalOpen, serviceTypeChoose, serviceChoose }) => {
    const [disclosureState, setDisclosureState] = useState(null);

    const handleDisclosureChange = (id) => {
        setDisclosureState((prevState) => (prevState === id ? null : id));
        setServiceTypeChoose([id]);
    };

    return (
        <div className=''>
            <ul className='flex flex-col gap-[13px]'>
                {serviceTypeData?.map(({ id, name, dk_name, image_name, image, services }, index) => (
                    <li key={index} className='w-full'>
                        <Disclosure as="div" className="border border-borderColor w-full py-1.5 ps-2 pe-4 rounded-lg">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex items-center justify-between gap-3 w-full" onClick={() => handleDisclosureChange(id)}>
                                        <SvgRender filePath={image} iconClassName={"size-8 flex-shrink-0"} />
                                        <h6 className='text-sm text-success-900 font-normal overflow-x-hidden'>{localStorage.getItem('i18nextLng') == "en" ? name : dk_name}</h6>
                                        <span><BottomArrowIcon className={`w-3 h-3 text-success-900 transition-all duration-100 ${open && "rotate-180"}`} /></span>
                                    </Disclosure.Button>
                                    <Transition
                                        show={id === disclosureState}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Disclosure.Panel className="mt-3 pt-2 text-sm text-gray-500 border-t border-borderColor">
                                            <CheckboxList
                                                groupClassName={"flex-col gap-1"}
                                                inputLableClassName={"w-full flex items-center text-center justify-center border-[1.5] peer-checked:bg-[#A2FFF7] peer-checked:text-success-900 peer-checked:font-[600] py-[4.1px] peer-checked:border-borderColor"}
                                                inputGroupClassName={"w-full"}
                                                itemClassName="w-full"
                                                subCategoryData={subCategoryData}
                                                serviceList={serviceList}
                                                setCategoryChoose={setCategoryChoose}
                                                categoryChoose={categoryChoose}
                                                SalonDetailsFilterModalOpen={SalonDetailsFilterModalOpen}
                                                serviceTypeChoose={serviceTypeChoose}
                                                serviceChoose={serviceChoose}
                                                setserviceTypeIds={setserviceTypeIds}
                                                setCategoryIds={setCategoryIds}
                                                checkedList={serviceTypeIds}
                                                setCheckedList={setserviceTypeIds}
                                            />
                                        </Disclosure.Panel>
                                    </Transition>
                                </>
                            )}
                        </Disclosure>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ServiceAccordianList;