import React from 'react'

const DashBoardIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.8117 19.9167C36.4847 21.0698 35.7626 21.8005 34.5614 22.0114C34.3898 22.0418 34.212 22.0431 34.0368 22.0431C30.3822 22.0445 26.7282 22.0466 23.0736 22.0438C21.4113 22.0424 20.2692 20.8991 20.2686 19.2351C20.2665 14.1516 20.2679 9.06873 20.2679 3.98517C20.2679 2.35369 20.8628 1.51898 22.3957 1C26.5215 1 30.6467 1 34.7725 1C35.8152 1.31644 36.4938 1.99716 36.8117 3.03933C36.8117 8.66507 36.8117 14.2908 36.8117 19.9167ZM22.6138 11.4955C22.6138 14.0152 22.6138 16.5355 22.6138 19.0551C22.6138 19.6655 22.6454 19.6979 23.2439 19.6986C26.7831 19.6993 30.3232 19.6993 33.8624 19.6986C34.4644 19.6986 34.5362 19.6283 34.5362 19.0376C34.5369 14.0095 34.5369 8.98153 34.5362 3.95352C34.5362 3.36845 34.4446 3.27561 33.8701 3.27561C30.3422 3.27491 26.8148 3.27491 23.2867 3.27561C22.6855 3.27561 22.6138 3.34594 22.6138 3.93594C22.6138 6.45558 22.6138 8.97591 22.6138 11.4955Z" fill="#00FDB4" stroke="#00FDB4" strokeWidth="0.06" />
            <path d="M0.808594 18.0832C1.13629 16.9314 1.85851 16.1993 3.05889 15.9876C3.23048 15.9574 3.40839 15.9567 3.58349 15.9567C7.23811 15.9553 10.892 15.9539 14.5467 15.956C16.2091 15.9574 17.351 17.1001 17.3519 18.764C17.3539 23.8468 17.3526 28.9304 17.3526 34.0132C17.3526 35.644 16.7597 36.4752 15.2246 36.9998C11.0988 36.9998 6.9737 36.9998 2.84792 36.9998C1.80716 36.6813 1.12504 36.0034 0.808594 34.9604C0.808594 29.3347 0.808594 23.7089 0.808594 18.0832ZM15.0066 26.5085C15.0066 23.9889 15.0066 21.4693 15.0066 18.9495C15.0066 18.3343 14.9756 18.3019 14.3807 18.3019C10.8295 18.3013 7.2789 18.3013 3.72766 18.3019C3.15944 18.3019 3.08421 18.3793 3.08421 18.9565C3.08351 23.9959 3.08351 29.0352 3.08421 34.0751C3.08421 34.6215 3.18758 34.7242 3.74664 34.7242C7.28593 34.7249 10.8252 34.7249 14.3645 34.7242C14.9299 34.7242 15.0066 34.6455 15.0066 34.0674C15.0066 31.5478 15.0066 29.0281 15.0066 26.5085Z" fill="#00FDB4" stroke="#00FDB4" strokeWidth="0.06" />
            <path d="M22.3969 37C21.2472 36.6703 20.5122 35.9516 20.3013 34.7497C20.2711 34.5781 20.2703 34.4003 20.2696 34.2251C20.2675 32.0705 20.2662 29.9159 20.2689 27.7611C20.2711 26.1001 21.4138 24.9581 23.0776 24.9574C26.6612 24.9547 30.2441 24.9567 33.8278 24.9567C35.4571 24.9567 36.2792 25.5433 36.8122 27.0846C36.8122 29.7098 36.8122 32.3356 36.8122 34.9608C36.495 36.0029 35.815 36.6821 34.7728 37C30.6477 37 26.5227 37 22.3969 37ZM28.5557 27.3027C26.7625 27.3027 24.97 27.302 23.1767 27.3034C22.6767 27.304 22.6162 27.3603 22.6162 27.8385C22.6142 29.9481 22.6142 32.0571 22.6162 34.1668C22.6171 34.6196 22.7211 34.723 23.181 34.7237C26.7666 34.7259 30.353 34.7259 33.9389 34.7237C34.4205 34.7237 34.5365 34.6021 34.5372 34.1169C34.5386 32.0424 34.5386 29.9685 34.5372 27.894C34.5365 27.3983 34.4402 27.304 33.9353 27.3034C32.1421 27.302 30.3489 27.3027 28.5557 27.3027Z" fill="currentcolor" stroke="white" strokeWidth="0.06" />
            <path d="M15.2246 1C16.3793 1.32488 17.1064 2.0499 17.3195 3.2489C17.3497 3.42048 17.351 3.5984 17.351 3.7735C17.3533 5.92815 17.3546 8.08281 17.3519 10.2375C17.3497 11.8992 16.207 13.0412 14.5439 13.0426C10.9603 13.0454 7.37736 13.0433 3.79375 13.0433C2.16581 13.0433 1.33812 12.4533 0.808594 10.9154C0.808594 8.29027 0.808594 5.66444 0.808594 3.03933C1.12575 1.99646 1.80575 1.31715 2.84792 1C6.9737 1 11.0988 1 15.2246 1ZM9.05382 10.6974C10.8351 10.6974 12.617 10.6981 14.3983 10.6967C14.9616 10.6967 15.0059 10.6538 15.0059 10.1017C15.0073 8.03922 15.0066 5.97598 15.0059 3.91343C15.0059 3.36 14.9236 3.27561 14.3807 3.27561C10.8295 3.27491 7.2782 3.27491 3.72696 3.27561C3.18969 3.27561 3.08421 3.37829 3.08421 3.9057C3.08281 5.95698 3.08281 8.00757 3.08421 10.0589C3.08421 10.6137 3.16579 10.6967 3.70937 10.6967C5.49062 10.6981 7.27257 10.6974 9.05382 10.6974Z" fill="currentcolor" stroke="white" strokeWidth="0.06" />
        </svg>
    )
}

export default DashBoardIcon