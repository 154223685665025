import React from 'react'

const HairRemovalIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.3515 25.873C2.37393 26.1308 2.19447 26.3652 1.9477 26.3886C1.70094 26.412 1.47661 26.2245 1.45417 25.9667C1.45417 25.9433 0.377386 16.0294 4.50508 12.6311C4.14615 12.0451 1.27471 8.31865 1.27471 8.29522C0.669014 4.87341 1.11767 2.64689 2.10473 1.38129C2.6207 0.701619 3.29369 0.30319 4.03399 0.115694C4.75185 -0.0718026 5.53701 -0.0249286 6.32217 0.209442C8.09438 0.77193 9.8666 2.31877 10.6293 4.38123C11.1677 5.76402 11.8407 8.03741 12.6034 10.7092C12.3567 10.6858 12.0875 10.7327 11.8631 10.8967C10.9434 11.5061 10.248 12.4201 9.84417 13.4748C9.4628 14.5295 9.3955 15.7013 9.68714 16.7794L10.405 19.6153C10.7191 20.8106 11.4145 21.8418 12.3791 22.5684C13.3437 23.2949 14.5103 23.623 15.6992 23.5293C15.9684 23.5058 16.1927 23.3887 16.3722 23.2246C17.4266 26.3417 18.4136 28.6854 19.1315 29.1776C24.5827 32.7166 27.2971 35.529 28.1272 37.3103C28.4637 38.0134 28.531 38.5759 28.3964 39.0446C28.2169 39.5368 27.8355 39.8649 27.2298 39.9586C26.2652 40.1461 24.65 39.7008 22.7432 38.529C21.4421 37.7321 19.9166 37.2868 18.2566 36.7946C16.8209 36.3728 15.3179 35.9275 13.7251 35.2009C12.0875 34.4744 12.3342 33.0447 12.8951 31.7322C13.0297 31.3807 13.1867 31.076 13.3213 30.7948C13.5232 30.3729 13.7027 30.0213 13.7475 29.8338C10.8985 26.8105 5.96323 16.0997 4.81915 13.5685C1.36444 16.8497 2.32907 25.8495 2.3515 25.873ZM16.0581 22.2402C16.0581 22.4981 15.8787 22.7324 15.6319 22.7559C14.6 22.8262 13.6129 22.5215 12.8053 21.9356C12.0202 21.3496 11.4145 20.459 11.1229 19.4044L10.405 16.5919C10.1582 15.6076 10.2255 14.6232 10.5396 13.756C10.8761 12.8654 11.4818 12.0686 12.2669 11.553C12.4688 11.4123 12.738 11.4827 12.8726 11.6936C13.0521 11.9748 16.0357 21.9824 16.0581 22.2402Z" fill="#3BDFFD" />
            <path d="M28.4171 10.3327V6.95482L25.0954 5.96857C24.5054 5.79598 24.1995 5.30285 24.1339 4.78507C24.1121 4.58782 24.1339 4.39058 24.1776 4.19333C24.2213 3.99608 24.3087 3.82349 24.3961 3.6509L24.3961 3.65089C24.6802 3.23174 25.161 2.96052 25.751 3.13311C28.1548 3.84814 31.5202 4.58783 33.6618 5.86995C34.6014 6.41238 35.3226 7.12741 35.3226 7.91641V12.6997C35.3226 13.1928 35.1696 13.5873 34.9511 13.7846C34.7763 13.9325 34.5577 13.9818 34.3174 13.9079C34.1644 13.8585 33.9896 13.7106 33.8366 13.5134C33.4869 12.9956 33.1592 11.8367 33.1592 9.86424C33.1373 9.61768 33.1154 9.42043 33.0499 9.32181H33.0062C32.8314 9.32181 32.7877 10.3081 32.7877 11.3436C32.7877 11.96 32.8095 12.5764 32.8314 13.0449C32.8532 13.3654 32.8532 13.612 32.8532 13.7599C32.8532 14.1051 32.7658 14.4256 32.591 14.6475C32.4162 14.9188 32.1321 15.0913 31.8261 15.0913C30.9302 15.0913 30.7335 13.3654 30.7335 11.7135C30.7335 11.0477 30.7772 10.3327 30.799 9.83959V9.2725C30.799 9.14921 30.7772 9.05059 30.7335 8.97662C30.6898 8.92731 30.6679 8.90265 30.6461 8.90265C30.4712 8.90265 30.4712 9.34646 30.4712 9.81493C30.4494 10.7519 30.4494 11.6641 29.4442 11.6641C29.1164 11.6641 28.8541 11.4915 28.6793 11.245C28.5045 10.9738 28.4171 10.6286 28.4171 10.3327Z" fill="#00FDB4" />
            <path d="M27.1933 18.198V19.0117C26.9311 19.1843 26.6907 19.3815 26.4722 19.6281C25.904 20.2691 25.5543 21.1568 25.5543 22.1184V27.2222C25.5543 28.1838 25.904 29.0714 26.4722 29.7125C27.0622 30.3535 27.8489 30.7727 28.7012 30.7727H35.4319C36.306 30.7727 37.0927 30.3535 37.6609 29.7125C38.229 29.0714 38.5787 28.1838 38.5787 27.2222V22.1184C38.5787 21.1568 38.229 20.2691 37.6609 19.6281C37.4423 19.3815 37.2019 19.1596 36.9179 19.0117L36.9397 18.272V18.2227V18.1734C36.9397 17.8035 36.8304 17.483 36.6556 17.2364C36.4808 16.9652 36.2186 16.8173 35.9345 16.7926C35.9126 16.768 35.8908 16.768 35.8689 16.768H35.8471H28.286C27.9582 16.768 27.6741 16.9652 27.4774 17.2364C27.3026 17.483 27.1933 17.8282 27.1933 18.198Z" fill="#00FDB4" />
        </svg>
    )
}

export default HairRemovalIcon