import React, { useEffect, useState } from 'react'
import Card from '../../../../uiComponents/Card'
import RowWrap from '../../../../uiComponents/RowWrap'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import Badge from '../../../../uiComponents/common/Badge'
import {  useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup';
import Http from '../../../../../../Http'
import {  useMutation, useQuery, useQueryClient } from 'react-query'
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios'
import { enqueueSnackbar } from 'notistack'
import { authStore } from '../../../../../contexts/AuthProvider'
import InputWrap from '../../../../uiComponents/inputs/InputWrap'
import { withTranslation } from 'react-i18next';
import { PATH_PROVIDER } from '../../../../../routes/path'

const PaymentInformation = ({ t, setStep }) => {

    const { 
        salonDataId, 
        becomeWorkerStep, 
        updateBecomeWorkerStep, 
        BecomeWorkerCompleteStep, 
        user: { id: providerId } = {} 
    } = authStore((state) => state);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        iban: '',
        bic_swift: ''
    });

    const validationSchema = Yup.object().shape({
        iban: Yup.string().required(t("validation_message.iban_required")).matches(/^[A-Z]{2}[0-9A-Z]*$/, t("validation_message.iban_invalid")),
        bic_swift: Yup.string().required(t("validation_message.bic_swift_required")).matches(/^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/, t("validation_message.bic_swift_invalid")),
    });

    const { mutateAsync: paymentStore } = useMutation(
        async (datas) => {
            try {
                const response = await Http.post(PROFILE_COMPLETE_URL.paymentStore, datas);
                if (response.data) {
                    if (salonDataId) {
                        const updatedArray = [...new Set([...becomeWorkerStep, 5])];
                        updateBecomeWorkerStep(updatedArray);
                        if (response.data.is_complete == 1) {
                            BecomeWorkerCompleteStep(1);
                        }
                        queryClient.invalidateQueries(["mySalonList"]);
                        navigate(PATH_PROVIDER.dashboard)
                    }
                    if (becomeWorkerStep.includes(5) && !salonDataId) {
                        const updatedArray = [...new Set([...becomeWorkerStep, 5])]; // Modify the array as needed
                        updateBecomeWorkerStep(updatedArray);
                        setStep(1)
                    }
                    enqueueSnackbar(t('snackbar_message.payment_information_updated'), {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });

                }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                const errorDataMsg = error?.response?.data?.message;
                if (errorData) {
                    Object.values(errorData).forEach(message => {
                        enqueueSnackbar(message, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
                else if (errorDataMsg) {
                    enqueueSnackbar(errorDataMsg, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const data = {
                    iban: values.iban,
                    bic_swift: values.bic_swift,
                    provider_id: providerId,
                    type: 'worker',
                    salon_id: salonDataId
                }
                await paymentStore(data);
            } catch (error) {
                console.error('Error adding payment info:', error.message);
            }
        },
    });

    const { handleSubmit,  getFieldProps, errors } = formik;

    const { data: paymentInformation, refetch: fetchPaymentInformation } = useQuery(
        ["fetchPaymentInformation", providerId],
        async () => {
            const res = await Http.get(PROFILE_COMPLETE_URL.paymentStore, { params: { provider_id: providerId, type: 2 } });
            const data = res.data.data;
            setInitialValues({
                iban: data.iban || '',
                bic_swift: data.bic_swift || ''
            });
            return res.data.data;
        },
        {
          refetchOnWindowFocus: false,
          enabled: !!providerId,
        }
      );
    
    useEffect(() => {
        fetchPaymentInformation();
    }, [providerId]);


    return (
        <FormikProvider value={formik}>
            <form onSubmit={handleSubmit} autoComplete="off">
                <Card>
                    <div className="mb-7">
                        <Badge groupClassName={"mb-0"} text={t("payment_info.payment_information")} />
                    </div>
                    <RowWrap className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 md:gap-6 lg:gap-x-8 lg:gap-y-5" lg={2}>
                        <InputWrap size="sm" groupClassName={""} className="" require
                            {...getFieldProps('iban')}
                            labelClassName={"text-[15px]"}
                            label={t('form_input_label.IBAN')}
                            placeholder={t('input_filed_placeholder.input_iban')}
                            errorType={errors.iban}
                            errorData={errors.iban}
                        />
                        <InputWrap labelClassName={"text-[15px]"} size="sm" groupClassName={""} className="" require
                            label={t('form_input_label.BIC/SWIFT')}
                            placeholder={t('input_filed_placeholder.input_bic_swift')}
                            {...getFieldProps('bic_swift')}
                            errorType={errors.bic_swift}
                            errorData={errors.bic_swift}
                        />
                    </RowWrap>
                    <ButtonPrimary size="sm" type="submit" className="ms-auto mt-6 min-w-[262px]" children={t("button_label.continue")} />
                </Card>
            </form>
        </FormikProvider>
    )
}

export default withTranslation()(PaymentInformation)