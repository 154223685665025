import React from 'react';
import { withTranslation } from 'react-i18next';
import StarIcon from '../icons/StarIcon';
import SalonSlider from './SalonSlider';

const SalonCardResponsive = ({ t, hasHairList, className, ServiceFacilityClassName, salonData, ...props }) => {
    return (
        <div className="">
            <div className="flex items-center justify-between mb-2">
                <p className='text-[20px] text-blue-950 font-bold'>{salonData?.business_name}</p>
                <div className="md:hidden flex item center gap-1.5">
                    <span className='-mt-1'><StarIcon className='h-6 w-6 text-success-800' /></span>
                    <p className='text-blue-950 text-base font-medium'>4.5/5 (12K.)</p>
                </div>
            </div>
            <div className="">
                <SalonSlider salonData={salonData} heartIcon={true} ServiceFacilityClassName={ServiceFacilityClassName} />
            </div>
        </div>
    );
}

export default withTranslation()(SalonCardResponsive);
