import React from 'react'
import { cn } from '../../../../lib/utils'
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const HairTypeList = ({t, colored, className, titleMainClassName, titleClassName, listGropuClassName, listMainClassName, listItemClassName, hairData, data, navigateUrl, handelClickEvent, salonData }) => {
    const navigate = useNavigate();
    const flattenedHairData = hairData?.flat();
    return (
        <div className={cn("hair-type-list h-full", className, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(navigateUrl, { state: { id: data.salon_id || data.id || salonData.id, data: data||salonData } })}>
            <div className={cn(`list-heading flex items-center justify-center px-2 py-1 border border-borderColor rounded-full mb-2 ${colored && "bg-light-800"}`, titleMainClassName)}>
                <h4 className={cn('text-sm leading-[1] text-success-900', titleClassName)}>{t('form_input_label.hair_types')}</h4>
            </div>
            <div className={cn(`list-body border-[1.3px] border-success-100 rounded-[10px] h-[calc(100%-35px)] p-2 flex items-center justify-center ${colored && "bg-light-800"}`, listGropuClassName)}>
                <ul className={cn("[&_li:not(:last-child)]:mb-0.5 text-center text-sm text-blue-950", listMainClassName)}>
                    {flattenedHairData?.map((type) => (
                        <li key={type.id} className={cn(listItemClassName)}>{type.name}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default withTranslation()(HairTypeList)