import { Calendar } from 'antd'
import React, { useCallback, useState } from 'react'
import { withTranslation } from 'react-i18next'
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon'
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon'
import dayjs from "dayjs"
import "dayjs/locale/zh-cn"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utcPlugin from 'dayjs/plugin/utc'
import ButtonPrimary from '../buttons/ButtonPrimary'
import moment from "moment";
import { cn } from '../../../../lib/utils'

dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);

const OfferRequestCalender = ({ mainClassName, setNewSelectDate, newSelectDate, itemListClassName }) => {
    const [selectDate, setSelectDate] = useState(moment().add(1, "days").format('YYYY-MM-DD'));
    const onSelect = (date, source) => {
        if (source !== "date") return
        const selectDate = dayjs(date).format("YYYY-MM-DD");
        setSelectDate(selectDate)
        setNewSelectDate(date)
    }

    const disabledDate = (date) => {
        const minAllowedDate = dayjs().add(1, 'day').startOf('d'); // Today + 1 day
        return date.isBefore(minAllowedDate, 'day');
    };

    const customRenderDate = useCallback(
        (current) => {
            const isDisabled = disabledDate(current);
            if (current.isSame(newSelectDate) && !isDisabled) {
                return <div className="custom_selected_date">
                    <div className="custom_selected_date_container relative z-[1] flex flex-col text-white before:w-[26px] before:h-[26px] before:absolute  before:top-1/2 before:start-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:-z-[1] before:bg-success-950 before:rounded">
                        <span className="text-sm !leading-[17px]">{current.format("DD")}</span>
                    </div>
                </div>;
            }
            return <div className={cn(`text-sm font-medium !leading-[17px] ${isDisabled ? "text-text_light_gray bg-transparent" : "text-blue-950 "}`, itemListClassName)}>{current.format("DD")}</div>;
        },
        [selectDate, setSelectDate, newSelectDate, setNewSelectDate]
    );

    return (
        <div className={cn("rounded-[10px] px-3.5", mainClassName)}>
            <Calendar
                className='font-primary'
                fullscreen={false}
                onSelect={(date, { source }) => onSelect(date, source)}
                fullCellRender={customRenderDate}
                disabledDate={disabledDate}
                value={newSelectDate}
                headerRender={({
                    value,
                    onChange,
                }) => {
                    const start = 0;
                    const end = 12;
                    const monthOptions = [];
                    let current = value.clone();
                    const localeData = value.localeData();
                    const months = [];

                    for (let i = 0; i < 12; i++) {
                        current = current.month(i);
                        months.push(localeData.monthsShort(current));
                    }
                    for (let i = start; i < end; i++) {
                        monthOptions.push(months[i]);
                    }

                    const month = value.month();
                    const year = value.year(); // Get the year
                    const day = value.date(); // Get the year

                    const cMonth = months.filter(
                        (item, ind) => ind === month
                    );

                    let currentMonths = cMonth;
                    const handlerPrevClick = () => {
                        const now = value.clone().month(month - 1);
                        const prevNow = moment(selectDate).subtract(1, 'month');
                        setSelectDate(prevNow.format('YYYY-MM-DD'));
                        setNewSelectDate(now)
                        onChange(now);
                    };
                    const handlerNextClick = () => {
                        const now = value.clone().month(month + 1);
                        const nextNow = moment(selectDate).add(1, 'month');
                        setSelectDate(nextNow.format('YYYY-MM-DD'));
                        setNewSelectDate(now)
                        onChange(now);
                    };

                    return (
                        <div className="flex items-center justify-center gap-6 lg:gap-9 pb-2.5 border-b-[1.3px] border-success-100">
                            <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2" onClick={() => handlerPrevClick()}>
                                <LeftSmallArrowIcon className="w-3 h-3" />
                            </ButtonPrimary>
                            <h6 className="mb-0 text-[15px] font-semibold text-blue-950">
                                {day} {currentMonths[0]} {year}
                            </h6>
                            <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2" onClick={() => handlerNextClick()}>
                                <RightSmallArrowIcon className="w-3 h-3" />
                            </ButtonPrimary>
                        </div>
                    );
                }}
            />
        </div>
    )
}

export default withTranslation()(OfferRequestCalender)