import React from 'react'
import Divider from '../../../uiComponents/Divider'
import BadgeSlider from '../../../uiComponents/common/BadgeSlider'
import CommonSliderWrapper from '../../../uiComponents/common/CommonSliderWrapper'
import StudentBadgeIcon from '../../../uiComponents/icons/badge-icon/StudentBadgeIcon'
import { cn } from '../../../../../lib/utils'
import { withTranslation } from 'react-i18next'

const CustomerSkills = ({ t, colored, BadgeList, className, groupClassName, educationCardClass, edCardTitle, edCardText, certiTitleClass, certiIconClass, workerData }) => {
    return (
        <div className={cn(`border border-borderColor rounded-[10px] p-2 ${colored && "bg-light-800"}`, groupClassName)}>
            <div className={cn("text-center", educationCardClass)}>
                <h5 className={cn('text-[15px] text-blue-950', edCardTitle)}>Name of study <span className="opacity-40"></span></h5>
                <Divider className="my-1.5" />
                <div className="flex items-center justify-center h-[calc(100%-34px)]">
                    <p className={cn('font-light text-sm text-blue-950', edCardText)}>
                        {
                            workerData?.educations?.length > 0 ? workerData?.educations.map((education) => education.keywords).join(" - ") : "No Education"
                        }
                    </p>
                </div>
            </div>
            <Divider className="my-2" />
            <div className="relative">
                {workerData?.educations?.length > 0 ?
                    <CommonSliderWrapper sildNoShow slidesToShow={1} arrowButton={"w-6 h-6"} arrowIconClass={"w-3.5 h-3.5"} arrowBtnGroupClassName="justify-start mt-0" defaultArrows={false} groupClassName="grid grid-cols-2 items-center" SliderClassName="ms-auto">
                        {
                            workerData?.educations?.length > 0 && workerData?.educations?.map((items) => (
                                <div className="!flex items-center flex-col gap-1">
                                    <span className={cn('text-[15px] font-medium text-center text-blue-950', certiTitleClass)}>{t("comman_text.certificate")}</span>
                                    {/* <StudentBadgeIcon className={cn('h-[60px] w-[60px]', certiIconClass)} /> */}
                                    <img src={items?.front_image} className='block mx-auto !size-[60px] flex-shrink-0' />
                                </div>
                            ))
                        }
                    </CommonSliderWrapper>
                    : <div className="!flex items-center flex-col gap-1">
                        <span className={cn('text-[15px] font-medium text-center text-blue-950', certiTitleClass)}>{t("comman_text.certificate")}</span>
                        <p className='font-light text-center text-sm text-blue-950 ms-1'>{t("comman_text.no_certificate")}</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default withTranslation()(CustomerSkills);