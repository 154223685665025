import React from 'react'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from "moment";
import ButtonPrimary, { buttonVariance } from '../buttons/ButtonPrimary';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import { Disclosure } from '@headlessui/react';
import BottomArrowIcon from '../icons/BottomArrowIcon';
import { withTranslation } from 'react-i18next';
import LabelWrap from '../inputs/LabelWrap';
import { cn } from '../../../../lib/utils';
import { enqueueSnackbar } from 'notistack';

dayjs.extend(customParseFormat);

function ProviderScheduleBookingCalender({ t, workerTiming, setAvailabilityData, selectedTime, endTime, employeeTimings, currentDate, workerId, setCurrentDate, highlightStartTime, setHighlightStartTime, setHighlightEndTime, highlightEndTime, setSelectedTime, setEndTime, handelTimeSelect }) {

    const current = moment()

    
    const isPrevClickable = moment(current).isBefore(moment(currentDate));

    const handlerPrevClick = () => {
        const previousDate = currentDate.clone().subtract(1, 'day');
        if (previousDate.isBefore(moment().startOf('day'))) {
            return;
        }
        if (workerId) {
            setHighlightStartTime("")
            setHighlightEndTime("")
            setSelectedTime("")
            setEndTime("")
            setCurrentDate(previousDate);
            setAvailabilityData(null)
            setTimeout(() => {
                employeeTimings({
                    workerId: workerId,
                    currentDateTime: previousDate.format("YYYY-MM-DD")
                })
            }, 50);


        } else {
            enqueueSnackbar(t('validation_message.please_choose_worker'), {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
        }
    };

    const handlerNextClick = () => {
        if (workerId) {

            setCurrentDate(currentDate.clone().add(1, 'day'));
            const nextDay = currentDate.clone().add(1, 'day')
            setHighlightStartTime("")
            setHighlightEndTime("")
            setSelectedTime("")
            setEndTime("")
            setAvailabilityData(null)
            setTimeout(() => {

                employeeTimings({
                    workerId: workerId,
                    currentDateTime: nextDay.format("YYYY-MM-DD")
                })
            }, 50);

        } else {
            enqueueSnackbar(t('validation_message.please_choose_worker'), {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
        }


    };

    return (
        <div className={`customer_booking_calender border-[1.3px] p-3 border-success-100 rounded-[15px] `}>
            <div className="flex items-center justify-center gap-6 lg:gap-9  ">
                <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2"
                    onClick={() => { isPrevClickable && handlerPrevClick() }}
                >
                    <LeftSmallArrowIcon className="w-3 h-3" />
                </ButtonPrimary>
                <h6 className="mb-0 text-[15px] font-semibold text-blue-950">
                    {moment.utc(currentDate, "YYYY-MM-DD HH:mm:ss").local().format("D MMMM YYYY")}
                </h6>
                <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2"
                    onClick={() => handlerNextClick()}
                >
                    <RightSmallArrowIcon className="w-3 h-3" />
                </ButtonPrimary>
            </div>
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <div className='flex flex-col sm:flex-row gap-3 gap-y-0 mt-3 justify-start items-start  border-t-[1.3px] border-success-100 sm:border-t-0'>
                        <Disclosure.Button className=" flex justify-between items-center gap-3 py-3 w-full max-w-[100px] ">
                            <LabelWrap labelClassName="text-[15px] font-medium mb-0"
                                className={"flex gap-2"}
                                label={t('comman_text.early_morning')} />
                            <BottomArrowIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-blue-950`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className={"w-full border-t-0 sm:border-t-[1.3px] border-success-100 pt-1 pb-2 sm:pt-3.5"}>
                            <ul className='w-full flex flex-wrap gap-x-1 md:gap-x-3 gap-y-2 ' >
                                {
                                    workerTiming?.early_morning?.map((data, ind) => {
                                        const selectedStartTime = selectedTime;
                                        const selectedEndTime = endTime;
                                        let isSelected = false
                                        let isHighLight = false
                                        const values = moment(data.value, 'HH:mm').format('HH:mm')
                                        // const currentDateString = currentDate.format('YYYY-MM-DD');
                                        // const isDisabled = moment().isAfter(moment(`${currentDateString} ${values}`, 'YYYY-MM-DD HH:mm'), 'minute');
                                        if (endTime) {
                                            isSelected = values >= selectedStartTime && values <= selectedEndTime;
                                        }
                                        if (highlightStartTime && highlightEndTime) {
                                            isHighLight = values >= highlightStartTime && values <= highlightEndTime;
                                        }
                                        const color = data.color ? data.color : ""


                                        return (
                                            <li onClick={() => data.disabled === true ? <></> : handelTimeSelect(data.value)}
                                                key={ind}
                                                disabled={data.disabled}
                                                style={{
                                                    background: isSelected || isHighLight ? "#0DEFDA" : color == "red" ? color : "#FFFFFF",
                                                    color: isSelected || isHighLight || color == "red" ? "#FFFFFF" : "#333333",
                                                    border: `1px solid ${color == "red" ? "red" : "#0DEFDA"}`
                                                }}
                                                className={cn(buttonVariance({ variant: "outline", size: "sm" }), `text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950 hours_schedule_list_item `, data.disabled ? "opacity-50 cursor-not-allowed disabled bg-success-950 text-white" : "", isSelected ? "bg-success-950 text-white" : "")
                                                }>
                                                {/* {data} */}
                                                {data.value}
                                                {/* {(ind + 1) % 4 === 0 && ind !== workerTiming?.early_morning?.length - 1 && <span className='ms-2'></span>} */}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Disclosure.Panel>
                    </div>
                )
                }
            </Disclosure >
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <div className='flex flex-col sm:flex-row gap-3 gap-y-0 mt-3 justify-start items-start  border-t-[1.3px] border-success-100 sm:border-t-0'>
                        <Disclosure.Button className=" flex justify-between items-center gap-3 py-3 w-full max-w-[100px] ">
                            <LabelWrap labelClassName="text-[15px] font-medium mb-0"
                                className={"flex gap-2"}
                                label={"Morning"} />
                            <BottomArrowIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-blue-950`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className={"w-full border-t-0 sm:border-t-[1.3px] border-success-100 pt-1 pb-2 sm:pt-3.5"}>
                            <ul className='w-full flex flex-wrap gap-x-1 md:gap-x-3 gap-y-2 ' >
                                {
                                    workerTiming?.morning?.map((data, ind) => {
                                        const selectedStartTime = selectedTime;
                                        const selectedEndTime = endTime;
                                        let isSelected = false
                                        let isHighLight = false
                                        const values = moment(data.value, 'HH:mm').format('HH:mm')
                                        const currentDateString = currentDate.format('YYYY-MM-DD');
                                        const isDisabled = moment().isAfter(moment(`${currentDateString} ${values}`, 'YYYY-MM-DD HH:mm'), 'minute');

                                        if (endTime) {
                                            isSelected = values >= selectedStartTime && values <= selectedEndTime;
                                        }
                                        if (highlightStartTime && highlightEndTime) {
                                            isHighLight = values >= highlightStartTime && values <= highlightEndTime;
                                        }
                                        // timeOptions?.morning?.map((data, ind) => {
                                        const color = data.color ? data.color : ""

                                        return (
                                            <li onClick={() => handelTimeSelect(data.value)}
                                                key={ind}
                                                style={{
                                                    background: isSelected || isHighLight ? "#0DEFDA" : color == "red" ? color : "#FFFFFF",
                                                    color: isSelected || isHighLight || color == "red" ? "#FFFFFF" : "#333333",
                                                    border: `1px solid ${color == "red" ? "red" : "#0DEFDA"}`
                                                }}
                                                disabled={data.disabled}
                                                className={cn(buttonVariance({ variant: "outline", size: "sm" }), `text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950 hours_schedule_list_item`, data.disabled ? "opacity-50 cursor-not-allowed disabled bg-success-950 text-white" : "", isSelected ? "bg-success-950 text-white" : "")}>
                                                {/* {data} */}
                                                {data.value}
                                                {/* {(ind + 1) % 4 === 0 && ind !== workerTiming?.early_morning?.length - 1 && <span className='ms-2'></span>} */}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <div className='flex flex-col sm:flex-row gap-3 gap-y-0 mt-3 justify-start items-start  border-t-[1.3px] border-success-100 sm:border-t-0'>
                        <Disclosure.Button className=" flex justify-between items-center gap-3 py-3 w-full max-w-[100px] ">
                            <LabelWrap labelClassName="text-[15px] font-medium mb-0"
                                className={"flex gap-2"}
                                label={"AfterNoon"} />
                            <BottomArrowIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-blue-950`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className={"w-full border-t-0 sm:border-t-[1.3px] border-success-100 pt-1 pb-2 sm:pt-3.5"}>
                            <ul className='w-full flex flex-wrap gap-x-1 md:gap-x-3 gap-y-2 ' >
                                {
                                    workerTiming?.afternoon?.map((data, ind) => {
                                        const selectedStartTime = selectedTime;
                                        const selectedEndTime = endTime;
                                        let isSelected = false
                                        let isHighLight = false
                                        const color = data.color ? data.color : ""

                                        const values = moment(data.value, 'HH:mm').format('HH:mm')
                                        const currentDateString = currentDate.format('YYYY-MM-DD');
                                        const isDisabled = moment().isAfter(moment(`${currentDateString} ${values}`, 'YYYY-MM-DD HH:mm'), 'minute');

                                        if (endTime) {
                                            isSelected = values >= selectedStartTime && values <= selectedEndTime;
                                        }
                                        if (highlightStartTime && highlightEndTime) {
                                            isHighLight = values >= highlightStartTime && values <= highlightEndTime;
                                        }
                                        // timeOptions?.afternoon?.map((data, ind) => {
                                        return (
                                            <li
                                                onClick={() => handelTimeSelect(data.value)}
                                                key={ind}
                                                style={{
                                                    background: isSelected || isHighLight ? "#0DEFDA" : color == "red" ? color : "#FFFFFF",
                                                    color: isSelected || isHighLight || color == "red" ? "#FFFFFF" : "#333333",
                                                    border: `1px solid ${color == "red" ? "red" : "#0DEFDA"}`
                                                }}
                                                disabled={data.disabled}
                                                className={cn(buttonVariance({ variant: "outline", size: "sm" }), `text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950 hours_schedule_list_item `, data.disabled ? "opacity-50 cursor-not-allowed disabled bg-success-950 text-white" : "", isSelected ? "bg-success-950 text-white" : "")
                                                }>
                                                {/* // className={cn(buttonVariance({ variant: "outline", size: "sm" }), `hours_schedule_list_item ${data.disabled ? "disabled" : ""} text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950`)} */}
                                                {/* > */}
                                                {/* {data} */}
                                                {data.value}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Disclosure.Panel>
                    </div>
                )
                }
            </Disclosure >
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <div className='flex flex-col sm:flex-row gap-3 gap-y-0 mt-3 justify-start items-start  border-t-[1.3px] border-success-100 sm:border-t-0'>
                        <Disclosure.Button className=" flex justify-between items-center gap-3 py-3 w-full max-w-[100px] ">
                            <LabelWrap labelClassName="text-[15px] font-medium mb-0"
                                className={"flex gap-2"}
                                label={"Evening"} />
                            <BottomArrowIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-blue-950`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className={"w-full border-t-0 sm:border-t-[1.3px] border-success-100 pt-1 pb-2 sm:pt-3.5"}>
                            <ul className='w-full flex flex-wrap gap-x-1 md:gap-x-3 gap-y-2 ' >
                                {
                                    workerTiming?.evening?.map((data, ind) => {
                                        const selectedStartTime = selectedTime;
                                        const selectedEndTime = endTime;
                                        let isSelected = false
                                        let isHighLight = false

                                        const values = moment(data.value, 'HH:mm').format('HH:mm')
                                        const currentDateString = currentDate.format('YYYY-MM-DD');
                                        const isDisabled = moment().isAfter(moment(`${currentDateString} ${values}`, 'YYYY-MM-DD HH:mm'), 'minute');

                                        if (endTime) {
                                            isSelected = values >= selectedStartTime && values <= selectedEndTime;
                                        }
                                        if (highlightStartTime && highlightEndTime) {
                                            isHighLight = values >= highlightStartTime && values <= highlightEndTime;
                                        }
                                        const color = data.color ? data.color : ""
                                        // const listItem = (
                                        //     <li
                                        //         style={{
                                        //             background: isSelected || isHighLight ? "#0DEFDA" : color == "red" ? color : "#FFFFFF",
                                        //             color: isSelected || isHighLight || color == "red" ? "#FFFFFF" : "#333333",
                                        //             border: `1px solid ${color == "red" ? "red" : "#0DEFDA"}`
                                        //         }}
                                        //         className={`hours_schedule_list_item ${data.disabled ? "disabled" : ""}`}
                                        //         onClick={() => handelTimeSelect(data.value)}
                                        //         key={ind}
                                        //         disabled={data.disabled}
                                        //     >
                                        //         {data.value}
                                        //     </li>
                                        // );
                                        // timeOptions?.evening?.map((data, ind) => {
                                        return (
                                            <li
                                                onClick={() => handelTimeSelect(data.value)}
                                                key={ind}
                                                style={{
                                                    background: isSelected || isHighLight ? "#0DEFDA" : color == "red" ? color : "#FFFFFF",
                                                    color: isSelected || isHighLight || color == "red" ? "#FFFFFF" : "#333333",
                                                    border: `1px solid ${color == "red" ? "red" : "#0DEFDA"}`
                                                }}
                                                disabled={data.disabled}
                                                className={cn(buttonVariance({ variant: "outline", size: "sm" }), `text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950 hours_schedule_list_item `, data.disabled ? "opacity-50 cursor-not-allowed disabled bg-success-950 text-white" : "", isSelected ? "bg-success-950 text-white" : "")}>
                                                {/* // className={cn(buttonVariance({ variant: "outline", size: "sm" }), `hours_schedule_list_item ${data.disabled ? "disabled" : ""} text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950`)}/> */}
                                                {/* {data} */}
                                                {data.value}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Disclosure.Panel>
                    </div>
                )
                }
            </Disclosure >
        </div >
    )
}

export default withTranslation()(ProviderScheduleBookingCalender)
