import React from 'react'
import { cn } from '../../../../lib/utils'
import { Link } from 'react-router-dom'
import { cva } from 'class-variance-authority'
import LoadingIcon from '../icons/LoadingIcon';

export const buttonVariance = cva(
    "flex items-center justify-center outline-0 gap-2 rounded-lg ease-in duration-300 transition-all text-lg lg:text-xl border-[2.5px] border-success-950 font-bold",
    {
        variants: {
            variant: {
                outline: "hover:bg-success-950 hover:text-white text-success-950 !leading-[25px] ",
                fill: "bg-success-950 text-white hover:bg-primary2 hover:border-primary2 !leading-[25px] ",
                asLink: "text-success-950 hover:text-primary2 border-0 !leading-none"
            },
            size: {
                default: 'md:py-2.5 md:px-6 py-1.5 py-2 px-4',
                sm: 'py-1.5 px-4 !leading-[24px]',
                auto: ""
            }
        },
        defaultVariants: {
            size: "default",
            variant: 'fill'
        }
    },
)

const ButtonPrimary = ({ href, icon, ref, iconClassName, className, size, variant, children, isLoading, ...props }) => {
    return (
        href ?
            <>
                <Link to={href} className={cn(buttonVariance({ variant, size, className }))} {...props} ref={ref}>
                    {isLoading ? <LoadingIcon className="w-5 h-5 animate-spin" /> : icon ? <span className={cn(`inline-flex leading-none flex-shrink-0`, iconClassName)}>{icon}</span> : ""}
                    {children}
                </Link>
            </>
            : <button className={cn(buttonVariance({ variant, size, className }), isLoading ? "cursor-not-allowed opacity-60" : "")} {...props} ref={ref}>
                {isLoading ? <LoadingIcon className="w-5 h-5 animate-spin" /> : icon ? <span className={cn(`inline-flex leading-none flex-shrink-0 `, iconClassName)}>{icon}</span> : ""}
                {children}
            </button>
    )
}

export default ButtonPrimary