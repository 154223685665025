import React from 'react'

const AddWorkerIcon = (props) => {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1.29012 23.3526C1.42106 21.5271 3.07789 20.1964 4.90242 20.3406C5.80767 20.4122 6.79409 20.4654 7.72538 20.4654C8.76234 20.4654 9.92099 20.3994 10.9742 20.3156C12.7922 20.1709 14.4282 21.5051 14.5587 23.3241V23.3241C14.7166 25.5251 12.9736 27.3975 10.767 27.3975H7.72538H5.05531C2.86416 27.3975 1.13337 25.5382 1.29012 23.3526V23.3526Z" fill="#3BDFFD" stroke="#3BDFFD" strokeWidth="0.998464" />
            <path d="M4.81393 14.4509C4.81393 12.6216 6.29684 11.1387 8.1261 11.1387H8.32683C10.1561 11.1387 11.639 12.6216 11.639 14.4509V14.838C11.639 16.4535 10.3294 17.7631 8.71397 17.7631V17.7631H7.73896V17.7631C6.12351 17.7631 4.81393 16.4535 4.81393 14.838V14.4509Z" fill="#3BDFFD" stroke="#3BDFFD" strokeWidth="0.998464" />
            <path d="M28.5541 14.4509C28.5541 12.6216 30.037 11.1387 31.8662 11.1387H32.067C33.8962 11.1387 35.3791 12.6216 35.3791 14.4509V14.838C35.3791 16.4535 34.0696 17.7631 32.4541 17.7631V17.7631H31.4791V17.7631C29.8637 17.7631 28.5541 16.4535 28.5541 14.838V14.4509Z" fill="#3BDFFD" stroke="#3BDFFD" strokeWidth="0.998464" />
            <path d="M25.4393 23.3526C25.5702 21.5271 27.227 20.1964 29.0516 20.3406C29.9568 20.4122 30.9432 20.4654 31.8745 20.4654C32.9115 20.4654 34.0701 20.3994 35.1234 20.3156C36.9414 20.1709 38.5774 21.5051 38.7078 23.3241V23.3241C38.8657 25.5251 37.1227 27.3975 34.9161 27.3975H31.8745H29.2045C27.0133 27.3975 25.2825 25.5382 25.4393 23.3526V23.3526Z" fill="#3BDFFD" stroke="#3BDFFD" strokeWidth="0.998464" />
            <path d="M9.21971 27.9967C9.25634 27.4865 9.27465 27.2315 9.2973 27.0713C9.6434 24.6232 11.5297 23.0015 14.002 23.0265C14.1638 23.0282 14.4727 23.0526 15.0905 23.1014C16.5654 23.218 18.1731 23.3049 19.6909 23.3049C21.3805 23.3049 23.2686 23.1973 24.9842 23.0608C25.5802 23.0134 25.8782 22.9897 26.0543 22.9888C28.4787 22.9778 30.3749 24.6071 30.7288 27.0056C30.7545 27.1797 30.7729 27.4366 30.8098 27.9504V27.9504C30.8478 28.4788 30.8667 28.743 30.8654 28.9629C30.8473 31.8963 28.585 34.3268 25.6604 34.555C25.4412 34.5721 25.1763 34.5721 24.6466 34.5721H19.6909H15.3399C14.8529 34.5721 14.6094 34.5721 14.4078 34.5576C11.4546 34.3459 9.16704 31.8882 9.16736 28.9274C9.16739 28.7253 9.18483 28.4824 9.21971 27.9967V27.9967Z" fill="#00FDB4" stroke="#00FDB4" strokeWidth="1.14179" />
            <path d="M13.9488 12.2908C13.9488 8.95718 16.683 6.25476 20.0166 6.25476V6.25476C23.3502 6.25476 26.0845 8.95718 26.0845 12.2908V13.1258C26.0845 15.9982 23.7559 18.3268 20.8834 18.3268V18.3268H19.1498V18.3268C16.2773 18.3268 13.9488 15.9982 13.9488 13.1258V12.2908Z" fill="#00FDB4" stroke="#00FDB4" strokeWidth="1.14179" />
        </svg>
    )
}

export default AddWorkerIcon