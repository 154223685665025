import React from 'react'

const ProfileIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.05057 30.6825C4.1021 30.2375 4.12786 30.0149 4.15248 29.8655C4.67843 26.6711 7.29858 24.5004 10.535 24.5777C10.6864 24.5813 10.949 24.6002 11.4742 24.6379C14.0861 24.8259 17.0922 24.9855 19.7642 24.9855C22.5507 24.9855 25.6579 24.8118 28.2962 24.6137C28.8059 24.5754 29.0606 24.5562 29.2109 24.5522C32.2661 24.4684 34.7831 26.3848 35.5156 29.352C35.5516 29.4979 35.5935 29.7107 35.6773 30.1366C35.7657 30.5859 35.8099 30.8105 35.8349 30.9916C36.336 34.641 33.6206 37.9429 29.9434 38.1562C29.7606 38.1667 29.5318 38.1667 29.0739 38.1667H19.7642H10.7183C10.3102 38.1667 10.1061 38.1667 9.93917 38.1579C6.43277 37.9722 3.75107 34.9621 3.96976 31.4577C3.98017 31.2907 4.00364 31.088 4.05057 30.6825Z" fill="#0DEFDA" stroke="#0DEFDA" strokeWidth="1.4" />
            <path d="M11.7081 9.91313C11.7081 5.45789 15.3198 1.84619 19.775 1.84619C24.2303 1.84619 27.8419 5.45789 27.8419 9.91313V11.072C27.8419 14.8908 24.7463 17.9865 20.9274 17.9865H18.6226C14.8038 17.9865 11.7081 14.8908 11.7081 11.072V9.91313Z" fill="#0DEFDA" stroke="#0DEFDA" strokeWidth="1.4" />
        </svg>
    )
}

export default ProfileIcon