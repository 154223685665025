import React from 'react'
import { cn } from '../../../../lib/utils'

const InputRadioBox = ({ id, groupClassName, text, className, inputClassName, checked, prefixIcon, suffixIcon, disabled,value, ...props }) => {
    function generateUniqueId() {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    }
    const uniqueId = generateUniqueId();
    return (
        <div className={cn("flex items-center custom-radio relative group", groupClassName)}>
            <input id={id ? id : uniqueId} type="radio" className={cn("sr-only radio-input peer", inputClassName)} {...props} disabled={disabled ? disabled : false} checked={checked} value={value} />
            <label htmlFor={id ? id : uniqueId} className={cn("flex items-start gap-2 cursor-pointer relative text-start radio-label text-sm font-normal leading-[20px] text-blue-950 before:mt-0 before:border before:border-success-100 before:block before:relative before:align-middle before:cursor-pointer before:w-[18px] before:h-[18px] before:rounded-full before:bg-transparent before:ease-in before:duration-150 before:flex-shrink-0 after:mt-0 after:absolute peer-checked:after:block peer-checked:after:absolute peer-checked:after:w-2.5 peer-checked:after:h-2.5 peer-checked:after:bg-success-950 peer-checked:after:rounded-full peer-checked:after:top-[4px] peer-checked:after:start-[4px] peer-checked:after:ease-in peer-checked:after:duration-150 peer-checked:before:bg-transparent peer-checked:before:border-success-950 ", disabled ? "peer-checked:before:border-success-950/70 peer-checked:text-success-950/70 text-dark-900/70 cursor-not-allowed before:cursor-not-allowed after:cursor-not-allowed" : "", className)}>
                {prefixIcon}
                {text ? text : ""}
                {suffixIcon}
            </label>
        </div>
    )
}

export default InputRadioBox;