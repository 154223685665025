import React from 'react'

const HomeIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 19.5153C4 16.4698 4 14.947 4.61567 13.6084C5.23132 12.2698 6.38754 11.2786 8.69997 9.2966L10.9431 7.3739C15.1228 3.79131 17.2128 2 19.7021 2C22.1914 2 24.2814 3.79131 28.461 7.3739L30.7042 9.2966C33.0166 11.2786 34.1729 12.2698 34.7886 13.6084C35.4042 14.947 35.4042 16.4698 35.4042 19.5153V29.0273C35.4042 33.2569 35.4042 35.3719 34.0901 36.6858C32.7761 37.9998 30.6613 37.9998 26.4316 37.9998H12.9726C8.74289 37.9998 6.62803 37.9998 5.31401 36.6858C4 35.3719 4 33.2569 4 29.0273V19.5153Z" stroke="#0DEFDA" strokeWidth="2.5" />
            <path d="M25.31 38V26.4638C25.31 25.4019 24.449 24.5411 23.3873 24.5411H16.0168C14.9549 24.5411 14.0941 25.4019 14.0941 26.4638V38" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default HomeIcon