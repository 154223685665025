import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import InputCheckbox from '../inputs/InputCheckbox';
import { cn } from '../../../../lib/utils';
import _ from "lodash";

const CategoryCheckBoxList = ({ selectAllId, category, customClass, setCategoryChoose, salonService, serviceChoose, groupClassName, ...props }) => {
    const [checkedList, setCheckedList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const handelChecboxOnChange = (event) => {
        let list = checkedList;

        if (event.target.checked) {
            list.push(parseInt(event.target.value));
        } else {
            let index = list.indexOf(parseInt(event.target.value))
            if (index !== -1) {
                list.splice(index, 1);
                setCheckedList(list);
            }
        }

        setCategoryChoose(list);
        setCheckedList(list);

        if (category?.length === checkedList.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }

        salonService({
            category_ids: list,
            service_type_ids: serviceChoose
        })
    };

    useEffect(() => {
        setCheckAll(false);
        const allCheck = _.map(category, "id");
        if (allCheck.length) {
    
          setCheckedList([allCheck[0]]);
          setCategoryChoose([allCheck[0]]);
        } else {
          setCheckedList([])
          setCategoryChoose([]);
        }
      }, [category]);

    const handelAllChecboxOnChange = (e) => {
        const allCheck = _.map(category, "id");
        if (!e?.target.checked && e?.target.checked !== undefined) {
            setCheckAll(false);
            setCheckedList([allCheck[0]]);
            setCategoryChoose([allCheck[0]]);
            salonService({
                category_ids: [allCheck[0]],
                service_type_ids: serviceChoose
            })
        } else {
            setCheckAll(true);
            const allCheck = _.map(category, "id");
            setCheckedList(allCheck);
            setCategoryChoose(allCheck);
            salonService({
                category_ids: allCheck,
                service_type_ids: serviceChoose
            })
        }
    };

    useEffect(() => {
        if (serviceChoose?.length == 0) {
          setCheckedList([])
        }
      }, [serviceChoose])

    return (
        <div className={cn("flex items-center justify-start gap-2 flex-wrap ", groupClassName)} {...props}>
            <InputCheckbox
                className={"before:hidden after:hidden border-[1.3px] text-[15px] peer-checked:text-white peer-checked:border-success-950 peer-checked:bg-success-950 border-success-100 py-0.5 px-4 rounded-full"}
                checked={checkAll}
                id={selectAllId}
                text={"All"}
                onChange={handelAllChecboxOnChange}

            />
            <div className="flex items-center justify-start gap-2 flex-wrap" value={checkedList} 
            // onChange={handelChecboxOnChange}
            >
                {category && category.length > 0
                    ? category?.map((data, ind) => {
                        return (
                            <InputCheckbox id={data.id+"_"+ind} value={data.id} key={ind} text={data.name} checked={checkedList?.includes(data.id)} className={"before:hidden after:hidden border-[1.3px] text-[15px] peer-checked:text-white peer-checked:border-success-950 peer-checked:bg-success-950 border-success-100 py-0.5 px-4 rounded-full"} onChange={handelChecboxOnChange}/>
                        );
                    })
                    : ""}
            </div>
        </div>
    )
}

export default withTranslation()(CategoryCheckBoxList)