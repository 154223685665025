import React from 'react'

function BarberIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.6559 12.5671C22.5805 12.5663 22.5078 12.5946 22.4537 12.6459C22.1879 12.8996 22.5966 13.2937 22.8547 13.0435C22.9081 12.9914 22.9382 12.9209 22.9385 12.8472C22.9388 12.7736 22.9093 12.7028 22.8563 12.6503C22.8034 12.5978 22.7314 12.5679 22.6559 12.5671Z" fill="#3BDFFD" />
            <path d="M11.7058 17.1015C11.4747 16.8722 11.1605 16.7408 10.831 16.7357C10.5015 16.7408 10.1873 16.8722 9.95613 17.1015C9.72499 17.3308 9.59544 17.6396 9.59543 17.9614C9.59542 18.2831 9.72496 18.5919 9.9561 18.8213C10.1872 19.0506 10.5014 19.182 10.8309 19.1871C11.1604 19.182 11.4746 19.0506 11.7058 18.8213C11.9369 18.592 12.0665 18.2832 12.0665 17.9614C12.0665 17.6397 11.9369 17.3309 11.7058 17.1015Z" fill="#3BDFFD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.4086 9.59072C22.0638 9.44846 20.5805 9.29155 19.727 10.5199C19.3299 11.0893 19.1588 11.7804 19.2458 12.464C19.2699 12.4651 13.7979 12.9945 11.9921 13.1692C11.6349 13.2037 11.4217 13.2244 11.4217 13.2244C11.4028 13.2259 11.22 13.2442 10.9917 13.267C10.6119 13.305 10.106 13.3556 10.0183 13.3628C5.63838 13.7203 4.43902 19.5733 8.01598 21.8637C11.7855 24.4379 16.8014 20.7149 15.4331 16.5341C15.3558 16.4242 15.4789 16.4204 15.7998 16.4105C15.9908 16.4046 16.252 16.3966 16.5828 16.3627L19.9539 16.1396C35.2486 15.075 39.0088 13.4827 39.3142 13.2672C39.41 13.2141 39.4898 13.1372 39.5455 13.0444C39.6011 12.9516 39.6308 12.8461 39.6314 12.7385C39.632 12.631 39.6036 12.5251 39.5489 12.4317C39.4943 12.3383 39.4154 12.2606 39.3203 12.2064C38.9069 11.9715 36.1987 10.7247 24.2182 9.6647C23.9588 9.64892 23.6866 9.62013 23.4086 9.59072ZM22.6531 14.3128C22.254 14.3155 21.8699 14.1647 21.5839 13.8929C21.3056 13.6137 21.1511 13.2387 21.1538 12.8491C21.1565 12.4595 21.3162 12.0866 21.5984 11.811C21.8806 11.5355 22.2625 11.3796 22.6616 11.3769C23.0607 11.3742 23.4448 11.5251 23.7308 11.7968C24.0091 12.076 24.1636 12.451 24.1609 12.8406C24.1581 13.2303 23.9984 13.6032 23.7162 13.8787C23.4341 14.1542 23.0521 14.3102 22.6531 14.3128ZM10.831 20.392C14.1054 20.3091 14.1133 15.6104 10.831 15.5312C7.54033 15.6104 7.5496 20.3101 10.831 20.392Z" fill="#3BDFFD" />
            <path d="M11.9351 8.351C12.0325 8.17329 12.0834 7.97488 12.0834 7.77336C12.0793 7.53304 12.0033 7.29914 11.8649 7.10037C11.7265 6.90161 11.5316 6.74665 11.3043 6.65452C11.0769 6.5624 10.8269 6.53712 10.585 6.5818C10.3431 6.62648 10.1199 6.73916 9.94268 6.90602C9.79654 7.04833 9.68861 7.22364 9.62847 7.41641C9.56832 7.60919 9.55782 7.81347 9.59789 8.01116C9.63796 8.20884 9.72735 8.39383 9.85816 8.54971C9.98897 8.70559 10.1571 8.82756 10.3478 8.9048C10.5384 8.98204 10.7456 9.01217 10.9511 8.99251C11.1565 8.97286 11.3538 8.90403 11.5254 8.79213C11.6971 8.68023 11.8378 8.5287 11.9351 8.351Z" fill="#3BDFFD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.3988 6.07912C15.0315 5.13442 14.3649 4.32886 13.4961 3.77977C10.5614 1.78778 6.22621 3.84614 5.97584 7.30936C5.73271 9.51035 7.22633 11.9317 9.63416 12.1701L10.4855 12.2725C10.7384 12.1219 15.3221 11.634 15.3221 11.634L18.0612 11.369C18.3213 10.6176 18.7069 9.91341 19.2025 9.28497L15.5381 9.032C15.8148 8.05834 15.766 7.02382 15.3988 6.07912ZM9.0666 6.05052C6.80813 8.38149 10.2093 11.6952 12.5892 9.48973C14.847 7.16706 11.4576 3.8456 9.0666 6.05052Z" fill="#3BDFFD" />
            <path d="M39.9989 28.5991C39.5237 29.8203 38.9008 30.9795 38.1441 32.051C36.5719 34.3345 34.1774 35.9294 31.4562 36.5053C30.0819 36.7415 28.6822 36.7948 27.2937 36.6639C26.3573 36.6111 25.4277 36.4743 24.516 36.2551C22.8867 35.9419 21.4295 35.0447 20.4209 33.7336C20.1276 33.333 19.8925 33.3117 19.6115 33.6919C19.0077 34.4606 18.2535 35.0995 17.3946 35.5702C16.5357 36.0408 15.5898 36.3333 14.614 36.4301C12.6029 36.8252 10.5355 36.8452 8.51704 36.489C5.04325 35.7834 2.65096 33.6503 0.952281 30.657C0.604986 30.0628 0.312857 29.4382 0.0795193 28.7912C0.041716 28.6866 -0.0380003 28.5869 0.0211699 28.4577C0.206077 28.4749 0.314557 28.6163 0.449334 28.7078C1.24047 29.2608 2.13436 29.6515 3.07886 29.8571C4.02335 30.0628 4.99955 30.0793 5.95052 29.9056C6.73968 29.7586 7.46129 29.3655 8.0108 28.7831C8.71591 28.0898 9.37418 27.3515 10.0834 26.6712C11.0972 25.7011 12.4252 25.122 13.8292 25.0378C15.2333 24.9536 16.6216 25.3698 17.7451 26.2117C18.7288 27.0148 19.4514 28.0896 19.8218 29.3006C19.8596 29.4094 19.8933 29.5222 19.9311 29.6276C19.9353 29.6399 19.9476 29.6481 19.9607 29.6653C20.0906 29.6113 20.0783 29.4772 20.1119 29.3775C20.5196 27.9832 21.3968 26.7708 22.5963 25.9436C23.7095 25.238 25.0278 24.923 26.3416 25.0486C27.6555 25.1742 28.8894 25.7333 29.8471 26.6369C30.5308 27.291 31.162 28.0063 31.8359 28.6694C32.2557 29.096 32.757 29.4347 33.3105 29.6656C33.8639 29.8964 34.4581 30.0148 35.0582 30.0136C36.8459 30.06 38.5925 29.4759 39.989 28.3645C39.9986 28.4423 40.0019 28.5208 39.9989 28.5991V28.5991Z" fill="#00FDB4" />
        </svg>
    )
}

export default BarberIcon