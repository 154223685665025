import { Switch } from '@headlessui/react'
import React from 'react'
import { useState } from 'react'
import { cn } from '../../../../lib/utils'

export const InputSwitch = ({checked, onChange, ...props}) => {
    const [enabled, setEnabled] = useState(false)
    return (
        <Switch
            checked={enabled || checked}
            onChange={onChange || setEnabled(!enabled) }
            {...props}
            className={cn("relative inline-flex h-1.5 w-12 shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-[0px]", enabled || checked ? 'bg-success-950' : 'bg-light-750')} >
            <span className="sr-only">switch</span>
            <span aria-hidden="true" className={cn("pointer-events-none absolute -top-1.5 inline-block h-[18px] w-[18px] transform rounded-full shadow-lg transition duration-200 ease-in-out", enabled || checked ? 'translate-x-9 bg-success-950 ' : 'translate-x-0 bg-light-750')} />
        </Switch>
    )
}
