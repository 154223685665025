import React from 'react'
import CloseFillIcon from '../icons/CloseFillIcon'
import { cn } from '../../../../lib/utils'

const ButtonBadge = ({ icon, ref, iconClassName, className, children, ...props }) => {
    return (
        <button type='button' className={cn("shadow-100 border-[1.3px] outline-0 border-success-100 rounded-full py-0.5 flex items-center gap-2.5 text-base font-medium text-blue-950 !leading-none min-h-[30px]", !iconClassName ? "ps-1 pe-5" : "px-5", className)} {...props} ref={ref}>
            <span className={cn(`block leading-none flex-shrink-0 `, iconClassName)}>{icon || <CloseFillIcon className="w-6 h-6" />}</span>
            {children}
        </button>
    )
}

export default ButtonBadge