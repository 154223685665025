import React from 'react'

const UserGroupIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.5487 11.9373C27.1531 10.8273 28.134 10.0424 29.2545 9.72407C30.3722 9.40656 31.5658 9.5701 32.5802 10.1911C33.5984 10.8143 34.3641 11.8563 34.6802 13.1072C34.9966 14.3591 34.829 15.6908 34.2234 16.8029C33.6189 17.9129 32.6381 18.6978 31.5176 19.0161C30.3999 19.3336 29.2063 19.1701 28.1918 18.5491C27.1737 17.9259 26.408 16.8839 26.0918 15.633C25.7754 14.3811 25.9431 13.0494 26.5487 11.9373L26.5487 11.9373Z" stroke="#0DEFDA" strokeWidth="2.83333" />
            <path d="M6.98643 11.9373C7.59088 10.8273 8.57174 10.0424 9.69225 9.72407C10.8099 9.40656 12.0035 9.5701 13.018 10.1911C14.0362 10.8143 14.8018 11.8563 15.118 13.1072C15.4344 14.3591 15.2668 15.6908 14.6611 16.8029C14.0567 17.9129 13.0758 18.6978 11.9553 19.0161C10.8376 19.3336 9.64405 19.1701 8.62957 18.5491C7.6114 17.9259 6.84573 16.8839 6.52959 15.633C6.21318 14.3811 6.3808 13.0494 6.98643 11.9373L6.98643 11.9373Z" stroke="#0DEFDA" strokeWidth="2.83333" />
            <path d="M27.0114 12.2943C27.0114 16.1719 24.0661 19.172 20.6046 19.172C17.1431 19.172 14.1978 16.1719 14.1978 12.2943C14.1978 8.41676 17.1431 5.41667 20.6046 5.41667C24.0661 5.41667 27.0114 8.41676 27.0114 12.2943Z" fill="white" stroke="#0DEFDA" strokeWidth="2.83333" strokeLinecap="round" />
            <path d="M30.153 33.0327L28.7612 33.2971L28.9801 34.4494H30.153V33.0327ZM37.9434 31.7096L36.5715 32.063L36.5715 32.063L37.9434 31.7096ZM26.0453 26.2125L25.1566 25.1093L23.6454 26.3266L25.2652 27.3951L26.0453 26.2125ZM36.8586 31.6161H30.153V34.4494H36.8586V31.6161ZM36.5715 32.063C36.542 31.9483 36.5763 31.8133 36.6511 31.7238C36.7183 31.6434 36.8013 31.6161 36.8586 31.6161V34.4494C38.3883 34.4494 39.7537 33.0581 39.3153 31.3563L36.5715 32.063ZM30.3867 26.1551C32.4434 26.1551 33.8007 27.0583 34.7559 28.2376C35.7482 29.4627 36.2935 30.9835 36.5715 32.063L39.3153 31.3563C38.9902 30.0942 38.3143 28.1293 36.9576 26.4543C35.5638 24.7334 33.4443 23.3217 30.3867 23.3217V26.1551ZM26.934 27.3158C27.7794 26.6348 28.884 26.1551 30.3867 26.1551V23.3217C28.2114 23.3217 26.485 24.0392 25.1566 25.1093L26.934 27.3158ZM25.2652 27.3951C27.4912 28.8634 28.403 31.4115 28.7612 33.2971L31.5448 32.7683C31.1361 30.6168 30.0107 27.1311 26.8253 25.03L25.2652 27.3951Z" fill="#0DEFDA" />
            <path d="M10.8235 24.7378C15.9379 24.7378 17.7771 29.3674 18.3802 31.709C18.5597 32.4057 18.0148 33.0321 17.2954 33.0321H4.35154C3.63218 33.0321 3.08727 32.4056 3.26672 31.709C3.86989 29.3674 5.70914 24.7378 10.8235 24.7378Z" fill="white" stroke="#0DEFDA" strokeWidth="2.83333" strokeLinecap="round" />
            <path d="M20.6042 24.7378C28.3162 24.7378 29.9465 31.1855 30.2912 33.9116C30.3744 34.57 29.8456 35.1057 29.182 35.1057H12.0264C11.3628 35.1057 10.834 34.57 10.9173 33.9116C11.2619 31.1855 12.8922 24.7378 20.6042 24.7378Z" fill="white" stroke="#0DEFDA" strokeWidth="2.83333" strokeLinecap="round" />
        </svg>
    )
}

export default UserGroupIcon