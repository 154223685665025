import React from 'react'

function RightSmallArrowIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2541 38L30 20L11.2541 2" stroke="currentcolor" strokeWidth={5} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default RightSmallArrowIcon