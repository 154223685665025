import React from 'react'

const LoginIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_101_46)">
                <path d="M25 2H33C34.0609 2 35.0782 2.42143 35.8284 3.17157C36.5786 3.92171 37 6 37 6C38.5884 15.2657 38.5884 24.7343 37 34C37 34 36.6739 35.983 35.8284 36.8284C34.9831 37.6739 34.0609 38 33 38H25" stroke="currentcolor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.8 29.9983L27.8001 19.9982L17.8 9.99817" stroke="#00FDB4" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M25 20H1" stroke="#00FDB4" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_101_46">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LoginIcon