import React from 'react'
import CommonSliderWrapper from '../../../../uiComponents/common/CommonSliderWrapper'
import { withTranslation } from 'react-i18next'

const ServiceDetailInfo = ({ t, data }) => {

  const calculateDiscount = (price, discountPrice, discountType) => {
    // 1=per , 2=kr
    if (discountType == 1) {
      let discount = price * discountPrice / 100;
      return price - discount + " Dkk.";
    } else if (discountType == 2) {
      return price - discountPrice + " Dkk.";
    }
    return price ?? "" + " Dkk.";
  }

  const datalist = [
    {
      heading: t('form_input_label.service_type'),
      value: data?.service_type?.name
    },
    {
      heading: t('comman_text.service'),
      value: data?.title
    },
    {
      heading: t('comman_text.for'),
      value: data?.for_names?.join('-')
    },
    {
      heading: t('comman_text.location'),
      value: data?.service_location == 1 ? "Salon" : data?.service_location == 2 ? "On The Go" : "Salon & On The Go"
    },
    {
      heading: t('comman_text.duration'),
      value: data?.duration ?? "" + ' min.'
    },
    {
      heading: t('services_module.original_price'),
      value: data?.price ?? "" + ' Dkk.',
      disable: true
    },
    {
      heading: t('services_module.discount_price'),
      discount: data?.discount_type == 1 ? "-" + data?.discount_price + "%" : "",
      value: calculateDiscount(data?.price, data?.discount_price, data?.discount_type) ?? ""
    },
    {
      heading: t('services_module.senior_price') +" "+ t('services_module.65_years+'),
      value: calculateDiscount(data?.price, data?.senior_price, data?.seior_type) ?? ""
    },
    {
      heading: t('services_module.student_price'),
      value: calculateDiscount(data?.price, data?.student_price, data?.student_type) ?? ""
    }
  ]
  const images = data?.images;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 mb-4 md:mb-0">
      <div className='w-full border border-success-100 p-3 rounded-2xl'>
        {datalist.map((items, index) => (
          <div className="flex items-center justify-between leading-[1.9]">
            <p className={`text-blue-950 text-[15px] ${items?.disable ? "text-blue-950/40" : ""}`}>{items.heading}</p>
            <p className={`text-[15px] font-medium ${items.discount ? "text-danger-950" : "text-blue-950"} ${items.disable ? "text-blue-950/40" : "text-blue-950"}`}>{items.discount && <span className='badge me-2 rounded-full bg-danger-950 text-white text-sm px-2'>{items.discount}</span>}{items.value}</p>
          </div>
        ))}
      </div>
      {
        data?.image?.length > 0 ? 
        <div className='w-full md:pe-2'>
          <CommonSliderWrapper slidesToShow={2} SliderClassName="h-full !m-0" prevArrowClassName={`-start-2 bg-primary1 text-white`} groupClassName="m-0 h-full" nextArrowClassName={`-right-[8px] bg-primary1 text-white`}>
            {
              images?.map((image, index) => (
                <div className="h-full rounded-lg overflow-hidden">
                  <img src={image?.file} alt="" className='h-full w-full' />
                </div>
              ))
            }
          </CommonSliderWrapper>
        </div> : <></>
      }

    </div>
  )
}

export default withTranslation()(ServiceDetailInfo)