import React from 'react'

function MaleIcon(props) {
    return (
        <svg {...props} viewBox="0 0 20 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.19178 39.9893C7.5681 39.9893 6.94441 39.9893 6.32073 39.9893C4.85507 39.9786 3.92994 39.016 3.91954 37.4972C3.90915 35.7538 3.89875 33.9998 3.92994 32.2564C3.94033 31.7537 3.75323 31.5077 3.30625 31.3793C2.85928 31.251 2.4123 31.0799 1.98612 30.898C0.936252 30.4595 0.322962 29.6466 0.322962 28.4808C0.302173 25.1545 -0.0304591 21.8282 0.135857 18.4911C0.20862 17.0579 0.0319093 15.6568 0.000725084 14.2343C-0.0200644 13.1968 0.40612 12.4267 1.28967 11.9133C2.32915 11.3037 3.35823 10.6726 4.3977 10.0844C4.78231 9.87047 4.85507 9.73143 4.50165 9.36778C1.72625 6.55483 2.63059 1.91294 6.22717 0.372771C8.07744 -0.418704 10.489 0.08399 11.9131 1.55998C13.4931 3.19641 13.9505 5.49597 13.1293 7.51744C12.8486 8.22335 12.464 8.85439 11.9235 9.35708C11.5909 9.66726 11.5597 9.83839 11.9963 10.0844C13.0045 10.6406 13.9713 11.2609 14.9795 11.8278C15.967 12.3839 16.3932 13.1861 16.362 14.3519C16.2789 17.7852 16.2789 21.2292 16.1853 24.6625C16.1542 25.9139 16.1334 27.1546 16.1022 28.406C16.071 29.6146 15.5201 30.4702 14.4078 30.9087C13.9401 31.0906 13.4723 31.2724 12.9941 31.4328C12.6303 31.5505 12.4848 31.7644 12.4952 32.1815C12.516 33.9142 12.5056 35.6362 12.4952 37.3689C12.4952 39.0481 11.5701 39.9893 9.95889 40C9.36639 40 8.77389 39.9893 8.19178 39.9893Z" fill="currentColor" />
        </svg>
    )
}

export default MaleIcon