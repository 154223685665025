import React from 'react';

const HeartIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.0491 6.42919C34.1912 5.53473 33.1611 4.82302 32.0211 4.33681C30.8811 3.85062 29.6544 3.59998 28.4151 3.59998C27.1757 3.59998 25.949 3.85062 24.809 4.33681C23.669 4.82302 22.6389 5.53473 21.781 6.42919L19.9735 8.28884L18.1659 6.42919C17.3079 5.5351 16.2778 4.8237 15.1377 4.33774C13.9977 3.85177 12.7712 3.60126 11.5319 3.60126C10.2926 3.60126 9.06611 3.85177 7.92607 4.33774C6.78604 4.8237 5.75599 5.5351 4.89783 6.42919C3.13468 8.26482 2.15002 10.7114 2.15002 13.2566C2.15002 15.8018 3.13468 18.2485 4.89783 20.084L6.70535 21.9451L19.9735 35.6L33.2416 21.9451L35.0491 20.084C36.8139 18.2493 37.7997 15.8024 37.7997 13.2566C37.7997 10.7108 36.8139 8.26396 35.0491 6.42919Z" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default HeartIcon;
