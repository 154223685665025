import React from 'react'

const SortIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.57196 4.58672C8.94747 4.21132 9.45672 4.00043 9.9877 4.00043C10.5187 4.00043 11.0279 4.21132 11.4034 4.58672L19.4133 12.5966C19.778 12.9742 19.9799 13.4801 19.9753 14.0051C19.9708 14.5301 19.7602 15.0324 19.3889 15.4036C19.0176 15.7749 18.5154 15.9855 17.9903 15.9901C17.4653 15.9946 16.9595 15.7928 16.5818 15.428L11.9902 10.8364V34.0369C11.9902 34.568 11.7792 35.0773 11.4037 35.4529C11.0281 35.8284 10.5188 36.0394 9.9877 36.0394C9.45661 36.0394 8.94728 35.8284 8.57174 35.4529C8.19621 35.0773 7.98524 34.568 7.98524 34.0369V10.8364L3.39359 15.428C3.01593 15.7928 2.5101 15.9946 1.98506 15.9901C1.46002 15.9855 0.95778 15.7749 0.586507 15.4036C0.215235 15.0324 0.00463814 14.5301 7.56983e-05 14.0051C-0.00448675 13.4801 0.19735 12.9742 0.562114 12.5966L8.57196 4.58672ZM28.0098 29.203V6.00246C28.0098 5.47138 28.2208 4.96204 28.5963 4.58651C28.9719 4.21097 29.4812 4 30.0123 4C30.5434 4 31.0527 4.21097 31.4283 4.58651C31.8038 4.96204 32.0148 5.47138 32.0148 6.00246V29.203L36.6064 24.6113C36.9841 24.2466 37.4899 24.0447 38.0149 24.0493C38.54 24.0539 39.0422 24.2644 39.4135 24.6357C39.7848 25.007 39.9954 25.5092 39.9999 26.0343C40.0045 26.5593 39.8027 27.0651 39.4379 27.4428L31.428 35.4527C31.0525 35.8281 30.5433 36.0389 30.0123 36.0389C29.4813 36.0389 28.9721 35.8281 28.5966 35.4527L20.5867 27.4428C20.222 27.0651 20.0201 26.5593 20.0247 26.0343C20.0292 25.5092 20.2398 25.007 20.6111 24.6357C20.9824 24.2644 21.4846 24.0539 22.0097 24.0493C22.5347 24.0447 23.0405 24.2466 23.4182 24.6113L28.0098 29.203Z" fill="currentcolor" />
        </svg>
    )
}

export default SortIcon