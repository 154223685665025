import React from 'react'
import { cn } from '../../../lib/utils';

const Step = ({ groupClassName, stepGroupClassName, stepCountClassName, stepItemClassName, data, step }) => {

    return (
        <div className={cn("steps-wrap", groupClassName)}>
            <div className={cn('text-center w-full text-[22px] font-bold text-white', stepCountClassName)}>{step}/{data.length}</div>
            <ul className={cn("flex items-start lg:max-w-[60%] max-w-[unset] mx-auto", stepGroupClassName)}>
                {data.map((items, index) => {
                    return (
                        <li key={"ind"+index} className={cn("flex flex-col items-center flex-grow md:gap-4 gap-2 relative first:before:w-0 before:absolute before:w-full before:h-[2px] sm:before:top-[18px] before:top-3 before:end-1/2", step > index ? "before:bg-primary1" : "before:bg-gray-500/40", stepItemClassName)}>
                            <div className={cn("sm:h-9 h-7 sm:w-9 w-7 rounded-full flex items-center justify-center z-[1]", step > index ? "bg-white text-white font-semibold border-[3px] border-success-800" : "bg-success-500 border border-gray-500/10 text-gray-500")}></div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Step;