import React from 'react'
import { withTranslation } from 'react-i18next'
import Rating from './Rating'
import ButtonPrimary from '../buttons/ButtonPrimary'
import Badge from './Badge'
import { cn } from '../../../../lib/utils'
import { useNavigate } from 'react-router-dom'

const PortfolioDetailsPortfolioCard = ({ t, PortfolioData, groupClassName, data, navigateUrl, handelClickEvent, ...props }) => {
    const navigate = useNavigate();
    return (
        <div className={cn('border-[1.3px] border-success-100 rounded-[10px] h-full flex flex-col p-1.5 ', groupClassName, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(navigateUrl, { state: { id: data.salon_id || data.id, data: data } })} >
            <div className='grid grid-cols-2 gap-1 flex-grow h-[calc(100%-30px)]'>
                {PortfolioData?.images?.map((item, ind) => (
                    <div key={ind} className='flex flex-col gap-1 justify-start items-center w-full h-full'>
                        <div className="flex items-center justify-center mb-1.5">
                            <Badge
                                text={t(item?.type == 0 ? "form_input_label.after" : "form_input_label.before")}
                                groupClassName={`mb-0 w-full max-w-[94px] w-full gap-0 font-medium text-sm py-0 border-success-850 justify-center px-${item?.type == 0 ? "6" : "5"} sm:px-${item?.type == 0 ? "6" : "5"} ${item?.type == 1 ? "bg-white" : ""}`}
                            />
                        </div>
                        <div className='img_wrapper rounded-lg h-full w-full object-cover object-center overflow-hidden'>
                            <img src={item?.image?.file} alt="" className='img-fluid w-full h-full' />
                        </div>
                    </div>
                ))}
            </div>
            <div className='flex justify-between items-center pt-1 flex-shrink-0'>
                <h2 className='text-blue-950 leading-none text-base'>{PortfolioData?.provider?.first_name}</h2>
                <div className='flex gap-1 justify-center items-center'>
                    <Rating
                        starClass={"h-5 w-5"}
                        starCount={1} rate={4.5} selected={1}
                        groupClass={" items-end"}
                        wrapperClass=" flex-row-reverse justify-end items-end gap-0.5 p-0 border-0"
                        showPeopleCount={false}
                        textClassName="m-0 mt-0 pb-0 text-sm" />
                    <ButtonPrimary size={"sm"} className={"text-sm lg:text-sm font-semibold px-1.5 py-0"}> More</ButtonPrimary>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(PortfolioDetailsPortfolioCard)