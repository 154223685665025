import React from 'react'
import CircleUser from '../../../uiComponents/icons/line-icon/CircleUser'
import { cn } from '../../../../../lib/utils'
import LocationPin4 from '../../../uiComponents/icons/LocationPin4'
import EducationIcon from '../../../uiComponents/icons/line-icon/EducationIcon'
import HandIcon from '../../../uiComponents/icons/line-icon/HandIcon'
import ImageIcon from '../../../uiComponents/icons/ImageIcon'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import LinkIcon from '../../../uiComponents/icons/line-icon/LinkIcon'

const CustomerDetails = ({ groupClassName, className, iconClass, setWorkerProtfolioModalOpen, workerData }) => {
    const isStudent = workerData?.educations?.some(education => education?.is_student == 1);
    // Function to handle education range
    const handleRange = () => {
        switch (workerData?.education_range) {
            case 1:
                return "Low - ";
            case 2:
                return "Medium - ";
            case 3:
                return "High - ";
            default:
                return "No ";
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(2);
        return `${day}-${month}-${year}`;
    }
    return (
        <div className={cn('flex-col justify-start gap-2.5 h-auto grid sm:grid-cols-2', groupClassName)}>
            {
                isStudent ? (
                    <div className={cn('text-sm font-normal leading-none text-blue-950 flex items-center gap-2', className)}><CircleUser className={cn('h-5 w-5 text-dark-900', iconClass)} stroke_width='2px' />Student</div>
                ) : null
            }
            <div className={cn('text-sm font-normal leading-none text-blue-950 flex items-center gap-2', className)}><LocationPin4 className={cn('h-5 w-5 text-dark-900', iconClass)} />{workerData?.service_location == 1 ? "Salon" : workerData?.service_location == 2 ? "On The Go" : "Salon & On The Go"} </div>
            <div className={cn('text-sm font-normal leading-none text-blue-950 flex items-center gap-2', className)}><EducationIcon className={cn('h-5 w-5 text-dark-900', iconClass)} />  {handleRange()}Education</div>
            <div className={cn('text-sm font-normal leading-none text-blue-950 flex items-center gap-2', className)}><LinkIcon className={cn('h-5 w-5 text-dark-900', iconClass)} />{formatDate(workerData?.created_at)} Member</div>
            <div className={cn('text-sm font-normal leading-none text-blue-950 flex items-center gap-2', className)}><HandIcon className={cn('h-5 w-5 text-dark-900', iconClass)} />{workerData?.customer_served} Customers served</div>
            {/* <div className={cn('text-sm font-normal leading-none text-blue-950 flex items-center gap-2', className)}><ImageIcon className={cn('h-5 w-5 text-dark-900', iconClass)} /> <ButtonPrimary size={"sm"} className={"py-0.5 rounded-[5px] text-[15px] lg:text-[15px] px-2 !leading-[16px]"} onClick={() => setWorkerProtfolioModalOpen(true)}>Portfolio</ButtonPrimary> </div> */}
            {workerData?.protfolios?.length > 0 && (
                <div className={cn('text-sm font-normal leading-none text-blue-950 flex items-center gap-2', className)}>
                    <ImageIcon className={cn('h-5 w-5 text-dark-900', iconClass)} />
                    <ButtonPrimary size={"sm"} className={"py-0.5 rounded-[5px] text-[15px] lg:text-[15px] px-2 !leading-[16px]"} onClick={() => setWorkerProtfolioModalOpen(true)}>Portfolio</ButtonPrimary>
                </div>
            )}
        </div>
    )
}

export default CustomerDetails