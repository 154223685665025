import React, { useEffect, useState } from 'react'
import InputCheckbox from './InputCheckbox'
import { cn } from '../../../../lib/utils';

const InputCheckBoxGroup = ({ selcetAll, data, selectValue, setCheckValue, groupClassName, itemGroupClassName, itemClassName, itemMainClassName, checkSize, ...props }) => {
    // Function to handle checkbox change

    const [checkAll, setCheckAll] = useState(false);

    const handleCheckboxChange = (option) => {
        if (option === "All") {
            setCheckAll(!checkAll)
            if (checkAll) {
                setCheckValue([]);
            } else {
                const allLabels = data?.map(item => item.label);
                setCheckValue(allLabels);
            }
        } else {
            option = option + ""
            if (selectValue?.includes(option)) {
                setCheckValue(selectValue.filter(item => item+"" !== option))}
             else {
                setCheckValue([...selectValue, option]);
            }
        }
    };

    useEffect(() => {
        if (selcetAll && data && data.length > 0) {
            const allLabels = data.map(item => item.label);
            const allSelected = allLabels.every(label => selectValue.includes(label));
            setCheckAll(allSelected);
        }
    }, [data, selectValue]);


    return (
        <div className={cn(groupClassName)}  >
            {
                selcetAll &&
                <InputCheckbox
                    size={checkSize}
                    checked={checkAll}
                    text={"All"}
                    value={"All"}
                    groupClassName={itemGroupClassName}
                    className={itemClassName}
                    mainClassName={itemMainClassName}
                    {...props}
                    onChange={(item) => handleCheckboxChange(item.target.value)}
                />}
            {
                data && data?.map((item, ind) => (
                    <InputCheckbox size={checkSize} groupClassName={itemGroupClassName} key={ind} mainClassName={itemMainClassName} className={itemClassName} id={item?.id} text={item?.label} checked={selectValue && selectValue?.includes(""+item.value) ? true : false} value={item.value+""} {...props} 
                    onChange={(item) => handleCheckboxChange(item.target.value)}
                    />
                ))
            }
        </div>
    )
}

export default InputCheckBoxGroup