import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
 
i18n.use(Backend).use(LanguageDetector).init({
   fallbackLng: "en",
   debug: false,
 
   // for all available options read the backend's repository readme file
   backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
   // have a common namespace used around the full app
   ns: ["translations"],
   defaultNS: "translations",
 
   // keySeparator: false, // we use content as keys
   interpolation: {
       escapeValue: false, // not needed for react!!
       formatSeparator: ","
   },
 
   react: { wait: true,useSuspense: false }
});
 
if (localStorage.getItem('i18nextLng') === '' || localStorage.getItem('i18nextLng') === null) {
   localStorage.setItem('i18nextLng', 'dk');
}
export default i18n;