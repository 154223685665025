
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { useNavigate } from 'react-router-dom';
import { PATH_CUSTOMER } from '../../routes/path';

const CancelBookingModal = ({ t, modalOpen, setModalOpen, title, description, hoursLeft }) => {    
    const navigate = useNavigate();
    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[560px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header mb-5">
                                    <h2 className="font-semibold text-xl text-center text-black-950">{t("delete_modal_message.cancel_booking")}</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {hoursLeft && 
                                        <>
                                            {
                                                title &&
                                                <h5 className='text-center text-base mb-4'>{t("cancel_booking_modal.cancel_modal_text")}</h5>
                                            }
                                            {
                                                description &&
                                                <h5 className='text-center text-base mb-4'>{description} </h5>
                                            }
                                            <div className='w-full flex flex-row justify-center gap-x-5 gap-y-3 mt-3'>
                                                <ButtonPrimary size="sm" onClick={() => setModalOpen(false)} className="max-w-[172px] w-full"  >{t('radio_label.no')}</ButtonPrimary>
                                                <ButtonPrimary size="sm" onClick={() => navigate(PATH_CUSTOMER.findService)} className="max-w-[172px] w-full !bg-danger-950 border-danger-950 " >{t('radio_label.yes')}</ButtonPrimary>
                                            </div>

                                        </>
                                    }
                                    {/* ---------------------------------first message------------------------------ */}
                                    

                                    {/* ---------------------------------secound message------------------------------ */}
                                    {!hoursLeft && 
                                        <>
                                            <h5 className='text-center text-base mb-4'>{t("cancel_booking_modal.cancel_modal_text")}
                                            <br />
                                            {t("cancel_booking_modal.cancel_modal_text2")}</h5>

                                            <ul className='text-center flex flex-col gap-1 text-blue-950 text-sm mb-1'>
                                                <li>{t("cancel_booking_modal.cancel_modal_text3")}</li>
                                                <li>{t("cancel_booking_modal.cancel_modal_text4")}</li>
                                                <li>{t("cancel_booking_modal.cancel_modal_text5")}</li>
                                            </ul>
                                            <div className='w-full flex flex-row justify-center gap-x-5 gap-y-3 mt-3'>
                                                <ButtonPrimary size="sm" onClick={() => setModalOpen(false)} className="max-w-[172px] w-full"  >{t('radio_label.no')}</ButtonPrimary>
                                                <ButtonPrimary size="sm" onClick={() => navigate(PATH_CUSTOMER.findService)} className="max-w-[172px] w-full !bg-danger-950 border-danger-950 " >{t('radio_label.yes')}</ButtonPrimary>
                                            </div>
                                        </>
                                    }   
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
export default withTranslation()(CancelBookingModal)