import React from 'react'

const DragandDropIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00661 31.0065C9.64405 31.0065 10.1608 30.4898 10.1608 29.8523C10.1608 29.215 9.64405 28.6981 9.00661 28.6981V31.0065ZM30.9934 28.6981C30.3559 28.6981 29.8392 29.215 29.8392 29.8523C29.8392 30.4898 30.3559 31.0065 30.9934 31.0065V28.6981ZM18.8458 36.1343C18.8458 36.7718 19.3625 37.2885 20 37.2885C20.6374 37.2885 21.1542 36.7718 21.1542 36.1343H18.8458ZM20 23.5704L20.8162 22.7544C20.3654 22.3036 19.6346 22.3036 19.1839 22.7544L20 23.5704ZM23.8953 29.098C24.3461 29.5488 25.0768 29.5488 25.5276 29.098C25.9782 28.6473 25.9782 27.9165 25.5276 27.4657L23.8953 29.098ZM14.4724 27.4657C14.0216 27.9165 14.0216 28.6473 14.4724 29.098C14.9232 29.5488 15.6539 29.5488 16.1047 29.098L14.4724 27.4657ZM9.00661 28.6981C5.30729 28.6981 2.3084 25.6993 2.3084 21.9999H0C0 26.9741 4.0324 31.0065 9.00661 31.0065V28.6981ZM37.6916 21.9999C37.6916 25.6993 34.6927 28.6981 30.9934 28.6981V31.0065C35.9676 31.0065 40 26.9741 40 21.9999H37.6916ZM32.3257 15.434C35.3868 16.0513 37.6916 18.7578 37.6916 21.9999H40C40 17.6373 36.8992 14.0013 32.7819 13.1712L32.3257 15.434ZM7.21802 13.1712C3.10074 14.0013 0 17.6373 0 21.9999H2.3084C2.3084 18.7578 4.61313 16.0513 7.67428 15.434L7.21802 13.1712ZM10.2599 12.7447C10.94 7.97555 15.0427 4.3084 20 4.3084V2C13.8775 2 8.81462 6.52834 7.97463 12.4188L10.2599 12.7447ZM20 4.3084C24.9572 4.3084 29.06 7.97555 29.74 12.7447L32.0254 12.4188C31.1854 6.52834 26.1225 2 20 2V4.3084ZM7.67428 15.434C8.96651 15.1735 10.0633 14.1238 10.2599 12.7447L7.97463 12.4188C7.92639 12.7571 7.62629 13.0889 7.21802 13.1712L7.67428 15.434ZM32.7819 13.1712C32.3737 13.0889 32.0736 12.7571 32.0254 12.4188L29.74 12.7447C29.9366 14.1238 31.0335 15.1735 32.3257 15.434L32.7819 13.1712ZM21.1542 36.1343V23.5704H18.8458V36.1343H21.1542ZM19.1839 24.3865L23.8953 29.098L25.5276 27.4657L20.8162 22.7544L19.1839 24.3865ZM19.1839 22.7544L14.4724 27.4657L16.1047 29.098L20.8162 24.3865L19.1839 22.7544Z" fill="currentcolor" />
        </svg>
    )
}

export default DragandDropIcon