import React from 'react';

const CircleUser = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 1.475C30.2311 1.475 38.525 9.76892 38.525 20C38.525 30.2311 30.2311 38.525 20 38.525C9.76892 38.525 1.475 30.2311 1.475 20C1.475 9.76892 9.76892 1.475 20 1.475Z" stroke="currentColor" stroke-width={props.stroke_width} />
            <path d="M15.4644 22.7037C16.8385 22.806 18.3652 22.8875 19.7427 22.8875C21.1825 22.8875 22.7657 22.7983 24.1635 22.6895C26.1777 22.533 28.0133 23.895 28.3632 25.8603L28.8063 25.7815L28.3632 25.8603C28.7728 28.1606 27.0039 30.2722 24.6674 30.2722H19.7427H14.9739C12.7525 30.2722 11.0198 28.3488 11.2512 26.1395L11.2512 26.1395C11.4688 24.0607 13.3552 22.5468 15.4644 22.7037ZM15.4644 22.7037L15.4978 22.255L15.4644 22.7037Z" stroke="currentColor" stroke-width={props.stroke_width} />
            <path d="M15.6139 12.735C15.6139 10.3131 17.5825 8.34862 20.0056 8.34862C22.4289 8.34862 24.3974 10.3131 24.3974 12.735V13.4213C24.3974 15.4648 22.7407 17.1214 20.6972 17.1214H19.314C17.2705 17.1214 15.6139 15.4648 15.6139 13.4213V12.735Z" stroke="currentColor" stroke-width={props.stroke_width} />
        </svg>

    );
}

export default CircleUser;
