import React from 'react'
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { cn } from '../../../../lib/utils';

const SlotTimeListCard = ({ data, t}) => {
  return (
    <ul className={cn("time-list-card !border border-success-100 bg-light-850 rounded-[5px] !px-4 !py-2 [&>li:last-child]:border-0")}>
      {
        data?.length ? data?.map((availability) => (
          <li className={cn("py-1.5" , data?.length - 1 ? "border-b border-success-100" : "")}>
            <p className="text-[15px] leading-[18px] font-normal text-blue-950 mb-0 flex items-center !gap-2 justify-between">
              <span>{t(availability?.day)}</span> {availability.status ? <span>{moment.utc(availability.slots[0].from, 'HH:mm').local().format("HH:mm") + " - " + moment.utc(availability.slots[availability.slots.length - 1].to, 'HH:mm').local().format("HH:mm")}</span> : <span className='text-danger-950'>Closed</span>} {" "}
            </p>
          </li>
        ))
          : <li><p className="text-[15px] leading-[18px] font-normal text-blue-950 mb-0 text-center">{t("comman_text.no_data_available")}</p></li>
      }
    </ul>
  )
}

export default withTranslation()(SlotTimeListCard)