import React from 'react'

const WatchIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.625 11.8262V20.8623C19.625 21.6516 19.9782 22.3981 20.5849 22.8912L26.0192 27.3076M27.9374 1L34.8645 5.22222M11.3125 1L4.38541 5.22222M36.2499 22.1111C36.2499 31.4385 28.8066 39 19.625 39C10.4433 39 3 31.4385 3 22.1111C3 12.7836 10.4433 5.22222 19.625 5.22222C28.8066 5.22222 36.2499 12.7836 36.2499 22.1111Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
        </svg>
    )
}

export default WatchIcon