import React from 'react'

const MasseuseIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.25 28.4981C31.25 28.7031 31.25 28.908 31.2915 29.0618C31.9972 31.8286 35.1935 32.7509 36.8125 32.4435C40.5484 31.9824 39.9673 27.6783 39.9673 27.6783C39.9673 27.6783 39.8427 23.323 36.1068 23.7842C34.4463 23.8867 31.4576 25.6288 31.25 28.4981Z" fill="#00FDB4" />
            <path d="M16.0425 13.4451C16.1622 13.5438 16.2819 13.6424 16.4016 13.6424C18.2769 14.2833 20.2719 11.9168 20.8305 10.3885C22.2669 6.93738 19.5537 5.50765 19.5537 5.50765C19.5537 5.50765 17.04 3.6342 15.6036 7.08529C14.9253 8.56433 14.5263 11.9661 16.0425 13.4451Z" fill="#3BDFFD" />
            <path d="M28.0194 33.1906V34.7555C28.0194 34.9022 27.9335 35 27.8046 35L19.6394 34.9022C18.3501 34.9022 17.8345 33.9731 18.0064 33.1417C18.2212 32.1637 20.8427 30.1588 21.8311 29.9142C21.8311 29.9142 20.284 29.9142 18.8658 31.1368C18.8229 31.1857 18.7369 31.1857 18.651 31.1857H0.257848C0.128924 31.1857 0 31.039 0 30.8923V28.4961C0 28.2516 0.0859493 28.0071 0.257848 27.8115C1.11734 26.8335 1.97683 25.8554 3.09417 25.2197C5.071 24.095 7.64948 23.7527 10.142 25.1708C12.2907 26.3444 14.4395 27.1758 16.889 26.4911C18.2212 26.0999 19.4675 25.3175 20.7997 24.8285C22.2179 24.3395 23.722 23.7038 25.0972 23.7527C25.8707 23.8016 26.6442 23.9483 27.2889 24.3884C28.5781 25.1708 28.75 26.1977 28.75 27.2247C28.75 29.9142 23.9368 31.8214 23.1203 32.1637C23.1203 32.2126 23.1203 32.1637 23.1203 32.2126C24.9682 32.1637 27.3318 32.8972 27.9335 33.0928C27.9765 32.995 28.0194 33.0928 28.0194 33.1906Z" fill="#00FDB4" />
            <path d="M18.7318 32.5521C18.4072 32.7865 18.1291 33.0729 18.1291 33.3594C18.1291 33.4115 18.1754 33.4896 18.1754 33.5417C18.2218 33.5937 18.3609 33.6719 18.3145 33.75H0.231829C0.0927317 33.75 0 33.6979 0 33.6198V32.6302C0 32.5521 0.0927317 32.5 0.231829 32.5H18.6391C18.7318 32.5 18.7782 32.526 18.7318 32.5521Z" fill="#00FDB4" />
            <path d="M15.9049 24.1406L14.7822 19.2269C14.7373 19.017 15.0067 18.933 15.1415 19.059L18.1055 22.0407C18.1504 22.0827 18.1953 22.0827 18.2402 22.0827L24.1234 22.9227C24.3928 22.9647 24.6174 22.7967 24.7072 22.5867L24.9766 21.6628C25.0665 21.4108 24.8868 21.1588 24.6174 21.0748L19.8121 19.8149C19.7671 19.8149 19.7222 19.7729 19.7222 19.7309L15.6804 14.9433C15.2762 14.4813 14.7373 14.0613 14.1535 13.8934C13.2553 13.5994 11.908 13.6414 10.5158 15.1533C10.5158 15.1533 8.0907 18.135 7.50688 22.3767C7.46197 22.6287 7.64161 22.8807 7.91106 22.9647C10.9649 23.8886 13.6595 25.7365 15.6355 24.6865C15.86 24.6025 15.9498 24.3506 15.9049 24.1406Z" fill="#3BDFFD" />
        </svg>
    )
}

export default MasseuseIcon