import React from 'react'

const ImageIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_815_6)">
                <path d="M22.9986 27.7509C18.9347 27.7509 14.8735 27.7613 10.8096 27.7456C8.36864 27.7378 6.92662 26.28 6.92138 23.8181C6.9109 17.9215 6.90827 12.0224 6.92138 6.12582C6.92662 3.4358 8.25066 2.03573 10.9328 2.02524C19.0133 1.99116 27.0965 1.99116 35.1771 2.02787C37.6994 2.03835 38.963 3.37288 38.9683 5.85315C38.9787 11.8808 38.9787 17.911 38.9683 23.9387C38.963 26.453 37.6862 27.7378 35.185 27.7482C31.1237 27.7613 27.0624 27.7509 22.9986 27.7509ZM9.35182 22.5386C10.765 21.0966 12.0943 19.7202 13.4445 18.3672C16.3418 15.4648 16.5566 15.4334 19.585 18.0683C20.3715 18.7526 20.8749 18.7133 21.5067 17.932C23.0955 15.9656 24.7186 14.0228 26.3467 12.0879C27.6498 10.5358 29.2229 10.4807 30.6072 11.9227C31.0896 12.4261 31.5301 12.9715 32.0021 13.4854C33.6434 15.2708 35.0459 17.2687 36.8026 18.9546C37.1539 18.6714 37.0411 18.3437 37.0411 18.0579C37.0491 14.0858 37.0595 10.1136 37.0411 6.14418C37.0333 4.37966 36.564 3.94182 34.7392 3.9392C26.9261 3.93133 19.1157 3.93133 11.3025 3.9392C9.40165 3.94182 9.02147 4.33248 9.01886 6.26478C9.01099 11.2411 9.01362 16.2147 9.02147 21.191C9.02147 21.5632 8.91136 21.9696 9.35182 22.5386ZM22.9723 25.6376C27.0336 25.6376 31.0949 25.6403 35.1562 25.635C35.864 25.635 36.7003 25.6638 36.897 24.8197C37.1514 23.7262 37.272 22.6251 36.383 21.621C34.068 19.007 31.8237 16.3327 29.5558 13.6794C28.6408 12.6096 28.4546 12.607 27.5738 13.661C25.6466 15.9709 23.7379 18.3018 21.7715 20.5774C20.8014 21.7022 20.3794 21.6394 19.3437 20.5434C18.835 20.0059 18.2478 19.5418 17.7523 18.9912C17.005 18.1574 16.339 18.1602 15.5525 18.965C13.8772 20.685 12.1467 22.3525 10.4609 24.0619C10.1122 24.4158 9.4331 24.7253 9.63237 25.2784C9.84211 25.8526 10.5526 25.6272 11.0482 25.6272C15.0255 25.6454 19.0003 25.6376 22.9723 25.6376Z" fill="currentcolor" fillOpacity="1" />
                <path d="M28.1395 37.1989C27.2664 37.1805 26.1469 36.7846 25.0115 36.4779C18.103 34.619 11.2023 32.7312 4.28846 30.8854C1.13176 30.0438 0.279661 28.7224 1.08457 25.5238C1.92357 22.1809 2.83334 18.8565 3.71691 15.524C3.89258 14.8633 4.1233 14.1633 5.00424 14.415C5.85896 14.6588 5.7436 15.4139 5.57581 16.0536C4.72894 19.3048 3.83227 22.5427 2.99066 25.7965C2.39549 28.1011 2.67341 28.4707 4.97802 29.0816C12.4844 31.0689 19.9854 33.0773 27.4866 35.0909C28.8762 35.4632 29.4347 35.2509 29.8909 33.8901C30.3077 32.6526 30.5962 31.3705 30.9554 30.112C31.1362 29.4776 31.464 28.9033 32.2269 29.1078C33.0134 29.3176 32.9374 29.9809 32.7723 30.6153C32.4235 31.9683 32.1194 33.3342 31.7288 34.6741C31.2621 36.276 30.0325 37.1857 28.1395 37.1989Z" fill="currentcolor" fillOpacity="1" />
                <path d="M15.0518 13.3875C13.1956 13.3771 11.6539 11.8564 11.7142 10.0945C11.7719 8.4375 13.3869 6.83292 15.0256 6.8067C16.861 6.77524 18.4786 8.3772 18.4523 10.1967C18.4235 12.0714 17.0104 13.398 15.0518 13.3875ZM14.9968 11.4343C15.82 11.4238 16.389 11.0305 16.4387 10.2361C16.4939 9.38136 15.9065 8.81766 15.1121 8.7626C14.3125 8.70492 13.872 9.3132 13.8038 10.084C13.733 10.9178 14.184 11.3635 14.9968 11.4343Z" fill="currentcolor" fillOpacity="1" />
            </g>
            <defs>
                <clipPath id="clip0_815_6">
                    <rect width="40" height="35.2" fill="white" transform="translate(0 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ImageIcon