import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import InputWrap from '../uiComponents/inputs/InputWrap';
import DropZone from '../uiComponents/inputs/DropZone';
import DragandDropIcon from '../uiComponents/icons/DragandDropIcon';
import { isEmpty } from 'lodash';

const AddSalonGuidelinesModal = ({ modalOpen, setModalOpen, t, setGuidelines }) => {
    const [fileList, setFileList] = useState([]);
    const [fileName, setFileName] = useState("");
    const allowedTypes = ".pdf, application/pdf";
    const [documentError, setDocumentError] = useState(false);
    const [titleError, setTitleError] = useState(false);

    const setOnDone = async (e) => {
        if (!isEmpty(fileName)) {
            setTitleError(false);
            if (fileList?.length > 0) {
                setDocumentError(false);
                setGuidelines([{ title: fileName === "" ? fileList[0].name : fileName, fileList }])
                setModalOpen(false)
            } else {
                setDocumentError(true);
            }
        } else {
            setTitleError(true);
        }
    }

    const handleFileName = (e) => {
        let text = e.target.value;
        setFileName(text);
    }

    const handleCloseClick = () => {
        setModalOpen(false)
        setDocumentError(false);
        setTitleError(false);
    }

    useEffect(() => {
        if (modalOpen) {
            setFileName("");
            setFileList([]);
            setGuidelines([])
            setDocumentError(false)
            setTitleError(false)
        }

    }, [modalOpen])

    useEffect(() => {
        if (fileList.length > 0) {
            setDocumentError(false);
        }
        if (fileName.length > 0) {
            setTitleError(false);
        }
    }, [])

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={handleCloseClick}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[516px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">{t("salon_guidelines.add_salon_guidelines")}</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <InputWrap groupClassName={"mb-4"} labelClassName="text-[15px] font-medium" label={"Title"} placeholder={t('input_filed_placeholder.enter_file_name')} onChange={(e) => handleFileName(e)} />
                                    {titleError && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{t('validation_message.add_salon_guidelines')}</p>}
                                    <DropZone
                                        id={t('input_filed_placeholder.enter_file_name')}
                                        accept={allowedTypes}
                                        heading="Add file(s)"
                                        document
                                        fileSelected={fileList}
                                        setFileSelected={setFileList}
                                        single={true}
                                        icon={<DragandDropIcon className="h-7 w-7 text-primary1" />} groupClassName="mb-5" labelTextClass="mb-1.5 text-[15px]" />
                                    {documentError && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{t('validation_message.upload_document')}</p>}
                                    <ButtonPrimary type="button" size="sm" children={"Upload"} onClick={() => setOnDone()} className="w-full" />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(AddSalonGuidelinesModal)