import React from 'react'

const EducationIcon = (props) => {
    return (
        <svg {...props} width={41} height={40} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_539_32)">
                <path d="M1 8.86434C1.23945 8.31596 1.64548 8.05424 2.11397 7.85483C8.20439 5.28745 14.2844 2.72006 20.3644 0.140209C20.7913 -0.0467364 21.1869 -0.0467364 21.6137 0.140209C26.5798 2.24646 31.5458 4.34025 36.5118 6.44651C37.6883 6.94503 38.8647 7.44355 40.0412 7.92961C40.5825 8.15395 40.9677 8.55276 40.999 9.30055C41.0198 10.0359 40.7283 10.5219 40.0516 10.821C38.9272 11.3071 37.8028 11.7807 36.668 12.2667C31.6395 14.3979 26.6214 16.5416 21.5929 18.6852C21.1661 18.8722 20.7704 18.8597 20.3436 18.6728C14.274 16.0555 8.19398 13.4757 2.11397 10.8958C1.64548 10.6964 1.23945 10.4222 1 9.88631C1 9.54981 1 9.20084 1 8.86434Z" fill="#00FDB4" />
                <path d="M9.18497 20.1123C9.18497 19.19 9.18497 18.2802 9.18497 17.358C9.18497 16.7971 9.21621 16.7722 9.64306 16.9592C12.808 18.3052 15.9833 19.6138 19.117 21.0221C20.3976 21.5954 21.5844 21.5954 22.865 21.0221C26.0195 19.6138 29.2053 18.3052 32.3806 16.9467C32.693 16.8096 32.7971 16.8844 32.7971 17.2832C32.7867 19.1651 32.7971 21.047 32.7866 22.9414C32.7866 23.6643 32.5368 24.2625 32.1099 24.7735C31.3812 25.6459 30.4754 26.1569 29.528 26.5682C27.6332 27.4032 25.676 27.8394 23.677 28.0762C20.4601 28.4501 17.2847 28.1884 14.1614 27.2162C12.8809 26.8299 11.642 26.3314 10.528 25.4465C9.61182 24.7236 9.08086 23.7889 9.16415 22.4055C9.21621 21.6328 9.18497 20.8725 9.18497 20.1123Z" fill="#3BDFFD" />
                <path d="M38.2016 20.6495C38.2016 22.5189 38.2016 24.3884 38.2016 26.2578C38.2016 26.5694 38.2744 26.7688 38.4827 26.9682C39.7007 28.1522 39.7112 30.246 38.5035 31.43C38.2744 31.6543 38.2016 31.8911 38.2016 32.2276C38.212 34.3339 38.212 36.4277 38.2016 38.5339C38.2016 39.2942 37.8164 39.8301 37.1917 39.9796C36.7024 40.1043 36.1402 39.7428 35.9112 39.1571C35.807 38.9078 35.807 38.6461 35.807 38.3844C35.807 36.328 35.807 34.2716 35.8175 32.2152C35.8175 31.8787 35.7446 31.6543 35.5155 31.43C34.3079 30.2335 34.3183 28.1398 35.5468 26.9682C35.7758 26.7564 35.8279 26.5196 35.8175 26.208C35.8175 22.8928 35.807 19.5777 35.8175 16.2625C35.8175 15.4399 35.7238 15.5396 36.4005 15.253C36.8794 15.0536 37.3583 14.8666 37.8268 14.6423C38.1183 14.5052 38.212 14.58 38.212 14.9663C38.1912 16.8607 38.2016 18.7551 38.2016 20.6495Z" fill="#00FDB4" />
            </g>
            <defs>
                <clipPath id="clip0_539_32">
                    <rect width={40} height={40} fill="white" transform="translate(0.818176)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EducationIcon