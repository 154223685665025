import React from 'react'

const ThinkingIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.09447 28.524C2.15879 27.9685 2.19094 27.6907 2.24345 27.481C2.60527 26.0364 3.78034 25.063 5.26702 24.9761C5.4828 24.9635 5.78434 24.9851 6.38743 25.0285C7.8978 25.1371 9.63623 25.2296 11.1814 25.2296C12.7927 25.2296 14.5895 25.1291 16.1152 25.0144C16.6893 24.9713 16.9764 24.9498 17.1852 24.9605C18.5897 25.0321 19.721 25.8935 20.1639 27.2282C20.2297 27.4268 20.281 27.6872 20.3836 28.2083C20.5065 28.8326 20.568 29.1447 20.5784 29.399C20.648 31.0858 19.4393 32.5556 17.7709 32.8132C17.5194 32.852 17.2012 32.852 16.5649 32.852H11.1814H5.95032C5.36825 32.852 5.07722 32.852 4.841 32.8178C3.24519 32.5869 2.04671 31.2417 2.00086 29.6299C1.99408 29.3914 2.02754 29.1023 2.09447 28.524Z" stroke="white" stroke-width="1.7" />
            <path d="M6.52267 16.2712C6.52267 13.8291 8.50239 11.8494 10.9445 11.8494H11.953C14.3952 11.8494 16.3749 13.8291 16.3749 16.2712V16.9606C16.3749 19.2926 14.4845 21.183 12.1525 21.183H10.745C8.41309 21.183 6.52267 19.2926 6.52267 16.9606V16.2712Z" stroke="white" stroke-width="1.7" />
            <path d="M31.8848 7.11622C28.9992 6.69597 26.5099 7.4021 24.4651 9.45196C22.357 11.5651 21.8932 14.9692 23.4399 17.4894C23.7894 18.0585 23.6862 18.3918 23.133 18.7291C22.3239 19.2231 21.5437 19.7606 20.7552 20.2849C20.513 20.4456 20.2199 20.5971 20.3409 20.9541C20.4497 21.2783 20.7345 21.348 21.0552 21.348C24.3054 21.3454 27.557 21.4152 30.8032 21.323C34.4401 21.2189 37.5817 18.4036 38 15.0469C37.9959 14.8506 37.9808 14.6544 37.989 14.4581C38.0826 11.8997 36.9639 9.92228 34.8516 8.41254C33.9571 7.7736 32.9031 7.48509 31.8848 7.11622ZM31.6399 14.1827C31.6481 13.6992 32.0623 13.3225 32.5728 13.3356C33.0655 13.3488 33.5017 13.7638 33.5017 14.2183C33.5017 14.6557 33.042 15.0482 32.5371 15.043C31.9935 15.039 31.6316 14.6925 31.6399 14.1827ZM29.8304 15.0443C29.2937 15.043 28.9138 14.6886 28.9166 14.1919C28.9194 13.7085 29.3336 13.3264 29.8454 13.337C30.3353 13.3475 30.7922 13.773 30.7825 14.2104C30.7716 14.686 30.3601 15.0456 29.8304 15.0443ZM26.0888 14.2064C26.097 13.7322 26.5181 13.3409 27.026 13.3356C27.5378 13.3317 27.9368 13.7085 27.9451 14.2038C27.9533 14.7057 27.5804 15.0469 27.026 15.0443C26.4796 15.043 26.0806 14.6873 26.0888 14.2064Z" fill="white" />
            <path d="M30.8391 14.2484C30.8293 14.8861 31.2609 15.3195 31.9135 15.3261C32.5183 15.331 33.067 14.84 33.0687 14.2946C33.0687 13.7261 32.5462 13.207 31.9564 13.1906C31.3466 13.1724 30.849 13.6437 30.8391 14.2484Z" fill="white" />
            <path d="M25.7268 14.2776C25.717 14.8791 26.1949 15.3256 26.8507 15.3273C27.5148 15.3289 27.9614 14.9021 27.9514 14.276C27.9416 13.6564 27.4637 13.1852 26.8507 13.1901C26.2426 13.1934 25.7368 13.6828 25.7268 14.2776Z" fill="#0DEFDA" />
            <path d="M31.9446 14.2484C31.9347 14.8861 32.3664 15.3195 33.019 15.3261C33.6237 15.331 34.1725 14.84 34.1741 14.2946C34.1741 13.7261 33.6517 13.207 33.0618 13.1906C32.4521 13.1724 31.9545 13.6437 31.9446 14.2484Z" fill="#0DEFDA" />
            <path d="M29.9202 15.3275C30.5563 15.3291 31.0474 14.8776 31.0605 14.2828C31.072 13.7357 30.5249 13.2019 29.9384 13.1903C29.3254 13.1772 28.8294 13.655 28.8261 14.2597C28.8211 14.8826 29.2759 15.3275 29.9202 15.3275Z" fill="#0DEFDA" />
        </svg>
    )
}

export default ThinkingIcon