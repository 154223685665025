import React from 'react'

const BottomArrowIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.38964 8.00202C4.51952 7.96312 5.35703 8.50873 6.11672 9.26843C10.4611 13.6125 14.8052 17.9569 19.1492 22.301C19.987 23.1387 19.987 23.1387 20.8636 22.2814C25.2077 17.9373 29.5518 13.5932 33.8959 9.24886C34.6558 8.48916 35.5129 7.96312 36.6428 8.00202C37.987 8.04117 38.9804 8.66458 39.5844 9.8138C40.2272 11.0217 40.1102 12.2294 39.3312 13.3397C39.0973 13.671 38.7856 13.9631 38.4935 14.2555C33.2921 19.4567 28.0909 24.6581 22.8895 29.8788C20.883 31.8853 19.0908 31.8853 17.1038 29.8984C11.8442 24.6385 6.58429 19.3789 1.32464 14.119C0.54537 13.3397 0 12.4826 0 11.3334C0.0389031 9.48276 1.50007 8.00202 3.38964 8.00202Z" fill="currentcolor" />
        </svg>
    )
}

export default BottomArrowIcon