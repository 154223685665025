import React from 'react';

const WorkersIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.55589 28.2374C8.72169 25.9162 8.80458 24.7557 9.38157 24.0091C9.65169 23.6596 9.94846 23.4043 10.3345 23.1896C11.159 22.7308 12.3771 22.8271 14.8136 23.0197C16.3672 23.1427 18.0577 23.2338 19.6543 23.2338C21.4337 23.2338 23.4209 23.1205 25.23 22.9765C27.6444 22.784 28.8516 22.6879 29.6866 23.1628C30.0486 23.3686 30.337 23.6165 30.5949 23.9434C31.1898 24.6975 31.2729 25.861 31.4391 28.1879C31.6407 31.0102 31.7415 32.4214 31.1142 33.4464C30.8517 33.8755 30.5054 34.2472 30.0962 34.5396C29.1184 35.2384 27.7037 35.2384 24.8742 35.2384H19.6543H15.0746C12.2922 35.2384 10.901 35.2384 9.93331 34.5616C9.50017 34.2586 9.136 33.8675 8.86468 33.4139C8.25853 32.4005 8.35765 31.0128 8.55589 28.2374Z" stroke="currentColor" strokeWidth="2.5" />
            <path d="M13.5715 10.2479C13.5715 6.79727 16.3688 4 19.8193 4H20.1858C23.6364 4 26.4337 6.79727 26.4337 10.2479V11.3498C26.4337 14.3942 23.9657 16.8622 20.9213 16.8622H19.0838C16.0395 16.8622 13.5715 14.3942 13.5715 11.3498V10.2479Z" stroke="currentColor" strokeWidth="2.5" />
            <path d="M35.2438 22.3764C35.2438 22.3764 38 23.5111 38 26.9699C38 30.4287 35.2438 31.5636 35.2438 31.5636" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.75618 22.3764C4.75618 22.3764 2 23.2951 2 26.9699C2 30.6448 4.75618 31.5636 4.75618 31.5636" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.6409 4.91644C30.6409 4.91644 34.3158 5.67816 34.3158 9.51007C34.3158 13.342 30.6409 14.1037 30.6409 14.1037" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.34448 4.91644C9.34448 4.91644 5.66957 5.53058 5.66957 9.51007C5.66957 13.4896 9.34448 14.1037 9.34448 14.1037" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default WorkersIcon;
