import React from 'react'

const ManicurePedicureIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1815 12.0673C13.1815 12.0673 10.0919 7.79554 9.47199 7.00892L7.41905 1C4.25833 3.64392 1.7074 7.07447 0 11.0294C0.274403 13.9793 0.376034 15.7492 1.25006 18.841C2.14441 21.9875 4.35996 26.9039 4.35996 26.9039L6.8804 26.5761L11.5148 25.4618L17.0943 22.8615L13.1815 12.0673Z" fill="#3BDFFD" />
            <path d="M21.3393 33.5868L16.7354 21.8202C16.3593 20.8806 15.4345 20.4218 14.6519 20.7714C13.8796 21.1319 13.4324 22.2791 13.8084 23.2186L18.7782 34.7558C19.1135 35.6079 19.9672 36.034 20.6685 35.7063C21.3799 35.3894 21.6746 34.4389 21.3393 33.5868ZM18.6765 37.3123L13.5238 23.164C13.1681 22.2244 12.1417 21.8639 11.3286 22.2244C10.5156 22.585 10.0074 23.6884 10.3733 24.628L16.0036 38.4813C16.3289 39.3335 17.1927 39.7596 17.9346 39.4427C18.6664 39.1259 19.0017 38.1645 18.6765 37.3123ZM14.408 36.4929L10.2107 24.7919C9.86514 23.8414 8.84883 23.4044 8.05611 23.7431C7.27355 24.0817 6.71458 25.1852 7.06013 26.1357L11.5522 37.7056C11.8978 38.6561 12.8226 39.1477 13.6052 38.8091C14.3979 38.4813 14.7536 37.4434 14.408 36.4929ZM8.90981 32.7564L6.82638 25.9172C6.491 24.9667 5.4442 24.5188 4.7023 24.8247C3.96039 25.1306 4.09251 26.092 4.43806 27.0425L6.5723 33.7179C6.86703 34.5482 7.6191 34.9961 8.26954 34.7339C8.91997 34.4608 9.20454 33.5758 8.90981 32.7564ZM19.8148 18.5317C18.6359 16.871 17.6805 15.0028 16.9082 14.5002C15.3939 13.5388 12.4161 11.5067 12.4161 11.5067L11.2575 16.871L14.9873 18.0182C14.9873 18.0182 17.0403 20.9353 19.2457 22.29C19.5099 22.4539 21.7864 23.6338 22.2133 21.9076C22.3759 21.2849 20.9429 20.1377 19.8148 18.5317Z" fill="#3BDFFD" />
            <path d="M20.612 19.4405L20.4721 19.3112L20.4863 19.5012L20.5611 19.4956L20.4863 19.5012L20.4863 19.5014L20.4864 19.5018L20.4865 19.5032L20.4869 19.5086L20.4888 19.5289C20.4905 19.5465 20.4932 19.5722 20.4971 19.6049C20.5051 19.6703 20.5182 19.7638 20.5389 19.8771C20.5803 20.1035 20.6522 20.41 20.7743 20.7296C21.0174 21.3656 21.4673 22.0722 22.291 22.2778L22.3841 22.3011V22.2325C22.3871 22.2287 22.3903 22.2245 22.3936 22.2198C22.4111 22.1951 22.431 22.1593 22.4453 22.1098C22.4741 22.0104 22.4778 21.8639 22.4055 21.6535C22.262 21.2364 21.8133 20.5513 20.612 19.4405ZM22.3669 22.2525L22.367 22.2525L22.3669 22.2525Z" fill="#00FDB4" stroke="white" strokeWidth="0.15" />
            <path d="M8.86277 33.5969L8.86283 33.5969L8.86192 33.594C8.85866 33.5834 8.84174 33.5188 8.82066 33.4384C8.81308 33.4094 8.80497 33.3785 8.79676 33.3472C8.78151 33.2891 8.76621 33.2312 8.75406 33.1864C8.74799 33.164 8.74264 33.1445 8.73843 33.1299L8.73279 33.1109L8.7327 33.1106C8.73192 33.1081 8.73046 33.1035 8.72858 33.0986C8.6219 32.7791 8.45108 32.5957 8.23334 32.5235C8.01983 32.4526 7.77893 32.4949 7.54394 32.5847C7.30809 32.6748 7.09977 32.8058 6.97943 33.0036C6.85755 33.204 6.83407 33.4592 6.93955 33.7788C6.94708 33.8096 6.97924 33.929 7.01186 34.0472C7.04551 34.1691 7.08226 34.2993 7.0939 34.3306L7.09381 34.3307L7.09527 34.3341C7.21895 34.6221 7.38456 34.9049 7.59812 35.0879C7.81583 35.2746 8.0857 35.3584 8.40048 35.2376C8.716 35.1164 8.87338 34.8549 8.93005 34.5532C8.98625 34.254 8.94562 33.9086 8.86277 33.5969Z" fill="#00FDB4" stroke="white" strokeWidth="0.15" />
            <path d="M14.0374 36.8285C14.0425 36.8448 14.0832 36.9869 14.1238 37.1289C14.1645 37.2709 14.2051 37.413 14.2102 37.4293L14.0374 36.8285ZM14.0374 36.8285C13.7732 36.0855 13.2447 36.0637 12.6959 36.2931C12.1471 36.5225 11.7812 36.9268 12.0455 37.6588C12.0556 37.6861 12.1014 37.8363 12.1471 37.9865C12.1928 38.1368 12.2386 38.287 12.2487 38.3143L14.0374 36.8285ZM14.282 37.4077L14.282 37.4077L14.2818 37.4071C14.2771 37.3918 14.2368 37.2512 14.1959 37.1083L14.1942 37.1024C14.1544 36.9632 14.1144 36.8234 14.1091 36.8062L14.1091 36.8062L14.1081 36.8033C13.9705 36.4166 13.758 36.2027 13.4948 36.1243C13.2366 36.0473 12.9477 36.1066 12.667 36.2239C12.3852 36.3417 12.1394 36.5087 12.0008 36.7501C11.8603 36.9948 11.8372 37.3027 11.9749 37.6842L11.9749 37.6842L11.9752 37.6849C11.9843 37.7094 12.0289 37.8559 12.0754 38.0084L12.0783 38.0181C12.1224 38.1631 12.1675 38.311 12.1784 38.3405L12.1783 38.3405L12.1805 38.3455C12.3401 38.6941 12.5519 39.0287 12.8168 39.2401C13.0857 39.4547 13.4114 39.5429 13.7813 39.3888C14.1524 39.2398 14.3346 38.9261 14.3947 38.5628C14.4544 38.2019 14.3957 37.7854 14.282 37.4077Z" fill="#00FDB4" stroke="white" strokeWidth="0.15" />
            <path d="M16.9046 36.8843L16.9046 36.8843L16.9041 36.8846C16.6338 37.0243 16.3989 37.2058 16.2756 37.4546C16.1506 37.7067 16.1468 38.014 16.31 38.3887C16.3128 38.3961 16.3183 38.4112 16.3258 38.4317C16.348 38.4927 16.388 38.6022 16.4275 38.7078C16.4549 38.781 16.4827 38.8543 16.5057 38.9122C16.5172 38.9411 16.5276 38.9667 16.5363 38.9867C16.5428 39.0016 16.5508 39.0194 16.5588 39.0322C16.7388 39.3689 16.9713 39.6884 17.249 39.8808C17.5319 40.0767 17.8637 40.1409 18.2226 39.957C18.5864 39.7732 18.7493 39.4463 18.7854 39.0795C18.8212 38.7161 18.7339 38.3086 18.5948 37.9425C18.5862 37.916 18.5389 37.7826 18.4916 37.6522C18.4668 37.5838 18.4416 37.5151 18.4212 37.4614C18.4111 37.4346 18.402 37.4111 18.3947 37.3931C18.3898 37.381 18.3837 37.3661 18.378 37.3552C18.2143 36.9823 17.9908 36.7837 17.7249 36.7238C17.4634 36.6648 17.1798 36.7448 16.9046 36.8843Z" fill="#00FDB4" stroke="white" strokeWidth="0.15" />
            <path d="M19.6359 33.2442L19.6359 33.2441L19.6354 33.2444C19.365 33.3841 19.1301 33.5656 19.0068 33.8144C18.8818 34.0665 18.878 34.3738 19.0412 34.7485C19.044 34.756 19.0495 34.771 19.057 34.7915C19.0792 34.8525 19.1192 34.962 19.1587 35.0676C19.1861 35.1408 19.2139 35.2141 19.2369 35.272C19.2484 35.3009 19.2589 35.3265 19.2676 35.3465C19.2741 35.3614 19.2821 35.3792 19.29 35.392C19.47 35.7287 19.7025 36.0482 19.9803 36.2406C20.263 36.4364 20.5947 36.5007 20.9534 36.317C21.3178 36.1387 21.4805 35.8111 21.5166 35.4433C21.5524 35.0787 21.4652 34.6685 21.326 34.3023C21.3174 34.2758 21.2702 34.1424 21.2228 34.012C21.198 33.9436 21.1728 33.8749 21.1524 33.8212C21.1423 33.7944 21.1332 33.7709 21.1259 33.7529C21.1211 33.7408 21.115 33.7259 21.1092 33.715C20.9455 33.3421 20.722 33.1435 20.4561 33.0836C20.1946 33.0246 19.9111 33.1046 19.6359 33.2442Z" fill="#00FDB4" stroke="white" strokeWidth="0.15" />
            <path d="M39.1155 13.2848L34.9189 21.231C34.6175 21.807 33.9098 22.0674 33.2967 21.7103L28.4617 18.8053C27.875 18.4543 27.6877 17.6727 28.0041 17.0767L32.2006 9.13046C32.756 8.079 34.0212 7.6764 35.0517 8.29303L38.3146 10.2524C39.341 10.8623 39.6772 12.2289 39.1155 13.2848ZM31.6234 21.4158L27.486 29.2583L26.9301 31.6229C26.7836 32.2519 26.1098 32.6197 25.5114 32.2718L24.0329 31.3828C23.4673 31.0366 23.368 30.2283 23.8155 29.7501L25.4208 28.0182L29.5635 20.1769L31.6234 21.4158Z" fill="#00FDB4" />
        </svg>
    )
}

export default ManicurePedicureIcon