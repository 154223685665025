import React from 'react'
import { Tab } from '@headlessui/react'
import { cn } from '../../../../lib/utils'
import { random } from 'lodash'

const CustomTabs = ({ tabsData, tabListClass, tabButtonClass, tabPanelsClass, tabItemClass, tabGroupClass, selectedIndex, setSelectedIndex, selectedItemClass, onChange, ...props }) => {

    return (
        <Tab.Group selectedIndex={selectedIndex} onChange={onChange} as="div" className={cn(tabGroupClass)} {...props}>
            <Tab.List className={cn("outline-0 shadow-none rounded-lg text-blue-950 grid grid-cols-2 mb-4 bg-white focus:border-transparent", tabListClass)} style={{ gridTemplateColumns: `repeat(${tabsData?.length}, minmax(0, 1fr))` }}>
                {
                    tabsData && tabsData?.map((item, ind) => (
                        <Tab as="div" key={random(1, 10000) + "_" + ind} className={"outline-0 shadow-none [&>button]:last:after:w-0"}>
                            {({ selected }) => (
                                <button type="button" className={cn("w-full block outline-0 shadow-none text-center text-sm font-normal !leading-none py-3 sm:py-4 px-1 sm:px-2 rounded-md relative [&.bg-success-950]:-ms-1 [&.bg-success-950]:w-[calc(100%+3%)]", tabButtonClass,
                                    tabsData.length > 2 ? 'after:absolute after:top-1/2 after:-translate-y-1/2 after:end-0 after:bg-success-950  after:h-[calc(100%-40%)] after:w-[0.7px]' : "",
                                    selected ? ` bg-success-950 text-white font-bold  ` + selectedItemClass : "")}>{item?.title}</button>
                            )}
                        </Tab>
                    ))
                }
            </Tab.List>
            <Tab.Panels className={cn(tabPanelsClass)}>
                {
                    Array.isArray(tabsData) && tabsData.length > 0 ? tabsData?.map((item, ind) => (
                        <Tab.Panel className={cn(tabItemClass)} key={ind}>{item?.content}</Tab.Panel>
                    )) : <></>
                }
            </Tab.Panels>
        </Tab.Group>
    )
}

export default CustomTabs