import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next';
import CommonSliderWrapper from '../uiComponents/common/CommonSliderWrapper';
import PortfolioCard from '../pages/vendor_panel/profiles/component/PortfolioCard';
import RightSmallArrowIcon from '../uiComponents/icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from '../uiComponents/icons/LeftSmallArrowIcon';

const WorkerProtfolioModal = ({ t, modalOpen, setModalOpen, portfolioData, workerData }) => {
    const sliderRef = useRef(null)

    const handleModalClose = () => {
        setModalOpen(false);
    }

    return (

        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className={`relative z-[999]`} onClose={handleModalClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70"
                    />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto" >
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[1129px]  transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="flex justify-center items-center gap-3 modal-header mb-4">

                                    <button className="md:hidden border-[1.6px] bg-primary1 border-primary1 text-white rounded-full h-8 w-8 flex items-center justify-center" onClick={() => sliderRef.current.slickPrev()}>
                                        <LeftSmallArrowIcon className="w-4 h-4" />
                                    </button>
                                    <h2 className="font-semibold text-xl text-center text-blue-950">{t('comman_text.portfolio')} ({portfolioData?.length})</h2>
                                    <button className="md:hidden border-[1.6px] bg-primary1 border-primary1 text-white rounded-full h-8 w-8 flex items-center justify-center" onClick={() => sliderRef.current.slickNext()}>
                                        <RightSmallArrowIcon className="w-4 h-4" />
                                    </button>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={handleModalClose}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center w-full ">

                                    <div className='w-full'>
                                        <CommonSliderWrapper
                                            ref={sliderRef}
                                            groupClassName="flex flex-col justify-center items-center w-full"
                                            nextArrowClassName={"hidden"}
                                            prevArrowClassName={"hidden"}
                                            infinite={false}
                                            slidesToShow={3}
                                            xsSlidesToShow={1}
                                            smSlidesToShow={1}
                                            mdSlidesToShow={1}
                                            lgSlidesToShow={2}
                                            xlSlidesToShow={3}
                                        >
                                            {
                                                portfolioData?.map((portfolioData,index) => (
                                                    <PortfolioCard workerData={workerData} key={index} PortfolioData={portfolioData} hasActionBtns={false} colored />
                                                ))
                                            }
                                        </CommonSliderWrapper>
                                    </div>
                                    <div className="justify-center items-center gap-3 flex-shrink-0 mt-3 hidden md:flex">
                                        <button className="border-[1.6px] bg-primary1 border-primary1 text-white rounded-full h-8 w-8 flex items-center justify-center" onClick={() => sliderRef.current.slickPrev()}>
                                            <LeftSmallArrowIcon className="w-4 h-4" />
                                        </button>
                                        <button className="border-[1.6px] bg-primary1 border-primary1 text-white rounded-full h-8 w-8 flex items-center justify-center" onClick={() => sliderRef.current.slickNext()}>
                                            <RightSmallArrowIcon className="w-4 h-4" />
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

    )
}

export default withTranslation()(WorkerProtfolioModal)