import React from 'react'

function BabyGirlIcon(props) {
    return (
        <svg {...props} viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.8218 9.60396L20.5941 16.8317V37.2277C20.5941 37.9868 20.3218 38.6386 19.7772 39.1832C19.2327 39.7277 18.5809 40 17.8218 40C17.0627 40 16.4109 39.7277 15.8663 39.1832C15.3218 38.6386 15.0495 37.9868 15.0495 37.2277V27.7228H13.4653V37.2277C13.4653 37.9868 13.1931 38.6386 12.6485 39.1832C12.104 39.7277 11.4521 40 10.6931 40C9.93399 40 9.28218 39.7277 8.73762 39.1832C8.19307 38.6386 7.92079 37.9868 7.92079 37.2277V16.8317L0.693069 9.60396C0.231023 9.14191 0 8.58086 0 7.92079C0 7.26073 0.231023 6.69967 0.693069 6.23762C1.15512 5.77558 1.71617 5.54455 2.37624 5.54455C3.0363 5.54455 3.59736 5.77558 4.05941 6.23762L9.70297 11.8812H18.8119L24.4554 6.23762C24.9175 5.77558 25.4785 5.54455 26.1386 5.54455C26.7987 5.54455 27.3597 5.77558 27.8218 6.23762C28.2838 6.69967 28.5148 7.26073 28.5148 7.92079C28.5148 8.58086 28.2838 9.14191 27.8218 9.60396ZM19.802 5.54455C19.802 7.07921 19.2615 8.38696 18.1807 9.46782C17.0998 10.5487 15.7921 11.0891 14.2574 11.0891C12.7228 11.0891 11.415 10.5487 10.3342 9.46782C9.2533 8.38696 8.71287 7.07921 8.71287 5.54455C8.71287 4.0099 9.2533 2.70215 10.3342 1.62129C11.415 0.540429 12.7228 0 14.2574 0C15.7921 0 17.0998 0.540429 18.1807 1.62129C19.2615 2.70215 19.802 4.0099 19.802 5.54455Z" fill="#FE7AB6" />
        </svg>
    )
}

export default BabyGirlIcon