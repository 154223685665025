import { Listbox, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { cn } from '../../../../lib/utils'
import AvailabilityCommon from '../../pages/vendor_panel/profiles/component/AvailabilityCommon'
import Badge from '../common/Badge'
import BottomArrowIcon from '../icons/BottomArrowIcon'
import RowWrap from '../RowWrap'

const SeeSalonAvailabilityDropdown = ({ t, selectedOption, handleSelectChange, categorySelectItems, size, children, groupClassName, className, disabledList, buttonIconClass, buttonClass, buttonTextClass, prefixIcon, dropArrowIcon, buttonText, dropMenuClass, dropItemClass, dropTextClass, avilablityData, selectedId, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleMouseLeave = () => {
        // setIsOpen(false)
        // handleSelectChange(false)
    }
    const handleOpenDropdown = () => {
        setIsOpen(!isOpen)
        handleSelectChange(!isOpen)
    }
    return (
        <Listbox static value={selectedOption} className={cn(groupClassName)} disabled={props.disabled ?? false}>
            <div className={cn("category-dropdown", className)}>
                <Listbox.Button
                    aria-label="button"
                    type='button'
                    disabled={disabledList ? true : false}
                    onClick={handleOpenDropdown}
                    className={cn("w-full text-start outline-0 cursor-default relative bg-light-800 border-[1.5px] border-success-100 rounded-lg text-[15px] font-semibold text-success-300 px-2.5 gap-2 py-1.5 pe-7 flex items-center", buttonClass)}>
                    <div className="flex items-center text-start justify-start">
                        <span className={cn("flex-grow", buttonTextClass)}>{buttonText}</span>
                    </div>
                    <span className={`pointer-events-none absolute inset-y-0 end-2 flex items-center transition-all duration-300 ease-in-out ${isOpen ? '-rotate-180' : ""}`}>
                        {dropArrowIcon ? dropArrowIcon : <BottomArrowIcon className="h-3 w-3 md:h-4 md:w-4" />}
                    </span>
                </Listbox.Button>
                <Transition
                    as={"div"}
                    show={isOpen}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    onMouseLeave={handleMouseLeave}
                    className={cn("absolute -start-[2px] top-full w-[calc(100%+4px)] theme-scrollbar rounded-md text-base bg-white z-[2] border-2 !border-t-transparent border-t-0 border-borderColor rounded-tr-none rounded-tl-none", dropMenuClass)}>
                    <Listbox.Options as="div" onClick={(e) => e.stopPropagation()} className={cn("px-3", dropMenuClass)}>
                        <div className="md:px-6 lg:px-8 xl:px-10 border-t border-borderColor">
                            <RowWrap className={cn("grid grid-cols-1 gap-6 mt-4 sm:mt-6 lg:mt-9 pb-6 md:pb-9 lg:pb-12", avilablityData?.[selectedId]?.service_location == 3 ? "md:grid-cols-2 lg:gap-8" : "")} md={avilablityData?.[selectedId]?.service_location === 3 ? 2 : ""}>
                                {
                                    avilablityData?.[selectedId]?.service_location == 1 || avilablityData?.[selectedId]?.service_location == 3 ?
                                        <div className="w-full">
                                            <div className="xl:max-w-[90%] xxl:max-w-[80%] mx-auto">
                                                <h5 className='text-blue-950 font-semibold text-[15px] text-center mb-2'>{t("comman_text.availability")}</h5>
                                                <Badge text={t('comman_text.salon')} groupClassName="leading-[1] py-0 w-full text-sm mb-2 rounded-md justify-center" />
                                                <AvailabilityCommon className="py-2 first:pt-0 last:pb-0" groupClassName="w-full bg-light-800" data={avilablityData?.[selectedId]?.salon} />
                                            </div>
                                        </div> : <></>
                                }

                                {
                                    avilablityData?.[selectedId]?.service_location == 2 || avilablityData?.[selectedId]?.service_location == 3 ?
                                        <div className="w-full">
                                            <div className="xl:max-w-[90%] xxl:max-w-[80%] mx-auto">
                                                <h5 className='text-blue-950 font-semibold text-[15px] text-center mb-2'>{t("comman_text.availability")}</h5>
                                                <Badge text={t('comman_text.on_the_go')} groupClassName="leading-[1] py-0 w-full text-sm mb-2 rounded-md justify-center" />
                                                <AvailabilityCommon className="py-2 first:pt-0 last:pb-0" groupClassName="w-full bg-light-800" data={avilablityData?.[selectedId]?.onthego} />
                                            </div>
                                        </div> : <></>
                                }
                            </RowWrap>
                        </div>
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default withTranslation()(SeeSalonAvailabilityDropdown)