import React from 'react';

const ShareIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.9737 1.51788C31.2897 1.51788 29.6749 2.18681 28.4842 3.37753C27.2935 4.56824 26.6246 6.18317 26.6246 7.86709C26.6274 8.31338 26.6772 8.75814 26.7733 9.19397L12.3574 16.4019C11.7628 15.7174 11.0285 15.1682 10.204 14.7912C9.37937 14.4142 8.48359 14.2181 7.57689 14.2163C5.89298 14.2163 4.27803 14.8852 3.08733 16.0759C1.89662 17.2666 1.22769 18.8816 1.22769 20.5655C1.22769 22.2494 1.89662 23.8644 3.08733 25.0551C4.27803 26.2457 5.89298 26.9147 7.57689 26.9147C8.48388 26.9137 9.38011 26.7183 10.2053 26.3419C11.0304 25.9654 11.7653 25.4166 12.3605 24.7322L26.7671 31.937C26.6732 32.3732 26.6253 32.8179 26.6246 33.2638C26.6246 34.9478 27.2935 36.5627 28.4842 37.7535C29.6749 38.9442 31.2897 39.6132 32.9737 39.6132C34.6577 39.6132 36.2726 38.9442 37.4633 37.7535C38.6539 36.5627 39.3229 34.9478 39.3229 33.2638C39.3229 31.58 38.6539 29.965 37.4633 28.7743C36.2726 27.5836 34.6577 26.9147 32.9737 26.9147C32.0667 26.9156 31.1705 27.1111 30.3453 27.4875C29.5202 27.864 28.7853 28.413 28.1901 29.0972L13.7835 21.8924C13.8775 21.4564 13.9253 21.0116 13.9261 20.5655C13.9231 20.1202 13.8732 19.6766 13.7773 19.2417L28.1932 12.0338C28.788 12.7177 29.5225 13.2664 30.3471 13.6428C31.1716 14.0193 32.0672 14.2148 32.9737 14.2163C34.6577 14.2163 36.2726 13.5474 37.4633 12.3567C38.6539 11.1659 39.3229 9.551 39.3229 7.86709C39.3229 6.18317 38.6539 4.56824 37.4633 3.37753C36.2726 2.18681 34.6577 1.51788 32.9737 1.51788Z" fill="currentColor" />
        </svg>

    );
}

export default ShareIcon;
