import React from 'react'
import { cn } from '../../../../lib/utils'
import Tooltip from '../Tooltip'

const Badge = ({ className, text, prefixIcon, suffixIcon, groupClassName, iconClassName, tooltipText, tooltipIcon, tooltipIconClass, tooltipContentClass, tooltipButtonClass, ...props }) => {
    return (
        <div className={cn("inline-flex gap-1.5 text-center mb-4 text-base py-1 leading-[20px] px-3 sm:px-5 rounded-full font-semibold text-blue-950 border-[1.3px] border-success-100", groupClassName)}>
            {prefixIcon ? <span className={cn("flex-shrink-0", iconClassName)}>{prefixIcon}</span> : ""}
            <span className={cn(className)}>{text}</span>
            {suffixIcon ? <span className={cn("flex-shrink-0", iconClassName)}>{suffixIcon}</span> : ""}
            {tooltipText ? <Tooltip title={tooltipText} tooltipIcon={tooltipIcon} tooltipIconClass={tooltipIconClass} tooltipContentClass={tooltipContentClass} tooltipButtonClass={tooltipButtonClass} /> : ""}
        </div>
    )
}

export default Badge