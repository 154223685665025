import React, { useEffect, useState } from 'react'
import Card from '../../../../uiComponents/Card'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import CameraLineIcon from '../../../../uiComponents/icons/line-icon/CameraLineIcon'
import RowWrap from '../../../../uiComponents/RowWrap'
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap'
import { withTranslation } from 'react-i18next'
import InputRadioBox from '../../../../uiComponents/inputs/InputRadioBox'
import InputWrap from '../../../../uiComponents/inputs/InputWrap'
import { DatePicker } from 'antd'
import IdentificationModal from '../../../../modals/IdentificationModal'
import FileUploadButton from '../../../../uiComponents/inputs/FileUploadButton'
import Badge from '../../../../uiComponents/common/Badge'
import * as Yup from "yup";
import { FormikProvider, useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import Http from '../../../../../../Http';
import { IMAGE_UPLOAD, PROFILE_COMPLETE_URL } from '../../../../../api/axios'
import { authStore } from '../../../../../contexts/AuthProvider'
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom'
import { isEmpty } from "lodash";
import avtarUser from '../../../../../../assets/images/user-image/avtar-user.png';
import { PATH_WORKER } from '../../../../../routes/path'
import CalendarIcon from '../../../../uiComponents/icons/CalenderIcon'

const PersonalInformation = ({ t, stepsNextClick }) => {
    const [identificationModalOpen, setIdentificationModalOpen] = useState(false)
    const [oldProfileStatus, setOldProfileStatus] = useState(false);
    const [getData, setGetData] = useState();
    const [oldDocumentStatus, setOldDocumentStatus] = useState(false);
    const [fileList, setFileList] = useState([]);
    const salonDataId = authStore((state) => state.salonDataId);
    const become_worker_profile = authStore((state) => state.become_worker_profile);
    const [genderValue, setGenderValue] = useState("");
    const [DOB, setDOB] = useState("");
    const [image, setImage] = useState();
    const [imageFile, setImageFile] = useState(null);
    const { updateUserProfile } = authStore();
    const BecomeWorkerCompleteStep = authStore((state) => state.BecomeWorkerCompleteStep);
    const becomeWorkerStep = authStore((state) => state.becomeWorkerStep);
    const profileId = authStore((state) => state.profileId);
    const updateBecomeWorkerStep = authStore((state) => state.updateBecomeWorkerStep);
    const navigate = useNavigate();

    const noNegativeValues = (value) => {
        if (value) {
            return !value.includes("-");
        }
        return true;
    };
    const { data: personalProfileData, refetch } = useQuery(
        ["personalProfileData", salonDataId],
        async () => {
            return await Http.get(
                PROFILE_COMPLETE_URL.getPersonalProfile.replace(':salon_id', salonDataId)
            ).then((res) => res?.data?.data);
        },
        {
            enabled: !!salonDataId,
            refetchOnWindowFocus: false,
            retry: false,
        });


    useEffect(() => {
        if (personalProfileData?.id == profileId) {
            updateUserProfile(personalProfileData)
        }
    }, [personalProfileData])

    const { mutateAsync: imageUpload } = useMutation(
        async (formData) => {
            try {
                formData.env = "test";
                return await Http.post(IMAGE_UPLOAD.uploadImage, formData).then(
                    (res) => res.data
                );
            } catch (error) {
                console.log(error);
            }
        });

    const handleGender = (val) => {
        setGenderValue(val);
        setFieldValue("gender", val)
    };

    const disabledDate = (current) => {
        const currentDate = dayjs();
        const minDate = currentDate.subtract(18, "year");
        return current && current > minDate;
    };

    const onChangeDOB = (value, name) => {
        setDOB(value);
        formik.setFieldValue(
            "dob",
            value !== null ? value.format("YYYY-MM-DD") : value
        );
    };

    const beforeUploadImage = async (file) => {
        return false;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageFile(reader.result);
            setImage(file);
            setOldProfileStatus(false);
        };
        if (file) {
            reader.readAsDataURL(file);
            beforeUploadImage(file);
        }
    };

    useEffect(() => {
        if (!isEmpty(personalProfileData) && (personalProfileData?.id == profileId)) {
            setDOB(personalProfileData?.dob == null ? "" : dayjs(personalProfileData?.dob));
            setGenderValue(personalProfileData?.gender);
            setGetData(personalProfileData);
            if (personalProfileData?.profile_name) {
                setImageFile(personalProfileData?.profile)
                setOldProfileStatus(true);
            }
            if (personalProfileData?.document !== "") {
                setFileList([{ url: personalProfileData?.document }]);
            }
        } else {
            setFileList([]);
        }
    }, [personalProfileData]);

    let personalInfoValidation = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, t("validation_message.first_name_matches"))
            .matches(/[^\s*].*[^\s*]/g, t("validation_message.first_name_matches"))
            .min(2, t("validation_message.first_name_min_max"))
            .max(20, t("validation_message.first_name_min_max"))
            .required(t("validation_message.first_name_req")),
        last_name: Yup.string()
            .trim()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, t("validation_message.last_name_matches"))
            .matches(/[^\s*].*[^\s*]/g, t("validation_message.last_name_matches"))
            .min(2, t("validation_message.last_name_min_max"))
            .max(20, t("validation_message.last_name_min_max"))
            .required(t("validation_message.last_name_req")),
        gender: Yup.string().required(t("validation_message.gender")),
        phone: Yup.string()
            .trim()
            .test("no-negative-values", t("validation_message.negative_phone"), noNegativeValues)
            .required(t("validation_message.phone"))
            .min(8, t("validation_message.min_phone"))
            .max(14, t("validation_message.max_phone"))
            .matches(/^\+[0-9]+$/, t("validation_message.digit_only_phone")),
        dob: Yup.date()
            .required(t("validation_message.dob"))
            .max(new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000), t("validation_message.dob_min")),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: getData?.first_name ?? "",
            last_name: getData?.last_name ?? "",
            gender: getData?.gender ?? "",
            phone: getData?.mobile ?? "+45",
            dob: getData?.dob ?? "",
            salon_id: salonDataId,
        },
        validationSchema: personalInfoValidation,
        onSubmit: async (values) => {
            values.type = "salon";
            values.role = "worker";
            if (isEmpty(imageFile)) {
                enqueueSnackbar("Please select profile image", {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            } else {
                if (oldProfileStatus == true) {
                    values.profile = getData.profile_name;
                } else {
                    if (image) {
                        const formData = new FormData();
                        formData.append("model", "provider_profile");
                        formData.append("image", image);
                        const imageUploadData = await imageUpload(formData);
                        values.old_image = getData?.profile !== "" && imageUploadData.name ? true : false;
                        values.profile = imageUploadData.name;
                    }
                }
                if (oldDocumentStatus == true) {
                    values.document = getData.document;
                }
                await updateProfile(values);
            }
        },
    });

    const { mutateAsync: updateProfile } = useMutation(
        async (dataa) => {
            try {
                const { data } = await Http.post(
                    PROFILE_COMPLETE_URL.updateProfile,
                    dataa
                );
                if (data) {
                    if (become_worker_profile === 0) {
                        if (becomeWorkerStep.includes(3)) {
                            const updatedArray = [...new Set([...becomeWorkerStep, 4])];
                            updateBecomeWorkerStep(updatedArray);
                            if (data.data.is_complete == 1) {
                                BecomeWorkerCompleteStep(1)
                                navigate(PATH_WORKER.dashboard)
                            }
                            stepsNextClick();
                        }
                    }
                    enqueueSnackbar(t("validation_message.profile_updated"), {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    refetch();
                }
            } catch (error) {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }

            }
        }
    );

    const {
        errors,
        handleSubmit,
        getFieldProps,
        setFieldValue,
    } = formik;

    const eighteenYearsAgo = dayjs().subtract(18, 'year');

    return (
        <FormikProvider value={formik}>
            <form onSubmit={handleSubmit} autoComplete="off">
                <Card>
                    <div className="text-center mb-7">
                        <Badge groupClassName={"mb-0"} text={t('comman_text.personal_info')} />
                    </div>
                    <div className="flex justify-center items-center gap-4">
                        <div className="h-[70px] w-[70px] rounded-full border">
                            <img src={imageFile ? imageFile : avtarUser} alt="" className='h-full w-full object-cover' />
                        </div>
                        <FileUploadButton icon={<CameraLineIcon className="w-5 h-5" />} onChange={handleImageChange} accept={"image/*"} />
                    </div>
                    <RowWrap className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 md:gap-6 lg:gap-x-8 lg:gap-y-5" lg={3}>
                        <div className="">
                            <LabelWrap require labelClassName={"text-[15px] mb-5"} label={t("form_input_label.gender")} />
                            <div className="grid grid-cols-2">
                                <InputRadioBox
                                    text={t("radio_label.male")}
                                    name="gender"
                                    {...getFieldProps("gender")}
                                    checked={genderValue === 'M' ? true : false}
                                    value='M'
                                    onChange={() => handleGender('M')} />

                                <InputRadioBox
                                    text={t("radio_label.female")}
                                    name="gender"
                                    {...getFieldProps("gender")}
                                    checked={genderValue === 'F' ? true : false}
                                    value='F'
                                    onChange={() => handleGender('F')} />
                            </div>
                            {errors.gender && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors.gender}</p>}
                        </div>
                        <InputWrap size="sm" groupClassName={""} className="" require
                            {...getFieldProps("first_name")}
                            label={t("form_input_label.first_name")}
                            placeholder={t('input_filed_placeholder.first_name')}
                            // errorType={errors.first_name || touched.first_name}
                            errorType={errors.first_name && "error"}
                            errorData={errors.first_name} />

                        <InputWrap size="sm" groupClassName={""} className="" require
                            {...getFieldProps("last_name")}
                            label={t("form_input_label.last_name")}
                            placeholder={t('input_filed_placeholder.last_name')}
                            errorType={errors.last_name && "error"}
                            errorData={errors.last_name} />

                        <div className="">
                            <LabelWrap label={t("form_input_label.date_of_birth")} require />
                            <DatePicker
                                onChange={onChangeDOB}
                                value={DOB}
                                onBlur={() => { formik.setFieldTouched("dob", true) }}
                                disabledDate={disabledDate}
                                className='w-full border-success-100 [&>.ant-picker-input]:font-normal hover:border-success-100 min-h-10 bg-light-950'
                                suffixIcon={<CalendarIcon className="w-5 h-5 text-primary1" />}
                                placeholder={t('input_filed_placeholder.choose_date')}
                            />
                            {errors.dob && (
                                <div className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors.dob}</div>
                            )}
                        </div>
                        <InputWrap type={"tel"} size="sm" groupClassName={""} className="" require
                            label={t("form_input_label.phone_number_")}
                            {...getFieldProps("phone")}
                            placeholder={'+45'}
                            errorType={errors.phone && "error"}
                            errorData={errors.phone}
                        />
                        <div className="">
                            <LabelWrap label={t('form_input_label.identification_document')} require />
                            <ButtonPrimary size="sm" type="button" variant="outline" className="w-full text-[15px] lg:text-[15px] font-bold" children={`Upload ID document (${fileList.length})`}
                                onClick={() => setIdentificationModalOpen(true)}
                                disabled={personalProfileData?.document_name && personalProfileData?.document}
                            />
                        </div>
                    </RowWrap>
                    <ButtonPrimary size="sm" type="submit" className="ms-auto mt-6 w-full sm:max-w-[262px]" children={t("button_label.continue")} />
                    <IdentificationModal identificationModalOpen={identificationModalOpen} setIdentificationModalOpen={setIdentificationModalOpen} fileList={fileList} setFileList={setFileList} setOldDocumentStatus={setOldDocumentStatus} refetchPersonalInfoData={refetch} />
                </Card>
            </form>
        </FormikProvider>
    )
}

export default withTranslation()(PersonalInformation)