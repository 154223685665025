import React from 'react'
import InputRadioBox from './InputRadioBox';
import { cn } from '../../../../lib/utils';

const InputRadioBoxGroup = ({ data, handelChange, selectValue, setCheckValue, groupClassName, itemGroupClassName, ...props }) => {
    // Function to handle checkbox change
    const handleRadioChange = (value) => {
        setCheckValue(value);
    };

    return (
        <div className={cn(groupClassName)} onChange={(item) => handleRadioChange(item.target.value)} >
            {
                data && data?.map((item, ind) => (
                    <InputRadioBox groupClassName={groupClassName} className={itemGroupClassName} key={ind} id={item?.id} text={item?.label} checked={selectValue === item.value ? true : false} value={item.value} {...props} />
                ))
            }
        </div>
    )
}

export default InputRadioBoxGroup