import { Form } from 'antd';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utcPlugin from 'dayjs/plugin/utc';
import { FormikProvider } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Http from '../../../../../../Http';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { authStore } from '../../../../../contexts/AuthProvider';
import weekDaysInitial from '../../../../../data/weekDaysArray';
import { PATH_PROVIDER } from '../../../../../routes/path';
import Card from '../../../../uiComponents/Card';
import RowWrap from '../../../../uiComponents/RowWrap';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import AvailabilityCalendar from '../../../../uiComponents/common/AvailabilityCalendar';
import Badge from '../../../../uiComponents/common/Badge';
import WorkerAddHoursListTable from '../../../../uiComponents/common/WorkerAddHoursListTable';
import CloseFillIcon from '../../../../uiComponents/icons/CloseFillIcon';
import RoundFillCheckIcon from '../../../../uiComponents/icons/RoundFillCheckIcon';
import InputCheckbox from '../../../../uiComponents/inputs/InputCheckbox';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import SeeSalonAvailabilityDropdown from '../../../../uiComponents/inputs/SeeSalonAvailabilityDropdown';
import CustomeTabsUpdated from '../../../../uiComponents/tabs/CustomeTabsUpdated';

dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);


const WorkerAvailability = ({ t, salonListData, serviceLocation, stepsNextClick }) => {

    const [form] = Form.useForm();
    const salonId = authStore((state) => state.salonDataId);

    const becomeWorkerStep = authStore((state) => state.becomeWorkerStep);
    const updateBecomeWorkerStep = authStore((state) => state.updateBecomeWorkerStep);
    const BecomeWorkerCompleteStep = authStore((state) => state.BecomeWorkerCompleteStep);
    const is_profile_complete = authStore((state) => state.become_worker_profile);
    const [isDropdownShow, setIsDropdownShow] = useState(false);
    const [salonAvailability, setSalonAvailability] = useState([]);
    const [onTheGoAvailability, setOnTheGoAvailability] = useState([]);
    const [salonUnAvailability, setSalonUnAvailability] = useState([]);
    const [salonUnAvailabilityData, setSalonUnAvailabilityData] = useState([]);
    const [removedUnAvailability, setRemovedUnAvailability] = useState([]);
    const [useSalonHours, setUseSalonHours] = useState(false);
    const [useOnTheGoHours, setUseOnTheGoHours] = useState(false);
    const [availabilityData, setAvailabilityData] = useState([]);
    const [selectedSalonDetails, setSelectedSalonDetails] = useState({});
    const [workerAvailabilityTabs, setWorkerAvailabilityTabs] = useState([]);
    const [dropDownData, setDropDownData] = useState([]);
    const [selectedTabId, setSelectdTabId] = useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const handleTabChange = (tabId) => {
        setSelectdTabId(tabId)
    }

    const { data: salonsAdnAvailblity } = useQuery(
        ["salonsAdnAvailblity"],
        async () => {
            return await Http.get(PROFILE_COMPLETE_URL.salonsAndAvailablities).then(
                (res) => {
                    return res?.data?.data
                }
            );
        }, {
        enabled: true,
        refetchOnWindowFocus: false,
        retry: false,
    });

    useEffect(() => {

        if (salonsAdnAvailblity?.length) {
            let tabData = [];
            let dropDown = [];
            let service_location;

            salonsAdnAvailblity?.map((item, index) => {
                tabData.push({ tabId: index, tabName: item?.salon_name })
                if (item?.salon_location != 3) {
                    service_location = item?.salon_location
                } else {
                    service_location = item?.worker_location
                }
                dropDown.push({ service_location: service_location, 'salon': item?.salon, 'onthego': item?.onthego })
            })
            setSelectdTabId(tabData?.[0]?.tabId)
            setWorkerAvailabilityTabs(tabData);
            setDropDownData(dropDown);
        }

    }, [salonsAdnAvailblity])

    useEffect(() => {
        if (!isEmpty(salonListData)) {
            setSelectedSalonDetails(salonListData);
        }
    }, [salonListData])

    useEffect(() => {
        if (availabilityData) {
            if (availabilityData?.salon?.length) {
                availabilityData?.salon?.map((day, index) => {
                    day?.slots?.map((slot, slotInd) => {
                        form.setFieldsValue({
                            ['salon_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                            ['salon_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
                        })
                    })
                });
                setSalonAvailability(availabilityData?.salon)
            } else {

                let newSalonWeekDays = cloneDeep(weekDaysInitial);
                newSalonWeekDays.map((day, index) => {
                    day.status = selectedSalonDetails?.salon?.[index]?.status;
                    day.disabled = selectedSalonDetails?.salon?.[index]?.status ? false : true;
                });
                setSalonAvailability(newSalonWeekDays)
            }

            if (availabilityData?.onthego?.length) {
                availabilityData?.onthego?.map((day, index) => {
                    day?.slots?.map((slot, slotInd) => {
                        form.setFieldsValue({
                            ['onthego_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                            ['onthego_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
                        })
                    })
                });
                setOnTheGoAvailability(availabilityData?.onthego)
            } else {
                let newOntheGoWeekDays = cloneDeep(weekDaysInitial);
                newOntheGoWeekDays.map((day, index) => {
                    day.status = selectedSalonDetails?.onthego?.[index]?.status;
                    day.disabled = selectedSalonDetails?.onthego?.[index]?.status ? false : true;
                });
                setOnTheGoAvailability(newOntheGoWeekDays)
            }
        }
    }, [availabilityData]);

    const handleUseSalonHours = (e) => {
        setUseSalonHours(e.target.checked);
        if (e.target.checked) {
            if (selectedSalonDetails?.salon?.length) {
                selectedSalonDetails?.salon?.map((day, index) => {
                    day?.slots?.map((slot, slotInd) => {
                        form.setFieldsValue({
                            ['salon_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                            ['salon_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
                        })
                    })
                });
                setSalonAvailability(selectedSalonDetails?.salon)
            }
        } else {

            if (getWorkerAvailability?.salon?.length) {
                getWorkerAvailability?.salon?.map((day, index) => {
                    day?.slots?.map((slot, slotInd) => {
                        form.setFieldsValue({
                            ['salon_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                            ['salon_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
                        })
                    })
                });
                setSalonAvailability(getWorkerAvailability.salon);
            } else {
                setSalonAvailability(cloneDeep(weekDaysInitial));
            }
        }
    }

    const handleUseOnTheGoHours = (e) => {
        setUseOnTheGoHours(e.target.checked);
        if (e.target.checked) {
            if (selectedSalonDetails?.onthego?.length) {
                selectedSalonDetails?.onthego?.map((day, index) => {
                    day?.slots?.map((slot, slotInd) => {
                        form.setFieldsValue({
                            ['onthego_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                            ['onthego_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
                        })
                    })
                });
                setOnTheGoAvailability(selectedSalonDetails?.onthego)
            }
        } else {
            if (getWorkerAvailability?.onthego?.length) {
                availabilityData?.onthego?.map((day, index) => {
                    day?.slots?.map((slot, slotInd) => {
                        form.setFieldsValue({
                            ['onthego_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                            ['onthego_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
                        })
                    })
                });
                setOnTheGoAvailability(getWorkerAvailability.onthego);
            } else {
                setOnTheGoAvailability(cloneDeep(weekDaysInitial));
            }
        }
    }

    const { data: getWorkerAvailability, refetch } = useQuery(
        ["getWorkerAvailability", salonId],
        async () => {
            if (salonId) {
                return await Http.get(PROFILE_COMPLETE_URL.workerAvailablity, { params: { salon_id: salonId } }).then(
                    (res) => res?.data?.data
                );
            }

        },
        {
            enabled: !!salonId,
            refetchOnWindowFocus: false,
            retry: false,
        });

    useEffect(() => {
        form.resetFields()
        if (!isEmpty(getWorkerAvailability)) {
            setAvailabilityData(getWorkerAvailability);
            setUseSalonHours(getWorkerAvailability.use_salon_hours);
            setUseOnTheGoHours(getWorkerAvailability.use_onthego_hours);
            setSelectedSalonDetails(salonListData);

            if (getWorkerAvailability?.unavailability?.length) {
                getWorkerAvailability?.unavailability.map((unavailability) => {
                    unavailability.date = unavailability.holiday_date
                    unavailability.salon = unavailability.service_location == 3 || unavailability.service_location == 1 ? true : false;
                    unavailability.onTheGo = unavailability.service_location == 3 || unavailability.service_location == 2 ? true : false;
                });
                setSalonUnAvailability(getWorkerAvailability?.unavailability);
                setSalonUnAvailabilityData(getWorkerAvailability?.unavailability)
            } else {
                setSalonUnAvailability([]);
            }
        }
    }, [getWorkerAvailability]);

    const { mutateAsync: storeWorkerAvailability } = useMutation(
        async (datas) => {
            try {
                const { data } = await Http.post(PROFILE_COMPLETE_URL.workerAvailablity, datas);
                if (data) {
                    queryClient.invalidateQueries(['getWorkerAvailability']);
                    if (is_profile_complete == 0 && salonId) {
                        const updatedArray = [...new Set([...becomeWorkerStep, 3])];
                        updateBecomeWorkerStep(updatedArray);
                        stepsNextClick()
                        if (data.data.is_complete == 1) {
                            BecomeWorkerCompleteStep(1)
                            navigate(PATH_PROVIDER.dashboard)
                        }
                        if (updatedArray.length == 5) {
                            queryClient.invalidateQueries(['mySalonList']);
                        }
                    }

                    enqueueSnackbar(data.message, {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            } catch (error) {
                enqueueSnackbar(error?.response?.data?.message, {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    );

    const handleFinish = async (values) => {
        let data = {};
        data.service_location = serviceLocation;
        data.salon = salonAvailability;
        data.onthego = onTheGoAvailability;
        data.holiday_date = salonUnAvailability;
        data.removed_holiday_date = removedUnAvailability;
        data.salon_id = salonId;
        data.profile_setup = is_profile_complete == 1 ? false : true;
        data.use_onthego_hours = useOnTheGoHours;
        data.use_salon_hours = useSalonHours;
        await storeWorkerAvailability(data);
    };

    return (
        <>
            <Card className={`my-2.5 py-2 sm:py-2 relative transition-all duration-300 ${isDropdownShow ? "rounded-bl-none rounded-br-none" : ""}`}>
                <div className="grid xsm:grid-cols-2 lg:grid-cols-4 items-center w-full gap-2">
                    <div className="lg:col-start-2 lg:col-span-2">
                        {isDropdownShow ? <CustomeTabsUpdated tabs={workerAvailabilityTabs} onTabChange={handleTabChange} groupClassName={"sm:gap-4 gap-2 xsm:justify-start justify-center lg:justify-center "} wrapperClassName="" selectedTabId={selectedTabId} /> : <></>}
                    </div>
                    <SeeSalonAvailabilityDropdown handleSelectChange={setIsDropdownShow} buttonText="See Salon Availability" buttonClass="text-primary1 border-primary1 ms-auto xsm:me-0 me-auto max-w-[200px] border-2 min-w-[200px]" avilablityData={dropDownData} selectedId={selectedTabId} />
                </div>
            </Card>
            <Card>
                <FormikProvider >
                    <Form form={form} noValidate onFinish={handleFinish}>
                        <Badge text={"Worker Hours"} />
                        <RowWrap className={`grid grid-cols-1 border-b border-b-success-100 pb-6 ${serviceLocation === 3 ? "xl:grid-cols-2 gap-8" : ""}`} xl={serviceLocation === 3 ? 2 : ""}>
                            {
                                serviceLocation === 1 || serviceLocation === 3 ?
                                    <div className="salon-card">
                                        <div className='flex items-center gap-5'>
                                            <h6 className="flex items-center gap-1.5 font-medium text-[15px] text-blue-950"><RoundFillCheckIcon className="w-6 h-6 text-success-950" /> {t('form_input_label.availability_salon')}</h6>
                                            <div className='bg-white border border-success-100 shadow-100 rounded-[5px] px-2 py-1'>
                                                <InputCheckbox
                                                    id={t('form_input_label.use_salon_hours')}
                                                    text={t('form_input_label.use_salon_hours')}
                                                    className={"peer-checked:text-blue-950 font-medium text-[15px] text-blue-950"}
                                                    onChange={(e) => handleUseSalonHours(e)}
                                                    checked={useSalonHours}
                                                />
                                            </div>
                                        </div>
                                        <WorkerAddHoursListTable tableClass="min-w-[600px]" salonAvailability={salonAvailability} setSalonAvailability={setSalonAvailability} id="salon" form={form} useSalonHours={useSalonHours} salonData={selectedSalonDetails} />
                                    </div>
                                    : <></>
                            }
                            {
                                serviceLocation === 2 || serviceLocation === 3 ?
                                    <div className="onthego-card">
                                        <div className='flex items-center gap-5'>
                                            <h6 className="flex items-center gap-1.5 font-medium text-[15px] text-blue-950"><RoundFillCheckIcon className="w-6 h-6 text-success-950" /> {t('form_input_label.availability_on_the_go')}</h6>
                                            <div className='bg-white border border-success-100 shadow-100 rounded-[5px] px-2 py-1'>
                                                <InputCheckbox
                                                    id={t('form_input_label.use_onthego_hours')}
                                                    text={t('form_input_label.use_salon_hours')}
                                                    className={"peer-checked:text-blue-950 font-medium text-[15px] text-blue-950"}
                                                    onChange={(e) => handleUseOnTheGoHours(e)}
                                                    checked={useOnTheGoHours}

                                                />
                                            </div>
                                        </div>
                                        <WorkerAddHoursListTable tableClass="min-w-[600px]" onTheGoAvailability={onTheGoAvailability} setOnTheGoAvailability={setOnTheGoAvailability} id="onthego" form={form} useSalonHours={useOnTheGoHours} salonData={selectedSalonDetails} />
                                    </div>
                                    :
                                    <></>
                            }
                        </RowWrap>

                        <div className="salon-card pt-6">
                            <h6 className="flex items-center gap-1.5 font-semibold text-[15px] text-blue-950 pb-4"><CloseFillIcon className="w-6 h-6 text-success-950" /> {t('form_input_label.unavailability')}</h6>
                            <LabelWrap htmlFor="" label={t('form_input_label.choose_your_dates')} labelClassName="text-[15px] font-bold italic text-blue-950 font-800" />
                        </div>
                        <div className="">
                            <AvailabilityCalendar salonUnAvailability={salonUnAvailability} setSalonUnAvailability={setSalonUnAvailability} setRemovedUnAvailability={setRemovedUnAvailability} location={serviceLocation} salonUnAvailabilityData={salonUnAvailabilityData}
                            />
                        </div>
                        <div className="flex items-center justify-end pt-7">
                            <ButtonPrimary size={"sm"} className={"lg:text-[15px] w-full     max-w-[200px] text-[15px] font-bold rounded-[5px]"} >{t('button_label.continue')}</ButtonPrimary>
                        </div>
                    </Form>
                </FormikProvider>
            </Card>
        </>
    )
}

export default withTranslation()(WorkerAvailability);