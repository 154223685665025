import React from 'react';

const EnergyIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6701 0.0104085C16.4625 0.47878 16.6085 0.863891 16.3791 1.81104C15.2843 6.1617 14.1894 10.5124 13.0841 14.8526C12.9903 15.2065 13.0633 15.2898 13.4282 15.2898C16.3166 15.2794 19.2153 15.2898 22.1038 15.2794C22.6354 15.2794 23.0839 15.3939 23.3654 15.883C23.647 16.3826 23.5219 16.851 23.2298 17.309C20.1433 22.1905 17.0673 27.0823 13.9809 31.9742C12.4481 34.4098 10.9048 36.8349 9.38244 39.2705C9.12176 39.6764 8.80894 39.9782 8.29801 39.999C7.43254 40.0303 6.86947 39.3016 7.08844 38.417C7.54724 36.5539 8.0269 34.6803 8.49613 32.8172C9.14262 30.2672 9.77868 27.7276 10.4252 25.1776C10.5503 24.6989 10.5399 24.6989 10.0289 24.6989C7.16143 24.6989 4.29394 24.6884 1.42645 24.6989C0.873801 24.6989 0.415006 24.574 0.143895 24.0639C-0.127214 23.5539 0.0187668 23.0959 0.300302 22.638C3.57445 17.4548 6.83819 12.2714 10.1123 7.08803C11.4366 4.98557 12.7713 2.8831 14.0851 0.780619C14.2937 0.447551 14.5335 0.176941 14.888 0C15.1487 0.0104085 15.4094 0.0104085 15.6701 0.0104085Z" fill="currentColor" />
        </svg>

    );
}

export default EnergyIcon;
