const weekDaysInitial = [
    { day: "comman_text.monday", slots: [{ from: "", to: "" }], status: 1 },
    { day: "comman_text.tuesday", slots: [{ from: "", to: "" }], status: 1 },
    { day: "comman_text.wednesday", slots: [{ from: "", to: "" }], status: 1 },
    { day: "comman_text.thursday", slots: [{ from: "", to: "" }], status: 1 },
    { day: "comman_text.friday", slots: [{ from: "", to: "" }], status: 1 },
    { day: "comman_text.saturday", slots: [{ from: "", to: "" }], status: 1 },
    { day: "comman_text.sunday", slots: [{ from: "", to: "" }], status: 1 },
];

export default weekDaysInitial;