import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import CloseIcon from '../uiComponents/icons/CloseIcon'
import LabelWrap from '../uiComponents/inputs/LabelWrap'
import CheckboxList from '../uiComponents/common/CheckboxList'
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary'
import InputRadioBox from '../uiComponents/inputs/InputRadioBox'
import InputWithIcon from '../uiComponents/inputs/InputWithIcon'
import ServiceTableWithSelect from '../uiComponents/common/ServiceTableWithSelect'
import Http from '../../../Http'
import { PROFILE_COMPLETE_URL } from '../../api/axios'
import { useMutation, useQuery } from 'react-query'
import { enqueueSnackbar } from 'notistack'

const CreateEditDiscountModal = ({ createEditDiscountModalOpen, setCreateEditDiscountModalOpen, profile_setup, salonId, serviceData, salonServiceFromParent, t }) => {
  const [serviceType, setServiceTypeData] = useState([]);
  const [serviceTypeIds, setserviceTypeIds] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [categoryChoose, setCategoryChoose] = useState([]);
  const [serviceChoose, setServiceChoose] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [amount, setAmount] = useState(0);
  const [selectedValue, setSelectedValue] = useState(1);
  const [selectedDiscountType, setSelectedDiscountType] = useState(0);
  const [perValidationMessage, setPerValidationMessage] = useState("");
  const [amountValidationMessage, setAmountValidationMessage] = useState("");

  const getsubcategory = useMemo(() => ["subCategory", serviceTypeIds], [serviceTypeIds])
  const { refetch: subCategory } = useQuery(
    getsubcategory,
    async () => {
      const response = await Http.post(PROFILE_COMPLETE_URL.categories, {
        service_type_ids: serviceTypeIds,
        salonId: salonId ?? null,
      });
      return response?.data?.data;
    },
    {
      enabled: !!salonId,
      onSuccess: (data) => setCategoryData(data),
      refetchOnWindowFocus: false,
    }
  );

  const handleAmountChange = (e) => {
    setAmount(0)
    const inputValue = e.target.value;
    if (selectedDiscountType === 1) {
      setAmount(inputValue);
      setPerValidationMessage("")
    } else if (selectedDiscountType === 2) {
      setAmount(inputValue);
      setAmountValidationMessage("");
    }
  };

  const resetModal = () => {
    setSelectedService([]);
    setSelectedDiscountType(0);
    setPerValidationMessage("");
    setAmountValidationMessage("");
  };

  const closeModal = () => {
    resetModal();
    setCreateEditDiscountModalOpen(false);
  };

  const serviceTypeData = useMemo(() => ["serviceTypeData"], []);
  useQuery(
    serviceTypeData,
    async () => {
      try {
        const { data } = await Http.get(PROFILE_COMPLETE_URL.serviceTypes).then(
          (res) => res.data
        );

        if (data.length) {
          setServiceTypeData(data);
          // subCategory([data[0].id]);
        }
      } catch (error) {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { refetch: salonService } = useQuery(
    ["salonService", profile_setup, salonId, serviceTypeIds, categoryIds, serviceChoose, categoryChoose],
    async () => {
      try {
        if (profile_setup === true) {
          const response = await Http.post(PROFILE_COMPLETE_URL.servicesFilter, {
            salon_id: salonId,
            service_type_ids: serviceTypeIds,
            category_ids: categoryIds,
            // promotions: filterData?.promotions,
          });
          return response?.data;
        } else {
          const datas = {
            // ...filterData,
            service_type_ids: serviceTypeIds,
            category_ids: categoryIds,
            salon_id: salonId,
          };
          const response = await Http.post(PROFILE_COMPLETE_URL.servicesFilterModal, datas);
          return response?.data;
        }
      } catch (error) {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    },
    {
      onSuccess: (res) => {
        // setServiceTotal(res?.data?.total);
        setServiceList(res?.data?.data);
      },
      refetchOnWindowFocus: false, 
    }
  );

  const handleDiscountFor = (event) => {
    setSelectedValue(event.target.value);
  };

 
  const { mutateAsync: addDiscount } = useMutation(
    async () => {
      try {
        const selectedServiceIds = selectedService.map(service => service.id);
        const requestData = {
          service_id: selectedServiceIds,
          discount_type: selectedDiscountType,
          amount: amount,
          discount_for: selectedValue
        };
  
        if (selectedDiscountType === 1 && amount >= 100) {
          setPerValidationMessage(t('service_discount_modal.disc_can_not_grater'));
          return false;
        }
  
        if (selectedDiscountType === 2 && amount >= parseFloat(serviceData?.price)) {
          setAmountValidationMessage(t('service_discount_modal.amount_can_not_grater'));
          return false;
        }

        if(selectedService?.length > 0)
        {
          const response = await Http.post(PROFILE_COMPLETE_URL.addDiscount, requestData);
          resetModal();
          closeModal();
          if(response)
          {
            enqueueSnackbar(response.data.message, {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          }
          setCreateEditDiscountModalOpen(false);
          salonServiceFromParent();
        }

        else
        {
          enqueueSnackbar(t('service_discount_modal.please_select_service'), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
        
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        enqueueSnackbar(errorMessage, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        
        throw error; // Re-throw the error to ensure onError handler is triggered
      }
    }
  );
  


  useEffect(() => {
    // if (serviceTypeIds?.length && categoryIds?.length) {
    salonService({
      service_type_ids: serviceChoose,
      category_ids: categoryChoose,
    });
    // }
  }, [categoryIds, serviceTypeIds]);

  return (
    <Transition appear show={createEditDiscountModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <form id={"verify-identity"} className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-5xl transform rounded-[10px] bg-white p-3 sm:p-6 text-start align-middle shadow-xl transition-all">
                <div className="modal-header mb-6">
                  <div className="flex items-center justify-center gap-2">
                    <h2 className="text-lg sm:text-[20px] leading-[26px] font-bold text-blue-950">{t('service_discount_modal.create_edit_discount')}</h2>
                  </div>
                  <button
                    type={"button"}
                    aria-label="button"
                    className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                    onClick={() => setCreateEditDiscountModalOpen(false)}>
                    <CloseIcon className="w-5 h-5 text-blue-950/50" />
                  </button>
                </div>
                <div className="modal-body text-center">
                  <div className="mb-4">
                    <LabelWrap className={"text-[15px] mb-0"} labelClassName={"mb-2.5"} label={t('service_discount_modal.Chose_discount_type')} />
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
                      <InputRadioBox
                        groupClassName="p-2 h-[35px] border border-borderColor rounded-md"
                        name="discount_type"
                        id={"Deal"}
                        text={t('radio_label.deal')}
                        labelClassName={"text-sm"}
                        onChange={handleDiscountFor}
                        checked={selectedValue == 1}
                        value={1}
                        className='peer-checked:text-blue-950 flex w-full before:!absolute before:start-0 justify-center'
                      />
                      <InputRadioBox
                        groupClassName="p-2 h-[35px] border border-borderColor rounded-md"
                        name="discount_type"
                        id={"Student card discount"}
                        text={t('radio_label.student_card_discount')}
                        labelClassName={"text-sm"}
                        onChange={handleDiscountFor}
                        checked={selectedValue == 2}
                        value={2}
                        className='peer-checked:text-blue-950 flex w-full before:!absolute before:start-0 justify-center'
                      />
                      <InputRadioBox
                        groupClassName="p-2 h-[35px] border border-borderColor rounded-md"
                        name="discount_type"
                        id={"Senior (+65) discount"}
                        text={t('radio_label.senior_discount')}
                        labelClassName={"text-sm"}
                        onChange={handleDiscountFor}
                        checked={selectedValue == 3}
                        value={3}
                        className='peer-checked:text-blue-950 flex w-full before:!absolute before:start-0 justify-center'
                      />
                    </div>
                  </div>
                  <div className="mb-3.5">
                    <LabelWrap className={"text-[15px] mb-0"} labelClassName={"mb-2.5"} label={t('service_discount_modal.Clear_Add_Update_discount')} />
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
                      <InputRadioBox
                        groupClassName="p-2 h-[35px] border border-borderColor rounded-md"
                        name="update-discount_type"
                        id={"Remove discount"}
                        text={t('service_discount_modal.remove_discount')}
                        labelClassName={"text-sm"}
                        value={0}
                        onChange={() => setSelectedDiscountType(0)}
                        checked={selectedDiscountType == 0}
                        className='peer-checked:text-blue-950 flex w-full before:!absolute before:start-0 justify-center'
                      />
                      <div className="mb-3.5">
                        <InputWithIcon
                          size="sm"
                          className="rounded-lg bg-light-800 h-[35px]"
                          prefixWrap={<InputRadioBox id="student_price" name="update-discount_type" onChange={() => { setSelectedDiscountType(1); setAmount(0) }} checked={selectedDiscountType == 1} />}
                          suffixWrap="%"
                          value={selectedDiscountType == 1 ? amount : ''}
                          // value={amount}
                          onChange={handleAmountChange}
                          disabled={selectedDiscountType !== 1}
                        />
                        {perValidationMessage && (
                          <p className="text-red-500 text-sm mt-1">{perValidationMessage}</p>
                        )}
                      </div>
                      <div className="mb-3.5">
                        <InputWithIcon
                          size="sm"
                          className="rounded-lg bg-light-800 h-[35px]"
                          prefixWrap={<InputRadioBox id="senior_price" name="update-discount_type" onChange={() => { setSelectedDiscountType(2); setAmount(0) }} checked={selectedDiscountType == 2} />}
                          suffixWrap="Dkk."
                          value={selectedDiscountType == 2 ? amount : ''}
                          onChange={handleAmountChange}
                          disabled={selectedDiscountType !== 2}
                        />
                        {amountValidationMessage && (
                          <p className="text-red-500 text-sm mt-1">{amountValidationMessage}</p>
                        )}
                      </div>
                    </div>
                    <h6 className='text-start mt-2 text-[15px] text-blue-950'>{t('service_discount_modal.Choose_concerning_category_or_service')} <span className='text-blue-950/40'>({t('service_discount_modal.from_the_service_menu')})</span> </h6>
                  </div>
                  <div className="mb-3.5">
                    <LabelWrap className={"text-[15px] mb-0"} labelClassName={"mb-2"} label={t('comman_text.service_type_name')} />
                    {/* <CheckboxList selectAllId="serviceTypes" serviceType={serviceType} /> */}
                    <CheckboxList selectAllId="service_type_edit" id="service_types"
                      subCategoryData={serviceType}
                      serviceChoose={serviceChoose}
                      setCategoryChoose={setCategoryChoose}
                      categoryChoose={serviceChoose}
                      setserviceTypeIds={setServiceChoose}
                      setCategoryIds={setserviceTypeIds}
                    />
                  </div>
                  <div className="mb-4">
                    <LabelWrap className={"text-[15px] mb-0"} labelClassName={"mb-2"} label={t('form_input_label.categories')} />
                    {/* <CheckboxList selectAllId="category" serviceType={subCategory} /> */}
                    <CheckboxList
                      selectAllId="category_edit"
                      category={categoryData}
                      subCategoryData={categoryData}
                      serviceChoose={serviceChoose}
                      setCategoryChoose={setCategoryChoose}
                      categoryChoose={categoryChoose}
                      setserviceTypeIds={setCategoryChoose}
                      setCategoryIds={setCategoryIds}
                    />
                  </div>
                  <div className="main-data-wrapper">
                    <div className="category_table_wrapper mt-3">
                      <ServiceTableWithSelect serviceList={serviceList} salonService={salonService} serviceChoose={serviceChoose} categoryChoose={categoryChoose} selectedService={selectedService} setSelectedService={setSelectedService} profile_setup={profile_setup} dataId={serviceData?.id} />
                    </div>
                    <div className='flex flex-col justify-center items-center w-full'>
                      <ButtonPrimary size="sm" type="button" children={t('comman_text.apply')} onClick={addDiscount} className="w-full md:font-extrabold" />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </form>
        </div>
      </Dialog>
    </Transition>
  )
}

export default withTranslation()(CreateEditDiscountModal)