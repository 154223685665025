import React from 'react'

const FaceBookIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.0137 7.97752H29.3669V2.25354C28.7884 2.17549 26.7989 2 24.4817 2C19.647 2 16.3352 4.98051 16.3352 10.4584V15.5H11V21.8989H16.3352V38H22.8763V21.9005H27.9956L28.8083 15.5015H22.8747V11.093C22.8763 9.24347 23.3843 7.97752 26.0137 7.97752Z" fill="white" />
        </svg>
    )
}

export default FaceBookIcon