import { DatePicker } from 'antd'
import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import CalendarIcon from '../icons/CalenderIcon'
import { cn } from '../../../../lib/utils'

const inputVariance = cva(
    'hover:border-borderColor border-[1.3px] rounded-[5px] border-borderColor [&_input]:!text-blue-950 [&_.ant-picker-clear]:-end-4 pe-[18px] shadow-none',
    {
        variants: {
            size: {
                default: 'py-2.5 px-4 h-[45px]',
                sm: 'px-3 leading-[22px] h-[35px]',
                md: 'px-3.5 leading-[24px] h-[40px]',
                lg: 'px-4 leading-[26px] h-[45px]',
            }
        },
        defaultVariants: {
            size: "default",
        }
    },
)


const DateInput = forwardRef(({ groupClassName, require, ref, id, variant, size, className, inputClassName, label, placeholder, suffixIcon = true, labelClassName, ...props }) => {

    return (
        <div className={cn("flex flex-col", groupClassName)}>
            {label && <label className={cn('text-blue-950 my-1', labelClassName)}><span className={cn(require ? "before:content-['*'] before:text-danger-950 before:text-2xl before:leading-[1] before:absolute before:top-0.5 before:end-0 inline-block pe-3 relative" : "")}>{label}</span></label>}
            <DatePicker ref={ref} id={id} {...props} className={cn(inputVariance({ variant, size, className }))} placeholder={placeholder} suffixIcon={suffixIcon && <CalendarIcon className="w-5 h-5 text-primary1" />} />
        </div>
    )
})

export { inputVariance }
export default DateInput