import React from 'react'

function PencilIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7517 6.50677L23.4387 5.7837C25.835 3.26123 27.0332 2 28.5221 2C30.011 2 31.2091 3.26123 33.6055 5.7837C36.0018 8.30617 37.2 9.56741 37.2 11.1347C37.2 12.7019 36.0018 13.9632 33.6055 16.4856L32.9185 17.2087M22.7517 6.50677L5.11415 25.0726C4.05555 26.1869 3.52625 26.7442 3.25235 27.454C2.97844 28.1637 2.9887 28.9516 3.00921 30.5273L3.05712 34.2084C3.07975 35.9477 3.09106 36.8174 3.6073 37.3608C4.12353 37.9042 4.9497 37.916 6.60205 37.9399L10.0991 37.9903C11.596 38.0119 12.3445 38.0226 13.0188 37.7343C13.6931 37.446 14.2224 36.8889 15.281 35.7746L32.9185 17.2087M22.7517 6.50677L32.9185 17.2087M22.8 37.9903L37.2 37.9903" stroke="currentColor" strokeWidth={props.strokeWidth ? props.strokeWidth : 3} strokeLinecap="round" />
        </svg>
    )
}

export default PencilIcon;