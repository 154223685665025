import React from 'react'

const PhysiotherapistIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.3846 25.4615C37.9336 25.4615 40 23.3952 40 20.8462C40 18.2972 37.9336 16.2308 35.3846 16.2308C32.8356 16.2308 30.7692 18.2972 30.7692 20.8462C30.7692 23.3952 32.8356 25.4615 35.3846 25.4615Z" fill="#3BDFFD" />
            <path d="M29.5085 20.9366C29.5085 22.3492 29.9713 23.6466 30.7533 24.6896C30.0511 25.1741 29.1973 25.4615 28.2876 25.4615H2.60934C1.17302 25.4615 0 24.2544 0 22.7761C0 21.298 1.17302 20.099 2.60934 20.099H10.8363C10.6368 19.8198 10.4373 19.5324 10.2378 19.2285C9.7271 18.473 8.88926 18.0213 7.98756 18.0213H2.60934C1.89914 18.0213 1.20492 17.7093 0.718181 17.1837C0.223451 16.6417 -0.0319005 15.9355 0.00798713 15.1963C0.0798251 13.7674 1.26877 12.6506 2.71307 12.6506H9.08076C10.7804 12.6506 12.3843 13.3322 13.5892 14.5722L15.3368 16.3789H21.6965L18.0339 10.5483C17.7147 9.97343 17.6349 9.34108 17.7865 8.73339C17.946 8.13392 18.3211 7.62477 18.8477 7.3127C19.3744 7.00064 19.9888 6.91849 20.5714 7.08274C21.1618 7.24699 21.6486 7.63294 21.9518 8.17497L27.0986 16.3789H28.2876C29.2053 16.3789 30.0591 16.6663 30.7692 17.1591C29.9793 18.202 29.5085 19.5159 29.5085 20.9366Z" fill="#3BDFFD" />
            <path d="M40 28.4727V31.5262C40 31.9557 39.64 32.3072 39.2 32.3072H0.8C0.360009 32.3072 0 31.9557 0 31.5262V28.4727C0 28.0432 0.36001 27.6918 0.8 27.6918H39.2C39.64 27.6918 40 28.0432 40 28.4727Z" fill="#00FDB4" />
        </svg>
    )
}

export default PhysiotherapistIcon