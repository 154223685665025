import React from 'react'

const UserIcon1 = (props) => {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7.67019 29.5979C7.85027 27.8776 7.94032 27.0175 8.2451 26.3876C8.76126 25.3209 9.62921 24.5956 10.7706 24.2771C11.4446 24.089 12.3503 24.1564 14.1615 24.2911C16.0542 24.4319 18.1485 24.5435 20.0316 24.5435C21.9998 24.5435 24.1717 24.4216 26.0967 24.2718C27.816 24.1381 28.6756 24.0712 29.3274 24.2419C30.4152 24.5267 31.2565 25.1776 31.8053 26.1591C32.1342 26.747 32.2778 27.5539 32.5651 29.1677V29.1677C32.9119 31.1152 33.0852 32.089 32.9103 32.8722C32.6272 34.139 31.747 35.1898 30.5494 35.6905C29.809 36 28.8199 36 26.8418 36L20.0316 36H13.4371C11.598 36 10.6784 36 9.96966 35.7198C8.80583 35.2595 7.92449 34.2811 7.58793 33.0757C7.38297 32.3416 7.47871 31.427 7.67019 29.5979V29.5979Z" fill="#00FDB4" stroke="#00FDB4" strokeWidth="0.9" />
            <path d="M13.7014 11C13.7014 8.23857 15.94 6 18.7014 6H22.0922C24.8536 6 27.0922 8.23858 27.0922 11V14.3759C27.0922 17.1373 24.8536 19.3759 22.0922 19.3759H21.3533H19.4403H18.7014C15.94 19.3759 13.7014 17.1373 13.7014 14.3759V11Z" fill="#3BDFFD" stroke="#3BDFFD" strokeWidth="0.9" />
        </svg>
    )
}

export default UserIcon1