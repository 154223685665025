import React from 'react';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
import Slider from 'react-slick';
import { cn } from '../../../../lib/utils';
import { withTranslation } from 'react-i18next';
import PortfolioDetailsPortfolioCard from './PortfolioDetailsPortfolioCard';

function SampleNextArrow(props) {
    const { onClick } = props;

    return (
        <div onClick={onClick} className='absolute top-1/2 -translate-y-1/2 end-1 bg-primary1 rounded-full h-[25px] w-[25px] flex items-center justify-center cursor-pointer'><RightSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className='absolute top-1/2 z-10 -translate-y-1/2 start-0.5 bg-primary1 rounded-full h-[25px] w-[25px] flex items-center justify-center cursor-pointer'><LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}


const PortfolioDetails = ({ colored, workerData, t, groupClassName, item, PortfolioDetails, data, navigateUrl, handelClickEvent, ...props }) => {
    const shopSliderMain = {
        infinite: false,
        slidesToShow: item >= 2 ? 2 : 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    };
    return (
        <Slider {...shopSliderMain} className={cn('slider_flex gap_14 h-full')}>
            {
                workerData?.map((portfolioData) => (
                    portfolioData.protfolios?.map((portfolio) => (
                        <PortfolioDetailsPortfolioCard
                            key={portfolio.id} PortfolioData={portfolio} groupClassName={cn(`${colored && "bg-light-800"}`, groupClassName)}
                        />
                    ))
                ))
            }
        </Slider>
    );
}

export default withTranslation()(PortfolioDetails);
