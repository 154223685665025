import React from 'react'

const BodyTreatmentIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.2557 40H8.50873L10.0941 31.4245H13.323C13.9135 31.4245 14.5169 31.3682 15.1009 31.2557L19.2283 30.4557H23.4905L25.2557 40ZM10.6847 17.7112C9.90167 14.6611 11.8274 11.5172 14.9854 10.7296C15.4861 10.6047 16.0125 10.5421 16.5323 10.5484C16.1356 11.1923 16.0181 12.1816 16.404 13.061C16.3393 13.0429 16.2377 13.0484 16.173 13.0484C14.6709 13.0484 13.3743 14.0423 13.0148 15.4548C12.5784 17.2051 11.8979 17.7801 10.8131 18.305L10.6847 17.7112Z" fill="#3BDFFD" />
            <path d="M20.172 28.9945L14.8506 30.0245C14.347 30.122 13.8346 30.1712 13.3211 30.1712H8.98381L7.87335 36.1715C7.623 37.5466 6.39696 38.5466 4.96554 38.5466C4.78583 38.5466 4.61251 38.5278 4.43919 38.5028C3.02701 38.2529 2 37.0653 2 35.6714C2 35.4965 2.01923 35.3214 2.05778 35.1526L3.40575 27.8148C3.75878 25.9334 5.36994 24.5459 7.30204 24.4271C7.97603 24.9521 8.86827 25.1896 9.78623 25.0146C11.1855 24.752 14.9727 23.4958 16.1153 22.4956C18.0474 20.8206 18.9396 18.5892 19.3439 17.0016C19.5044 16.3516 19.4595 15.7142 19.2412 15.1453C20.3567 15.6358 21.6484 16.0921 22.5086 16.3391L23.8244 22.5082C24.5433 25.2208 22.99 28.2945 20.172 28.9945ZM16.5196 5.85727C16.5196 8.36367 14.4271 10.4013 11.8595 10.4013C9.28542 10.4013 7.18652 8.36367 7.18652 5.85727C7.18652 3.35088 9.28542 1.31326 11.8595 1.31326C14.4271 1.31326 16.5196 3.35088 16.5196 5.85727Z" fill="#3BDFFD" />
            <path d="M34.2821 29.2892L33.8586 37.5021C33.788 38.7646 32.7223 39.7585 31.4194 39.7585C30.1227 39.7585 29.0507 38.7646 28.9866 37.5021L28.6142 30.2017L34.2821 29.2892ZM38.1656 27.4015L27.048 29.1828L25.822 17.0885C27.1892 17.2947 28.4409 17.3448 29.5321 17.2698C31.0534 17.1697 32.2474 15.9196 32.2474 14.4196C32.2474 12.782 30.8031 11.4506 29.1342 11.5694C28.3061 11.6194 27.446 11.5569 26.5731 11.4194C27.2791 10.5256 28.2933 9.89437 29.4551 9.65676C29.7824 9.59434 30.1098 9.56299 30.45 9.56299C32.6903 9.56299 34.6353 11.0381 35.1808 13.1569L38.1656 27.4015ZM33.3 4.65025C33.3 7.21289 31.1626 9.30047 28.5243 9.30047C25.8926 9.30047 23.7552 7.21289 23.7552 4.65025C23.7552 2.08763 25.8926 0 28.5243 0C31.1626 0 33.3 2.08763 33.3 4.65025Z" fill="#00FDB4" />
            <path d="M30.9668 14.4069C30.9668 15.2183 30.3319 15.9523 29.4457 16.0103C29.1614 16.0296 28.8571 16.036 28.5265 16.036C27.8982 16.036 27.1707 15.9974 26.3507 15.8944C24.9752 15.714 23.335 15.3535 21.4636 14.6709C20.4913 14.3232 19.4597 13.8917 18.3619 13.3572C17.575 12.9773 17.2509 12.0565 17.5883 11.29C17.6081 11.2386 17.6345 11.187 17.6675 11.1356C17.9652 10.6075 18.5273 10.3178 19.1093 10.3178C19.3606 10.3178 19.6184 10.3692 19.8566 10.4852C22.601 11.8182 24.8495 12.4235 26.5557 12.6682C27.6865 12.8356 28.5727 12.842 29.2142 12.8035C29.9483 12.7519 30.6163 13.1898 30.8675 13.8659C30.9337 14.0462 30.9668 14.233 30.9668 14.4069Z" fill="#00FDB4" />
            <path d="M18.1002 16.6981C18.1399 16.5371 18.1598 16.3761 18.1598 16.2151C18.1598 15.1977 17.3331 14.309 16.2287 14.2897C15.3028 14.264 14.4828 14.8693 14.2646 15.7451C14.0265 16.6852 13.5503 17.8572 12.6245 18.6685C12.4261 18.8359 12.0888 19.0485 11.6789 19.2674C10.8191 19.7118 9.66838 20.1754 9.05988 20.2462C8.21344 20.3492 7.52567 20.9739 7.44632 21.8046L7.43308 21.9334C7.32729 23.0796 8.37876 24.0069 9.53605 23.7816C10.2767 23.6399 11.3944 23.2986 12.4525 22.9058C13.6628 22.4679 14.8068 21.9528 15.2631 21.5535C16.9098 20.1239 17.71 18.2049 18.1002 16.6981Z" fill="#3BDFFD" />
        </svg>
    )
}

export default BodyTreatmentIcon