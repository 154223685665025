import React, { useEffect, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { cn } from '../../../../../../lib/utils'
import ImageGalleryIcon from '../../../../uiComponents/icons/ImageGalleryIcon'
import InfoFIllIcon from '../../../../uiComponents/icons/InfoFIllIcon'
import InputCheckbox from '../../../../uiComponents/inputs/InputCheckbox'
import NoDataFound from '../../../../uiComponents/table/NoDataFound'
import Table from '../../../../uiComponents/table/Table'
import TBody from '../../../../uiComponents/table/TBody'
import TD from '../../../../uiComponents/table/TD'
import TH from '../../../../uiComponents/table/TH'
import THead from '../../../../uiComponents/table/THead'
import TR from '../../../../uiComponents/table/TR'
import Tooltip from '../../../../uiComponents/Tooltip'
import { enqueueSnackbar } from 'notistack'

const columns = [
    {
        className: "td_category",
        title: "Category",
        dataIndex: "category",
    },
    {
        className: "td_service",
        title: "Service",
        dataIndex: "service"

    },
    {
        className: "td_price",
        title: "Price",
        dataIndex: "price"
    },
    {
        className: "td_images",
        title: "Images",
        dataIndex: "images",
    },
    {
        className: "td_for",
        title: "For",
        dataIndex: "for",
    },
    {
        className: "td_duration text-center",
        title: "Duration",
        dataIndex: "duration",
    }
];

const ServiceSpecialistTable = ({ t, className, groupClassName, tableClass, data, salonService, serviceList, serviceChoose, categoryChoose, selectedServices, setSelectedServices, selectedIds, setSelectedIds, ...props }) => {
    const [count, setCount] = useState(0);
    const handleGender = (data) => {
        let textData = data?.map((item) => {
            if (item == '1') return 'Men';
            if (item == '2') return 'Women';
            if (item == '3') return 'Girl';
            if (item == '4') return 'Boy';
        });
        return textData?.join(',');
    }

    useEffect(() => {
        const selectedIdsLength = selectedIds?.length
        setCount(selectedIdsLength);
    }, [selectedIds]);

    const handleRowSelect = (value, category, service_title, service_for) => {
        if (selectedIds?.includes(value)) {
            setSelectedServices(selectedServices.filter(item => item?.id !== value));
            setSelectedIds(selectedIds.filter(item => item !== value));
        } else {
            if (count  >= 4) {
                enqueueSnackbar("Sorry ! You can't select more than 4 Specialities.", {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                return;
            }
            setSelectedServices([...selectedServices, { id: value, category: category, service_title: service_title, service_for: service_for }]);
            setSelectedIds([...selectedIds, value]);
        }
    };
    return (
        <div className={cn('service-table-wrapper', groupClassName)}>
            <div className={cn(`overflow-x-auto border-[1.3px] border-success-100 rounded-[8px] `, className)}>
                <Table className={cn("text-[15px] text-blue-950 rounded-[8px] border-collapse border-success-100", tableClass)}>
                    <THead>
                        <TR className={"font-bold bg-success-950 text-white hover:bg-success-950 py-2"}>
                            <TH className={"font-semibold border-b-0 py-2"}>Selected</TH>
                            {
                                columns.map((column, index) => (
                                    <TH className={"font-semibold border-b-0 py-2 px-3 md:px-5"}>{column.title}</TH>
                                ))
                            }
                        </TR>
                    </THead>
                    <TBody>
                        {
                            serviceList?.length ? serviceList.map((data, index) => (
                                <>
                                    <TR className={" odd:bg-success-150"}>
                                        <TD className={"px-5 py-1 font-medium leading-[1.3] text-blue-950 text-center"}></TD>
                                        <TD className={"px-5 py-1 font-medium leading-[1.3] text-blue-950 text-center"}>
                                            {data[0].category?.name}
                                        </TD>
                                        <TD className={"px-5 py-1 font-medium leading-[1.3] text-blue-950 text-center"} colSpan={5}></TD>
                                    </TR>

                                    {
                                        data.length && data.map((service, rowIndex) => (
                                            <TR className={`py-2 border-b-[1.3px] last:border-b-0 odd:bg-success-150`} key={rowIndex}>
                                                <TD className={"py-1"}>
                                                    <div className={"w-full flex justify-center items-center"}>
                                                        <InputCheckbox
                                                            id={`rowIndex_${index}-${rowIndex}`}
                                                            size={"md"}
                                                            className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                                                            value={service?.id}
                                                            checked={selectedIds?.includes(service?.id)}
                                                            onChange={() => handleRowSelect(service?.id, service?.category?.name, service?.title, service?.service_for)}
                                                        />
                                                    </div>
                                                </TD>
                                                <TD></TD>
                                                <TD className={"py-1"}>
                                                    <div className='flex gap-2 w-full items-center '>
                                                        <Tooltip rootClassName='theme_tooltip' title="lorem" ><InfoFIllIcon className="w-5 h-5 " /></Tooltip>{service.title}
                                                    </div>
                                                </TD>
                                                <TD className={"text-center py-1"}>{service?.price}</TD>
                                                <TD className={"text-center py-1"}>
                                                    <div className='flex justify-center items-center w-full'>
                                                        <ImageGalleryIcon className="w-6 h-6" />
                                                    </div>
                                                </TD>
                                                <TD className={"text-center py-1"}>{handleGender(service?.service_for)}</TD>
                                                <TD className={"text-center py-1"}>{service?.duration}</TD>
                                            </TR>
                                        ))
                                    }
                                </>

                            )) : <NoDataFound colSpan={7} className={"text-center"} />
                        }
                    </TBody>
                </Table>
            </div>
        </div>
    )
}

export default withTranslation()(ServiceSpecialistTable)