import Lottie from "lottie-react";
import preloaderJson  from "../../assets/json/preloader.json";

export default function LoadingScreen() {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Lottie animationData={preloaderJson} loop={true} style={{maxWidth:"450px" ,width:"100%"}} />
        </div>
    );
}
