import React from 'react'

const FindServiceIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_101_61)">
                <ellipse cx="22.1763" cy="17.3002" rx="15.3002" ry="16.1423" transform="rotate(90 22.1763 17.3002)" stroke="#0DEFDA" strokeWidth={3} />
                <path d="M1 39.1228L8.38385 31.4003" stroke="currentcolor" strokeWidth="2.6" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_101_61">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FindServiceIcon