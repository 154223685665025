import React from 'react'

function ChildIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.3333 16.3333C37.0152 16.3333 40 13.3485 40 9.66665C40 5.98476 37.0152 3 33.3333 3C29.6514 3 26.6667 5.98476 26.6667 9.66665C26.6667 13.3485 29.6514 16.3333 33.3333 16.3333Z" fill="#3BDFFD" />
            <path d="M12.36 27.5893L15.0192 27.2322L9.60739 16.5478C6.16295 17.0574 4.15407 18.9774 3.38962 21.5537C1.63259 27.127 6.08295 29.7952 9.8459 33.0574L1.29185 34.127C0.564445 34.2174 0.00148397 34.8366 2.49199e-06 35.5878C-0.00147899 36.4026 0.657778 37.0648 1.47259 37.0663C2.63703 37.0692 -1.35851 37.0603 13.6992 37.09C15.8044 37.087 17.0814 34.7922 15.997 33.0115C14.8163 31.0678 13.8518 29.3907 12.36 27.5893ZM36.8636 34.9107C29.8429 21.4337 31.074 23.7967 29.6414 21.0471C28.7288 19.2945 26.9259 14.496 21.3866 14.8752C20.9155 14.9182 16.2163 15.5878 12.6977 16.096L18.1259 26.813L26.5259 25.6826C29.197 29.6396 31.4962 32.7389 34.397 36.49C34.8236 37.0411 35.5969 37.2263 36.2369 36.8929C36.9584 36.5181 37.2384 35.6307 36.8636 34.9107Z" fill="#00FDB4" />
        </svg>
    )
}

export default ChildIcon