import React from 'react'

const TikTokIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M26.7227 2.03023C26.7248 2.57937 26.8884 10.5155 35.3859 11.0105C35.3859 13.2992 35.3889 14.9586 35.3889 17.1425C34.7454 17.179 29.7946 16.8268 26.7133 14.1331L26.704 26.0707C26.8209 34.3564 20.5996 39.3985 12.4679 37.6567C-1.55436 33.5455 3.10513 13.1621 17.1848 15.3439C17.1848 21.9244 17.1884 15.3418 17.1884 21.9244C11.3717 21.0856 9.42614 25.8281 10.9721 29.2235C12.3782 32.3152 18.1698 32.9851 20.19 28.6238C20.4189 27.7702 20.5329 26.797 20.5329 25.7044V2L26.7227 2.03023Z" fill="white" />
        </svg>
    )
}

export default TikTokIcon