import React, { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import AuthMaster from '../layout/authlayout/AuthMaster';
import ErrorMaster from '../layout/errorlayout/ErrorMaster';
import routes from './routes';
import FrontMaster from '../layout/frontlayout/FrontMaster';
// import ProviderMaster from '../layout/providerlayout/ProviderMaster';
import authStores from "../contexts/AuthProvider";
import { PATH_AUTH, PATH_CUSTOMER, PATH_PROVIDER, PATH_WORKER } from './path';
import LoadingScreen from '../loading/LoadingScreen';
import { Suspense } from 'react';
// import CustomerMaster from '../layout/customerlayout/CustomerMaster';
// import ApplyJobMaster from '../layout/applyJobLayout/ApplyJobMaster';


const AuthMaster = lazy(() => import("../layout/authlayout/AuthMaster"));
// const FrontMaster =  lazy(() => import("../layout/frontlayout/FrontMaster"));
const ProviderMaster = lazy(() => import("../layout/providerlayout/ProviderMaster"));
const CustomerMaster = lazy(() => import("../layout/customerlayout/CustomerMaster"));
const ApplyJobMaster = lazy(() => import("../layout/applyJobLayout/ApplyJobMaster"));

const RoutePage = () => {
    const isAuthenticated = authStores((state) => state.isAuthenticated);
    const is_profile_complete = authStores((state) => state.is_profile_complete);
    const salonProfileStep = authStores((state) => state.salonProfileStep);
    const become_worker_profile = authStores((state) => state.become_worker_profile);
    const profileType = authStores((state) => state.profileType);


    return (
        <Router>
            <Suspense fallback={
                <LoadingScreen
                    sx={{
                        ...{
                            top: 0,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: "fixed",
                        },
                    }}
                />
            }>
                <Routes>
                    {routes.map(({ path, auth, authType, MenuCollapsed, layout, ActiveMenuKey, component: Component, IsProfile, IsManager, isRegister }, key) => {
                        return (
                            layout === 'penal' ?
                                auth === true && MenuCollapsed === true ?
                                    <Route path="/" element={<ProviderMaster salonProfileStep={salonProfileStep} IsManager={IsManager} ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} authType={authType} IsProfile={IsProfile} />} key={key}>
                                        <Route exact path={path} element={Component} />
                                    </Route>
                                    : auth === true && isAuthenticated ?
                                        <Route path="/" element={<ProviderMaster salonProfileStep={salonProfileStep} IsManager={IsManager} ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} authType={authType} IsProfile={IsProfile} />} key={key}>
                                            <Route exact path={path} element={Component} />
                                        </Route>
                                        :
                                        <Route path="/" element={isAuthenticated ? <ProviderMaster salonProfileStep={salonProfileStep} IsManager={IsManager} ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} authType={authType} IsProfile={IsProfile} /> : <Navigate to={PATH_AUTH.login} />} key={key}>
                                            <Route exact path={path} element={Component} />
                                        </Route>
                                : layout === 'customer' ?
                                    auth === true && MenuCollapsed === true ?
                                        <Route path="/" element={isAuthenticated ? <CustomerMaster ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} /> : <Navigate to={PATH_AUTH.login} />} key={key}>
                                            <Route exact path={path} element={Component} />
                                        </Route>
                                        :
                                        auth === true && isAuthenticated ?
                                            <Route path="/" element={

                                                isRegister == 1 ?
                                                    <AuthMaster ActiveMenuKey={ActiveMenuKey} ptitle={Component.props.title} /> :
                                                    <CustomerMaster ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} />} key={key}>
                                                <Route exact path={path} element={Component} />
                                            </Route>
                                            :
                                            <Route path="/" element={isAuthenticated ? <CustomerMaster ActiveMenuKey={ActiveMenuKey} isAuthenticated={isAuthenticated} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} /> : <Navigate to={PATH_AUTH.login} />} key={key}>
                                                <Route exact path={path} element={Component} />
                                            </Route>
                                    : layout === 'front' ?
                                        auth === true && MenuCollapsed === true ?
                                            <Route path="/" element={<FrontMaster ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} />} key={key}>
                                                <Route exact path={path} element={Component} />
                                            </Route>
                                            : auth === true ?
                                                <Route path="/" element={

                                                    <FrontMaster ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} />
                                                } key={key}>
                                                    <Route exact path={path} element={Component} />
                                                </Route>
                                                :
                                                <Route path="/" element={
                                                    isAuthenticated && profileType == 2 && is_profile_complete == 1 ?
                                                        <Navigate to={PATH_PROVIDER.dashboard} /> :
                                                        isAuthenticated && profileType == 1 && become_worker_profile == 1 ?
                                                            <Navigate to={PATH_WORKER.dashboard} /> :
                                                            isAuthenticated && !profileType ?
                                                                <Navigate to={PATH_CUSTOMER.findService} /> :
                                                                <AuthMaster ActiveMenuKey={ActiveMenuKey} ptitle={Component.props.title} />
                                                } key={key} >
                                                    <Route exact path={path} element={Component} />
                                                </Route>
                                        :
                                        layout === 'applyjob' ?
                                            auth === true && MenuCollapsed === true ?
                                                <Route path="/" element={<ApplyJobMaster ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} />} key={key}>
                                                    <Route exact path={path} element={Component} />
                                                </Route>
                                                : auth === true ?
                                                    <Route path="/" element={<ApplyJobMaster ActiveMenuKey={ActiveMenuKey} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} />} key={key}>
                                                        <Route exact path={path} element={Component} />
                                                    </Route>
                                                    :
                                                    <Route path="/" element={!isAuthenticated ? <AuthMaster ActiveMenuKey={ActiveMenuKey} isAuthenticated={isAuthenticated} MenuCollapsed={MenuCollapsed} ptitle={Component.props.title} /> : <Navigate to={PATH_AUTH.createBusiness} />} key={key}>
                                                        <Route exact path={path} element={Component} />
                                                    </Route>
                                            :
                                            <Route path="/" element={<ErrorMaster ActiveMenuKey={ActiveMenuKey} ptitle={Component.props.title} />} key={key} >
                                                <Route exact path={path} element={Component} />
                                            </Route>
                        )
                    })
                    }
                </Routes>
            </Suspense>
        </Router >
    )
}
export default RoutePage;