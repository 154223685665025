import React from 'react';
import Slider from "react-slick"
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
import Badge2 from './Badge2';
import { cn } from '../../../../lib/utils';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_CUSTOMER } from '../../../routes/path';
import { authStore } from '../../../contexts/AuthProvider';

function SampleNextArrow(props) {
    const { btnClassName, style, onClick } = props;

    return (
        <div onClick={onClick} className={cn('absolute top-1/2 -translate-y-1/2 -end-0 bg-primary1 rounded-full h-[26px] w-[26px] flex items-center justify-center cursor-pointer text-white', btnClassName)}><RightSmallArrowIcon className="w-3.5 h-3.5 " /></div>
    );
}

function SamplePrevArrow(props) {
    const { btnClassName, style, onClick } = props;
    return (
        <div onClick={onClick} className={cn('absolute top-1/2 z-10 -translate-y-1/2 start-1 bg-primary1 rounded-full h-[26px] w-[26px] flex items-center justify-center cursor-pointer text-white', btnClassName)}><LeftSmallArrowIcon className="w-3.5 h-3.5 " /></div>
    );
}

const BadgeSlider = ({ salonData, badgelist, badgeGroupClassName, badgeTextClass, iconClassName, btnClassName, data, navigateUrl, handelClickEvent, ...props }) => {
    const { isAuthenticated,profileType } = authStore((state) => state)
    const navigate = useNavigate();
    const shopSliderMain = {
        centerMode: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow btnClassName={btnClassName} />,
        prevArrow: <SamplePrevArrow btnClassName={btnClassName} />,
        dots: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    arrows: true,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: true,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 630,
                settings: {
                    arrows: true,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]

    };

    return (
        <Slider {...shopSliderMain} className='slider_flex gap_14 h-full [&_.slick-track]:mx-0'>
            {

                (badgelist && badgelist.length > 0) && badgelist?.map((data, index) =>
                    <div className={cn("slider_item text-blue-950", handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate((isAuthenticated && profileType == "") ? PATH_CUSTOMER.findSalonProfile : PATH_AUTH.findSalonProfile, { state: { id: data?.salon_id || salonData?.id, data: salonData } })}>
                        <Badge2 data={data?.badge} groupClassName={badgeGroupClassName} badgeTitle={data?.badge} className={badgeTextClass} iconClassName={iconClassName} />
                    </div>
                )
            }
        </Slider>
    );
}

export default BadgeSlider;
