import React from 'react';
import EnergyIcon from '../icons/EnergyIcon';
import TeddyIcon from '../icons/TeddyIcon';
import ChairWithManIcon from '../icons/ChairWithManIcon';
import PhoneIcon2 from '../icons/PhoneIcon2';
import { cn } from '../../../../lib/utils';

const ServiceFacility = ({ className, phoneIcon2, serviceFacilityIconClass, salonData, ...props }) => {
    return (
        <div className={cn('flex items-center gap-2', className)} >
            <span className={cn('h-7 w-7 rounded-full bg-blue-800 flex items-center justify-center', serviceFacilityIconClass)}><EnergyIcon className='text-white h-4 w-4' /></span>
            {salonData?.child_friendly == 1 && (
                <span className={cn('h-7 w-7 rounded-full bg-blue-800 flex items-center justify-center', serviceFacilityIconClass)}><TeddyIcon className='text-white h-6 w-6' /></span>
            )}
            {salonData?.wheelchair_friendly == 1 && (
                <span className={cn('h-7 w-7 rounded-full bg-blue-800 flex items-center justify-center', serviceFacilityIconClass)}><ChairWithManIcon className='text-white h-5 w-5' /></span>
            )}
            {phoneIcon2 && <span className={cn('h-7 w-7 rounded-full bg-success-950 flex items-center justify-center', serviceFacilityIconClass)}><PhoneIcon2 className='text-white h-4 w-4' /></span>}
        </div>
    );
}

export default ServiceFacility;
