import React from 'react'

const FilterIcon = (props) => {
    return (
        <svg {...props} width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_585_59)">
                <path d="M14.7869 23.3662C17.1056 23.3662 18.9853 21.4865 18.9853 19.1678C18.9853 16.849 17.1056 14.9694 14.7869 14.9694C12.4682 14.9694 10.5885 16.849 10.5885 19.1678C10.5885 21.4865 12.4682 23.3662 14.7869 23.3662Z" stroke="currentcolor" strokeWidth="1.8" />
                <path d="M38.4013 17.7974H19.2013C18.3176 17.7974 17.6013 18.5138 17.6013 19.3974C17.6013 20.2811 18.3176 20.9974 19.2013 20.9974H38.4013C39.2849 20.9974 40.0013 20.2811 40.0013 19.3974C40.0013 18.5138 39.2849 17.7974 38.4013 17.7974Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.3" />
                <path d="M9.6 17.7974H1.6C0.716344 17.7974 0 18.5138 0 19.3974C0 20.2811 0.716344 20.9974 1.6 20.9974H9.6C10.4837 20.9974 11.2 20.2811 11.2 19.3974C11.2 18.5138 10.4837 17.7974 9.6 17.7974Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.3" />
                <path d="M25.7566 11.6798C28.0753 11.6798 29.955 9.80007 29.955 7.48136C29.955 5.16265 28.0753 3.28296 25.7566 3.28296C23.4379 3.28296 21.5582 5.16265 21.5582 7.48136C21.5582 9.80007 23.4379 11.6798 25.7566 11.6798Z" stroke="currentcolor" strokeWidth="1.8" />
                <path d="M20.8 6.59375H1.6C0.716344 6.59375 0 7.31009 0 8.19375C0 9.07741 0.716344 9.79375 1.6 9.79375H20.8C21.6837 9.79375 22.4 9.07741 22.4 8.19375C22.4 7.31009 21.6837 6.59375 20.8 6.59375Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.3" />
                <path d="M38.3993 6.59344H31.9993C31.1157 6.59344 30.3993 7.30979 30.3993 8.19344C30.3993 9.0771 31.1157 9.79344 31.9993 9.79344H38.3993C39.283 9.79344 39.9993 9.0771 39.9993 8.19344C39.9993 7.30979 39.283 6.59344 38.3993 6.59344Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.3" />
                <path d="M25.7566 36.5173C28.0753 36.5173 29.955 34.6376 29.955 32.3189C29.955 30.0002 28.0753 28.1205 25.7566 28.1205C23.4379 28.1205 21.5582 30.0002 21.5582 32.3189C21.5582 34.6376 23.4379 36.5173 25.7566 36.5173Z" stroke="currentcolor" strokeWidth="1.8" />
                <path d="M20.8 30.599H1.6C0.716344 30.599 0 31.3153 0 32.199C0 33.0827 0.716344 33.799 1.6 33.799H20.8C21.6837 33.799 22.4 33.0827 22.4 32.199C22.4 31.3153 21.6837 30.599 20.8 30.599Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.3" />
                <path d="M38.3993 30.599H31.9993C31.1157 30.599 30.3993 31.3153 30.3993 32.199C30.3993 33.0827 31.1157 33.799 31.9993 33.799H38.3993C39.283 33.799 39.9993 33.0827 39.9993 32.199C39.9993 31.3153 39.283 30.599 38.3993 30.599Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.3" />
            </g>
            <defs>
                <clipPath id="clip0_585_59">
                    <rect width={40} height="35.7968" fill="currentcolor" transform="translate(0 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FilterIcon