import React from 'react';

const LocationPin4 = (props) => {
    return (
        <svg {...props} viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3238 44.4589C13.6085 44.2524 8.31097 43.8767 3.44544 41.3782C2.24315 40.7583 1.20993 39.9691 0.533642 38.7857C-0.443219 37.0575 -0.0675043 35.2163 1.56686 33.8074C3.50179 32.1543 5.85001 31.403 8.23581 30.7265C9.87017 30.2757 9.87017 30.2757 8.96845 28.9042C6.78931 25.6168 5.11738 22.1227 4.44109 18.1777C3.06972 10.1937 8.31097 1.98433 15.938 0.293595C21.1417 -0.852338 26.8337 1.42074 29.9897 5.98569C33.3524 10.8324 33.8972 16.0924 32.075 21.6343C31.1733 24.3581 29.8583 26.8755 28.2614 29.2236C27.7166 30.0126 27.7919 30.2757 28.7686 30.5011C31.3798 31.0835 33.9536 31.8348 36.1139 33.5444C38.5184 35.4606 38.6501 38.0342 36.3581 40.0631C34.1226 42.0544 31.3234 42.806 28.5058 43.4258C25.3498 44.1584 22.1373 44.4402 18.3238 44.4589ZM6.56388 15.4913C6.62023 16.5057 6.63903 17.2572 6.71416 17.9898C6.77051 18.5158 6.92082 19.0418 7.0523 19.549C8.83697 25.9362 12.6505 30.9145 17.7226 35.0472C18.5116 35.686 19.1504 35.6297 19.9018 35.0285C24.5982 31.2339 27.942 26.4811 29.9146 20.7889C31.2107 17.0505 31.098 13.3685 29.5014 9.72406C26.6459 3.09269 19.0188 0.349953 12.9323 3.95683C8.55518 6.56804 6.71416 10.7573 6.56388 15.4913ZM19.3382 42.3738C21.2544 42.2048 23.6026 42.1295 25.9133 41.7914C28.6749 41.3969 31.4174 40.777 33.8785 39.2929C36.4334 37.7713 36.4334 36.0992 33.8972 34.5964C31.8119 33.3753 29.5014 32.7741 27.1531 32.3233C26.4205 32.1732 25.932 32.3423 25.4435 32.9058C24.2601 34.2772 22.9827 35.5733 21.5362 36.663C19.1691 38.4476 18.3614 38.4286 15.9943 36.569C14.623 35.4983 13.3831 34.2959 12.2184 33.0185C11.8803 32.6427 11.5797 32.2296 10.9973 32.3233C8.14188 32.7931 5.34281 33.488 3.03214 35.3856C1.84865 36.3623 1.94257 37.6773 3.16365 38.6353C4.1405 39.4056 5.24888 39.9504 6.41359 40.3825C10.4338 41.9041 14.6793 42.1108 19.3382 42.3738Z" fill="currentColor" />
            <path d="M18.9646 23.4495C12.2769 23.4307 7.97494 16.4236 10.4734 10.2055C12.0327 6.31687 15.8274 3.8935 19.6785 4.36314C23.5859 4.83279 26.911 8.21422 27.4745 12.3283C28.226 17.7198 25.1451 22.2848 20.0918 23.318C19.6221 23.4119 19.1149 23.4307 18.9646 23.4495ZM12.089 14.0566C12.1078 14.2445 12.1078 14.6202 12.1454 14.9771C12.4647 17.7762 14.3809 20.1996 17.0297 20.9134C19.6785 21.6273 21.914 20.8007 23.6611 18.7343C26.047 15.9352 25.8779 11.4454 23.3417 8.7778C21.7637 7.10586 19.8476 6.31687 17.5557 6.7865C14.193 7.48158 12.0702 10.2995 12.089 14.0566Z" fill="currentColor" />
        </svg>

    );
}

export default LocationPin4;
