import React from 'react'

function CarIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.0602 16.9601C31.1708 17.1588 32.2814 17.347 33.3919 17.5458C34.7987 17.7968 36.2055 18.0687 37.6122 18.2988C38.9132 18.5079 39.9815 19.6897 39.9921 20.997C40.0026 21.9905 40.0026 22.9841 39.9921 23.9776C39.9815 25.3685 38.6594 26.6863 37.242 26.6967C36.8612 26.6967 36.4805 26.6967 36.0891 26.6967C36.0997 26.6026 36.0997 26.5189 36.1103 26.4248C36.4487 24.5319 35.317 22.6076 33.4766 21.9382C31.5938 21.2584 29.4467 22.0323 28.4842 23.7266C27.9447 24.6678 27.8072 25.6509 28.0188 26.7072C23.1004 26.7072 18.1715 26.7072 13.2531 26.7072C13.3166 26.1529 13.3695 25.5986 13.2637 25.0339C12.9464 23.2873 11.5608 22.001 9.66748 21.7081C8.1338 21.4676 6.36742 22.3775 5.58472 23.7893C5.07701 24.7097 4.96067 25.6823 5.15105 26.7072C4.27315 26.6549 3.39525 26.8118 2.51735 26.5712C1.12117 26.1947 0 24.8142 0 23.3815C0 22.1892 0 21.0074 0 19.8152C0 18.2674 1.30099 16.981 2.8664 16.981C3.77603 16.981 4.68566 16.981 5.59529 16.981C8.03861 16.981 10.4713 16.981 12.9147 16.981C13.5704 16.981 13.9512 16.6045 13.9512 15.9561C13.9618 15.224 13.9512 14.4919 13.9512 13.7599C13.9512 13.0173 13.5916 12.6617 12.83 12.6617C11.6666 12.6513 10.5137 12.6513 9.35017 12.6408C10.0165 11.6473 10.9579 11.0407 12.1637 11.0198C15.3685 10.9884 18.5734 10.9989 21.7889 11.0198C22.5821 11.0198 23.312 11.3231 23.9043 11.8669C24.1899 12.1284 24.5812 12.2643 24.761 12.6408C24.6024 12.6513 24.4437 12.6617 24.2851 12.6617C21.9792 12.6617 19.6629 12.6617 17.357 12.6617C16.6061 12.6617 16.2782 12.986 16.2676 13.7285C16.2676 14.4083 16.2676 15.0881 16.2676 15.7679C16.2676 16.6777 16.5743 16.981 17.5051 16.981C21.6937 16.9601 25.8716 16.9601 30.0602 16.9601Z" fill="#00FDB4" />
            <path d="M30.0609 16.9578C25.8724 16.9578 21.6944 16.9578 17.5059 16.9578C16.5751 16.9578 16.2684 16.6545 16.2684 15.7447C16.2684 15.0649 16.2684 14.3851 16.2684 13.7053C16.2684 12.9628 16.5963 12.6386 17.3578 12.6386C19.6636 12.6386 21.98 12.6386 24.2858 12.6386C24.4445 12.6386 24.6031 12.6281 24.7618 12.6176C25.1955 12.7745 25.481 13.1301 25.8301 13.402C27.1628 14.4792 28.4849 15.5669 29.8071 16.6545C29.9023 16.7487 30.0398 16.8114 30.0609 16.9578Z" fill="#3BDFFD" />
            <path d="M28.0171 26.6795C27.8161 25.6337 27.9536 24.6401 28.4825 23.6989C29.4556 22.0047 31.5921 21.2308 33.4749 21.9105C35.3259 22.5799 36.4576 24.5042 36.1086 26.3971C36.0874 26.4913 36.0874 26.5749 36.0874 26.669C35.8865 27.3175 35.6114 27.9345 35.1355 28.4365C34.046 29.5869 32.6922 30.0575 31.1373 29.7124C29.5931 29.3673 28.5882 28.3842 28.0911 26.8887C28.07 26.8259 28.0382 26.7527 28.0171 26.6795ZM33.9614 25.7173C33.9614 24.6924 33.0941 23.8558 32.0681 23.8453C31.0104 23.8453 30.1537 24.7029 30.1537 25.7383C30.1537 26.7736 31.0316 27.6207 32.0893 27.6103C33.1152 27.6103 33.972 26.7423 33.9614 25.7173Z" fill="#3BDFFD" />
            <path d="M5.1659 26.6809C4.97551 25.656 5.09186 24.6834 5.59956 23.7631C6.38227 22.3512 8.14865 21.4413 9.68233 21.6819C11.5756 21.9747 12.9507 23.2715 13.2785 25.0076C13.3843 25.5724 13.3314 26.1266 13.268 26.6809C13.0564 27.3084 12.792 27.9255 12.3372 28.417C10.1054 30.8329 6.38227 29.9753 5.33513 27.0783C5.27167 26.9424 5.21879 26.8169 5.1659 26.6809ZM9.21694 23.8572C8.1698 23.8572 7.30248 24.7148 7.31305 25.7606C7.32363 26.7855 8.18038 27.6222 9.21694 27.6326C10.2641 27.6326 11.1314 26.7646 11.1208 25.7292C11.1208 24.6939 10.2535 23.8572 9.21694 23.8572Z" fill="#3BDFFD" />
            <path d="M9.37374 12.6268C10.5372 12.6373 11.6901 12.6373 12.8536 12.6477C13.6152 12.6477 13.9748 13.0033 13.9748 13.7459C13.9748 14.4779 13.9854 15.21 13.9748 15.9421C13.9642 16.5905 13.5834 16.967 12.9382 16.967C10.4949 16.967 8.06218 16.967 5.61887 16.967C6.25349 16.1094 6.98331 15.346 7.6814 14.5407C8.23141 13.8923 8.74969 13.2125 9.37374 12.6268Z" fill="#3BDFFD" />
        </svg>
    )
}

export default CarIcon