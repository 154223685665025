import React from 'react'
import Step from './Step'
import ButtonPrimary from './buttons/ButtonPrimary'
import LeftSmallArrowIcon from './icons/LeftSmallArrowIcon'
import { cn } from '../../../lib/utils'

export const StepsWithTitle = ({groupClassName, titleClassName, stepData, step, setStep }) => {
    return (
        <div className={cn("page-title mb-5", groupClassName)}>
            <div className="text-center">
                <h1 className={cn("text-[40px] font-bold leading-[1.2] text-white mb-2 flex items-center gap-3 justify-center", titleClassName)}>{step === 1 ? <></> : <ButtonPrimary className="bg-transparent p-0 border-0 hover:bg-transparent" onClick={() => setStep(step - 1)} size="sm" icon={<LeftSmallArrowIcon className="w-6 h-6" />} />} {stepData[step - 1]?.icon}{stepData[step - 1]?.title}</h1>
            </div>
            <Step data={stepData} step={step} setStep={setStep} />
        </div>
    )
}
