import { FormikProvider, useFormik } from "formik"
import { isEmpty } from "lodash"
import { enqueueSnackbar } from "notistack"
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from "react-query"
import * as Yup from "yup"
import Http from '../../../../../../Http'
import { IMAGE_UPLOAD, PROFILE_COMPLETE_URL } from "../../../../../api/axios"
import { authStore } from '../../../../../contexts/AuthProvider'
import AddSalonGuidelines from '../../../../uiComponents/AddSalonGuidelines'
import Card from '../../../../uiComponents/Card'
import Divider from '../../../../uiComponents/Divider'
import RowWrap from '../../../../uiComponents/RowWrap'
import ButtonBadge from '../../../../uiComponents/buttons/ButtonBadge'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import BagIcon from '../../../../uiComponents/icons/BagIcon'
import CarIcon from '../../../../uiComponents/icons/CarIcon'
import ChildIcon from '../../../../uiComponents/icons/ChildIcon'
import CityLocationIcon from '../../../../uiComponents/icons/CityLocationIcon'
import DollarRoundIcon from '../../../../uiComponents/icons/DollarRoundIcon'
import DragandDropIcon from '../../../../uiComponents/icons/DragandDropIcon'
import InfoFIllIcon from '../../../../uiComponents/icons/InfoFIllIcon'
import LocationPin1 from '../../../../uiComponents/icons/LocationPin1'
import LocationPin2 from '../../../../uiComponents/icons/LocationPin2'
import LocationPin3 from '../../../../uiComponents/icons/LocationPin3'
import PlusLineIcon from '../../../../uiComponents/icons/PlusLineIcon'
import SalonChairIcon from '../../../../uiComponents/icons/SalonChairIcon'
import ShopIcon from '../../../../uiComponents/icons/ShopIcon'
import TimerClockIcon from '../../../../uiComponents/icons/TimerClockIcon'
import DropZone from '../../../../uiComponents/inputs/DropZone'
import InputCheckbox from '../../../../uiComponents/inputs/InputCheckbox'
import InputRadioBox from '../../../../uiComponents/inputs/InputRadioBox'
import InputWithIcon from '../../../../uiComponents/inputs/InputWithIcon'
import InputWrap from '../../../../uiComponents/inputs/InputWrap'
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap'
import TextareaWrap from '../../../../uiComponents/inputs/TextareaWrap'
import CustomTabs from '../../../../uiComponents/tabs/CustomTabs'
import InputAutocompleteWrap from "../../../../uiComponents/inputs/InputAutocompleteWrap"
import SlotTimeListCard from "../../../../uiComponents/common/SlotTimeListCard"
import SalonUnAvailabilityListCard from "../../../../uiComponents/common/SalonUnAvailabilityListCard"
import CustomSliderWithThumbnail from '../../../../uiComponents/CustomSliderWithThumbnail'
import PhoneIcon2 from '../../../../uiComponents/icons/PhoneIcon2'
import PencilIcon from '../../../../uiComponents/icons/PencilIcon'

const BusinessProfile = ({ t, salonData, ...props }) => {

  const { stepsNextClick, userProfileData, refetch } = props;

  const updateSalonProfileStep = authStore((state) => state.updateSalonProfileStep);
  const updateServiceLocation = authStore((state) => state.updateSalonServiceLocation);
  const salonProfileStep = authStore((state) => state.salonProfileStep);
  const is_profile_complete = authStore((state) => state.is_profile_complete);
  const [salonAvailability, setSalonAvailability] = useState([]);
  const [onSalone, setOnSalone] = useState(true);
  const [deleteGuidLineArray, setDeleteGuidLineArray] = useState([]);
  const [guidelinesSelectData, setGuidelinesSelectData] = useState([]);
  const [deleteImageArray, setDeleteImageArray] = useState([]);
  const [onTheGoCheckd, setOnTheGoCheckd] = useState(true);
  const [offerRequestValue, setOfferRequestValue] = useState("");
  const [wheelchairFriendly, setWheelchairFriendly] = useState("");
  const [childFriendly, setChildFriendly] = useState("");
  const queryClient = useQueryClient();
  const [guidelines, setGuidelines] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [onTheGoAvailability, setOnTheGoAvailability] = useState([]);
  const [images, setImages] = useState([]);
  const [fileSelected, setFileSelected] = useState([])
  const [location, setLocation] = useState({});
  const [databaseGuidList, setDatabaseGuidList] = useState([]);
  const [availablityTabData, setAvailablityTabData] = useState([]);
  const [unAvailablityTabData, setUnAvailablityTabData] = useState([]);
  const [onTheGoCityCard, setOnTheGoCityCard] = useState([{
    id: "",
    work_from_address: "",
    work_from_address_lat: "",
    work_from_address_long: "",
  }])
  const [offerRequestCity, setOfferRequestCity] = useState('')
  const [loading, setloading] = useState(false)
  const [address, setAddress] = useState('');
  const currentPath = window.location.pathname;

  const handleCityChange = (index, city, location) => {
    const newCities = [...onTheGoCityCard];

    const lat = location?.lat();
    const long = location?.lng();

    newCities[index]['work_from_address'] = city;
    newCities[index]['work_from_address_lat'] = lat
    newCities[index]['work_from_address_long'] = long
    setFieldValue(`salon_cities[${index}]`, city);
    setFieldValue(`work_from_address_lat[${index}]`, lat);
    setFieldValue(`work_from_address_long[${index}]`, long);

    setOnTheGoCityCard(newCities);
  };

  const handleOfferRequestCityChange = (city, location) => {
    setFieldValue("offer_request_address", city);
    setOfferRequestCity(city)
    setFieldValue("offer_request_lat", location?.lat());
    setFieldValue("offer_request_long", location?.lng());
    if (city == profileData?.offer_request_address) {
      setFieldValue("offer_request_lat", profileData?.offer_request_lat);
      setFieldValue("offer_request_long", profileData?.offer_request_long);
    }
  }

  const handleAddressChange = (city, location) => {
    setFieldValue("address", city);
    setAddress(city)
    setFieldValue("address_lat", location?.lat());
    setFieldValue("address_long", location?.lng());
    if (city == profileData?.address) {
      setFieldValue("address_lat", profileData?.address_lat);
      setFieldValue("address_long", profileData?.address_long);
    }
  }

  const handleAddCardClick = () => {
    const newCard = {
      id: "",
      work_from_address: "",
      work_from_address_lat: "",
      work_from_address_long: ""
    };

    const newCities = [...onTheGoCityCard, newCard];
    setFieldValue(`salon_cities[${newCities?.length - 1}]`, "")
    setOnTheGoCityCard(newCities);
  };

  const handleRemoveCardClick = (id) => {

    if (onTheGoCityCard.length <= 1) {
      return enqueueSnackbar(t('snackbar_message.at_least_one_area_is_required'), {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
    const updatedCities = onTheGoCityCard.filter((city, index) => index !== id);
    const cities = updatedCities.map((item) => item.work_from_address);
    setFieldValue(`salon_cities`, cities);
    setOnTheGoCityCard(updatedCities);
  };

  const handleRemoveImage = (type, index) => {
    if (type == 1) {
      if (images[index]?.file_name && images[index]?.file_name !== "") {
        setDeleteImageArray([...deleteImageArray, images[index]?.file_name])
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
      }
    }
    if (type == 2) {
      console.log('fileSelected[index]',fileSelected[index]);
      
      if (fileSelected[index]) {
        const newFileImages = [...fileSelected];
        newFileImages.splice(index, 1);
        setFileSelected(newFileImages);
      }
    }
  };


  const handleOfferRequestValue = (val) => {
    setOfferRequestValue(val);
    setFieldValue("offer_request", val)

    if (val === 0) {
      formik.setFieldValue("offer_request_address", "");
      formik.setFieldValue("offer_request_radius", "");
      formik.setFieldValue("offer_request_cost", "");
      formik.setFieldValue("offer_transport_time", "");

      setOfferRequestCity("");
      formik.setFieldValue("offer_request_long", "");
      formik.setFieldValue("offer_request_lat", "");

      formik.setFieldTouched("offer_request_address", false);
      formik.setFieldTouched("offer_request_radius", false);
      formik.setFieldTouched("offer_request_cost", false);
      formik.setFieldTouched("offer_transport_time", false);

      formik.setFieldError("offer_request_address", "");
      formik.setFieldError("offer_request_radius", "");
      formik.setFieldError("offer_request_cost", "");
      formik.setFieldError("offer_transport_time", "");
    }
  };

  const handleWheelChairValue = (val) => {
    setWheelchairFriendly(val);
    setFieldValue("wheelchair_friendly", val)
  };

  const handleChildFriendly = (val) => {
    setChildFriendly(val);
    setFieldValue("child_friendly", val)
  };

  const handleTranportFeesIncluded = (e) => {
    const isChecked = e.target.checked;
    formik.setFieldValue("transport_fee_included", isChecked); // Update the checked state
    if (isChecked) {
      formik.setFieldValue("transport_fee", ""); // Clear the transport fee field
    }

    // Trigger form validation after changing checkbox state
    formik.setFieldTouched('transport_fee', false); // Reset field touched status
  };

  //validations
  const noNegativeValues = (value) => {
    if (value) {
      return !value.includes("-");
    }
    return true;
  };

  let salonValidation = Yup.object().shape({
    business_name: Yup.string()
      .trim()
      // .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/, 'Invalid name')
      .required(t("validation_message.business_name_required")),
    cvr_no: Yup.string().matches(/^\d{8}$/, t("validation_message.cvr_max"))
      .required(t("validation_message.cvr_req")),
    address: Yup.string()
      .test('valid-location', 'Invalid address. Please select a valid address from the dropdown.', function (value) {
        const { address_lat, address_long, address } = this.parent;

        if (!address_lat && !address_long && address) {
          return this.createError({ message: 'Please select a proper value from the dropdown.' });
        }

        if (!address_lat && !address_long && !address) {
          return this.createError({ message: 'address is required' });
        }
        return true;
      }),

    about_us: Yup.string().required(t("validation_message.about_is_required")),
    phone: Yup.string()
      .trim()
      .test("no-negative-values", t('validation_message.negative_phone'), noNegativeValues)
      .required(t('validation_message.phone'))
      .matches(/^\+[0-9]+$/, t('validation_message.digit_only_phone'))
      .min(8, t('validation_message.min_phone'))
      .max(14, t('validation_message.max_phone')),
    wheelchair_friendly: Yup.boolean().required(
      t("validation_message.wheelchair_friendly_req")
    ),
    child_friendly: Yup.boolean().required(t("validation_message.child_friendly_req")),
    offer_request: Yup.boolean().required(t("validation_message.offer_request_req")),
    offer_request_address: Yup.string().when("offer_request", {
      is: true,
      then: () =>
        Yup.string()
          .test('valid-location', 'Invalid address. Please select a valid address from the dropdown.', function (value) {
            const { offer_request_lat, offer_request_long, offer_request } = this.parent;
            // Check if both lat and long are null or undefined
            if (!offer_request_lat && !offer_request_long && offer_request) {
              return this.createError({ message: 'Please select a proper value from the dropdown.' });
            }
            return true;
          }),
    }),
    offer_request_radius: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.radius_must_be_numeric"))
          .required(t("validation_message.radius_is_req"))
          .min(0, t("validation_message.radius_must_be_greater")),
    }),
    offer_transport_time: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.transport_time_must_be_numeric"))
          .required(t("validation_message.transport_time_req"))
          .min(0, t("validation_message.transport_time_must_be_greater")),
    }),
    offer_request_cost: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.offer_request_budget"))
          .required(t("validation_message.offer_cost_req"))
          .min(0, t("validation_message.offer_must_be_greater")),
    }),
  });
  let onTheGoValidation = Yup.object().shape({
    business_name: Yup.string()
      .trim()
      // .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/, 'Invalid name')
      .required(t("validation_message.business_name_required")),
    cvr_no: Yup.string().matches(/^\d{8}$/, t("validation_message.cvr_max"))
      .required(t("validation_message.cvr_req")),
    work_radius: Yup.number().typeError(t("validation_message.radius_must_be_numeric"))
      .required(t("validation_message.radius_is_req"))
      .min(0, t("validation_message.radius_must_be_greater")),
    transport_time: Yup.number().typeError(t("validation_message.transport_time_must_be_numeric"))
      .required(t("validation_message.transport_time_req"))
      .min(0, t("validation_message.transport_time_must_be_greater")),
    salon_cities: Yup.array()
      .of(Yup.string()
        // .required(t("validation_message.area_req"))
        .min(1, t("validation_message.area_req"))
        .test('valid-location',
          'Invalid address. Please select a valid address from the dropdown.',
          function (value, context) {
            const { index } = this.options; // Get the index of the current item being validated
            const formData = this.options.context; // Get the entire form data from context

            // Access lat/long for the current index from form data
            const work_from_address_lat = formData.work_from_address_lat[index];
            const work_from_address_long = formData.work_from_address_long[index];

            // Check if both lat and long are null or undefined
            if (!work_from_address_long) {
              return this.createError({ message: 'Please select a proper value from the dropdown.' });
            }
            return true;
          }
        )
      ),
    offer_request: Yup.boolean().required(t("validation_message.offer_request_req")),
    phone: Yup.string()
      .trim()
      .test("no-negative-values", t('validation_message.negative_phone'), noNegativeValues)
      .required(t('validation_message.phone'))
      .matches(/^\+[0-9]+$/, t('validation_message.digit_only_phone'))
      .min(8, t('validation_message.min_phone'))
      .max(14, t('validation_message.max_phone')),
    // work_from_address: Yup.string().required(t('validation_message.work_address_req')),
    wheelchair_friendly: Yup.boolean().required(
      t("validation_message.wheelchair_friendly_req")
    ),
    child_friendly: Yup.boolean().required(t("validation_message.child_friendly_req")),
    about_us: Yup.string().required(t("validation_message.about_is_required")),
    transport_fee_included: Yup.boolean(),
    transport_fee: Yup.number().nullable().when("transport_fee_included", {
      is: false,
      then: () =>
        Yup.number().typeError(t("validation_message.transport_fee_must_be_numeric"))
          .required(t("validation_message.transport_fee_req"))
          .min(0, t("validation_message.transport_fee_must_be_greater")),

    }),
    offer_request_address: Yup.string().when("offer_request", {
      is: true,
      then: () =>
        Yup.string()
          // .required(t("validation_message.area_req"))
          .test('valid-location', 'Invalid address. Please select a valid address from the dropdown.', function (value) {
            const { offer_request_lat, offer_request_long } = this.parent;

            // Check if both lat and long are null or undefined
            if (!offer_request_lat && !offer_request_long) {
              return this.createError({ message: 'Please select a proper value from the dropdown.' });
            }
            // Optionally check if lat and long are not empty strings (if that's a concern)
            return true;
          }),
    }),
    offer_request_radius: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.radius_must_be_numeric"))
          .required(t("validation_message.radius_is_req"))
          .min(0, t("validation_message.radius_must_be_greater")),
    }),
    offer_transport_time: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.transport_time_must_be_numeric"))
          .required(t("validation_message.transport_time_req"))
          .min(0, t("validation_message.transport_time_must_be_greater")),
    }),
    offer_request_cost: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.offer_request_budget"))
          .required(t("validation_message.offer_cost_req"))
          .min(0, t("validation_message.offer_must_be_greater")),
    }),
  });
  let bothValidation = Yup.object().shape({
    business_name: Yup.string()
      .trim()
      // .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]+$/, 'Invalid name')
      .required(t("validation_message.business_name_required")),
    cvr_no: Yup.string().matches(/^\d{8}$/, t("validation_message.cvr_max"))
      .required(t("validation_message.cvr_req")),
    // address: Yup.string().trim().required(t("validation_message.address_required")),
    address: Yup.string()
      .test('valid-location', 'Invalid address. Please select a valid address from the dropdown.', function (value) {
        const { address_lat, address_long, address } = this.parent;

        if (!address_lat && !address_long && address) {
          return this.createError({ message: 'Please select a proper value from the dropdown.' });
        }

        if (!address_lat && !address_long && !address) {
          return this.createError({ message: 'address is required' });
        }
        return true;
      }),
    about_us: Yup.string().required(t("validation_message.about_is_required")),
    work_radius: Yup.number().typeError(t("validation_message.radius_must_be_numeric"))
      .required(t("validation_message.radius_is_req"))
      .min(0, t("validation_message.radius_must_be_greater")),
    transport_time: Yup.number().typeError(t("validation_message.transport_time_must_be_numeric"))
      .required(t("validation_message.transport_time_req"))
      .min(0, t("validation_message.transport_time_must_be_greater")),
    salon_cities: Yup.array()
      .of(Yup.string()
        // .required(t("validation_message.area_req"))
        .min(1, t("validation_message.area_req"))
        .test('valid-location',
          'Invalid address. Please select a valid address from the dropdown.',
          function (value, context) {
            const { index } = this.options; // Get the index of the current item being validated
            const formData = this.options.context; // Get the entire form data from context

            // Access lat/long for the current index from form data
            const work_from_address_lat = formData.work_from_address_lat[index];
            const work_from_address_long = formData.work_from_address_long[index];

            // Check if both lat and long are null or undefined
            if (!work_from_address_long) {
              return this.createError({ message: 'Please select a proper value from the dropdown.' });
            }
            return true;
          }
        )
      ),
    offer_request: Yup.boolean().required(t("validation_message.offer_request_req")),
    transport_fee_included: Yup.boolean(),
    transport_fee: Yup.number().nullable().typeError(t("validation_message.transport_fee_must_be_numeric")).when("transport_fee_included", {
      is: false,
      then: () =>
        Yup.number().typeError(t("validation_message.transport_fee_must_be_numeric"))
          .required(t("validation_message.transport_fee_req"))
          .min(0, t("validation_message.transport_fee_must_be_greater")),
    }),
    phone: Yup.string()
      .trim()
      .test("no-negative-values", t('validation_message.negative_phone'), noNegativeValues)
      .required(t('validation_message.phone'))
      .matches(/^\+[0-9]+$/, t('validation_message.digit_only_phone'))
      .min(8, t('validation_message.min_phone'))
      .max(14, t('validation_message.max_phone')),
    wheelchair_friendly: Yup.boolean().required(
      t("validation_message.wheelchair_friendly_req")
    ),
    child_friendly: Yup.boolean().required(t("validation_message.child_friendly_req")),
    offer_request_address: Yup.string()
      .test('valid-location', 'Invalid address. Please select a valid address from the dropdown.', function (value) {
        const { offer_request_lat, offer_request_long, offer_request } = this.parent;

        // Check if both lat and long are null or undefined
        if (!offer_request_lat && !offer_request_long && offer_request) {
          return this.createError({ message: 'Please select a proper value from the dropdown.' });
        }
        // Optionally check if lat and long are not empty strings (if that's a concern)
        return true;
      }),
    offer_request_lat: Yup.number().nullable(),
    offer_request_long: Yup.number().nullable(),
    offer_request_radius: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.radius_must_be_numeric"))
          .required(t("validation_message.radius_is_req"))
          .min(0, t("validation_message.radius_must_be_greater"))
    }),
    offer_transport_time: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.transport_time_must_be_numeric"))
          .required(t("validation_message.transport_time_req"))
          .min(0, t("validation_message.transport_time_must_be_greater")),
    }),
    offer_request_cost: Yup.number().when("offer_request", {
      is: true,
      then: () =>
        Yup.number().typeError(t("validation_message.offer_request_budget"))
          .required(t("validation_message.offer_cost_req"))
          .min(0, t("validation_message.offer_must_be_greater")),
    }),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      business_name: profileData?.business_name ?? "",
      cvr_no: profileData?.cvr_no ?? "",
      address: profileData?.address ?? "",
      // work_from_address:  "",
      about_us: profileData?.about_us ?? "",
      work_radius: profileData?.work_radius ?? null,
      transport_time: profileData?.transport_time ?? null,
      offer_request_cost: profileData?.offer_request_cost ?? "",
      transport_fee: profileData?.transport_fee ?? null,
      phone: profileData?.phone ?? "+45",
      wheelchair_friendly: profileData?.wheelchair_friendly ?? "",
      child_friendly: profileData?.child_friendly ?? "",
      offer_request: profileData?.offer_request ?? "",
      offer_request_address: profileData?.offer_request_address ?? "",
      offer_request_long: profileData?.offer_request_long,
      offer_request_lat: profileData?.offer_request_lat,
      offer_request_radius: profileData?.offer_request_radius ?? "",
      offer_transport_time: profileData?.offer_transport_time ?? "",
      address_lat: profileData?.address_lat ?? "",
      address_long: profileData?.address_long ?? "",
      salon_cities: userProfileData?.data?.salon_cities?.length > 0 ? userProfileData?.data?.salon_cities.map(city => city.work_from_address) : [""],
      work_from_address_lat: userProfileData?.data?.salon_cities?.length > 0 ? userProfileData?.data?.salon_cities.map(city => city.work_from_address_lat) : [""],
      work_from_address_long: userProfileData?.data?.salon_cities?.length > 0 ? userProfileData?.data?.salon_cities.map(city => city.work_from_address_long) : [""],
      transport_fee_included: Boolean(profileData?.transport_fee_included) ?? false,
    },
    validationSchema: onSalone && onTheGoCheckd ? bothValidation : onSalone ? salonValidation : onTheGoValidation,
    onSubmit: async (values) => {

      //salon image validation
      if (images?.length === 0) {
        enqueueSnackbar(t("validation_message.salon_image_req"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return false;
      }

      if (onTheGoCityCard?.length > 0) {

        onTheGoCityCard?.map((city, index) => {
          if (!city['work_from_address'] || city['work_from_address'].trim() === "") {
            setFieldError(`salon_cities[${index}]`, "Salon city is required");
          }

          if (!city['work_from_address_lat'] || !city['work_from_address_long']) {
            setFieldError(`work_from_address_lat[${index}]`, "location lat-long are required.");
          }
        })
      }

      values.service_location = onSalone && onTheGoCheckd ? 3 : onSalone ? 1 : onTheGoCheckd ? 2 : 1;


      // values.address = location?.address ?? "";
      // values.work_from_address_lat = location.work_from_address_lat ?? "";
      // values.work_from_address_long = location.work_from_address_long ?? "";
      // values.work_from_address = location.work_from_address ?? "";
      // values.work_from_address = formik.values.work_from_address ? formik.values.work_from_address : location.work_from_address;
      values.images = [];
      values.salon_guidelines = [];
      values.deleted_salon_images = deleteImageArray;
      values.deleted_salon_guidelines = deleteGuidLineArray;
      values.profile_setup = profileData ? false : true;
      values.id = profileData ? profileData.id : "";
      values.salon_cities = onTheGoCheckd ? onTheGoCityCard : [];
      values.address = formik.values.address ? formik.values.address : location?.address;
      values.address_lat = formik.values?.address_lat ?? "";
      values.address_long = formik.values?.address_long ?? "";

      if (profileData.offer_request == 1) {
        values.offer_request_lat = formik.values.offer_request_lat;
        values.offer_request_long = formik.values.offer_request_long;
      }


      if ((profileData && (profileData.service_location === 3 || profileData.service_location === 2)) && values.service_location === 1) { //when onthego unchecked send old data of below fields

        values.salon_cities = userProfileData?.data?.salon_cities;
        values.work_radius = profileData?.work_radius;
        values.transport_time = profileData?.transport_time;
        values.transport_fee = profileData?.transport_fee;
        values.transport_fee_included = userProfileData?.data?.transport_fee_included;

      } else if ((profileData && (profileData.service_location === 3 || profileData.service_location === 1)) && values.service_location === 2) { // salon unchecked send old data of below fields

        // values.address = profileData?.address;
        // values.address_lat = profileData?.address_lat;
        // values.address_long = profileData?.address_long;
        values.salon_cities = onTheGoCheckd ? onTheGoCityCard : [];
        values.address = formik.values.address ? formik.values.address : location?.address;
        values.address_lat = location?.address_lat ?? "";
        values.address_long = location.address_long ?? "";

      }

      try {
        if (images.length) {
          const new_images = images.filter(item => !item.hasOwnProperty('file_name'));
          if (new_images.length > 0) {
            const formData = new FormData();
            formData.append("model", "salon_images");
            new_images.map((image) => {
              formData.append("image[]", image.image);
            });
            const imageUploadData = await imageUpload(formData);
            values.images = imageUploadData.name;
          }
        }
        if (!isEmpty(guidelines)) {
          const new_guidelines = guidelines.filter(item => !item.hasOwnProperty('file_name'));
          if (new_guidelines.length > 0) {
            const formData2 = new FormData();
            formData2.append("model", "salon_guidelines");
            guidelines.map((image) => {
              formData2.append(
                "salon_guideline[]",
                image.fileList[0]
              );
              formData2.append("title[]", image.title);
              formData2.append("type", 2);
            });
            const guidelinesUpload = await imageUpload(formData2);
            values.salon_guidelines = guidelinesUpload.name;

          }
        }

        // return false;
        await salonProfile(values);

      } catch (error) {
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.values(errorData).forEach((value) => {
            enqueueSnackbar(value, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else {
          enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
  });
  const { mutateAsync: salonProfile } = useMutation(
    async (datas) => {
      try {
        setloading(true);
        const { data } = await Http.post(PROFILE_COMPLETE_URL.salonProfile, datas);
        if (data) {
          refetch();
          setImages([]);
          setApiResponse(true);
          setGuidelines([]);
          // updateSaloneDataId()
          if (salonProfileStep <= 2) {
            updateSalonProfileStep(2);
          }
          updateServiceLocation(formik.values.service_location);
          enqueueSnackbar(data.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          if (currentPath == "/provider/add-salon") {
            queryClient.invalidateQueries(["userProfileSetting2"]);
            queryClient.invalidateQueries(["mySalonList"]);
          } else {
            if (is_profile_complete == 0 && salonProfileStep < 5) {
              stepsNextClick()
            }
            queryClient.invalidateQueries(["userProfileSetting"]);
          }
          setloading(false);
          resetForm();
        }
        return data;
      } catch (error) {
        setloading(false);
        const errorData = error?.response?.data?.errors;
        if (errorData) {
          Object.keys(errorData).forEach((key) => {
            setFieldError(key, errorData[key]);
          });
        }
        throw error;
      }
    }
  );

  const { mutateAsync: imageUpload } = useMutation(
    async (formData) => {
      formData.env = "test";
      try {
        return await Http.post(IMAGE_UPLOAD.uploadImage, formData).then(
          (res) => res.data
        );
      } catch (error) {
        console.log(error);
      }
    });

  const {
    errors,
    handleSubmit,
    touched,
    values,
    resetForm,
    getFieldProps,
    setFieldValue,
    setFieldError,
  } = formik;



  useEffect(() => {
    if (userProfileData) {
      setProfileData(userProfileData?.data);
      if (userProfileData && userProfileData?.data?.availability) {
        if (userProfileData?.data?.availability?.salon?.length) {
          setSalonAvailability(userProfileData.data.availability.salon);

          if (userProfileData?.data?.service_location == 1 || userProfileData?.data?.service_location == 3) {
            let tabData = availablityTabData;
            tabData[0] = {
              id: "1",
              title: t('check_box_label.salon'),
              content: <SlotTimeListCard data={userProfileData.data.availability.salon} />
            }
            setAvailablityTabData(tabData);
          }
        }
        if (userProfileData?.data?.availability?.onthego?.length) {
          setOnTheGoAvailability(userProfileData.data.availability.onthego);
          if (userProfileData?.data?.service_location == 2 || userProfileData?.data?.service_location == 3) {
            let tabData = availablityTabData;
            const index = userProfileData?.data?.service_location === 2 ? 0 : 1;
            tabData[index] = {
              id: "2",
              title: t('check_box_label.on_the_go'),
              content: <SlotTimeListCard data={userProfileData.data.availability.onthego} />
            }
            setAvailablityTabData(tabData);
          }
        }
        if (userProfileData?.data?.unavailability?.length) {
          userProfileData?.data?.unavailability.map((unavailability) => {
            unavailability.date = unavailability.holiday_date
            unavailability.salon = unavailability.service_location == 3 || unavailability.service_location == 1 ? true : false;
            unavailability.onTheGo = unavailability.service_location == 3 || unavailability.service_location == 2 ? true : false;
          });

          if (userProfileData?.data?.service_location === 1 || userProfileData?.data?.service_location === 3) {

            let tabData = unAvailablityTabData;
            tabData[0] = {
              id: "1",
              title: t('check_box_label.salon'),
              content: <SalonUnAvailabilityListCard data={userProfileData?.data?.unavailability?.filter((item) => item.salon === true) ?? []} />
            }
            setUnAvailablityTabData(tabData);
          }
          if (userProfileData?.data?.service_location === 2 || userProfileData?.data?.service_location === 3) {
            let tabData = unAvailablityTabData;
            const index = userProfileData?.data?.service_location === 2 ? 0 : 1;
            tabData[index] = {
              id: "2",
              title: t('check_box_label.on_the_go'),
              content: <SalonUnAvailabilityListCard data={userProfileData?.data?.unavailability?.filter((item) => item.onTheGo === true) ?? []} />
            }
            setUnAvailablityTabData(tabData);
          }
        }

        if (userProfileData?.data?.images?.length) {
          setImages(userProfileData.data?.images);
        }
        if (userProfileData?.data?.salon_cities?.length) {
          let data = userProfileData?.data?.salon_cities?.map((item) => ({
            id: item?.id,
            work_from_address: item?.work_from_address,
            work_from_address_lat: item?.work_from_address_lat,
            work_from_address_long: item?.work_from_address_long,

          }))
          setOnTheGoCityCard(data);
        }
      }
      setFieldValue('transport_fee_included', userProfileData?.data?.transport_fee_included)
      setOfferRequestValue(userProfileData?.data?.offer_request)
      setOfferRequestCity(userProfileData?.data?.offer_request_address)
      setAddress(userProfileData?.data?.address)
      setWheelchairFriendly(userProfileData?.data?.wheelchair_friendly);
      setChildFriendly(userProfileData?.data?.child_friendly);
      setProfileData(userProfileData?.data);
    }
  }, [userProfileData]);
  useEffect(() => {
    if (!isEmpty(userProfileData?.data)) {
      if (userProfileData?.data?.service_location === 2) {
        setOnTheGoCheckd(true);
        setOnSalone(false);
      } else if (userProfileData?.data?.service_location === 1) {
        setOnTheGoCheckd(false);
        setOnSalone(true);
      } else {
        setOnTheGoCheckd(true);
        setOnSalone(true);
      }
      if (userProfileData.data?.guidelines?.length) {
        setDatabaseGuidList(userProfileData.data?.guidelines);
      }
      if (userProfileData?.data?.address) {
        location.address_lat = userProfileData?.data?.address_lat;
        location.address_long = userProfileData?.data?.address_long;
        location.address = userProfileData?.data?.address;
      }
      if (userProfileData?.data?.work_from_address) {
        location.work_from_address_lat = userProfileData?.data?.work_from_address_lat;
        location.work_from_address_long = userProfileData?.data?.work_from_address_long;
        location.work_from_address = userProfileData?.data?.work_from_address;
      }
    } else {
      setDatabaseGuidList([]);
      setOnTheGoCheckd(true);
      resetForm();
      setOnSalone(true);
    }
  }, [userProfileData]);

  useEffect(() => {

    if (userProfileData?.data?.length === 0) {

      if (onSalone === false) {
        formik.setFieldValue("address", "");
        formik.setFieldValue("address_lat", "");
        formik.setFieldValue("address_long", "");
      }

      if (onTheGoCheckd === false) {
        formik.setFieldValue("work_radius", "");
        formik.setFieldValue("transport_time", "");
        formik.setFieldValue("transport_fee", "");
        formik.setFieldValue("salon_cities", [""]);
        setOnTheGoCityCard([{
          id: "",
          work_from_address: "",
          work_from_address_lat: "",
          work_from_address_long: "",
        }]);
      }
    }

  }, [onSalone, onTheGoCheckd]);

  return (
    <div className="grid grid-cols-1 xl:grid-cols-4 gap-3">
      <div className="left-part">
        <Card className={"h-full px-3 py-4 sm:px-4 sm:py-5"}>
          <h4 className='text-[15px] font-medium text-blue-950 mb-1.5'>{t('form_input_label.salon_images')}</h4>
          <div className="mb-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-4 xl:gap-2.5">
            <CustomSliderWithThumbnail thumbSlider={true} images={images}
              handleRemoveImage={handleRemoveImage} fileSelected={fileSelected}
            />
            <DropZone multiple labelClassName="p-2 h-full" labelTitleClass="mt-1.5 inline" setFileSelected={setFileSelected} br icon={<DragandDropIcon className="w-6 h-6 text-primary1" />} type={'salon'} />
          </div>

          {salonAvailability.length > 0 || onTheGoAvailability.length > 0
            ? <>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-4 mb-4">
                <div className="">
                  <h4 className='text-[15px] font-medium text-blue-950 mb-2'>{t('comman_text.availability')}</h4>
                  <CustomTabs tabListClass="border-[1.3px] border-primary1 mb-1.5" tabButtonClass="!py-0 !text-sm font-bold" tabsData={availablityTabData} />
                </div>
                {unAvailablityTabData.length > 0
                  ? <>
                    <div className="">
                      <h4 className='text-[15px] font-medium text-blue-950 mb-2'>{t("form_input_label.unavailability")}</h4>
                      <CustomTabs tabListClass="border-[1.3px] border-primary1 mb-1.5" tabButtonClass="!py-0 !text-sm font-bold" tabsData={unAvailablityTabData} />
                    </div>
                  </>
                  : <></>
                }
              </div>
              <ButtonPrimary size={"sm"} className={"w-full lg:text-[17px] py-1.5 rounded-[5px] !leading-[18px] my-2"} icon={<PencilIcon className="w-5 h-5" />} type="button" onClick={() => stepsNextClick()}>{t('business_profile_module.edit_availablity')}</ButtonPrimary>
            </>
            : <></>
          }
        </Card>
      </div>
      <div className="xl:col-span-3 right-part">
        <FormikProvider >
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Card className={"mb-2.5"}>
              <RowWrap className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-x-8 lg:gap-y-5" lg={3}>
                <InputWrap
                  require
                  size="sm"
                  {...getFieldProps("business_name")}
                  labelIcon={<ShopIcon className="w-5 h-5 text-blue-450" />}
                  label={t("form_input_label.business_name")}
                  placeholder={t('input_filed_placeholder.business_name')}
                  errorType={Boolean(touched.business_name && errors.business_name) && "error"}
                  errorData={touched.business_name && errors.business_name}
                />
                <InputWrap
                  size="sm"
                  {...getFieldProps("cvr_no")}
                  labelIcon={<BagIcon className="w-5 h-5" />}
                  label={t("form_input_label.cvr_no")}
                  placeholder={t('input_filed_placeholder.input_8_digit')}
                  errorType={Boolean(touched.cvr_no && errors.cvr_no) && "error"}
                  errorData={touched.cvr_no && errors.cvr_no}
                />
                {onSalone === true &&
                  // <InputWrap
                  //   size="sm"
                  //   {...getFieldProps("address")}
                  //   labelIcon={<LocationPin1 className="w-5 h-5" />}
                  //   label={t('form_input_label.salon_address')}
                  //   placeholder={t("form_input_label.salon_address")}
                  //   name="address"
                  //   errorType={Boolean(touched.address && errors.address) && "error"}
                  //   errorData={touched.address && errors.address}
                  // />

                  <InputAutocompleteWrap
                    // {...getFieldProps("address")}
                    label={t("form_input_label.salon_address")}
                    labelIcon={<LocationPin1 className="w-5 h-5" />}
                    placeholder={t('form_input_label.salon_address')}
                    value={address}
                    handleChange={handleAddressChange}
                    errorType={Boolean((touched.address && errors.address) || errors?.address) && "error"}
                    errorData={(touched.address && errors.address) || errors?.address}
                  />
                }
                <InputWrap
                  size="sm"
                  {...getFieldProps("phone")}
                  labelIcon={<PhoneIcon2 className="w-5 h-5 text-success-950" />}
                  label={"Salon Phone nr."}
                  placeholder={"+45"}
                  errorType={Boolean(touched.phone && errors.phone) && "error"}
                  errorData={touched.phone && errors.phone}
                />
                <div className="meetime-form-group">
                  <LabelWrap labelClassName="!mb-4 !text-[15px]" label={t('form_input_label.service_location')}
                    tooltipText={t('form_input_label.service_location')}
                    prefixIcon={<LocationPin2 className="w-5 h-5" />} />
                  <div className="flex items-center flex-wrap !gap-4">
                    <InputCheckbox
                      checked={onSalone}
                      onChange={() => setOnSalone(!onSalone)}
                      disabled={!onTheGoCheckd}
                      id={t('check_box_label.salon')}
                      prefixIcon={<LocationPin3 className="w-5 h-5" />}
                      text={t('check_box_label.salon')}
                    />
                    <InputCheckbox
                      checked={onTheGoCheckd}
                      onChange={() => setOnTheGoCheckd(!onTheGoCheckd)}
                      disabled={!onSalone}
                      id={t('check_box_label.on_the_go')}
                      prefixIcon={<SalonChairIcon className="w-5 h-5" />}
                      text={t('check_box_label.on_the_go')}
                    />
                  </div>
                </div>
                <div className="meetime-form-group">
                  <LabelWrap labelClassName="!mb-4 !text-[15px]" label={t("form_input_label.offer_request")}
                    tooltipText={t('form_input_label.offer_request')}
                    prefixIcon={<LocationPin2 className="w-5 h-5" />}
                  />
                  <div className="flex items-center flex-wrap !gap-4" >
                    <InputRadioBox
                      name="offer_Request"
                      {...getFieldProps("offer_request")}
                      id={t('radio_label.yes') + "Offer"}
                      text={t("radio_label.yes")}
                      labelClassName={"text-sm"}
                      checked={offerRequestValue === 1 ? true : false}
                      value={1}
                      onChange={() => {
                        handleOfferRequestValue(1);
                        formik.setFieldTouched("offer_request_address", false); // Optionally reset touched state if needed
                      }}
                    />

                    <InputRadioBox
                      name="offer_Request"
                      {...getFieldProps("offer_request")}
                      id={t('radio_label.no') + "Offer"}
                      text={t("radio_label.no")}
                      labelClassName={"text-sm"}
                      checked={offerRequestValue === 0 ? true : false}
                      value={0}
                      onChange={() => { handleOfferRequestValue(0); formik.setFieldTouched("offer_request_address", false); }}
                    />
                  </div>
                  {touched.offer_request && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors.offer_request}</p>}
                </div>
                <div className="meetime-form-group">
                  <LabelWrap
                    labelClassName="!mb-4 !text-[15px]"
                    label={t('form_input_label.wheelchair_friendly')}
                    tooltipText={t('form_input_label.wheelchair_friendly')}
                    prefixIcon={<LocationPin2 className="w-5 h-5" />}
                  />
                  <div className="flex items-center flex-wrap !gap-4">
                    <InputRadioBox
                      name="wheelchair_friendly"
                      {...getFieldProps("wheelchair_friendly")}
                      id={t('radio_label.yes') + "wheelchair"}
                      text={t("radio_label.yes")}
                      labelClassName={"text-sm"}
                      checked={wheelchairFriendly === 1 ? true : false}
                      value={1}
                      onChange={() => handleWheelChairValue(1)}
                    />
                    <InputRadioBox
                      name="wheelchair_friendly"
                      {...getFieldProps("wheelchair_friendly")}
                      id={t('radio_label.no') + "wheelchair"}
                      text={t("radio_label.no")}
                      labelClassName={"text-sm"}
                      checked={wheelchairFriendly === 0 ? true : false}
                      value={0}
                      onChange={() => handleWheelChairValue(0)}
                    />
                  </div>
                  {touched.wheelchair_friendly && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors.wheelchair_friendly}</p>}
                </div>
                <div className="meetime-form-group">
                  <LabelWrap
                    labelClassName="!mb-4 !text-[15px]"
                    label={t('form_input_label.child_friendly')}
                    tooltipText={t('form_input_label.child_friendly')}
                    prefixIcon={<ChildIcon className="w-5 h-5" />} />

                  <div className="flex items-center flex-wrap !gap-4" >
                    <InputRadioBox
                      name="child_friendly"
                      {...getFieldProps("child_friendly")}
                      id={t('radio_label.yes') + "child_friendly"}
                      text={t("radio_label.yes")}
                      value={1}
                      checked={childFriendly === 1 ? true : false}
                      onChange={() => handleChildFriendly(1)}

                    />
                    <InputRadioBox
                      name="child_friendly"
                      {...getFieldProps("child_friendly")}
                      id={t('radio_label.no') + "child_friendly"}
                      text={t("radio_label.no")}
                      value={0}
                      checked={childFriendly === 0 ? true : false}
                      onChange={() => handleChildFriendly(0)}

                    />
                  </div>
                  {touched.child_friendly && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors.child_friendly}</p>}
                </div>
                <TextareaWrap
                  groupClassName="lg:col-span-3 sm:col-span-2"
                  {...getFieldProps("about_us")}
                  label={<>{t("form_input_label.salon_description")} <span className="text-blue-950/40">({t("form_input_label.short_is_best")})</span></>}
                  rows={4}
                  placeholder={t('input_filed_placeholder.tell_your_customers_about_your_salon_and_your_team')}
                  errorType={Boolean(touched.about_us && errors.about_us) && "error"}
                  errorData={touched.about_us && errors.about_us}
                />
              </RowWrap>
              <Divider className="my-6" />
              <div className="meetime-form-group">
                <LabelWrap label={t('business_profile_module.salon_guidelines')} />
                <div className="form_widget">
                  <AddSalonGuidelines
                    setGuidelines={setGuidelines}
                    databaseGuidList={databaseGuidList}
                    setDatabaseGuidList={setDatabaseGuidList}
                    setDeleteGuidLineArray={setDeleteGuidLineArray}
                    deleteGuidLineArray={deleteGuidLineArray}
                    apiResponse={apiResponse}
                    guidelinesSelectData={guidelinesSelectData}
                    setGuidelinesSelectData={setGuidelinesSelectData}
                  />
                </div>
              </div>
            </Card>
            {offerRequestValue == 1 &&

              <Card className="mb-2.5">
                <ButtonBadge className={"mb-6"}>{t("form_input_label.offer_request")}</ButtonBadge>
                {/* <InputWithIcon
                  groupClassName="mb-4"
                  require
                  size="sm"
                  labelIcon={<LocationPin2 className="w-5 h-5" />}
                  label={t("form_input_label.area")}
                  placeholder={t('input_filed_placeholder.input_city_or_address')}
                  suffixWrap={<span>{t('comman_text.km')}</span>}
                  tooltipText="Lorem ipsum"
                  {...getFieldProps("offer_request_address")}
                  errorType={Boolean(touched.offer_request_address && errors.offer_request_address) && "error"}
                  errorData={touched.offer_request_address && errors.offer_request_address}
                /> */}
                <InputAutocompleteWrap
                  groupClassName="mb-4"
                  {...getFieldProps("offer_request_address")}
                  label={t("form_input_label.area")}
                  labelIcon={<LocationPin2 className="w-5 h-5" />}
                  placeholder={t('input_filed_placeholder.input_city_or_address')}
                  value={offerRequestCity}
                  handleChange={handleOfferRequestCityChange}
                  errorType={Boolean((touched.offer_request_address && errors.offer_request_address) || errors?.offer_request_address) && "error"}
                  errorData={(touched.offer_request_address && errors.offer_request_address) || errors?.offer_request_address}
                />

                {/* {errors?.offer_request_address?.length > 0 &&
                  <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">
                    {errors.offer_request_address}
                  </p>
                } */}
                <RowWrap className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8" lg={3}>
                  <InputWithIcon
                    require
                    size="sm"
                    labelIcon={<CityLocationIcon className="w-5 h-5" />}
                    label={t("form_input_label.city_address_radius")}
                    placeholder={"5"}
                    suffixWrap={<span>{t('comman_text.km')}</span>}
                    tooltipText={t("form_input_label.city_address_radius")}
                    {...getFieldProps("offer_request_radius")}
                    errorType={Boolean(touched.offer_request_radius && errors.offer_request_radius) && "error"}
                    errorData={touched.offer_request_radius && errors.offer_request_radius}
                  />
                  <InputWithIcon
                    require
                    size="sm"
                    labelIcon={<DollarRoundIcon className="w-5 h-5" />}
                    label={t("form_input_label.offer_request_budget")}
                    placeholder={t('input_filed_placeholder.minimum_amount')}
                    suffixWrap={<span>{t('comman_text.dkk')}</span>}
                    {...getFieldProps("offer_request_cost")}
                    errorType={Boolean(touched.offer_request_cost && errors.offer_request_cost) && "error"}
                    errorData={touched.offer_request_cost && errors.offer_request_cost}
                  />
                  <InputWithIcon
                    require
                    size="sm"
                    labelIcon={<CarIcon className="w-5 h-5" />}
                    label={t('form_input_label.transport_time')}
                    placeholder={"45"}
                    suffixWrap={<span>{t('comman_text.min')}</span>}
                    tooltipText="Lorem ipsum"
                    {...getFieldProps("offer_transport_time")}
                    errorType={Boolean(touched.offer_transport_time && errors.offer_transport_time) && "error"}
                    errorData={touched.offer_transport_time && errors.offer_transport_time}
                  />
                </RowWrap>
              </Card>
            }

            {onTheGoCheckd || (onSalone && onTheGoCheckd) ? (
              <>
                <Card className="sm:py-3 sm:px-4 mb-2.5">
                  <div className="flex items-center justify-between">
                    <div className='text-base text-blue-950 inline-block px-2 py-0.5 font-semibold'>{t('comman_text.on_the_go')}</div>
                    <ButtonPrimary variant={"outline"} size={"sm"} type={"button"}
                      onClick={() => {
                        handleAddCardClick();
                        formik.setFieldTouched("salon_cities", false); // Optionally reset touched state if needed
                      }}
                      icon={<PlusLineIcon className="w-5 h-5" />} className={"text-[15px] lg:text-[15px] font-bold py-0.5 px-2.5 max-w-[120px] rounded-full w-full border"} >{t('comman_text.new_city')}</ButtonPrimary>
                  </div>
                </Card>
                <Card className="mb-2.5">
                  <div className="border inline-block rounded-3xl px-4 mb-5 py-0.5 text-blue-950 text-base font-medium">{t('comman_text.settings')}</div>
                  <RowWrap className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8" lg={3}>
                    <InputWithIcon
                      require
                      size="sm"
                      labelIcon={<CityLocationIcon className="w-5 h-5" />}
                      label={t("form_input_label.city_address_radius")}
                      placeholder={"5"}
                      suffixWrap={<span>{t('comman_text.km')}</span>}
                      tooltipText={t("form_input_label.city_address_radius")}
                      lableEndIcon={
                        <InfoFIllIcon className="w-5 h-5" />}
                      {...getFieldProps("work_radius")}
                      errorType={Boolean(touched.work_radius && errors.work_radius) && "error"}
                      errorData={touched.work_radius && errors.work_radius}

                    />
                    <InputWithIcon
                      require
                      size="sm"
                      labelIcon={<TimerClockIcon className="w-5 h-5" />}
                      label={t('form_input_label.transport_time')}
                      placeholder={"45"}
                      suffixWrap={<span>{t('comman_text.min')}</span>}
                      tooltipText={t('form_input_label.transport_time')}
                      lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                      {...getFieldProps("transport_time")}
                      errorType={Boolean(touched.transport_time && errors.transport_time) && "error"}
                      errorData={touched.transport_time && errors.transport_time}
                    />
                    <InputWithIcon
                      require
                      size="sm"
                      labelIcon={<CarIcon className="w-5 h-5" />}
                      label={t('form_input_label.transport_fee')}
                      placeholder={t('comman_text.amount')}
                      suffixWrap={
                        <div className="flex items-center gap-1.5">
                          <span>{t('comman_text.dkk')}</span> <span className="border-r border-success-100 h-6"></span>
                          <InputCheckbox
                            {...getFieldProps(
                              "transport_fee_included"
                            )}
                            checked={formik.values.transport_fee_included}
                            id={"Included1"}
                            text={t('check_box_label.included')}
                            // onChange={(e) => {
                            //   formik.setFieldValue("transport_fee_included", e.target.checked); // Update the checked state
                            //   if (e.target.checked) {
                            //     formik.setFieldValue("transport_fee", ""); // Clear the transport fee field
                            //   }
                            // }}

                            onChange={handleTranportFeesIncluded}
                          />
                        </div>
                      }
                      {...getFieldProps("transport_fee")}
                      errorType={Boolean(touched.transport_fee && errors.transport_fee) && "error"}
                      disabled={formik.values.transport_fee_included}
                      errorData={touched.transport_fee && errors.transport_fee}
                    />
                  </RowWrap>
                </Card>
                {onTheGoCheckd && onTheGoCityCard?.map((data, ind) => (
                  <Card className="mb-2.5" id={ind} key={ind}>
                    <ButtonBadge className={"mb-6"}
                      onClick={() => handleRemoveCardClick(ind)}
                    >{t('form_input_label.city')} {ind + 1}</ButtonBadge>
                    <InputAutocompleteWrap
                      {...getFieldProps(`salon_cities[${ind}]`)}
                      label={t("form_input_label.area")}
                      placeholder={t('input_filed_placeholder.input_city_or_address')}
                      value={onTheGoCityCard[ind]?.work_from_address}
                      index={ind}
                      handleChange={handleCityChange}
                      errorType={Boolean((touched.salon_cities?.[ind] && errors.salon_cities?.[ind]) || errors.salon_cities?.[ind]) && "error"}
                      errorData={(touched.salon_cities?.[ind] && errors.salon_cities?.[ind]) || errors.salon_cities?.[ind]}
                    />
                    {/* {errors?.salon_cities?.length > 0 && errors.salon_cities.map((error, index) => (
                      <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">
                        {index == ind && error ? error : ""}
                      </p>
                    ))} */}

                  </Card>
                ))}
              </>
            ) : (
              <></>
            )}
            <Card className="sm:py-4">
              <ButtonPrimary isLoading={loading} disabled={loading} type={"submit"} className={"w-full font-extrabold text-xl"} size="sm" >{t('button.save')} </ButtonPrimary>
            </Card>
          </form>
        </FormikProvider>
      </div>
    </div>
  )
}

export default withTranslation()(BusinessProfile)