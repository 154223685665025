
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import Card from '../../../../uiComponents/Card';
import Badge from '../../../../uiComponents/common/Badge';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import InputRadioBox from '../../../../uiComponents/inputs/InputRadioBox';
import ProtfolioOutsideMeeTime from './ProtfolioOutsideMeeTime';
import ProtfolioThroughMeeTime from './ProtfolioThroughMeeTime';
import { PATH_WORKER } from '../../../../../routes/path';
import { useLocation, useNavigate } from 'react-router-dom';
import LeftSmallArrowIcon from '../../../../uiComponents/icons/LeftSmallArrowIcon';
import Http from '../../../../../../Http';
import { useQuery } from 'react-query';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';

function Portfolio({ t }) {
    const navigate = useNavigate();
    const [portfolioType, setPortfolioType] = useState(1);
    const { state } = useLocation();

    const { data: portFolliEditData } = useQuery(
        ["portFolliEditData", state],
        async () => {
            if (state?.edit == true) {
                return await Http.get(
                    PROFILE_COMPLETE_URL.showPortfolio.replace(":id", state?.id)
                ).then((res) => res.data.data);
            }
        },
        {
            enabled: !!state?.id,
            refetchOnWindowFocus: false,
            retry: false,
        });

    useEffect(() => {
        if (portFolliEditData?.length > 0) {
            setPortfolioType(Number(portFolliEditData?.work_done_at));
        }
    }, [portFolliEditData])

    return (
        <>
            <div className="page-title mb-3.5 flex items-center justify-center gap-2">
                <LeftSmallArrowIcon className='h-8 w-8 text-white cursor-pointer' onClick={() => navigate(PATH_WORKER.profile, { state: { selectedTab: 4 } })} />
                <h1 className="text-[40px] font-bold leading-[1.2] text-white">{t('comman_text.portfolio')}</h1>
            </div>
            <Card className={"mb-2.5"}>
                <Badge text={t('comman_text.portfolio')} />
                <div className="meetime-form-group"
                >
                    <LabelWrap labelClassName="text-[15px] font-medium" label={t("form_input_label.where_was_the_work_performed")} />
                    <div className="flex items-center flex-wrap gap-4 md:gap-6">
                        <InputRadioBox
                            name="work_done_at"
                            id={t('comman_text.outside_mee_time')}
                            text={t('comman_text.outside_mee_time')}
                            value={1}
                            onChange={(e) => setPortfolioType(Number(e.target.value))}
                            checked={portfolioType === 1 ? true : false}
                            className='peer-checked:text-blue-950'
                        />
                        <InputRadioBox
                            name="work_done_at"
                            id={t('comman_text.through_mee_time')}
                            text={t('comman_text.through_mee_time')}
                            value={2}
                            onChange={(e) => setPortfolioType(Number(e.target.value))}
                            className='peer-checked:text-blue-950'
                            checked={portfolioType === 0 ? true : false}
                            disabled={1}
                        />
                    </div>
                </div>

            </Card>
            {
                portfolioType === 1 ?
                    <ProtfolioOutsideMeeTime isDummy={1} portFolliEditData={portFolliEditData} /> :
                    <ProtfolioThroughMeeTime />
            }

        </>
    )
}

export default withTranslation()(Portfolio);
