import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import CloseIcon from '../uiComponents/icons/CloseIcon'
import ServiceDetailInfo from '../pages/vendor_panel/salonProfile/component/ServiceDetailInfo'
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary'
import Http from '../../../Http'
import { PROFILE_COMPLETE_URL } from '../../api/axios'
import { useQuery } from 'react-query'

const ServiceDetailsModal = ({ serviceDetailsModal, setServiceDetailsModal, handleDelete, id, action, t }) => {

    const [serviceData, setServiceData] = useState([]);
    const { refetch } = useQuery(
        ["serviceRefetchData"],
        async () => {
            return await Http.get(
                PROFILE_COMPLETE_URL.editServices.replace(":id", id)
            ).then((res) => setServiceData(res.data.data));
        },
        {
            enabled: serviceDetailsModal,
            refetchOnWindowFocus: false,
            retry: false,
        });

    return (
        <Transition appear show={serviceDetailsModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => { setServiceDetailsModal(false); setServiceData([]) }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[1050px] transform rounded-[10px] bg-white lg:p-5 p-3 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-1">
                                    <h2 className="font-semibold text-lg sm:text-xl text-center text-blue-950">
                                        {action == "edit" ? t("comman_text.service_details") : "Delete Service"}  </h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => { setServiceDetailsModal(false); setServiceData([]) }}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h4 className='text-blue-950 text-[15px] font-semibold mb-2'>{t('services_module.info')}</h4>
                                    <ServiceDetailInfo data={serviceData} />
                                    {
                                        serviceData?.description !== "" ? <> <h4 className='text-blue-950 text-[15px] font-medium my-2'>{t('comman_text.description')}</h4>
                                            <div className="border-[1.5px] text-[15px] border-success-100 rounded-2xl min-h-[100px] flex items-center justify-center text-blue-950 p-2">
                                                {serviceData?.description ?? ""}
                                            </div></> : <></>
                                    }

                                    {action == "edit" ?
                                        <ButtonPrimary role="button" size="sm" className="w-full mt-5" onClick={() => setServiceDetailsModal(false)}>OK</ButtonPrimary> :
                                        <div className='sm:flex items-center justify-center gap-7'>
                                            <ButtonPrimary type="button" size="sm" className="w-full sm:w-[220px] mt-5 bg-danger-950 border-danger-950" onClick={() => setServiceDetailsModal(false)}>No</ButtonPrimary>
                                            <ButtonPrimary size="sm" className="w-full sm:w-[220px] mt-5" type="button" onClick={() => handleDelete(id)}>Yes</ButtonPrimary>
                                        </div>
                                    }
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(ServiceDetailsModal)