import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary'
import Badge from '../uiComponents/common/Badge'
import Divider from '../uiComponents/Divider'
import CloseFillIcon from '../uiComponents/icons/CloseFillIcon'
import CloseIcon from '../uiComponents/icons/CloseIcon'
import InputWrap from '../uiComponents/inputs/InputWrap'
import LabelWrap from '../uiComponents/inputs/LabelWrap'

const SyncYourCalendarModal = ({ importedCalendar, syncYourCalendarModal, setSyncYourCalendarModal }) => {
    return (
        <Transition appear show={syncYourCalendarModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setSyncYourCalendarModal(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[800px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header mb-8">
                                    <h2 className="font-bold text-[22px] text-center text-blue-950">Sync Your Calendar</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setSyncYourCalendarModal(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body mx-auto ">
                                    <div className="mb-6 text-[15px] text-blue-950 leading-normal">
                                        <p className='leading-[1.6] font-medium text-center'>Import possibilities:</p>
                                        <p className='mb-3 text-center'>Google calendar - www.admind.dk - www.planway.com</p>
                                    </div>
                                    {importedCalendar &&
                                        <div className="">
                                            <LabelWrap labelClassName={"text-[15px] leading-[1.3]"} label={"Imported Calendar"} />
                                            <div className="flex gap-2 border border-borderColor rounded-lg py-2 px-2.5">
                                                <div className="">
                                                    <CloseFillIcon className="w-6 h-6" />
                                                </div>
                                                <div className="w-full">
                                                    <p className='text-sm text-blue-950 font-medium leading-[1.3] mb-1'>My Planway calendar</p>
                                                    <p className="break-all text-sm text-blue-950/40 leading-[1.3]">www.planway.dk/lillyslittlehasssmkdmdkmkasl,xfdvhjck,lxmjfhgfnjmkd,lskcjfjfkdlsælkjikcodll,mkjirsalons</p>
                                                </div>
                                            </div>
                                            <Divider className={"my-6"} />
                                            <div className="flex items-center justify-center mb-6">
                                                <Badge groupClassName={"mb-0"} text={"Import Another Calendar"} />
                                            </div>
                                        </div>}
                                    <InputWrap groupClassName="mb-3" labelClassName="mb-2" label={"Calendar Link"} size="sm" />
                                    <InputWrap groupClassName="mb-5" labelClassName="mb-2" label={"Name this Calendar"} size="sm" />
                                    <ButtonPrimary className={"w-full mx-auto"} children={"Import Your bookings"} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default SyncYourCalendarModal