import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary'
import CloseIcon from '../uiComponents/icons/CloseIcon'
import InputWrap from '../uiComponents/inputs/InputWrap'

const ImportCalendarModal = ({ importCalendarModal, setImportCalendarModal }) => {
    return (
        <Transition appear show={importCalendarModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setImportCalendarModal(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[560px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">Import Calendar</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setImportCalendarModal(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="text-blue-950 mx-auto md:max-w-[95%]">
                                        <p className='text-[15px] leading-[1.3] font-medium text-center mb-4'>We can import your bookings from another website into your Mee Time calendar</p>
                                        <p className='text-[15px] leading-[1.3] mb-6 text-center'>In order to do this, you will need to share your login info with us. If you don´t wish to share your password, you can simply change it to something temporary, let us import your bookings, and then change it back again to your personal password.</p>
                                        <InputWrap groupClassName="mb-3" label={"Website Link"} size="sm" require labelClassName="font-medium text-[15px] mb-2" />
                                        <InputWrap groupClassName="mb-3" label={"Your user name"} size="sm" require labelClassName="font-medium text-[15px] mb-2" />
                                        <InputWrap groupClassName="mb-6" type="password" label={"Your password"} size="sm" require labelClassName="font-medium text-[15px] mb-2" />
                                        <ButtonPrimary size={"sm"} className={"w-full mx-auto mb-4"} children={"Import"} />
                                        <p className='text-[13px] font-normal text-blue-950 text-center'>By clicking the “Import” button, you accept to share your login info (momentarily) with Mee Time, in order to import your bookings.</p>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ImportCalendarModal