import socketio from "socket.io-client";
import React, { useEffect, useState } from "react";
import socketStore from "./socketStore";
import { authStore } from "./AuthProvider";
const SocketURL = process.env.REACT_APP_SOCKET_URL;
export const SocketContext = React.createContext();

const SocketContextProvider = ({ children }) => {
  const [chatClient, setChatClient] = useState(null);
  const [chatUserId, setChatUserId] = useState("");
  const [chatId, setChatId] = useState("");
  const [connected, setConnected] = useState(false);
  const [onlineState, setOnlineState] = useState({});
  const [pathName, setPathName] = useState({});
  const [lastId, setLastId] = useState(null)
  const [currentchat, setCurrentchat] = useState(JSON.parse(localStorage.getItem("conversation")))
  const setChatList = socketStore(state => state.setChatList)
  const profileType = authStore(state => state.profileType)
  const profileId = authStore(state => state.profileId)
  const salonDataId = authStore(state => state.salonDataId)
  const replaceMessageInChatList = socketStore(state => state.replaceMessageInChatList)
  const providerId = authStore(state => state.providerId)
  const setChatLogs = socketStore(state => state.setChatLogs)
  const appendChatLogs = socketStore((state) => state.appendChatLogs);

  useEffect(() => {
    if (profileId) {
      const initChat = () => {
        const client = socketio(SocketURL, {
          query: {
            userId: profileType == 3 ? providerId : profileId,
            userType: profileType == 3 ? 2 : profileType ? profileType : 4,
          },
          transports: ["websocket"],
          upgrade: true,
          reconnection: false,
          autoConnect: false,
          forceNew: true,
        });
        if (!client.connected) {
          client.connect();
        }
        setChatClient(client);
        setConnected(true);
        client.on('connect', function () {
        });

      };
      initChat();

    }
  }, [profileId, profileType, salonDataId]);

  const handleSendMessage = (msgData) => {
    chatClient.emit("message", msgData, function (data) {
      replaceMessageInChatList({ msgData, replaceId: msgData.receiverId });
      appendChatLogs(data)
    });
  };

  useEffect(() => {
    if (connected) {
      chatClient.on('message', function (data) {
        const { senderId, receiverId, message, createdDate, convType } = data
        appendChatLogs(data);
        replaceMessageInChatList({
          replaceId: receiverId, msgData: {
            lastMessageDate: createdDate,
            message: message,
            senderId: receiverId,
            receiverId: senderId,
            convType: convType
          }
        })
      })
      return () => {
        chatClient.off('message')
      }
    }
  }, [connected, pathName]);

  useEffect(() => {
    if (chatUserId && connected) {
      const paramsObject = { userId: providerId ? providerId : profileId, receiverId: chatUserId, chatId: chatId }
      try {
        chatClient.emit('getMessages', paramsObject, (data) => {
          const recievedMsg = data ? data.map(element => {
            // var types = element.message.indexOf("https://") == 0 && element.msgType != 1 ? 2 : element.msgType
            return ({
              chatId: element.chatId,
              message: element.message,
              receiverId: element.receiverId,
              senderId: element.senderId,
              userType: element.userType,
              senderName: element.senderName,
              senderAvatar: element.senderAvatar,
              media: element.media,
              convType: element.convType,
              createdDate: element.createdDate,
              messageType: element.messageType
            })
          }) : []
          if (!recievedMsg.length) setLastId(null)
          setChatLogs(recievedMsg, lastId)
        })
      } catch (error) {
      }
    }
  }, [connected, chatUserId, chatId]);

  useEffect(() => {
    if (connected && profileId) {
      chatListRestore(profileId)
    }
  }, [connected, currentchat, pathName]);

  function convType(profileType) {
    //conv 3 customer ,2 service Provider

    if (profileType == 2 || profileType == 3) {
      return JSON.parse(localStorage.getItem("conversation")) ?? [1]
    }
    if (profileType == 1) {
      return [1, 4]
    }
    if (!profileType) {
      return [2, 3]
    }
  }

  function chatListRestore(profileId) {
    chatClient.emit('listChats', {
      userId: providerId ? providerId : profileId,
      loginUserId: profileType == 3 ? profileId : "",
      convType: convType(profileType),
      userType: profileType ? profileType : 4,
      salonId: salonDataId
    }, function (data) {
      if (data) {
        setChatList(data)
      }
    })
  }
  return (
    <SocketContext.Provider
      value={{
        chatClient,
        currentchat,
        setCurrentchat,
        connected,
        handleSendMessage,
        setChatUserId,
        setChatId,
        chatId,
        providerId,
        chatUserId,
        profileId,
        onlineState,
        setOnlineState,
        setPathName,
        lastId,
        setLastId,
        chatListRestore
      }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
