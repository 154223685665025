import React from 'react'

const FaqQuestionIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.1262 15.4C27.1749 17.2252 26.4568 18.7398 25.2535 20.0505C24.7393 20.6135 24.225 21.167 23.6913 21.7008C22.9053 22.4969 22.4783 23.4289 22.4396 24.5551C22.4007 25.5552 21.7506 26.1766 20.8093 26.1474C19.8874 26.1279 19.3053 25.4581 19.2664 24.4484C19.2082 22.6135 19.9069 21.0796 21.1296 19.7494C21.702 19.128 22.2843 18.5359 22.8568 17.9145C23.8466 16.8369 24.1765 15.5942 23.7203 14.1961C23.3031 12.9438 21.9544 12.0603 20.7124 12.1865C19.1888 12.3418 18.0924 13.3127 17.8692 14.7204C17.8401 14.9243 17.8109 15.1379 17.7915 15.3418C17.7042 16.2544 17.0444 16.8466 16.1322 16.8175C15.2686 16.7883 14.6476 16.1379 14.6282 15.2447C14.5506 11.9244 17.3452 9.07974 20.6735 8.98265C23.7495 8.88558 26.5539 11.1477 27.0488 14.235C27.0972 14.6233 27.0972 15.0117 27.1262 15.4Z" fill="currentcolor" />
            <path d="M20.5352 37.8547C30.7655 37.8547 39.0588 29.8267 39.0588 19.9234C39.0588 10.0204 30.7655 1.99236 20.5352 1.99236C10.3048 1.99236 2.01147 10.0204 2.01147 19.9234C2.01147 29.8267 10.3048 37.8547 20.5352 37.8547Z" stroke="#0DEFDA" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.868 27.6521C22.207 27.6521 23.226 28.6715 23.226 30.0209C23.226 31.3608 22.207 32.3801 20.8583 32.3801C19.5192 32.3801 18.5003 31.3608 18.5003 30.0111C18.5003 28.6617 19.5192 27.6521 20.868 27.6521Z" fill="currentcolor" />
        </svg>
    )
}

export default FaqQuestionIcon