import React from 'react'

const CityLocationIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 10.1974C39.4076 13.0644 37.7607 15.3484 35.9368 17.5291C35.0513 18.5887 34.1033 19.591 33.0707 20.5108C32.9071 20.6568 32.7788 20.7455 32.5785 20.5557C30.1506 18.2487 27.9344 15.7749 26.4534 12.7254C25.9132 11.6126 25.5461 10.4393 25.5606 9.19617C25.5805 7.4889 26.1312 5.94537 27.2355 4.6313C28.6853 2.90735 30.5605 1.99688 32.7985 2.00001C36.5384 2.00731 39.3616 4.70535 39.9425 8.01454C39.9562 8.09067 39.9791 8.16576 39.9979 8.24086C40 8.89373 40 9.54556 40 10.1974ZM32.8068 11.5146C34.0834 11.5146 35.0596 10.5478 35.0647 9.27752C35.0701 7.98012 34.099 6.99978 32.809 6.99873C31.5106 6.99873 30.5459 7.97283 30.5543 9.2744C30.5626 10.5572 31.5251 11.5146 32.8068 11.5146Z" fill="#3BDFFD" />
            <path d="M0.00166331 26.2411C-0.0734264 23.1719 2.39934 19.7729 6.31761 19.265C10.2463 18.756 13.8725 21.5646 14.4075 25.4871C14.6255 27.0818 14.2313 28.5606 13.5565 29.9841C12.6325 31.9324 11.333 33.6218 9.92611 35.2353C9.17207 36.0999 8.35755 36.904 7.53052 37.6966C7.32401 37.8948 7.18113 37.926 6.94961 37.7101C4.6823 35.593 2.65277 33.283 1.17287 30.5338C0.480364 29.252 -0.0244093 27.9045 0.00166331 26.2411ZM7.22808 24.2075C5.95257 24.2064 4.97118 25.1752 4.96701 26.4404C4.96284 27.7418 5.92859 28.7222 7.21765 28.7233C8.514 28.7244 9.48391 27.7493 9.47765 26.4507C9.47141 25.1679 8.50982 24.2094 7.22808 24.2075Z" fill="#00FDB4" />
            <path d="M20.5445 37.5203C17.8778 37.5203 15.2099 37.5203 12.5432 37.5203C11.8757 37.5203 11.8757 37.5203 11.8757 36.8332C11.8757 36.3639 11.8851 35.8944 11.8726 35.4251C11.8653 35.1561 11.9727 35.0392 12.2397 35.055C12.369 35.0623 12.4994 35.055 12.6297 35.055C17.9381 35.055 23.2467 35.058 28.5552 35.0518C29.9787 35.0508 31.1604 34.0996 31.5003 32.7146C31.9687 30.8114 30.5241 28.933 28.5636 28.9206C26.4297 28.908 24.2958 28.9184 22.1621 28.9163C19.248 28.9131 17.0121 27.1121 16.5386 24.2378C15.99 20.9056 18.4493 18.2044 21.2808 17.9281C23.4573 17.7163 25.6485 17.8853 27.8324 17.8488C28.089 17.8446 28.1704 17.9708 28.1672 18.2117C28.1589 18.7718 28.1505 19.3329 28.1704 19.893C28.1817 20.2225 28.0524 20.3174 27.7375 20.3163C25.8383 20.3059 23.9381 20.3091 22.0389 20.3133C20.292 20.3174 18.929 21.5856 18.9019 23.3366C18.8726 25.2296 20.3756 26.4237 21.7803 26.4403C23.9528 26.4653 26.1261 26.4508 28.2987 26.4519C31.2668 26.454 33.5497 28.3281 33.9731 31.2659C34.4122 34.3061 32.2992 36.9458 29.4365 37.4401C29.0506 37.5068 28.6626 37.5214 28.2715 37.5203C25.6966 37.5194 23.1205 37.5203 20.5445 37.5203Z" fill="#0DEFDA" />
        </svg>
    )
}

export default CityLocationIcon