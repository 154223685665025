import React from 'react'

const AddMoreIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={20} cy={20} r="19.25" fill="white" stroke="#335A70" strokeWidth="1.5" />
            <path d="M20.3994 11.1449V28.8609" stroke="#335A70" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.2505 20L11.5346 19.9996" stroke="#335A70" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default AddMoreIcon