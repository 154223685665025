import React, { useEffect } from 'react'
import Table from '../table/Table'
import TBody from '../table/TBody'
import TR from '../table/TR'
import TD from '../table/TD'
import InputCheckbox from '../inputs/InputCheckbox'
import { cn } from '../../../../lib/utils'
import ButtonPrimary from '../buttons/ButtonPrimary'
import PlusLineIcon from '../icons/PlusLineIcon'
import TrashIcon from '../icons/TrashIcon'
import { withTranslation } from 'react-i18next'
import Tooltip from '../Tooltip'
import { cloneDeep } from 'lodash'
import dayjs from "dayjs";
import moment from "moment";
import { Form, TimePicker } from "antd";
import weekDaysInitial from '../../../data/weekDaysArray'

const WorkerAddHoursListTable = ({ t, salonData, error, tableClass, groupClassName, setOnTheGoAvailability, onTheGoAvailability, setSalonAvailability, salonAvailability, id, form, useSalonHours }) => {

    const mapData = id === 'salon' ? [...salonAvailability] : [...onTheGoAvailability];
    const availablityData = id === 'salon' ? salonData?.salon : salonData?.onthego;
    useEffect(() => {
        // This code runs when the component mounts
        if (id === 'salon') {
            setSalonAvailability(cloneDeep(weekDaysInitial));
        }

        if (id === 'onthego')
            setOnTheGoAvailability(cloneDeep(weekDaysInitial));
    }, []);

    const getDisabledHours = (slots, slotIndex, dayIndex, type, location) => {
        const disabledHours = [];
        let selectedSalonAv = salonData?.[location][dayIndex];
        const shiftStartHour = moment.utc(selectedSalonAv?.slots?.[0].from, 'HH:mm').local().hour();
        const shiftEndHour = moment.utc(selectedSalonAv?.slots?.[selectedSalonAv?.slots?.length - 1].to, 'HH:mm').local().hour();

        for (let hour = 0; hour < 24; hour++) {
            if (hour < shiftStartHour || hour > shiftEndHour) {
                disabledHours.push(hour);
            }
        }

        if (type === "start" && slotIndex != 0) {
            const endHour = moment.utc(slots[slotIndex - 1].to, 'HH:mm').local().hour();
            for (let hour = 0; hour < 24; hour++) {
                if (hour < endHour) {
                    disabledHours.push(hour);
                }
            }
        }

        if (type === "end") {
            const fromHour = moment.utc(slots[slotIndex].from, 'HH:mm').local().hour();
            for (let hour = 0; hour < 24; hour++) {
                if (hour < fromHour) {
                    disabledHours.push(hour);
                }
            }

        }
        return disabledHours;
    };

    const getDisabledMinutes = (slots, slotIndex, selectedHour, type, dayIndex, location) => {
        const disabledMinutes = [];
        let selectedSalonAv = salonData?.[location][dayIndex];
        const shiftStartHour = moment.utc(selectedSalonAv?.slots?.[0].from, 'HH:mm').local().hour();
        const shiftStartMinute = moment.utc(selectedSalonAv?.slots?.[0].from, 'HH:mm').local().minute();

        if (type === "start") {
            if (slotIndex != 0) {
                const endHour = moment.utc(slots[slotIndex - 1].to, 'HH:mm').local().hour();
                const endMinute = moment.utc(slots[slotIndex - 1].to, 'HH:mm').local().minute();
                if (selectedHour === endHour) {
                    for (let minute = 0; minute < 60; minute++) {
                        if (minute <= endMinute) {
                            disabledMinutes.push(minute);
                        }
                    }
                }
            }

            if (selectedHour === shiftStartHour) {
                for (let minute = 0; minute < 60; minute++) {
                    if (minute <= shiftStartMinute) { //|| minute >= shiftEndMinute
                        disabledMinutes.push(minute);
                    }
                }
            }

            return disabledMinutes;
        }

        if (type === "end") {
            const fromHour = moment.utc(slots[slotIndex].from, 'HH:mm').local().hour();
            const fromMinute = moment.utc(slots[slotIndex].from, 'HH:mm').local().minute();
            if (selectedHour === fromHour) {
                for (let minute = 0; minute < 60; minute++) {
                    if (minute <= fromMinute) {
                        disabledMinutes.push(minute);
                    }
                }
            }

            return disabledMinutes;
        }
    };


    const handleSalonTimeChange = (index, slotIndex, key, value) => {
        form.validateFields([`${id}_to_${index}_${slotIndex}`])
        const localDateTime = dayjs(value, "HH:mm");
        const timeZoneOffset = localDateTime.utcOffset();
        const utcDateTime = localDateTime.subtract(timeZoneOffset, 'minute');
        const formattedUtcTime = utcDateTime.format("HH:mm");

        let updateWeekDays = id === 'salon' ? [...salonAvailability] : [...onTheGoAvailability];
        updateWeekDays[index].slots[slotIndex][key] = value != null ? formattedUtcTime : "";
        if (updateWeekDays[index].slots.length > 1) {
            let slot = updateWeekDays[index].slots[slotIndex];
            if (slot.from == "" && slot.to == "") {
                updateWeekDays[index]?.slots.splice(slotIndex, 1);
                updateWeekDays[index].slots?.map((slot, slotInd) => {
                    form.setFieldsValue({
                        [id + '_from_' + index + '_' + slotInd]: slot.from ? dayjs(moment.utc(slot.from, 'HH:mm').local()) : null,
                        [id + '_to_' + index + '_' + slotInd]: slot.to ? dayjs(moment.utc(slot.to, 'HH:mm').local()) : null
                    })
                })
            }
        }
        if (id === 'salon') {
            setSalonAvailability(updateWeekDays);
        } else {
            setOnTheGoAvailability(updateWeekDays);
        }
    };

    const handleSalonDayOnOff = (index, slotIndex, e) => {
        if (!e.target.checked) {
            form.validateFields([`${id}_from_${index}_${slotIndex}`, `${id}_to_${index}_${slotIndex}`]);
        }
        let updateWeekDays = id === 'salon' ? [...salonAvailability] : [...onTheGoAvailability];
        updateWeekDays[index].status = e.target.checked;
        if (id === 'salon') {
            setSalonAvailability(updateWeekDays);
        } else {
            setOnTheGoAvailability(updateWeekDays);
        }
    }

    const handleSalonAddHours = (index) => {
        const slotLength = id === 'salon' ? salonAvailability[index]?.slots?.length : onTheGoAvailability[index]?.slots?.length;
        if (slotLength < 3) {
            const updateWeekDays = id === 'salon' ? [...salonAvailability] : [...onTheGoAvailability];;
            const slot = updateWeekDays[index]['slots'][slotLength - 1];
            let isValid = true;

            if (!slot.from) {
                form.validateFields([`${id}_from_${index}_${slotLength - 1}`])
                isValid = false;
            }

            if (!slot.to) {
                form.validateFields([`${id}_to_${index}_${slotLength - 1}`])
                isValid = false;
            }

            if (isValid) {
                updateWeekDays[index]['slots'].push({ from: '', to: '' });
                form.setFieldsValue({
                    [id + '_from_' + index + '_' + slotLength]: null,
                    [id + '_to_' + index + '_' + slotLength]: null
                })
                if (id === 'salon') {
                    setSalonAvailability(updateWeekDays);
                } else {
                    setOnTheGoAvailability(updateWeekDays);
                }
            }
        }
    };

    const handleSalonRemoveHours = (index, slotIndex) => {
        let updateWeekDays = id === 'salon' ? [...salonAvailability] : [...onTheGoAvailability];
        updateWeekDays[index]?.slots.splice(slotIndex, 1);
        if (id === 'salon') {
            setSalonAvailability(updateWeekDays);
        } else {
            setOnTheGoAvailability(updateWeekDays);
        }
        form.setFieldsValue({
            [id + '_from_' + index + '_' + slotIndex]: null,
            [id + '_to_' + index + '_' + slotIndex]: null
        })
    }
    return (
        <div className={cn("add-hours-list", groupClassName)}>
            <div className="overflow-x-auto">
                <Table className={cn(tableClass)}>
                    <TBody>
                        {
                            mapData.map((item, index) => (
                                item?.slots.map((slot, slotIndex) => (
                                    <TR className={`[&_.last-td]:last:border-0 [&_.last-td]:last:pb-0 
                                    ${availablityData?.[index]?.status ? '' : 'disabled'}`}
                                        disabled={availablityData?.[index]?.status ? "disabled" : item?.status ? "" : "disabled"}>
                                        {slotIndex === 0 ?
                                            <TD className={`td_day_title py-3 lg:py-6 last-td `}>
                                                <InputCheckbox
                                                    onChange={(e) => handleSalonDayOnOff(index, slotIndex, e)}
                                                    checked={availablityData?.[index]?.status && item?.status}
                                                    className='peer-checked:text-blue-950'
                                                    size="xl"
                                                    id={id + "-slot" + index}
                                                    disabled={availablityData?.[index]?.status == false ? "disabled" : (useSalonHours && useSalonHours == true) ? "disabled" : false}
                                                    text={<div className='block'>{t(item.day)}<span className='block text-danger-950 text-[15px]'>{availablityData?.[index]?.status && item?.status == true ? "" : "Closed"}</span></div>} />

                                            </TD>
                                            :
                                            <TD className={`td_day_title py-3 lg:py-6 last-td `}></TD>
                                        }
                                        <TD className={`py-3 lg:py-6 last-td `}>
                                            <div className="flex items-center justify-center gap-4">
                                                <div className="relative max-w-[120px]">
                                                    <Form.Item className='availability-form-item' name={id + "_from_" + index + "_" + slotIndex}
                                                        rules={[
                                                            {
                                                                required: !useSalonHours ? availablityData?.[index]?.status && item?.status : false,
                                                                message: (<span className='absolute top-2.5 md:top-3.5 end-1.5 z-[1] block'><Tooltip tooltipIconClass="text-danger-950 w-4 h-4" title="Please enter start time" /></span>)
                                                            },
                                                            {
                                                                validator(rule, value) {
                                                                    if (value && slotIndex != 0) {
                                                                        const start = new Date(form.getFieldValue(`${id}_to_${index}_${slotIndex - 1}`));
                                                                        const end = new Date(value);

                                                                        const startHour = start.getHours();
                                                                        const startMinutes = start.getMinutes();

                                                                        const endHour = end.getHours();
                                                                        const endMinutes = end.getMinutes();
                                                                        // Compare only the time portion and return the appropriate promise
                                                                        return (endHour > startHour || (endHour === startHour && endMinutes > startMinutes))
                                                                            ? Promise.resolve()
                                                                            : Promise.reject(<span className='absolute top-2.5 md:top-3.5 end-1.5 z-[1] block'><Tooltip tooltipIconClass="text-danger-950 w-4 h-4" title="Start time must be greater than previous" /></span>);
                                                                    }
                                                                    return Promise.resolve();
                                                                }
                                                            }

                                                        ]}
                                                    >

                                                        <TimePicker
                                                            suffixIcon={false}
                                                            className={cn("availability-timepicker [&_input]:text-[15px] [&_input]:!text-blue-950 border-[1.3] rounded-lg bg-light-950 outline-[0px] [&_input]:!text-center py-2 md:py-3", error ? "border-danger-950" : "border-success-100")}
                                                            placeholder={t('comman_text.start_time')}
                                                            format={"HH:mm"}
                                                            onChange={(e) => handleSalonTimeChange(index, slotIndex, 'from', e)}
                                                            value={slot?.from}
                                                            disabledTime={() => ({
                                                                disabledHours: () => getDisabledHours(item?.slots, slotIndex, index, 'start', id),
                                                                disabledMinutes: (selectedHour) => getDisabledMinutes(item?.slots, slotIndex, selectedHour, 'start', index, id)
                                                            })}
                                                            showNow={false}
                                                            disabled={availablityData?.[index]?.status == false || item?.status == false ? "disabled" : (useSalonHours && useSalonHours == true) ? "disabled" : false}

                                                        />
                                                    </Form.Item>

                                                </div>
                                                <span>To</span>
                                                <div className="relative max-w-[120px]">

                                                    <Form.Item name={id + "_to_" + index + "_" + slotIndex}
                                                        className='availability-form-item'
                                                        rules={[
                                                            {
                                                                required: !useSalonHours ? availablityData?.[index]?.status && item?.status : false,
                                                                message: (<span className='absolute top-2.5 md:top-3.5 end-1.5 z-[1] block'><Tooltip tooltipIconClass="text-danger-950 w-4 h-4" title="Please enter end time" /></span>)

                                                            },
                                                            {
                                                                validator: (name, value) => {
                                                                    if (value) {
                                                                        const start = new Date(form.getFieldValue(id + `_from_${index}_${slotIndex}`));
                                                                        const end = new Date(value);

                                                                        const startHour = start.getHours();
                                                                        const startMinutes = start.getMinutes();

                                                                        const endHour = end.getHours();
                                                                        const endMinutes = end.getMinutes();

                                                                        // Compare only the time portion and return the appropriate promise
                                                                        return (endHour > startHour || (endHour === startHour && endMinutes > startMinutes))
                                                                            ? Promise.resolve()
                                                                            : Promise.reject(<span className='absolute top-2.5 md:top-3.5 end-1.5 z-[1] block'><Tooltip tooltipIconClass="text-danger-950 w-4 h-4" title="End time must be greater than start time" /></span>)
                                                                            ;
                                                                    }
                                                                    return Promise.resolve();

                                                                }
                                                            }

                                                        ]}
                                                    >

                                                        <TimePicker suffixIcon={false}
                                                            format="HH:mm"
                                                            className={cn("availability-timepicker [&_input]:text-[15px] [&_input]:!text-blue-950 border-[1.3] rounded-lg bg-light-950 outline-[0px] [&_input]:!text-center py-2 md:py-3", error ? "border-danger-950" : "border-success-100")}
                                                            placeholder={t('comman_text.end_time')}
                                                            onChange={(e) => handleSalonTimeChange(index, slotIndex, 'to', e)}
                                                            value={slot?.to}
                                                            disabledTime={() => ({
                                                                disabledHours: () => getDisabledHours(item?.slots, slotIndex, index, 'end', id),
                                                                disabledMinutes: (selectedHour) => getDisabledMinutes(item?.slots, slotIndex, selectedHour, 'end', index, id)
                                                            })}
                                                            showNow={false}
                                                            disabled={availablityData?.[index]?.status == false || item?.status == false ? "disabled" : (useSalonHours && useSalonHours == true) ? "disabled" : false}

                                                        />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                        </TD>
                                        <TD className={`py-3 lg:py-6 last-td `}>
                                            <div className="flex items-center justify-end">
                                                {slotIndex !== 0 ?
                                                    <ButtonPrimary size={"sm"}
                                                        className={"lg:text-[15px] text-[15px] font-bold rounded-[5px] !p-2"} icon={<TrashIcon className="w-5 h-5" />}
                                                        disabled={availablityData?.[index]?.status == false || item?.status == false ? "disabled" : (useSalonHours && useSalonHours == true) ? "disabled" : false}
                                                        type='button'
                                                        onClick={() => { handleSalonRemoveHours(index, slotIndex) }}
                                                    ></ButtonPrimary>
                                                    :
                                                    <ButtonPrimary size={"sm"}
                                                        className={"lg:text-[15px] text-[15px] font-bold rounded-[5px]"}
                                                        icon={<PlusLineIcon className="w-5 h-5" />}
                                                        type='button'
                                                        disabled={availablityData?.[index]?.status == false || item?.status == false ? "disabled" : (useSalonHours && useSalonHours == true) ? "disabled" : false}
                                                        onClick={() => { handleSalonAddHours(index) }} >{t('comman_text.add_hours')}</ButtonPrimary>
                                                }
                                            </div>
                                        </TD>
                                    </TR>
                                ))
                            ))
                        }
                    </TBody>
                </Table>
            </div>
        </div>
    )
}
export default withTranslation()(WorkerAddHoursListTable);