import React from 'react'
import { cn } from '../../../../lib/utils'
import Tooltip from '../Tooltip'

function LabelWrap({ label, className, require, labelClassName, prefixIcon, suffixIcon, tooltipText, tooltipIcon, tooltipIconClass, tooltipContentClass, tooltipButtonClass }) {
    return (
        <label className={cn(prefixIcon || suffixIcon || tooltipText ? "flex items-center justify-between gap-2" : "block", "mb-1.5 text-blue-950", labelClassName)}>
            <span className='flex items-center justify-start gap-2'>
                {prefixIcon ? prefixIcon : ''} <span className={cn(require ? "before:content-['*'] before:text-danger-950 before:text-2xl before:leading-[1] before:absolute before:top-0.5 before:end-0 inline-block pe-3 relative" : "",className)}>{label}</span>
            </span>
            {suffixIcon}
            {tooltipText ? <Tooltip title={tooltipText} tooltipIcon={tooltipIcon} tooltipIconClass={tooltipIconClass} tooltipContentClass={tooltipContentClass} tooltipButtonClass={tooltipButtonClass} /> : ""}
        </label>
    )
}

export default LabelWrap