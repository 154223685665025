import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cn } from '../../../../lib/utils';


const JobDetails = ({colored, t, salonData, data, navigateUrl, handelClickEvent}) => {
    const navigate = useNavigate();
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const salonAvailability = salonData?.availability?.filter(day => day.service_location === 'salon');
    const onTheGoAvailability = salonData?.availability?.filter(day => day.service_location === 'onthego');

    const displayOpeningHours = (availabilityData) => {
        return availabilityData?.map(day => {
            const dayOfWeek = daysOfWeek[day.day - 1];
            const startTime = day.start_time && day.status != 0 ? day.start_time.split(':').slice(0, 2).join('.') : '';
            const endTime = day.end_time && day.status != 0 ? day.end_time.split(':').slice(0, 2).join('.') : '';

            return (
                <li key={day.id} className='text-blue-950 text-sm flex items-center justify-between gap-2.5'>
                    <p>{dayOfWeek}</p>
                    {startTime !== '' ? <p>{startTime}-{endTime}</p> : <p className='text-danger-950 text-sm font-medium'>Closed</p>}
                </li>
            );
        });
    };
    return (
        <div className={cn(`border-[1.3px] border-success-100 rounded-[10px] h-full p-0 ${colored && "bg-light-800"}`, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(navigateUrl, { state: { id: data.salon_id || data.id, data: data } })}>
            <div className="sm:flex py-3 sm:px-0 px-3">
                <div className="flex items-center justify-center text-center text-blue-950 text-sm flex-grow sm:pb-0 pb-2.5 px-2.5">We are looking for a new teamate, for our Salon, down in the center!e are looking for a new teamate, for our Salon, down in the center!e are looking for a new teamate, for our Salon, down in the center!</div>
                <div className="sm:px-2.5 px-0 sm:pt-0 pt-2.5 flex-shrink-0 sm:border-s-[1.3px] border-s-0 sm:border-t-0 border-t-[1.3px] border-success-100">
                    <div className="pb-2">
                        <p className='text-blue-950 text-sm text-center'>{t('salon_invite_modal.salary')}</p>
                        <p className='text-blue-950 font-semibold text-sm text-center'>25.000 Dkk.</p>
                    </div>
                    <ul className=''>
                        {salonData?.service_location == 2 ? displayOpeningHours(onTheGoAvailability) : displayOpeningHours(salonAvailability)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(JobDetails);
