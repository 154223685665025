import { cva } from 'class-variance-authority'
import React, { forwardRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import InputWrap from './InputWrap'
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api'
import InputWithIcon from './InputWithIcon'
const libraries = ["drawing", "places"];


const inputVariance = cva(
    "input-wrap peer text-[15px] rounded-md leading-[23px] font-medium w-full outline-0 border-[1.3px] border-success-100 bg-light-950 text-blue-950 placeholder:text-dark-950/30 placeholder:text-sm required:border-danger-950 invalid:border-danger-950",
    {
        variants: {
            size: {
                default: 'py-2.5 !px-4 h-[45px]',
                sm: '!px-4 leading-[26px] h-[40px]',
            }
        },
        defaultVariants: {
            size: "default",
        }
    },
)

const InputAutocompleteWrap = forwardRef(({ t, tReady, index, value, errorData, errorType, variant, size, type, ref, className, label, labelIcon, labelClassName, groupClassName, errorClassName, tooltipText, tooltipIcon, tooltipIconClass, tooltipContentClass, tooltipButtonClass, require, placeholder, handleChange, setValue, suffixWrap, suffixIcon, prefixWrap, prefixIcon, ...props }) => {
    const [autoComplete, setAutoComplete] = useState();
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, libraries });

    const onAutoCompleteIsLoad = (inputAutoComplete) => {
        setAutoComplete(inputAutoComplete);
    };
    const onAutoCompletePlaceIsChanged = () => {
        if (autoComplete !== null) {
            var place = autoComplete?.getPlace();
            if (place) {
                // handleChange(index, place?.formatted_address)
                if (typeof index !== "undefined") {
                    handleChange(index, place?.formatted_address, place?.geometry?.location);
                } else {
                    handleChange(place?.formatted_address, place?.geometry?.location);
                }
            }
        }
    };
    return (
        isLoaded ?
            <Autocomplete
                onLoad={onAutoCompleteIsLoad}
                onPlaceChanged={onAutoCompletePlaceIsChanged}
            >
                {
                    (suffixWrap || suffixIcon || prefixWrap || prefixIcon) ?
                        <InputWithIcon
                            {...props}
                            groupClassName={groupClassName}
                            require
                            value={value}
                            onChange={(event) => {
                                if (typeof index !== "undefined") {
                                    handleChange(index, event.target.value);
                                } else {
                                    handleChange(event.target.value);
                                }
                            }}
                            labelIcon={labelIcon}
                            labelClassName={labelClassName}
                            className={className}
                            label={label}
                            suffixWrap={suffixWrap}
                            suffixIcon={suffixIcon}
                            prefixWrap={prefixWrap}
                            prefixIcon={prefixIcon}
                            placeholder={placeholder}
                            tooltipText={tooltipText}
                            errorData={errorData ?? errorData}
                            errorType={errorType ?? errorType}
                        />
                        :
                        <InputWrap
                            {...props}
                            groupClassName={groupClassName}
                            require
                            size="sm"
                            value={value}
                            onChange={(event) => {
                                if (typeof index !== "undefined") {
                                    console.log({ index });
                                    handleChange(index, event.target.value);
                                } else {
                                    handleChange(event.target.value);
                                }
                            }}
                            labelIcon={labelIcon}
                            labelClassName={labelClassName}
                            className={className}
                            label={label}
                            placeholder={placeholder}
                            tooltipText={tooltipText}
                            errorData={errorData ?? errorData}
                            errorType={errorType ?? errorType}
                        />
                }
            </Autocomplete>
            : <div>Loading....</div>
    )
})

export { inputVariance }
export default withTranslation()(InputAutocompleteWrap)
