import React from 'react'

const MinusCircularIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_817_4)">
                <path d="M18.8919 18.5993C22.8425 14.74 22.8425 8.48264 18.8919 4.62325C14.9414 0.763854 8.53626 0.763854 4.5857 4.62325C0.635138 8.48264 0.635138 14.74 4.5857 18.5993C8.53626 22.4587 14.9414 22.4587 18.8919 18.5993Z" stroke="currentcolor" stroke-width="2" />
                <path d="M16.5186 11.643L6.7881 11.6298" stroke="currentcolor" stroke-width="2.3" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_817_4">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MinusCircularIcon    