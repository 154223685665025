import React from 'react'
import { cn } from '../../../../../../lib/utils'
import { withTranslation } from 'react-i18next'
import dayjs from "dayjs";
import utcPlugin from 'dayjs/plugin/utc';
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);

const AvailabilityCommon = ({ t, groupClassName, className,data }) => {
    return (
        <ul className={cn("[&>li]:after:w-full [&>li]:after:absolute [&>li]:after:bottom-0 [&>li]:after:border-b [&>li]:after:border-borderColor border border-borderColor rounded-lg py-2 px-4", groupClassName)}>
            {data?.length ? data?.map((item,index)=>(
                // console.log("here",item);
                  <li className={cn('text-blue-950 text-sm flex items-center justify-between gap-2.5 py-1 relative', className)} ><p>{t(item?.day)}</p><p><span>{ dayjs.utc(item?.slots?.[0]?.from, 'HH:mm').local().format('HH:mm')}</span>-<span>{ dayjs.utc(item?.slots?.[0]?.to, 'HH:mm').local().format('HH:mm')}</span></p></li>
            )) : <></>}
        </ul>
    )
}

export default withTranslation()(AvailabilityCommon)