import React from 'react'

function LeftSmallArrowIcon({strok, ...props}) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.7459 2L11 20L29.7459 38" stroke="currentColor" strokeWidth={strok ?? 5} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default LeftSmallArrowIcon