import React from 'react'

function PlusLineIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.8002 20.4869C38.0286 30.2811 30.2491 38.229 20.4138 38.229C10.5785 38.229 2.42824 30.2811 2.1998 20.4869C1.97136 10.6927 9.75091 2.74481 19.5862 2.74481C29.4215 2.74481 37.5718 10.6927 37.8002 20.4869Z" stroke="currentColor" strokeWidth="3.49056" />
            <path d="M19.8522 11.8861V29.1525" stroke="currentColor" strokeWidth="3.64922" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.4832 20.5216L11.2172 20.5212" stroke="currentColor" strokeWidth="3.64922" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default PlusLineIcon