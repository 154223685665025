import React from 'react'
import MinusCircularIcon from '../icons/MinusCircularIcon'
import { cn } from '../../../../lib/utils'
import StarIcon from '../icons/StarIcon'
import AddCircularIcon from '../icons/AddCircularIcon'

const CountHandler = ({ setCount, count, groupClassName, star, starClassName, btnClassName, iconClassName, maxValue, showMaxValue, countClassName }) => {
    return (
        <div className={cn(`count_handler flex gap-2 `, groupClassName)}>
            <button className={cn("count_changer", btnClassName)} onClick={() => setCount(count === 0 ? 0 : count - 1)}>
                <MinusCircularIcon className={cn("w-5 h-5 text-success-950", iconClassName)} />
            </button>
            <div className={cn(`flex justify-center w-[60px] items-center gap-1`, countClassName)}>
                <span className='flex  justify-center items-center text-sm text-blue-950'>{count}{showMaxValue ? `,${maxValue}` : ""}</span>
                {star ? <StarIcon className={cn("min-w-3.5 max-h-3.5 text-success-800", starClassName)} /> : ''}
            </div>
            <button className={cn("count_changer", btnClassName)} onClick={() => setCount(count === maxValue ? maxValue : count + 1)} >
                <AddCircularIcon className={cn("w-5 h-5 text-success-950", iconClassName)} />
            </button>
        </div>
    )
}

export default CountHandler