import React from 'react'

const ReportsIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={20} cy={20} r="19.25" fill="white" stroke="#335A70" strokeWidth="1.5" />
            <rect x="11.1111" y="24.6939" width="4.44444" height="6.97301" rx="1.00001" fill="#335A70" />
            <rect x="18.0002" y="18.6508" width="4.44444" height="13.0163" rx="1.00001" fill="#335A70" />
            <rect x="24.8893" y="22.3697" width="4.44444" height="9.29735" rx="1.00001" fill="#335A70" />
            <path d="M10 31.4125H30.3249" stroke="#335A70" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.0001 18.3374L15.6459 12.6683L22.4209 15.5029L30.325 8.88889" stroke="#335A70" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.3246 13.6131V8.88889H24.6788" stroke="#335A70" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ReportsIcon