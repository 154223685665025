import React from 'react'

const LogoutIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4759 38.7767H6.30332C5.21957 38.7767 4.18019 38.3461 3.41388 37.5798C2.64754 36.8135 2.21701 34.6905 2.21701 34.6905C0.594331 25.2248 0.594331 15.552 2.21701 6.08629C2.21701 6.08629 2.55023 4.0605 3.41388 3.19685C4.2775 2.33322 5.21957 2 6.30332 2H14.4759" stroke="currentcolor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.7843 30.6005L39 20.3848L28.7843 10.169" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M38.9928 20.3883H14.475" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default LogoutIcon