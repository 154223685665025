import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import SalonCard from '../../../uiComponents/common/SalonCard';
import Card from '../../../uiComponents/Card';
import InputWithIcon from '../../../uiComponents/inputs/InputWithIcon';
import LocationPin1 from '../../../uiComponents/icons/LocationPin1';
import LabelWrap from '../../../uiComponents/inputs/LabelWrap';
import InfoFIllIcon from '../../../uiComponents/icons/InfoFIllIcon';
import RowWrap from '../../../uiComponents/RowWrap';
import InputCheckbox from '../../../uiComponents/inputs/InputCheckbox';
import LocationPin3 from '../../../uiComponents/icons/LocationPin3';
import SalonGuidelinesSlider from '../../../uiComponents/common/SalonGuidelinesSlider';
import SearchIcon from '../../../uiComponents/icons/bottombar-icon/SearchIcon';
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary';
import FilterIcon from '../../../uiComponents/icons/FilterIcon';
import SortIcon from '../../../uiComponents/icons/SortIcon';
import { cn } from '../../../../../lib/utils';
import Badge2 from '../../../uiComponents/common/Badge2';
import RightSmallArrowIcon from '../../../uiComponents/icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from '../../../uiComponents/icons/LeftSmallArrowIcon';
import ServicesFilter from '../../../modals/ServicesFilter';
import SortByModal from '../../../modals/SortByModal';
import ProfileIcon from '../../../uiComponents/icons/ProfileIcon';
import IconShop2 from '../../../uiComponents/icons/IconShop2';
import AboutUsDeatils from '../../../uiComponents/common/AboutUsDeatils';
import ReviewDetails from '../../../uiComponents/common/ReviewDetails';
import SpecialitiesCompo from '../../customer_panel/components/SpecialitiesCompo';
import PortfolioDetails from '../../../uiComponents/common/PortfolioDetails';
import BadgeInfoModal from '../../../modals/BadgeInfoModal';
import { useLocation } from 'react-router-dom';
import Http from '../../../../../Http';
import { FRONT_API_URL } from '../../../../api/axios';
import { useMutation, useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import InputAutocompleteWrap from '../../../uiComponents/inputs/InputAutocompleteWrap';
import dayjs from 'dayjs';
import moment from 'moment';
import SelectDropdown from '../../../uiComponents/inputs/SelectDropdown';

function SampleNextArrow(props) {
    const { onClick } = props;

    return (
        <div onClick={onClick} className='absolute top-1/2 -translate-y-1/2 -end-0 bg-primary1 rounded-full h-[22px] w-[22px] flex items-center justify-center cursor-pointer'><RightSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className='absolute top-1/2 z-10 -translate-y-1/2 start-1 bg-primary1 rounded-full h-[22px] w-[22px] flex items-center justify-center cursor-pointer'><LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}

const FindSalon = (props) => {
    const { t } = props;
    const { state } = useLocation();
    const sliderRef = useRef(null)
    // const [select, setSelect] = useState('')
    const [ServicesFilterModalOpen, setServicesFilterModalOpen] = useState(false)
    const [SortByModalOpen, setSortByModalOpen] = useState(false)
    const [selectedSortBy, setselectedSortBy] = useState([])
    const [badgeInfoModalOpen, setbadgeInfoModalOpen] = useState(false)
    const [salonList, setSalonList] = useState([]);
    const [greaterradiussalonList, setGreaterRadiusSalonList] = useState([]);
    // const [servicesFilterOpen, setServicesFilterOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(1);
    const [windowwidth, setWindowWidth] = useState(window.innerWidth);
    const [onTheGoChecked, setOnTheGoChecked] = useState(false)
    // const [onSalone, setOnSalone] = useState(false);
    const [onSalone, setOnSalone] = useState(true);
    const [discount, setDiscount] = useState([]);
    // const [isChecked, setIsChecked] = useState(false);
    const [address, setAddress] = useState('');
    const [addressLat, setAddressLat] = useState('');
    const [addressLong, setAddressLong] = useState('');
    const [sortBy, setSortBy] = useState("");
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState([]);
    const [providerType, setProviderType] = useState([]);
    const [genderType, setGenderType] = useState([]);
    const [time, setTime] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectHairTpes, setHairtype] = useState([]);
    const [selectedRating, setSelectedRating] = useState("");
    const [checkedList, setCheckedList] = useState([]);
    const [count, setCount] = useState(0);
    // const [servicesIds, setservicesIds] = useState("");
    const [selectCategoryTpes, setCategorytype] = useState("");
    // const [onThegoPrice, setOnThegoPrice] = useState('');
    // const [offerRequestBudget, setOfferRequestBudget] = useState('');
    const [workerName, setWorkerName] = useState('')
    const [selectedList, setSelectedList] = useState([]);

    // const BadgeTypeData = [
    //     {
    //         title: "Trusted",
    //         icon: <TrustedBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(1 K.)'
    //     },
    //     {
    //         title: "Top Rated",
    //         icon: <StudentBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(57)'
    //     },
    //     {
    //         title: "Advisor",
    //         icon: <SkilledBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(23)'
    //     },
    //     {
    //         title: "Skilled",
    //         icon: <TrustedBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(108)'
    //     },
    //     {
    //         title: "Very Skilled",
    //         icon: <StudentBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(12)'
    //     },
    //     {
    //         title: "Top Skilled",
    //         icon: <SkilledBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(88)'
    //     },
    //     {
    //         title: "Relax & Enjoy",
    //         icon: <TrustedBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(44)'
    //     },
    //     {
    //         title: "Student",
    //         icon: <SkilledBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //     },
    //     {
    //         title: "Exclusive",
    //         icon: <TrustedBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(23)'
    //     },
    //     {
    //         title: "Organic",
    //         icon: <StudentBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(23)'
    //     },
    //     {
    //         title: "Extra Disabilities care",
    //         icon: <SkilledBadgeIcon className="md:h-[60px] h-[55px] md:w-[60px] w-[55px]" />,
    //         count: '(57)'
    //     },
    // ]

    const { data: badgetAllData } = useQuery(
        ["userRefetchBudgeData"],
        async () => {
            return await Http.get(FRONT_API_URL.badgeFilter).then((res) => res.data.data);
        },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    const prefrenceBadgeType = {
        centerMode: false,
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        arrows: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]

    };

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const SortByOption = [
        {
            id: "recommendations",
            label: t("comman_text.recommendations"),
        },
        {
            id: "closest_me",
            label: t("comman_text.closest_me"),
        },
        {
            id: "price_asc",
            label: t("comman_text.price_asc"),
        },
        {
            id: "price_desc",
            label: t("comman_text.price_desc"),
        },
        {
            id: "review_asc",
            label: t("comman_text.review_asc"),
        },
        {
            id: "review_desc",
            label: t("comman_text.review_des"),
        },
        {
            id: "customer_served_asc",
            label: t("comman_text.customer_asc"),
        },
        {
            id: "customer_served_desc",
            label: t("comman_text.customer_desc"),
        },
    ]

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop - 80,
                behavior: 'smooth',
            });
        }
    }

    const handleServiceLocation = (values) => {
        if (values == 1)
        // setOnTheGoChecked(!onTheGoChecked)
        { setOnSalone(!onSalone) }
        else if (values == 2) { setOnTheGoChecked(!onTheGoChecked) }

    };

    const handleWorkerName = (e) => {
        setWorkerName(e.target.value.trim());
    }

    const handleCheckboxFilter = async (page) => {
        const filterData = {
            wheelchair_friendly: selectedCheckboxes.includes(1) ? 1 : 0,
            child_friendly: selectedCheckboxes.includes(2) ? 1 : 0,
            student_price: discount.includes(1) ? true : false,
            senior_price: discount.includes(2) ? true : false,
            deals: discount.includes(3) ? true : false,
            rating_service: selectedRating.includes(1) ? true : false,
            rating_price: selectedRating.includes(2) ? true : false,
            rating_salon: selectedRating.includes(3) ? true : false,
            rating_worker: selectedRating.includes(4) ? true : false,
            rating_atmosphere: selectedRating.includes(5) ? true : false,
            service_location: selectedLocation,
            service_type_ids: selectedList,
            category_ids: selectCategoryTpes,
            minimum_rating: count,
            hair_type_ids: selectHairTpes,
            education_type: selectedEducation,
            prices: selectedPrice,
            badge_ids: checkedList,
            search: address,
            address_lat: addressLat,
            address_long: addressLong,
            sort_field: sortBy,
            provider_type: providerType,
            worker_name: workerName,
            gender: genderType,
            date: time && !dateTime ? dayjs().format('YYYY-MM-DD') : dateTime,
            time: time && !dateTime ? moment(`${dayjs().format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm') : moment(`${dateTime} ${time}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
        }

        await handleFilterData(filterData);
        windowwidth < 768 && await scrollToSection('result')
    };

    const { mutateAsync: handleFilterData } = useMutation(async (datas) => {
        try {

            const { data } = await Http.post(FRONT_API_URL.salonFilter, datas);
            if (data?.data) {
                setSalonList(data.data.data || []);
                setGreaterRadiusSalonList(data.data.greater_radius || []);
                // setServicesFilterOpen(false);
            }
        } catch (error) {
            enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
        }
    });

    const userRefetchServiceTypeData = useMemo(() => ["userRefetchServiceTypeData", selectedLocation], [selectedLocation]);
    const { data: serviceTypeData } = useQuery(
        userRefetchServiceTypeData,
        async () => {

            return await Http.get(FRONT_API_URL.serviceType, { params: { salon_id: "", location: selectedLocation, flag: 1 } })
                .then((res) => res.data.data);
        },
        {
            enabled: !!selectedLocation,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    useEffect(() => {
        if (state && state?.filterData) {
            let data = state?.filterData;
            setHairtype(data.hair_type_ids)
            if (data.selectedList) {
                setSelectedList(data.selectedList)
            }
            let selectedCheckboxes = []
            if (data.wheelchair_friendly && data.child_friendly) {
                setSelectedCheckboxes([1, 2])
                selectedCheckboxes = [1, 2]
            } else if (data.wheelchair_friendly) {
                setSelectedCheckboxes([1])
                selectedCheckboxes = [1]
            } else if (data.child_friendly) {
                setSelectedCheckboxes([2])
                selectedCheckboxes = [2]
            }

            let rating = []
            if (data.rating_service) {
                setSelectedRating(prev => [...prev, 1])
                rating.push(1);
            } if (data.rating_price) {
                setSelectedRating(prev => [...prev, 2])
                rating.push(2);

            } if (data.rating_salon) {
                setSelectedRating(prev => [...prev, 3])
                rating.push(3);

            } if (data.rating_worker) {
                setSelectedRating(prev => [...prev, 4])
                rating.push(4);

            } if (data.rating_atmosphere) {
                setSelectedRating(prev => [...prev, 5])
                rating.push(5);
            }

            let discounts = []
            if (data.student_price) {
                setDiscount(prev => [...prev, 1])
                discounts.push(1)
            } if (data.deals) {
                setDiscount(prev => [...prev, 2])
                discounts.push(2)

            } if (data.senior_price) {
                setDiscount(prev => [...prev, 3])
                discounts.push(3)
            }

            if (data.badge_ids?.length > 0) {
                setCheckedList(data.badge_ids)
            }
            if (data.provider_type?.length > 0) {
                setProviderType(data.provider_type)
            }
            if (data.gender?.length > 0) {
                setGenderType(data.gender)
            }

            setCount(data.minimum_rating)
            setSelectedLocation(data.service_location)
            // setservicesIds(data.service_type_ids)
            setCategorytype(data.category_ids)
            // setOnThegoPrice(data.onthego_price)
            // setOfferRequestBudget(data.offer_request_budget)
            // setIsChecked(data.offer_request)
            setDateTime(data.date)
            setTime(data.time)
            setAddress(data.search)
            setAddressLat(data.address_lat)
            setAddressLong(data.address_long)

            const filterData = {
                wheelchair_friendly: selectedCheckboxes.includes(1) ? 1 : 0,
                child_friendly: selectedCheckboxes.includes(2) ? 1 : 0,
                student_price: discount.includes(1) ? true : false,
                senior_price: discount.includes(2) ? true : false,
                deals: discount.includes(3) ? true : false,
                rating_service: rating.includes(1) ? true : false,
                rating_price: rating.includes(2) ? true : false,
                rating_salon: rating.includes(3) ? true : false,
                rating_worker: rating.includes(4) ? true : false,
                rating_atmosphere: rating.includes(5) ? true : false,
                service_location: data.service_location,
                service_type_ids: data.selectedList,
                category_ids: data.category_ids,
                minimum_rating: data.minimum_rating,
                hair_type_ids: data.hair_type_ids,
                worker_name: workerName,
                education_type: selectedEducation,
                prices: selectedPrice,
                search: data.search,
                address_lat: data.address_lat,
                address_long: data.address_long,
                badge_ids: checkedList,
                provider_type: data.provider_type,
                gender: data.gender,
                date: data.time && !data.date ? dayjs().format('YYYY-MM-DD') : data.date,
                time: data.time && !data.date ? moment(`${dayjs().format('YYYY-MM-DD')} ${data.time}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm') : moment(`${data.date} ${time}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
            }

            handleFilterData(filterData)

        } else {
            handleFilterData({ service_location: selectedLocation });
        }
    }, [state])

    const handleChangeAddress = (city, location) => {
        setAddress(city);
        setAddressLat(location?.lat())
        setAddressLong(location?.lng())
    }

    useEffect(() => {
        // let location;
        if (onSalone && onTheGoChecked) {
            // location = 3;
            setSelectedLocation(3); // Both are checked
        } else if (onSalone) {
            // location = 1;
            setSelectedLocation(1); // Only salon is checked
        }
        else if (onTheGoChecked) {
            // location = 2;
            setSelectedLocation(2); // Only on-the-go is checked
        }
        else {
            // location = "";
            setSelectedLocation("");
        }
        // setSelectedLocation(location);
        // handleCheckboxFilter();
    }, [onSalone, onTheGoChecked]); // Depend on these states

    useEffect(() => {
        if (state) {
            const data = state?.filterData
            setOnSalone(true);
            setOnTheGoChecked(false);
            if (data?.service_location === 2) {
                setOnSalone(false);
                setOnTheGoChecked(true);
            } else if (data?.service_location === 3) {
                setOnSalone(true);
                setOnTheGoChecked(true);
            }
        }
    }, [state]);

    return (
        <div className='container'>
            <h1 className='text-center text-white text-[40px] font-700 py-5'>{t("comman_text.my_search")}</h1>
            <div className='serach_area bg-white border-2 border-success-100 rounded-[10px] md:p-4 p-2 px-3 mb-3'>
                <div className="grid lg:grid-cols-8 sm:grid-cols-4 grid-cols-1 gap-5">
                    <div className="sm:col-span-2 col-span-full">
                        {/* <InputWithIcon
                            // {...getFieldProps("business_name")}
                            prefixIcon={<LocationPin1 className="w-5 h-5" color="#0DEFDA" />}
                            label='Area (City/Address)'
                            placeholder='Valby'
                            name="address"
                            className='bg-white rounded-[10px]'
                        /> */}
                        <InputAutocompleteWrap
                            // {...getFieldProps("business_name")}
                            prefixIcon={<LocationPin1 className="w-5 h-5" color="#0DEFDA" />}
                            label={t('form_input_label.area_city_Address')}
                            placeholder='Valby'
                            value={address}
                            className='bg-white rounded-[10px]'
                            handleChange={handleChangeAddress}
                        />
                    </div>
                    <div className="sm:col-span-2 col-span-full">
                        <InputWithIcon
                            // {...getFieldProps("business_name")}
                            prefixIcon={<ProfileIcon className="w-5 h-5" />}
                            label={t('form_input_label.salon_worker_name')}
                            placeholder='Mads Laurisen'
                            name="worker_name"
                            onChange={handleWorkerName}
                            className='bg-white rounded-[10px]'
                        />
                    </div>
                    <div className="lg:col-span-3 col-span-full">
                        <div className="meetime-form-group">
                            <LabelWrap
                                labelClassName="mb-1.5 w-full justify-start"
                                label={t("form_input_label.service_location")}
                                tooltipText={
                                    onSalone
                                        ? `${t('form_input_label.book_your_services_at_salon')}`
                                        : onTheGoChecked
                                            ? `${t('form_input_label.book_your_services_at_location')}`
                                            : "" // or provide a default tooltip if needed
                                }
                            // tooltipText={"Service your customers at the Salon"}
                            // lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                            />

                            <RowWrap className="grid grid-cols-2 gap-x-6 lg:gap-x-8 gap-y-5 border border-success-100 rounded-xl p-2 h-[45px] items-center" sm={2}>
                                <InputCheckbox name="location"
                                    id={"t('check_box_label.salon')"}
                                    prefixIcon={<IconShop2 className="w-5 h-5" />}
                                    text={t('check_box_label.salon')}
                                    className={"before:rounded-full peer-checked:before:bg-success-800 peer-checked:before:border-success-800 flex-row-reverse w-full peer-checked:after:start-[unset] peer-checked:after:end-[6px]"}
                                    titleClass={"me-auto"}
                                    value={1}
                                    checked={onSalone}
                                    onChange={() => handleServiceLocation(1)}
                                />
                                <InputCheckbox name="location"
                                    id={t('check_box_label.on_the_go')}
                                    prefixIcon={<LocationPin3 className="w-5 h-5" />}
                                    text={t('check_box_label.on_the_go')}
                                    className={"before:rounded-full peer-checked:before:bg-success-800 peer-checked:before:border-success-800 flex-row-reverse w-full peer-checked:after:start-[unset] peer-checked:after:end-[6px]"}
                                    titleClass={"me-auto"}
                                    value={2}
                                    onChange={() => handleServiceLocation(2)}
                                    checked={onTheGoChecked}
                                />
                            </RowWrap>
                        </div>
                    </div>
                    <div className="lg:col-span-7 col-span-full">
                        <SalonGuidelinesSlider slidesToShow={9} setSelectedList={setSelectedList} selectedList={selectedList} serviceTypeData={serviceTypeData} itemClassName={"border-blue-950"} />
                    </div>
                    <div className="lg:col-span-1 col-span-full flex md:flex-col flex-row gap-3 items-center justify-center">
                        <ButtonPrimary className='relative w-full max-w-full sm:max-w-[216px] !px-2 sm:!ps-7 pe-7 !py-1.5' type='button' iconClassName={"absolute sm:start-3 end-3"} icon={<SearchIcon className="h-6 w-6" />} onClick={() => handleCheckboxFilter()}>{t("button_label.search")}</ButtonPrimary>
                        <ButtonPrimary className='relative w-full max-w-full sm:max-w-[216px] !px-2 !ps-7 !py-1.5 sm:block hidden' type='button' iconClassName={"absolute start-3"} icon={<FilterIcon className="h-6 w-6" />} onClick={() => setServicesFilterModalOpen(true)} >{t("button_label.filter")}</ButtonPrimary>
                    </div>
                </div>
            </div>
            <Card className='sm:px-3 sm:py-3.5 py-2.5 flex items-center justify-between'>
                <p className='text-blue-950 text-sm font-500'>{salonList?.length ? salonList?.length : 0} profiles</p>
                <ButtonPrimary className='relative !px-2 !ps-8 !py-0 !text-[15px] sm:flex hidden' onClick={() => setSortByModalOpen(true)} type='button' iconClassName={"absolute start-2"} icon={<SortIcon className="h-[18px] w-[18px]" />}>{t("comman_text.sort_by")}</ButtonPrimary>
                <div className="sm:hidden flex item-center gap-2.5">
                    <ButtonPrimary className='relative !px-2 !py-1.5' type='button' onClick={() => setServicesFilterModalOpen(true)}><FilterIcon className="h-6 w-6" /></ButtonPrimary>
                    <ButtonPrimary className='relative !px-2 !py-1.5' type='button' onClick={() => setSortByModalOpen(true)}><SortIcon className="h-6 w-6" /></ButtonPrimary>
                </div>
            </Card>


            <LabelWrap
                suffixIcon={<InfoFIllIcon className={"h-5 w-5 text-success-950 cursor-pointer"} onClick={() => setbadgeInfoModalOpen(true)} />}
                labelClassName="mt-2.5 mb-0 w-fit justify-start bg-white border-2 border-success-100 rounded-xl p-2 py-1"
                label={t('comman_text.choose_preferences')}
            />
            <div className="mb-2.5 flex items-center gap-5">
                {badgetAllData?.length &&
                    <Slider {...prefrenceBadgeType} className='slider_flex gap_8 flex-grow w-full max-w-[calc(100%-40px)] [&_.slick-track]:pt-2.5 [&_.slick-track]:mx-auto [&_.slick-track]:before:hidden [&_.slick-track]:after:hidden px-2.5' ref={sliderRef}>
                        {
                            badgetAllData.map((data, index) =>
                                <div className="slider_item text-blue-950 w-full" key={index}>
                                    <InputCheckbox
                                        id={`index${index}`}
                                        groupClassName={cn("flex-grow w-full h-full relative")}
                                        className={cn(`bg-white text-blue-950 rounded-[14px] items-center gap-1 relative before:hidden after:hidden px-2 sm:px-2.5 py-1.5 border-[1.3px] border-success-100 w-full text-sm font-medium peer-checked:before:h-[25px] peer-checked:before:w-[25px]  peer-checked:before:flex peer-checked:before:rounded-full peer-checked:before:bg-success-800 peer-checked:before:border-success-800 peer-checked:before:absolute sm:peer-checked:before:-end-1 peer-checked:before:start-0 peer-checked:before:-top-1.5 peer-checked:after:w-2 peer-checked:after:h-3.5 peer-checked:after:flex peer-checked:after:absolute peer-checked:after:text-white sm:peer-checked:after:end-1 peer-checked:after:start-2 peer-checked:after:-top-0.5 transition-all duration-300 h-full leading-none`)}
                                        titleClass='justify-center w-full'
                                        text={
                                            <Badge2 badgeTitle={data} data={data} groupClassName='border-0 p-0 w-full gap-2 justify-center' className='text-blue-950 text-[13px]' />
                                        }
                                    // onChange={() => setSelect(data.name)}
                                    />
                                </div>
                            )
                        }
                    </Slider>
                }
                <div className={cn("flex justify-center items-center flex-col-reverse sm:gap-2 gap-1.5 flex-shrink-0 pt-2.5")}>
                    <button className={cn("border-[1.6px] text-blue-950 bg-white border-text-blue-950 rounded-full h-[33px] w-[33px] flex items-center justify-center")} onClick={() => prevSlide}>
                        <LeftSmallArrowIcon className="w-4 h-4" />
                    </button>
                    <button className={cn("border-[1.6px] text-blue-950 bg-white border-text-blue-950 rounded-full h-[33px] w-[33px] flex items-center justify-center")} onClick={() => nextSlide}>
                        <RightSmallArrowIcon className="w-4 h-4" />
                    </button>
                </div>
            </div>

            {
                salonList?.length > 0 ? (
                    salonList.map((data, index) => (
                        <SalonCard
                            colored
                            key={index}
                            heartIcon={true}
                            ratingItemClassName={"text-[13px]"}
                            badgeTextClass="text-sm"
                            cardTitleClass="text-blue-950"
                            salonInfo={data}
                            className="mt-3"
                            tabsData={[
                                {
                                    id: "1",
                                    title: t('comman_text.specialities'),
                                    content: <SpecialitiesCompo salonData={data} workers={data.workers} colored />
                                },
                                {
                                    id: "2",
                                    title: t("comman_text.about_us"),
                                    content: <AboutUsDeatils salonData={data} Data={data.about_us} colored />
                                },
                                {
                                    id: "3",
                                    title: t('comman_text.reviews'),
                                    content: <ReviewDetails salonData={data} item={5} colored />
                                }
                            ]}
                            badgeSliderBtnClassName={
                                "bg-white md:bg-primary1 text-blue-950 md:text-white border-[1.5px] md:border-0 border-borderColor"
                            }
                        />
                    ))
                ) : (
                    <div className={cn(`border-[1.3px] border-success-100 rounded-[10px] h-20 p-0 flex items-center justify-center bg-light-800`)}>
                        <p className={cn('text-blue-950 text-sm text-center')}>{t('comman_text.no_data_found')}</p>
                    </div>
                )
            }




            {greaterradiussalonList?.length > 0 && (
                <>
                    <Card className="w-full text-center font-medium text-blue-950 mt-3">
                        {t('comman_text.results_outside')}
                    </Card>
                    {greaterradiussalonList.map((item, index) => (
                        <SalonCard
                            colored
                            key={index}
                            heartIcon={true}
                            salonInfo={item}
                            ratingItemClassName="text-[13px]"
                            badgeTextClass="text-sm"
                            cardTitleClass="text-blue-950"
                            className="mt-3"
                            tabsData={[
                                {
                                    id: "1",
                                    title: 'Specialities',
                                    content: (
                                        <SpecialitiesCompo
                                            salonData={item?.workers}
                                            workers={item?.workers}
                                            colored
                                            props={props}
                                        />
                                    ),
                                },
                                {
                                    id: "2",
                                    title: 'Portfolio',
                                    content: <PortfolioDetails workerData={item?.workers} item={3} colored />,
                                },
                                {
                                    id: "3",
                                    title: 'About Us',
                                    content: <AboutUsDeatils salonData={item?.about_us} colored />,
                                },
                                {
                                    id: "4",
                                    title: 'Reviews',
                                    content: <ReviewDetails item={3} colored SliderClassName={'h-full'} />,
                                },
                            ]}
                            badgeSliderBtnClassName="bg-white md:bg-primary1 text-blue-950 md:text-white border-[1.5px] md:border-0 border-borderColor"
                        />
                    ))}
                </>
            )}

            {/* {greaterradiussalonList?.length === 0 && (
                <div className="border-[1.3px] border-success-100 rounded-[10px] h-20 p-0 flex items-center justify-center bg-light-800 mt-3">
                    <p className="text-blue-950 text-sm text-center">
                        {t('comman_text.no_data_found')}
                    </p>
                </div>
            )} */}


            {ServicesFilterModalOpen && <ServicesFilter
                modalOpen={ServicesFilterModalOpen}
                setModalOpen={setServicesFilterModalOpen}
            />}
            {SortByModalOpen && <SortByModal
                modalOpen={SortByModalOpen}
                setModalOpen={setSortByModalOpen}
                SortByOption={SortByOption}
                selectedSortBy={selectedSortBy}
                setselectedSortBy={setselectedSortBy}

            />}
            {badgeInfoModalOpen && <BadgeInfoModal
                modalOpen={badgeInfoModalOpen}
                setModalOpen={setbadgeInfoModalOpen}
            />}
        </div>
    );
}

export default withTranslation()(FindSalon);
