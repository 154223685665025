import React from 'react'

function FeMaleIcon(props) {
    return (
        <svg {...props} viewBox="0 0 20 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.96313 39.9892C9.30207 39.9892 8.65167 40 7.99061 39.9892C6.6045 39.9678 5.60224 38.9834 5.59158 37.5814C5.57026 35.8158 5.57026 34.05 5.59158 32.2844C5.60224 31.8136 5.44232 31.578 4.9945 31.439C3.94958 31.1072 2.90466 30.7326 1.85976 30.3796C0.388352 29.8766 -0.251386 28.8386 0.0898087 27.319C0.516303 25.3928 0.996107 23.4666 1.46525 21.551C2.14764 18.7259 2.83004 15.9114 3.4911 13.0756C3.6617 12.3479 4.0562 11.8129 4.6213 11.3848C5.16508 10.9782 5.6982 10.5608 6.25264 10.1863C6.61516 9.94014 6.61516 9.75822 6.2953 9.45858C4.84522 8.06742 4.24812 6.35522 4.63196 4.36478C5.23972 1.26142 8.23583 -0.568495 11.4346 0.159191C14.2281 0.801266 16.0087 3.93674 15.2623 6.84748C14.9851 7.9069 14.4627 8.7844 13.6523 9.49068C13.3751 9.72612 13.3005 9.89734 13.663 10.1328C14.1215 10.4324 14.5266 10.8283 14.9958 11.1066C15.9234 11.6737 16.4032 12.5084 16.6378 13.5572C17.2562 16.3181 17.9706 19.0576 18.557 21.8292C18.8022 22.9742 19.0048 24.13 19.282 25.275C19.4526 25.9492 19.7832 26.5592 19.9218 27.2548C20.2416 28.7636 19.5699 29.9408 18.0985 30.4116C17.0323 30.754 15.9874 31.1394 14.9211 31.4818C14.516 31.6102 14.3667 31.8242 14.3774 32.2522C14.3987 33.9858 14.388 35.7088 14.3774 37.4424C14.3667 39.0582 13.4391 39.9892 11.8184 40C11.2106 40 10.5922 39.9892 9.96313 39.9892Z" fill="currentColor" />
        </svg>
    )
}

export default FeMaleIcon