import React, { forwardRef } from 'react'
import { cn } from '../../../../lib/utils'
import { cva } from 'class-variance-authority'
import Tooltip from '../Tooltip'

const inputVariance = cva(
    "input-wrap peer rounded text-[15px] leading-[23px] font-medium w-full outline-0 border-[1.3px] border-success-100 text-blue-950 bg-light-950 placeholder:text-dark-950/30 placeholder:text-sm required:border-danger-950 invalid:border-danger-950 block",
    {
        variants: {
            size: {
                default: 'py-2.5 !px-4',
                sm: 'py-1.5 !px-4 leading-[26px]',
            }
        },
        defaultVariants: {
            size: "default",
        }
    },
)

const TextareaWrap = forwardRef(({errorData, errorType, variant, size, type, ref, className, label, labelIcon, labelClassName, groupClassName, errorClassName, require, tooltipText, tooltipIcon, tooltipIconClass, tooltipContentClass, tooltipButtonClass, placeholder, ...props}) => {
    return (
        <div className={cn("meetime-form-group", groupClassName)}>
            {label &&
                <label className={cn(labelIcon || tooltipText ? "flex items-center justify-between gap-2" : "block", "mb-1.5 text-blue-950", labelClassName)}>
                    <span className='flex items-center justify-start gap-2'>
                        {labelIcon ? labelIcon : ''} <span className={cn(require ? "before:content-['*'] before:text-danger-950 before:text-2xl before:leading-[1] before:absolute before:top-0.5 before:end-0 inline-block pe-3 relative" : "")}>{label}</span>
                    </span>
                    {tooltipText ? <Tooltip title={tooltipText} tooltipIcon={tooltipIcon} tooltipIconClass={tooltipIconClass} tooltipContentClass={tooltipContentClass} tooltipButtonClass={tooltipButtonClass} /> : ""}
                </label>}
            <textarea {...props} ref={ref} type={type ?? "text"} className={cn(inputVariance({ variant, size, className }))} placeholder={placeholder && placeholder} />
            {errorType && <p className={cn("error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium", errorClassName)}>{errorData}</p>}
        </div>
    )
})

export { inputVariance }
export default TextareaWrap
