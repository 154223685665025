import React from 'react'

const AboutUsIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 38.0001C29.9411 38.0001 38 29.9413 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9413 10.0589 38.0001 20 38.0001Z" stroke="#0DEFDA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.0049 27.1988V19.9989" stroke="currentcolor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.0049 12.8H20.0223" stroke="currentcolor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default AboutUsIcon