
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Card from '../../../../uiComponents/Card';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import RowWrap from '../../../../uiComponents/RowWrap';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import BagIcon from '../../../../uiComponents/icons/BagIcon';
import PlusLineIcon from '../../../../uiComponents/icons/PlusLineIcon';
import PortfolioCard from './PortfolioCard';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import Http from '../../../../../../Http';
import { useQuery } from 'react-query';
import { PATH_WORKER } from '../../../../../routes/path';
import { useNavigate } from 'react-router-dom';

const PortfolioListCard = ({ t, error, setCount, ...props }) => {
    const navigate = useNavigate();

    const { data: portfolioAllData} = useQuery(
        ["userPortfolioAllData"],
        async () => {
            return await Http.get(PROFILE_COMPLETE_URL.getPortfolio).then((res) => res.data.data);
        },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            retry: false,
        });

    useEffect(() => {
        if (portfolioAllData?.length > 0) {
            setCount(1);
        }
    }, [portfolioAllData])

    return (
        <>
            <Card className={"sm:py-3 mb-3"}>
                <RowWrap className={"grid grid-cols-1 xsm:grid-cols-3"}>
                    <LabelWrap
                        labelClassName="font-semibold mb-0"
                        label={t('comman_text.portfolio')}
                        prefixIcon={<BagIcon className="w-6 h-6" />}
                    />
                    <div className='flex justify-center items-center w-full order-3 col-span-2 xsm:order-2 xsm:col-span-1 mt-4 xsm:mt-0'>
                        <LabelWrap
                            labelClassName="font-semibold mb-0"
                            label={`${t("comman_text.uploaded")} (${portfolioAllData?.length ?? 0})`}
                        />
                    </div>
                    <div className='flex justify-end items-end w-full order-2 xsm:order-3'>
                        <ButtonPrimary
                            onClick={() => navigate(PATH_WORKER.addPortfolio)}
                            size={"sm"} type={"button"}
                            icon={<PlusLineIcon className="w-5 h-5" />}
                            className={"text-[15px] lg:text-[15px] font-bold rounded-[5px] py-0.5 px-2.5 max-w-[82px] w-full border"} >Add</ButtonPrimary>
                    </div>
                </RowWrap>
            </Card>
            {
                portfolioAllData?.length > 0 &&
                <Card className={"mb-3"}>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-3 md:gap-5'>
                        {
                            portfolioAllData?.map((item, index) => (
                                <PortfolioCard key={index} PortfolioData={item} />
                            ))
                        }
                    </div>
                </Card>
            }
        </>
    )
}

export default withTranslation()(PortfolioListCard);