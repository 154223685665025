import create from "zustand";
import { devtools } from "zustand/middleware";
import produce from "immer";

const socketStore = create(
  devtools((set, get) => ({
    profileId: "",
    state: "",
    reciverId: "",
    chatList: [],
    profileInfo: "",
    curentChatLogs: "",
    chatLogs: [],
    chatLogHasMoreData: false,
    setChatList: (payload) => {
      set(
        produce((draft) => {
          draft.chatList = payload;
        }),
        false,
        "message/setChatList"
      );

    },
    filterChatListByTimeStamp: () => {
      set(
        produce((draft) => {
          draft.chatList.sort((x, y) => new Date(y.lastMessageDate) - new Date(x.lastMessageDate));
        })
      );
    },
    setOnlineUser: ({ receiverId, status }) =>
      set(
        produce((draft) => {
          let chat = draft.chatList.find((el) => el.receiverId === receiverId);
          if (chat) chat.status = status;
        })
      ),
    appendUserInChatList: (payload) =>
      set(
        produce((draft) => {
          draft.chatList.push(payload);
        })
      ),
    interactionUser: {
      name: "",
      image: "",
      userId: "",
      chatId: "",
    },
    setInteraction: (payload) => {
      set(
        produce((draft) => {
          draft.interactionUser = payload;
        }),
        false,
        "interactionUser/set"
      );
    },
    replaceMessageInChatList: ({ receiverId, msgData }) => {
      set(
        produce((draft) => {
          const { message, receiverId, senderId, lastMessageDate,convType } = msgData;
          const chat = draft.chatList.find((el) => el.chatUserId === receiverId && el.convType==convType);
          chat.loginUserId = senderId;
          chat.chatUserId = receiverId;
          chat.lastMessage = message;
          chat.lastMessageDate = lastMessageDate;
        }),
        false,
        "message/replace"
      );
      get().filterChatListByTimeStamp();
    },

    appendChatLogs: (payload) =>
      set(
        produce((draft) => {
          draft.chatLogs.unshift(payload);
        }),
        false,
        "messaage/appendChatLogs"
      ),
    setChatLogs: (payload, currentChatUser, lastId) => {
      set(
        produce((draft) => {
          draft.curentChatLogs = currentChatUser;
          if (lastId && payload.length) {
            draft.chatLogs.push(...payload);
          } else {
            draft.chatLogs = payload;
          }

          draft.chatLogHasMoreData = payload.length === 15

        }), false, 'message/setChatLogs'
      );
    },
  }))
);

export default socketStore;
