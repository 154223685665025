import React from 'react'

function GuidelinesListIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_358_50" style={{ "maskType": "luminance" }} maskUnits="userSpaceOnUse" x={3} y={0} width={34} height={40}>
                <path d="M36.046 0H3V40H36.046V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_358_50)">
                <path d="M25.9885 7.90231H12.3391C11.5417 7.90231 10.9023 8.54168 10.9023 9.33909V10.7759C10.9023 11.5661 11.5417 12.2127 12.3391 12.2127H25.9885C26.7788 12.2127 27.4253 11.5661 27.4253 10.7759V9.33909C27.4253 8.54168 26.7788 7.90231 25.9885 7.90231ZM26.5632 10.7759C26.5632 11.092 26.3046 11.3506 25.9885 11.3506H12.3391C12.023 11.3506 11.7644 11.092 11.7644 10.7759V9.33909C11.7644 9.023 12.023 8.76438 12.3391 8.76438H25.9885C26.3046 8.76438 26.5632 9.023 26.5632 9.33909V10.7759Z" fill="currentcolor" />
                <path d="M31.6997 0H7.34626C4.94684 0 3 1.94684 3 4.34626V35.6537C3 38.0532 4.94684 40 7.34626 40H31.6997C34.092 40 36.046 38.0532 36.046 35.6537V4.34626C36.046 1.94684 34.092 0 31.6997 0ZM35.0402 35.6537C35.0402 37.4928 33.5388 38.9943 31.6997 38.9943H7.34626C5.5 38.9943 4.00575 37.4928 4.00575 35.6537V4.34626C4.00575 2.5 5.5 1.00575 7.34626 1.00575H31.6997C33.5388 1.00575 35.0402 2.5 35.0402 4.34626V35.6537Z" fill="currentcolor" />
                <path d="M30.2557 32.7299C30.2557 33.0316 30.1048 33.2184 29.8246 33.2902C29.6235 33.3405 29.4008 33.3405 29.1925 33.3405C27.7916 33.3477 26.3979 33.3476 24.9971 33.3476H21.8649C20.4137 33.3476 18.9626 33.3477 17.5114 33.3405C17.2959 33.3405 17.0588 33.3333 16.8505 33.283C16.5632 33.2184 16.4123 33.0243 16.4123 32.7226C16.4123 32.4209 16.5632 32.2413 16.8505 32.1695C17.0445 32.1192 17.2456 32.1121 17.4396 32.1121H29.2787C29.4367 32.1121 29.6379 32.1192 29.8246 32.1695C30.112 32.2413 30.2557 32.4353 30.2557 32.7299Z" fill="currentcolor" />
                <path d="M30.2558 18.4626C30.2343 18.8075 29.99 19.0014 29.5734 19.0014C28.1653 19.0086 26.7645 19.0014 25.3564 19.0014H17.2745C17.1452 19.0086 17.0303 19.0086 16.9225 19.0014C16.6208 18.9583 16.4556 18.7859 16.4196 18.4914C16.3837 18.2112 16.513 18.0029 16.7932 17.8807C16.9441 17.8161 17.1165 17.8089 17.2817 17.8089C21.3191 17.8161 25.3636 17.8161 29.401 17.8161C29.48 17.8161 29.5518 17.8161 29.6237 17.8233C30.0475 17.8736 30.2846 18.1106 30.2558 18.4698V18.4626Z" fill="currentcolor" />
                <path d="M30.2487 25.4885C30.2918 25.8262 30.1122 26.0776 29.7746 26.1494C29.6453 26.1782 29.4944 26.1782 29.3507 26.1782H29.3005C25.3206 26.1782 21.3479 26.1782 17.368 26.1782C17.2099 26.1782 17.0232 26.1782 16.8579 26.1422C16.5849 26.0704 16.391 25.819 16.4125 25.5532C16.4413 25.2443 16.6065 25.0575 16.9154 24.9928C16.9944 24.9785 17.0806 24.9785 17.1668 24.9785H29.516C29.9614 24.9785 30.2056 25.1509 30.2487 25.4885Z" fill="currentcolor" />
                <path d="M25.9453 15.2874C25.9525 15.5963 25.816 15.7831 25.5359 15.8621C25.3419 15.9124 25.1264 15.9195 24.9468 15.9195H17.3965C17.1666 15.9195 16.9942 15.898 16.8505 15.8621C16.5632 15.7902 16.4123 15.5962 16.4123 15.3017C16.4123 15.0071 16.5632 14.8132 16.8505 14.7414C17.0445 14.6983 17.2456 14.6911 17.4396 14.6911C18.1436 14.6911 18.8405 14.6911 19.5373 14.6911H22.1451C23.0646 14.6911 23.9913 14.6911 24.918 14.6911C25.1192 14.6911 25.3419 14.6983 25.543 14.7486C25.8017 14.8204 25.9453 15.0072 25.9453 15.2874Z" fill="currentcolor" />
                <path d="M25.5285 30.1725C25.4063 30.2084 25.2627 30.23 25.1046 30.23H17.2454C17.1233 30.23 17.0155 30.23 16.9221 30.2012C16.5845 30.1294 16.3762 29.8636 16.4121 29.5547C16.448 29.2458 16.6779 29.0446 17.0084 29.0374H22.2885C23.2799 29.0302 24.2713 29.0302 25.2627 29.0374C25.6578 29.0374 25.8877 29.1955 25.9451 29.5044C26.0026 29.8205 25.8374 30.0863 25.5285 30.1725Z" fill="currentcolor" />
                <path d="M25.9525 22.4786C25.9382 22.8378 25.6939 23.0461 25.2988 23.0533C24.6666 23.0605 24.0344 23.0605 23.4022 23.0605H17.2456C17.1379 23.0605 17.0445 23.0605 16.9439 23.0461C16.635 23.003 16.4123 22.7516 16.4123 22.457C16.4123 22.1625 16.6206 21.9326 16.9223 21.8751C17.0014 21.8536 17.0948 21.8536 17.1666 21.8536H25.191C25.191 21.8536 25.2629 21.8536 25.2988 21.8608C25.7155 21.8823 25.9597 22.1194 25.9525 22.4786Z" fill="currentcolor" />
                <path d="M14.5018 14.9713C14.509 15.1581 14.4371 15.3162 14.2719 15.4814L13.7403 16.013C12.8207 16.9397 11.9012 17.8665 10.9673 18.786C10.6368 19.1093 10.3136 19.1164 9.98313 18.8004C9.55928 18.3981 9.11387 17.9598 8.63974 17.457C8.35238 17.1552 8.33083 16.7745 8.58945 16.5302C8.70439 16.4153 8.84089 16.365 8.98456 16.365C9.16416 16.365 9.35094 16.444 9.51617 16.6021C9.73169 16.8032 9.93284 16.9972 10.1124 17.1983C10.2274 17.3277 10.3351 17.3923 10.4501 17.3923H10.4572C10.6081 17.3923 10.723 17.2917 10.8164 17.2055C11.3983 16.6021 11.9946 16.0058 12.5909 15.4167L13.2877 14.7199C13.4529 14.5475 13.6038 14.4182 13.8049 14.3823C13.9702 14.3535 14.1498 14.4038 14.2791 14.5187C14.4156 14.6337 14.4946 14.7917 14.5018 14.9713Z" fill="currentcolor" />
                <path d="M14.401 29.6194C14.3363 29.7128 14.2573 29.8062 14.1855 29.8708L13.8406 30.2156C12.9211 31.1424 12.0016 32.0691 11.0748 32.9886C10.6079 33.4556 10.3205 33.4556 9.86075 32.9886C9.4369 32.572 9.01304 32.1481 8.59638 31.7171C8.40241 31.5159 8.35213 31.2717 8.46707 31.0059C8.57483 30.7616 8.77598 30.6682 8.93402 30.661C9.22138 30.661 9.37224 30.7616 9.53029 30.9125C9.58057 30.9628 9.63804 31.0131 9.68833 31.0705C9.83201 31.1998 9.97569 31.3435 10.105 31.4872C10.22 31.6237 10.3277 31.6883 10.4498 31.6955H10.457C10.6151 31.6955 10.7372 31.5806 10.8306 31.4872C11.4915 30.8119 12.174 30.1366 12.8277 29.4829L13.2516 29.059C13.3521 28.9656 13.4527 28.8651 13.5605 28.7861C13.7975 28.628 14.1208 28.6639 14.322 28.8579C14.5303 29.059 14.559 29.3679 14.401 29.6194Z" fill="currentcolor" />
                <path d="M14.4515 22.3708C14.3868 22.5145 14.2863 22.6151 14.1641 22.7372L12.9932 23.9082C12.3466 24.5548 11.7001 25.2085 11.0535 25.855C10.8524 26.0562 10.6871 26.1783 10.4572 26.1855C10.2848 26.1855 10.1268 26.0993 9.98313 25.9628L9.63112 25.6036C9.29347 25.2731 8.96301 24.9499 8.63974 24.6122C8.35238 24.3105 8.33083 23.9298 8.58945 23.6783C8.71158 23.5634 8.84807 23.4987 8.99893 23.4987C9.14979 23.4987 9.32221 23.5777 9.48744 23.7214C9.73887 23.9513 9.95439 24.1596 10.1412 24.3752C10.2489 24.4973 10.3495 24.5548 10.4572 24.5548H10.4644C10.6081 24.5548 10.723 24.4542 10.8093 24.368C11.4055 23.7645 12.0018 23.1611 12.6052 22.5576L13.3093 21.8536C13.4314 21.7315 13.5535 21.6165 13.7044 21.5591C13.9486 21.48 14.157 21.5375 14.3366 21.7315C14.5162 21.9183 14.5521 22.1266 14.4515 22.3708Z" fill="currentcolor" />
                <path d="M28.6541 3.03879H24.8394C24.7389 3.44827 24.4874 3.80028 24.1498 4.04453H28.6541C30.4932 4.04453 31.9946 5.54597 31.9946 7.39225V32.6078C31.9946 34.454 30.4932 35.9483 28.6541 35.9483H10.3854C8.54631 35.9483 7.04487 34.454 7.04487 32.6078V7.39225C7.04487 5.54597 8.54631 4.04453 10.3854 4.04453H15.1986C14.861 3.80028 14.6095 3.44827 14.509 3.03879H10.3854C7.99315 3.03879 6.03912 4.99281 6.03912 7.39225V32.6078C6.03912 35.0072 7.99315 36.954 10.3854 36.954H28.6541C31.0463 36.954 33.0004 35.0072 33.0004 32.6078V7.39225C33.0004 4.99281 31.0463 3.03879 28.6541 3.03879Z" fill="currentcolor" />
                <path d="M25.2056 1.00575C24.7315 0.395115 23.9843 0 23.151 0H16.197C15.3637 0 14.6165 0.395115 14.1424 1.00575C13.7976 1.44397 13.5892 2.00431 13.5892 2.60776C13.5892 2.75144 13.6036 2.9023 13.6252 3.03879C13.6898 3.40517 13.8263 3.74282 14.0203 4.04454C14.4872 4.74856 15.2918 5.21552 16.197 5.21552H23.151C24.0562 5.21552 24.8608 4.74856 25.3277 4.04454C25.5217 3.74282 25.6582 3.40517 25.7229 3.03879C25.7444 2.9023 25.7588 2.75144 25.7588 2.60776C25.7588 2.00431 25.5504 1.44397 25.2056 1.00575ZM24.1496 4.04454C23.8694 4.23851 23.5246 4.35345 23.151 4.35345H16.197C15.8234 4.35345 15.4786 4.23851 15.1984 4.04454C14.8608 3.80029 14.6093 3.44828 14.5088 3.03879C14.4729 2.9023 14.4513 2.75862 14.4513 2.60776C14.4513 1.88937 14.8823 1.27155 15.5002 1.00575C15.7157 0.912356 15.9527 0.862069 16.197 0.862069H23.151C23.3953 0.862069 23.6323 0.912356 23.8479 1.00575C24.4657 1.27155 24.8967 1.88937 24.8967 2.60776C24.8967 2.75862 24.8752 2.9023 24.8392 3.03879C24.7387 3.44828 24.4872 3.80029 24.1496 4.04454Z" fill="currentcolor" />
            </g>
        </svg>

    )
}

export default GuidelinesListIcon