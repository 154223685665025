import React from 'react'
import { cn } from '../../../../lib/utils';
import ButtonPrimary from '../buttons/ButtonPrimary';
import Slider from 'react-slick';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';

function SampleNextArrow(props) {
  const {  onClick } = props;

  return (
    <div onClick={onClick} className='absolute top-1/2 -translate-y-1/2 end-0 bg-primary1 rounded-full h-[22px] w-[22px] flex items-center justify-center cursor-pointer'><RightSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
  );
}

function SamplePrevArrow(props) {
  const {  onClick } = props;
  return (
    <div onClick={onClick} className='absolute top-1/2 z-10 -translate-y-1/2 start-0 bg-primary1 rounded-full h-[22px] w-[22px] flex items-center justify-center cursor-pointer'><LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
  );
}

const CustomeTabsSliderUpdated = ({ tabs, className, onTabChange, wrapperClassName, selectedTabId }) => {
  const shopSliderMain = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    responsive: [
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        }
      },
    ]
  };
  return (
    <div className={cn("", wrapperClassName)}>
      <Slider {...shopSliderMain} className='slider_flex gap_4 h-full px-7'>
        {
          tabs?.map(({ tabId, tabName }, index) => (
            <li key={tabId}><ButtonPrimary variant={selectedTabId === tabId ? "fill" : "outline"} size={"sm"} onClick={() => onTabChange(tabId)} className={cn(`rounded-full duration-150  !text-sm py-[5px]  !leading-[1] w-full ${selectedTabId === tabId ? "border-primary1 font-extrabold" : "border-borderColor hover:border-primary1 text-success-900 font-medium"}`, className)}>{tabName}</ButtonPrimary></li>
          ))
        }
      </Slider>
    </div>
  )
}

export default CustomeTabsSliderUpdated