import React from 'react'
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import TrashIcon from '../../../../uiComponents/icons/TrashIcon';
import RoundFillCheckIcon from '../../../../uiComponents/icons/RoundFillCheckIcon';
import CloseFillIcon from '../../../../uiComponents/icons/CloseFillIcon';
import ClockIcon from '../../../../uiComponents/icons/ClockIcon';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import Http from '../../../../../../Http';
import { useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';


const EducationCard = ({ t, educationData }) => {
    const queryClient = useQueryClient();

const handleDelete = (id)=>{
    Http.del(PROFILE_COMPLETE_URL.deleteEducation.replace(':id', id)).then(
        (res) => {
            enqueueSnackbar(t("snackbar_message.education_deleted_successfully"), {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });

            queryClient.invalidateQueries(["userEducationAllData"]);
        }
    );
}

    return (
        <div className='border-[1.5px] bg-light-800 border-success-100 rounded-xl p-3 flex flex-col gap-2.5'>
            <div className='grid grid-cols-1 md:grid-cols-12 gap-2.5'>
                <div className='md:col-span-7 img_wrapper rounded-lg  object-cover object-center overflow-hidden flex-shrink-0 h-[205px]'>
                    <img src={educationData?.front_image} alt="" className='img-fluid w-full h-full object-cover object-center' />
                </div>
                <ul className='md:col-span-5 bg-white text-sm text-blue-950 font-normal border border-success-100 rounded-lg flex-grow w-full p-2 md:p-3 flex flex-col gap-2'>
                    <li>{educationData?.name_of_study}</li>
                    <li>{educationData?.start_date} - {educationData?.end_date}</li>
                    <li>{educationData?.school_name}</li>
                    <li>{educationData?.school_address}</li>
                </ul>
            </div>
            <div className='bg-white border border-success-100 rounded-lg text-center p-2.5'>
                <h6 className="font-medium text-sm text-blue-950 mb-1">{t('comman_text.skills')}</h6>
                <p className='text-sm font-normal text-blue-950'>{educationData?.keywords}</p>
            </div>
            <div className='flex w-full justify-between items-center'>
                <div className={"p-1 leading-none rounded-lg border-[1.5px] border-success-100 bg-white"} >
                    {
                        educationData?.status === 1 ? //Approved 
                            <RoundFillCheckIcon className="w-6 h-6 text-success-800" />
                            :
                            educationData?.status === 2 ?
                                <CloseFillIcon className="w-6 h-6" />
                                :
                                <ClockIcon className="w-6 h-6 text-warning-950" />
                    }
                </div>
                <ButtonPrimary size={"sm"} className={"p-1 !leading-none w-[34px] h-[34px]"} icon={<TrashIcon className="h-5 w-5" />} type="button"  onClick={()=>handleDelete(educationData?.id)}/>
            </div>
        </div>
    )
}

export default withTranslation()(EducationCard)