import { Switch } from '@headlessui/react'
import React from 'react'
import { cn } from '../../../../lib/utils'
import { useTranslation } from 'react-i18next'

export const InputLanguageSwitch = ({ checked, onChange,activeTextClassName, className, ...props }) => {
    const { i18n } = useTranslation();

    // const [enabled, setEnabled] = useState(false)
    const switchValueClick = (check) => {
        // setEnabled(!enabled);
        if (check) {
            localStorage.setItem('i18nextLng', 'dk');
            i18n.changeLanguage("dk")
        } else {
            localStorage.setItem('i18nextLng', 'en');
            i18n.changeLanguage("en")
        }
        // window.location.reload()
    }

    const lng = localStorage.getItem('i18nextLng');

    return (
        <Switch
            checked={lng == "dk" ? true : false}
            onChange={switchValueClick}
            {...props}
            className={cn("relative inline-flex h-[35px] w-[100px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-[0px] bg-light-950 text-black", className)} >
            <div className='grid grid-cols-2 w-full h-full items-center'>
                <span className="">DK</span>
                <span className="">EN</span>
            </div>
            <span aria-hidden="true" className={cn("pointer-events-none absolute top-0 py-[7px] font-medium text-white inline-block h-full w-1/2 transform rounded-full shadow-lg transition duration-200 ease-in-out bg-success-950", lng == "en" ? 'translate-x-full' : 'translate-x-0',activeTextClassName)}>{lng == "en" ? "EN" : "DK"}</span>
        </Switch>
    )
}
