import React from 'react'

const PricesIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.8863 15.0201C21.7994 15.0201 20.7126 14.9848 19.6585 15.0201C18.8352 15.0555 18.2423 15.5507 18.0447 16.3289C17.8801 16.9655 18.1435 17.7083 18.7034 18.0974C19.0328 18.345 19.4281 18.4158 19.8232 18.4158C20.8443 18.4158 21.8653 18.4158 22.8863 18.4158C25.4223 18.4511 27.596 20.6442 27.6948 23.3676C27.8267 26.1619 25.9163 28.6378 23.3803 28.9209C22.9192 28.9562 22.7875 29.1331 22.8204 29.5929C22.8534 29.9819 22.8534 30.3711 22.8204 30.7601C22.7545 31.6797 22.1288 32.3872 21.3054 32.4225C20.416 32.4933 19.6916 31.8919 19.4938 30.937C19.395 30.4417 19.4281 29.9466 19.4281 29.4513C19.4281 29.0623 19.2962 28.9209 18.934 28.9562C18.0777 28.9562 17.2213 28.9562 16.3321 28.9562C15.344 28.9562 14.5535 28.178 14.5535 27.1876C14.5535 26.1972 15.3111 25.4191 16.2991 25.4191C18.3411 25.4191 20.416 25.4191 22.4581 25.4191C23.4791 25.4191 24.1378 24.8531 24.2366 23.9335C24.3354 22.8017 23.6767 22.0589 22.5569 22.0235C21.5358 21.9882 20.5148 22.0235 19.4938 22.0235C16.7932 22.0235 14.6853 19.866 14.5865 16.8948C14.4877 14.1359 16.365 11.9075 18.8352 11.5538C19.3293 11.4831 19.4281 11.2708 19.4281 10.811C19.3952 10.3866 19.395 9.92676 19.4938 9.46694C19.6916 8.51192 20.3832 7.94601 21.3054 8.01674C22.1288 8.08748 22.7545 8.7949 22.8204 9.71454C22.8534 10.0329 22.8204 10.3866 22.8204 10.7049C22.8204 11.5184 22.8204 11.5185 23.545 11.5185C24.3354 11.5185 25.1259 11.5185 25.9163 11.5185C26.9373 11.5185 27.662 12.2612 27.662 13.287C27.662 14.3127 26.9373 15.0555 25.9163 15.0555C24.9283 15.0201 23.9073 15.0201 22.8863 15.0201Z" fill="currentcolor" stroke="white" strokeWidth="0.8" />
            <path d="M20 38C29.9411 38 38 29.9411 38 19.9999C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 19.9999C2 29.9411 10.0589 38 20 38Z" stroke="#0DEFDA" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default PricesIcon