import React from 'react'

function DollarRoundIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_38)">
                <path d="M19.9839 0.00105902C30.9626 -0.113253 40.0094 9.04213 39.999 20.0057C39.9886 30.9589 30.9522 40 19.9943 40C8.99487 39.9896 -0.0207375 30.8862 3.58267e-05 19.9434C0.0311958 9.01095 9.0468 -0.0924693 19.9839 0.00105902ZM36.561 20.0161C36.5714 10.7568 29.2488 3.42004 19.9943 3.42004C10.7814 3.42004 3.53149 10.7152 3.42763 19.9849C3.53149 29.1819 10.7191 36.5395 19.9943 36.5498C29.2281 36.5602 36.5506 29.2442 36.561 20.0161Z" fill="#00FDB4" />
                <path d="M21.909 14.9108C20.7231 14.9108 19.5372 14.876 18.3873 14.9108C17.4889 14.9456 16.842 15.433 16.6264 16.199C16.4467 16.8256 16.7342 17.5567 17.3451 17.9397C17.7045 18.1834 18.1357 18.253 18.567 18.253C19.681 18.253 20.795 18.253 21.909 18.253C24.6761 18.2879 27.0479 20.4464 27.1557 23.1271C27.2994 25.8775 25.2151 28.3145 22.4481 28.593C21.945 28.6278 21.8012 28.8019 21.8372 29.2545C21.8731 29.6375 21.8731 30.0204 21.8372 30.4034C21.7653 31.3086 21.0825 32.0049 20.1841 32.0397C19.2138 32.1093 18.4232 31.5175 18.2076 30.5775C18.0998 30.0901 18.1357 29.6027 18.1357 29.1152C18.1357 28.7323 17.992 28.593 17.5967 28.6278C16.6624 28.6278 15.728 28.6278 14.7578 28.6278C13.6797 28.6278 12.8172 27.8619 12.8172 26.8871C12.8172 25.9123 13.6437 25.1464 14.7218 25.1464C16.9499 25.1464 19.2138 25.1464 21.4418 25.1464C22.5559 25.1464 23.2746 24.5893 23.3824 23.6841C23.4902 22.5701 22.7715 21.839 21.5497 21.8041C20.4357 21.7693 19.3216 21.8041 18.2076 21.8041C15.2608 21.8041 12.961 19.6804 12.8531 16.756C12.7453 14.0404 14.7937 11.8471 17.4889 11.499C18.0279 11.4293 18.1357 11.2204 18.1357 10.7679C18.0998 10.3501 18.0998 9.89749 18.2076 9.44489C18.4232 8.50489 19.1779 7.94786 20.1841 8.01749C21.0825 8.08712 21.7653 8.78341 21.8372 9.6886C21.8731 10.0019 21.8372 10.3501 21.8372 10.6634C21.8372 11.4642 21.8372 11.4642 22.6277 11.4642C23.4902 11.4642 24.3527 11.4642 25.2151 11.4642C26.3291 11.4642 27.1197 12.1953 27.1197 13.2049C27.1197 14.2145 26.3291 14.9456 25.2151 14.9456C24.137 14.9108 23.023 14.9108 21.909 14.9108Z" fill="#3BDFFD" />
            </g>
            <defs>
                <clipPath id="clip0_3_38">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DollarRoundIcon