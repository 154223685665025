import React from 'react'

const CalendarIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16595 15.3278H36.2453" stroke="currentcolor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.1927 22.3585H28.2109" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.2056 22.3585H20.2238" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.201 22.3585H12.2192" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28.1927 29.3455H28.2109" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.2056 29.3455H20.2238" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.201 29.3455H12.2192" stroke="currentcolor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.4765 2V7.9225" stroke="currentcolor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.9348 2V7.9225" stroke="currentcolor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.826 4.84717H12.586C7.30866 4.84717 4 7.79188 4 13.2034V29.4912C4 34.9891 7.30866 38 12.586 38H27.8077C33.1089 38 36.3936 35.0387 36.3936 29.6272V13.2034C36.412 7.79188 33.1272 4.84717 27.826 4.84717Z" stroke="currentcolor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CalendarIcon