import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

function CustomerFooter({ ToggleSidebar, t }) {

  return (
    <>
      <div className="mobile-bottom-menu fixed bottom-0 z">
        <div className="mobile-bottom-menu-card">
          <ul>
            <li>
              <Link to="/customer/messages">
                <div className="icon">
                  <svg width="28" height="28" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_734_6911)">
                      <path d="M36.4018 8H7.59991C6.64797 8.00369 5.73635 8.38473 5.06494 9.05957C4.39352 9.73441 4.01713 10.648 4.01829 11.5999L4 33.2003C4.00276 34.1542 4.38292 35.0682 5.05744 35.7427C5.73195 36.4173 6.646 36.7974 7.59991 36.8002H36.4018C37.3557 36.7974 38.2698 36.4173 38.9443 35.7427C39.6188 35.0682 39.999 34.1542 40.0017 33.2003V11.5999C39.999 10.646 39.6188 9.73195 38.9443 9.05744C38.2698 8.38292 37.3557 8.00276 36.4018 8V8ZM36.4018 15.1998L22.0004 24.2009L7.59991 15.1998V11.5999L22.0004 20.6001L36.4018 11.5999V15.1998Z" fill="#00FDB4" />
                    </g>
                    <defs>
                      <clipPath id="clip0_734_6911">
                        <rect width="36" height="28.8002" fill="white" transform="translate(4 8)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p>Messages</p>
              </Link>
            </li>
            <li>
              <Link to="/customer/my-booking">
                <div className="icon">
                  <svg width="28" height="28" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66378 17.6684C6.66378 17.3471 6.28906 12.8535 6.28906 12.5352C7.04567 11.0625 7.9412 9.66543 8.96345 8.36299C11.1514 7.71943 13.3879 7.25421 15.651 6.9719C16.2926 6.9719 24.48 7.02529 24.905 6.9719C25.3301 6.91852 33.5709 7.18544 33.5709 7.18544L38.1712 12.0004V17.1335L6.66378 17.6684Z" fill="#00FDB4" />
                    <path d="M6.16602 17.3262H38.2484" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M30.1934 24.3555H30.2094" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.207 24.3555H22.223" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.2031 24.3555H14.2191" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M30.1934 31.3516H30.2094" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.207 31.3516H22.223" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.2031 31.3516H14.2191" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29.4785 4V9.92369" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.9395 4V9.92369" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M29.8282 6.84375H14.5878C9.30166 6.84375 6 9.78814 6 15.2016V31.4902C6 36.9878 9.30166 40.001 14.5878 40.001H29.8118C35.1144 40.001 38.3996 37.0391 38.3996 31.6267V15.2006C38.416 9.78814 35.1308 6.84375 29.8282 6.84375Z" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <p>Date</p>
              </Link>
            </li>
            <li>
              <Link to="/customer/find-service" className="center_icon">
                <div className="icon">
                  <svg width="28" height="28" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.011 4.00001C16.6077 3.99683 14.2389 4.5715 12.1042 5.67558C9.96951 6.77967 8.13156 8.3808 6.74529 10.344C5.35902 12.3072 4.46507 14.5749 4.13879 16.956C3.81252 19.337 4.06349 21.7616 4.87055 24.0254C5.67761 26.2891 7.0171 28.3257 8.7761 29.9633C10.5351 31.6009 12.662 32.7916 14.9776 33.4351C17.2932 34.0785 19.7295 34.1558 22.0812 33.6604C24.4329 33.165 26.631 32.1115 28.4903 30.5887L37.4359 39.5415C37.6219 39.7331 37.8553 39.872 38.1124 39.944C38.3695 40.016 38.6411 40.0186 38.8996 39.9515C39.1581 39.8872 39.3942 39.7538 39.5825 39.5654C39.7709 39.377 39.9044 39.1409 39.9687 38.8824C40.0358 38.624 40.0331 38.3524 39.9611 38.0953C39.8891 37.8382 39.7502 37.6048 39.5587 37.4188L30.6058 28.4732C32.4059 26.2791 33.5455 23.6187 33.8917 20.802C34.238 17.9852 33.7767 15.128 32.5617 12.5633C31.3466 9.9986 29.4277 7.83198 27.0286 6.31589C24.6295 4.79979 21.849 3.99664 19.011 4.00001V4.00001ZM19.011 6.9937C21.3827 6.99496 23.7007 7.6993 25.6721 9.01768C27.6435 10.3361 29.1798 12.2093 30.0868 14.4006C30.9938 16.592 31.2307 19.003 30.7678 21.329C30.3048 23.655 29.1626 25.7915 27.4856 27.4685C25.8086 29.1455 23.6721 30.2876 21.3461 30.7506C19.0201 31.2136 16.6091 30.9766 14.4178 30.0696C12.2264 29.1626 10.3532 27.6263 9.03482 25.6549C7.71643 23.6835 7.0121 21.3655 7.01084 18.9939C7.00772 17.4174 7.31603 15.8559 7.91807 14.3989C8.52011 12.9419 9.40402 11.6182 10.5191 10.5038C11.6341 9.38934 12.9583 8.50613 14.4156 7.90486C15.8729 7.3036 17.4346 6.99611 19.011 7.00006V6.9937Z" fill="#FEFEFE" stroke="#00FDB4" strokeWidth="0.4" />
                    <path d="M18.8931 31.835C25.9619 31.835 31.6924 26.1045 31.6924 19.0356C31.6924 11.9668 25.9619 6.23633 18.8931 6.23633C11.8242 6.23633 6.09375 11.9668 6.09375 19.0356C6.09375 26.1045 11.8242 31.835 18.8931 31.835Z" stroke="#00FDB4" strokeWidth="0.4" />
                  </svg>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/customer/favorites">
                <div className="icon">
                  <svg width="28" height="28" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M37.2283 8.83598C36.3503 7.95756 35.3078 7.26073 34.1604 6.78531C33.0131 6.30989 31.7833 6.06518 30.5413 6.06518C29.2993 6.06518 28.0695 6.30989 26.9221 6.78531C25.7748 7.26073 24.7323 7.95756 23.8543 8.83598L22.0326 10.6577L20.21 8.83598C19.335 7.94363 18.2918 7.23355 17.1407 6.74679C15.9896 6.26003 14.7535 6.00625 13.5037 6.00011C12.254 5.99398 11.0154 6.2356 9.8596 6.71103C8.7038 7.18646 7.65369 7.88626 6.76997 8.76997C5.88626 9.65369 5.18646 10.7038 4.71103 11.8596C4.2356 13.0154 3.99398 14.254 4.00011 15.5037C4.00625 16.7535 4.26003 17.9896 4.74679 19.1407C5.23355 20.2918 5.94363 21.335 6.83598 22.21L8.65862 24.0326L22.0326 37.4066L35.4066 24.0326L37.2292 22.21C38.1076 21.332 38.8045 20.2895 39.2799 19.1421C39.7553 17.9947 40 16.7649 40 15.523C40 14.281 39.7553 13.0512 39.2799 11.9038C38.8045 10.7564 38.1076 9.71397 37.2292 8.83598H37.2283Z" stroke="#00FDB4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <p>Favorites</p>
              </Link>
            </li>
            <li>
              <a role={"button"} onClick={ToggleSidebar}>
                <div className="icon">
                  <svg width="28" height="28" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_734_6921)">
                      <path d="M37.9429 9H6.05714C4.92101 9 4 9.92101 4 11.0571C4 12.1933 4.92101 13.1143 6.05714 13.1143H37.9429C39.079 13.1143 40 12.1933 40 11.0571C40 9.92101 39.079 9 37.9429 9Z" fill="#00FDB4" />
                      <path d="M37.9429 20.3145H6.05714C4.92101 20.3145 4 21.2355 4 22.3716C4 23.5077 4.92101 24.4287 6.05714 24.4287H37.9429C39.079 24.4287 40 23.5077 40 22.3716C40 21.2355 39.079 20.3145 37.9429 20.3145Z" fill="#00FDB4" />
                      <path d="M37.9429 31.6289H6.05714C4.92101 31.6289 4 32.5499 4 33.686C4 34.8222 4.92101 35.7432 6.05714 35.7432H37.9429C39.079 35.7432 40 34.8222 40 33.686C40 32.5499 39.079 31.6289 37.9429 31.6289Z" fill="#00FDB4" />
                    </g>
                    <defs>
                      <clipPath id="clip0_734_6921">
                        <rect width="36" height="26.7429" fill="white" transform="translate(4 9)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p>Menu</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <footer className="footer-wrapper">
        <div className="row align-items-center">
          <div className="col-lg-9">
            <div className="footer_menu mb-4 mb-lg-0">
              <ul className='flex_item_cs flex-wrap gap-4'>
                <li><Link to="/">{t('comman_text.home')}</Link></li>
                <li><Link to="/about-us">{t('comman_text.about_me_time')}</Link></li>
                <li><Link to="/prices">{t('comman_text.prices')}</Link></li>
                <li><Link to="/contact-us">{t('comman_text.contact_us')}</Link></li>
                <li><Link to={"/faq"}>{t('comman_text.faq')}</Link></li>
                <li><Link to="/privacy-policy">{t('comman_text.privacy_policy')}</Link></li>
                <li><Link to="/terms-of-use">{t('comman_text.terms_of_service')}</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer_right text-lg-end text-center">
              <ul className="footer_social_icon flex_item_cc mb-2">
                <li>
                  <Link to={condition?.tiktok ?? '/'} target="_blank">
                    {tiktok}
                  </Link>
                </li>
                <li>
                  <Link to={condition?.facebook ?? '/'} target="_blank">
                    {facebook}
                  </Link>
                </li>
                <li>
                  <Link to={condition?.instagram ?? '/'} target="_blank">
                    {instagram}
                  </Link>
                </li>
              </ul>
              <p className="text-white fs-12 lh-14 fw-6000">© {new Date().getFullYear()} {t('front_footer.all_rights_Reserved')}</p>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  )
}

export default withTranslation()(CustomerFooter)
