import { enqueueSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { cn } from "../../../../lib/utils";
import CloseFillIcon from "../icons/CloseFillIcon";
import ImageGalleryIcon from "../icons/ImageGalleryIcon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import GuidelinesListIcon from "../icons/GuidelinesListIcon";


function DropZone({ t, id, type, className, groupClassName, fileSelected, setFileSelected, dropzoneText, labelTitleClass, labelTextClass, removeUploadedImage, labelClassName, icon, errorType, itemId, br, accept, heading, preBr, handleGuideLineOnChange, document, single, limit, ...props }) {
    
    const handleFileUpload = (event, type) => {
        if (document) {
            const selectedImages = Array.from(event.target.files);
            if (fileSelected.length < 1) {
                setFileSelected([...fileSelected, ...selectedImages]);
            } else {
                enqueueSnackbar(t("you can select only one document at a time"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                return false;
            }

        } else {
            let invalidImage = false;
            const files = event.target.files;
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const uploadedImages = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                if (!validImageTypes.includes(file.type)) {
                    invalidImage = true;
                }

                if (invalidImage) {
                    enqueueSnackbar(t("validation_message.image_type_invalid"), {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    return false;
                }

                if (limit && limit > 0) {                    
                    let totalImages = files?.length + fileSelected?.length;
                    if (totalImages > limit) {
                        enqueueSnackbar(t("only " + limit + " images are allowed"), {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                        return false;
                    }
                }

                reader.onload = () => {
                    uploadedImages.push({ image: file, file: reader.result, portfolioType:0 });

                    if (uploadedImages.length === files.length) {
                        if (single && single === true) {
                            setFileSelected(uploadedImages);

                        } 
                        else {
                            setFileSelected(prevImages => [...prevImages, ...uploadedImages]);
                        }
                    }
                };

                reader.readAsDataURL(file);
            }

        }

    };

    // useEffect(() => {
    //     setFileSelected([...images])
    // }, [images])

    const handleRemoveImage = (index) => {
        const updatedImages = [...fileSelected];
        updatedImages.splice(index, 1);
        setFileSelected(updatedImages);
    };


    return (
        <>
            <div className={cn("form-group ", groupClassName)}>
                {heading ? <div className={cn("text-blue-950 mb-2", labelTextClass)}>{heading}</div> : ""}
                <label
                    htmlFor={id || "dropzone-file"}
                    className={cn(
                        "flex flex-col items-center justify-center w-full p-4 border border-success-100 bg-white rounded-lg cursor-pointer",
                        labelClassName,
                    )}>
                    <div className={cn("flex flex-col items-center justify-center ", className)}>
                        {icon ? icon : <></>}
                        <h6 className={cn("text-sm font-medium text-blue-950/50 flex flex-col text-center leading-4 mt-2.5", labelTitleClass)}>
                            {dropzoneText ? dropzoneText : <><span>Drag & Drop</span> {preBr && <br />} <span>Or</span> {br && <br />} <span>Click her and choose file</span></>}
                        </h6>
                    </div>
                    <input id={id || "dropzone-file"} type="file" className="hidden" onInput={handleGuideLineOnChange ?? handleFileUpload} onClick={(e) => {
                        e.target.value = null
                    }} {...props} accept={accept || "image/png, image/gif, image/jpeg"} />
                </label>
            </div>
            {fileSelected ? (
                fileSelected?.length > 0 ? (
                    <div className="upload-preview flex justify-start items-start gap-2 group mt-4">
                        {fileSelected && fileSelected?.map((file, index) => {
                            return (
                                <div
                                    className="border rounded-lg p-1 overflow-hidden w-[150px] h-[150px] relative flex justify-center items-center"
                                    key={index}>
                                    {file.type && file.type.includes("video") ?
                                        <ImageGalleryIcon className={"w-5 h-5 text-black"} />
                                        : (file.image ? (file.image.type && file.image.type) : (file.type && file.type)).includes("application") ?
                                            <GuidelinesListIcon className={"w-14 h-14"} /> :
                                            <img className="w-full h-full object-contain object-center" src={file.image && URL.createObjectURL(file?.image)} alt="..." />
                                    }
                                    <Link
                                        onClick={() => handleRemoveImage(index)}
                                        type="button"
                                        role="button"
                                        className="absolute top-2 end-2">
                                        <CloseFillIcon className="w-4 h-4" />
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                ) : fileSelected && fileSelected?.size ? (
                    <div className="upload-preview flex justify-start items-start gap-2 group my-4">
                        <div className="border rounded-lg p-1 overflow-hidden w-[150px] h-[150px] relative flex justify-center items-center">
                            {fileSelected && fileSelected?.size ? (
                                fileSelected.type && fileSelected.type.includes("video") ? (
                                    <ImageGalleryIcon className={"w-14 h-14"} />
                                ) : fileSelected.type && fileSelected.type.includes("application") ? (
                                    <GuidelinesListIcon className={"w-14 h-14"} />
                                ) : (
                                    <img className="w-full h-full object-contain object-center" src={URL.createObjectURL(fileSelected)} alt="..." />
                                )
                            ) : (
                                <></>
                            )}
                            <Link
                                onClick={() => handleRemoveImage(itemId, "single")}
                                type="button"
                                role="button"
                                className="absolute top-2 end-2"
                                icon={<CloseFillIcon className="w-4 h-4" />}></Link>
                        </div>
                    </div>
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
        </>
    );
}

export default withTranslation()(DropZone)