import React from 'react';
import { Tab } from '@headlessui/react'
import Slider from "react-slick"
import { cn } from '../../../../lib/utils';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';

function SampleNextArrow(props) {
    const { onClick } = props;

    return (
        <div onClick={onClick} className={'absolute top-1/2 -translate-y-1/2 end-0 bg-primary1 rounded-full h-[22px] w-[22px] flex items-center justify-center cursor-pointer'}><RightSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div onClick={onClick} className={'absolute top-1/2 z-10 -translate-y-1/2 start-0 bg-primary1 rounded-full h-[22px] w-[22px] hidden items-center justify-center cursor-pointer'}><LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" /></div>
    );
}

const SliderTabs = ({ tabsData, tabListClass, tabButtonClass, tabPanelsClass, tabItemClass, tabGroupClass, selectedIndex, setSelectedIndex, selectedItemClass, tabSliderClass, ...props }) => {
    const shopSliderMain = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: false,
        responsive: [
            {
                breakpoint: 374,
                settings: {
                    slidesToShow: 2,
                },
            },
        ]

    };
    return (
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex} as="div" className={cn(tabGroupClass)} {...props}>
            <Tab.List className={cn("outline-0 shadow-none rounded-lg text-blue-950 mb-4 bg-white focus:border-transparent", tabListClass)}>
                <Slider {...shopSliderMain} className={cn('slider_flex gap_4 h-full pe-7', tabSliderClass)}>
                    {
                        tabsData && tabsData?.map((item, ind) => (
                            <Tab key={ind} className={"outline-0 shadow-none"}>
                                {({ selected }) => (
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        onClick={(e) => e.currentTarget.click()} // Mimics button behavior
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                e.preventDefault();
                                                e.currentTarget.click(); // Handles keyboard accessibility
                                            }
                                        }}
                                        className={cn(
                                            "w-full block outline-0 shadow-none text-center text-sm font-normal !leading-none py-2 px-1 sm:px-2 rounded-full border border-success-100",
                                            tabButtonClass,
                                            selected ? "bg-success-950 text-white font-bold border-success-950" + selectedItemClass : ""
                                        )}
                                    >
                                        {item?.title}
                                    </div>

                                )}
                            </Tab>
                        ))
                    }
                </Slider>
            </Tab.List>
            <Tab.Panels className={cn(tabPanelsClass)}>
                {
                    tabsData && tabsData?.map((item, ind) => (
                        <Tab.Panel className={cn(tabItemClass)} key={ind}>{item?.content}</Tab.Panel>
                    ))
                }
            </Tab.Panels>
        </Tab.Group>
    );
}

export default SliderTabs;
