import React from 'react'

const ClockIcon = (props) => {
    return (
        <svg {...props} width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7417 39.9572C16.1054 39.9572 12.469 39.9572 8.83265 39.9572C7.72035 39.9572 6.60805 39.9572 6.18024 38.6738C5.75243 37.4332 6.56527 36.8342 7.50644 36.3636C8.31928 35.9786 8.36206 35.2513 8.2765 34.5241C7.80591 28.7487 10.2444 24.4706 14.9931 21.3476C16.3193 20.492 16.3193 19.5508 14.9931 18.6952C10.2872 15.6578 7.89147 11.4225 8.2765 5.73262C8.36206 4.70588 7.97703 3.97861 7.03586 3.50802C6.30858 3.12299 5.83799 2.48128 6.0519 1.58289C6.2658 0.727273 6.99308 0.342246 7.80591 0.171123C8.4904 0.0427806 9.21767 0 9.94495 0C16.6615 0 23.3353 0 30.0519 0C30.6936 0 31.3353 0.0427812 31.977 0.128343C32.8754 0.256685 33.6455 0.641712 33.8594 1.62567C34.0733 2.52406 33.5599 3.20856 32.8326 3.5508C31.7203 4.06417 31.5492 4.96257 31.6348 6.03209C31.9343 11.5936 29.5813 15.6578 25.0466 18.6952C23.4209 19.7647 23.4637 20.492 25.0466 21.5615C29.5813 24.5562 31.9342 28.6203 31.592 34.1818C31.5492 35.0802 31.4209 36.0642 32.576 36.5348C33.5171 36.9626 34.1161 37.6471 33.731 38.7594C33.3888 39.8289 32.4476 40 31.4637 40C27.5706 39.9572 23.6776 39.9572 19.7417 39.9572ZM19.8701 36.3209C22.3514 36.3209 24.8754 36.2781 27.3567 36.3209C28.6829 36.3636 29.2391 35.8503 29.2818 34.4813C29.453 29.9037 27.8701 26.3102 24.0198 23.7433C20.2551 21.262 20.2551 18.9519 24.0626 16.4278C27.8273 13.9037 29.4957 10.3957 29.3246 5.86096C29.2818 4.49198 28.8113 3.93583 27.4423 3.93583C22.4369 3.97861 17.4744 3.97861 12.469 3.93583C11.0572 3.93583 10.6722 4.62032 10.6294 5.90374C10.4155 10.4385 12.1695 13.9037 15.9343 16.4278C19.6562 18.9519 19.6562 21.2193 15.8487 23.7433C11.9556 26.3102 10.4155 29.9465 10.6294 34.5241C10.6722 35.7647 11.1428 36.3209 12.4262 36.3209C14.9075 36.2781 17.3888 36.3209 19.8701 36.3209Z" fill="currentcolor" />
            <path d="M19.7823 34.7812C17.8572 34.7812 15.9321 34.7384 14.0069 34.7812C12.5952 34.824 12.1246 34.1395 12.1246 32.8133C12.1674 29.6475 13.7075 27.2946 16.1032 25.3694C17.1727 24.5566 18.4561 24.0005 19.2262 22.8454C19.5684 22.332 20.0818 22.2465 20.4668 22.7598C21.7075 24.4711 23.8037 25.1128 25.1727 26.6957C26.67 28.4497 27.5684 30.3748 27.654 32.6422C27.6968 34.0539 27.1834 34.7384 25.6861 34.6957C23.7609 34.7384 21.793 34.7812 19.7823 34.7812Z" fill="currentcolor" />
            <path d="M20.0828 12.0637C21.4946 12.0637 22.9063 12.0637 24.3181 12.0637C24.7459 12.0637 25.2165 12.0637 25.4732 12.4915C25.7299 12.9621 25.3449 13.2615 25.0882 13.6038C23.7192 15.1867 21.6229 15.8711 20.3395 17.5396C20.1684 17.7535 19.7406 17.7535 19.5695 17.5396C18.3288 15.9139 16.3181 15.2294 14.9919 13.7749C14.6924 13.4326 14.1363 13.0904 14.4358 12.5342C14.6497 12.1064 15.163 12.1064 15.5908 12.1064C17.0882 12.0637 18.5855 12.0637 20.0828 12.0637Z" fill="currentcolor" />
        </svg>
    )
}

export default ClockIcon