import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { FOOTER_URL, FRONT_API_URL } from "../../api/axios";
import Http from "../../../Http";
import { enqueueSnackbar } from "notistack";
import ButtonPrimary from "../../view/uiComponents/buttons/ButtonPrimary"

import MeeTimeLogoIcon from "../../view/uiComponents/icons/MeeTimeLogoIcon";
import MeeTimeTextIcon from "../../view/uiComponents/icons/MeeTimeTextIcon";
import InputWrap from "../../view/uiComponents/inputs/InputWrap";
import TikTokIcon from "../../view/uiComponents/icons/header-footer-icon/TikTokIcon";
import FaceBookIcon from "../../view/uiComponents/icons/header-footer-icon/FaceBookIcon";
import InstaGramIcon from "../../view/uiComponents/icons/header-footer-icon/InstaGramIcon";
import { cn } from '../../../lib/utils';

function FrontFooter({ paymentmethod, bgColor, bottomBgColor, ...props }) {
  const { t } = props;
  let subscribe = Yup.object().shape({
    email: Yup.string()
      .email(t('validation_message.email_not_valid'))
      .required(t('validation_message.email_address_req')),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: subscribe,
    onSubmit: async (values) => {
      subscriberRequest(values)
      resetForm()
    },
  });

  const { mutateAsync: subscriberRequest } = useMutation(
    async (datas) => {
      try {
        const { data } = await Http.post(
          FRONT_API_URL.subscriberRequest,
          datas
        );
        if (data) {
          enqueueSnackbar('Subscribe Request submitted successfully.', {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }

      } catch (error) {
        console.log(error);

      }
    }
  );


  const { data: condition, refetch } = useQuery(
    ["condition"],
    async () => {
      const result = await Http.get(FOOTER_URL.commonSetting).then(
        (res) => res?.data?.data
      );

      return result;
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: false,
  });

  const {
    errors,
    handleSubmit,
    touched,
    getFieldProps,
    resetForm
  } = formik;

  return (
    <footer className={cn("text-white bg-success-900", bgColor)}>
      <div className='py-6 md:py-8 lg:py-10 xl:py-12 px-3 sm:px-5'>
        <div className='container mx-auto'>
          <div className=''>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10'>
              <div className='pe-3 xl:pe-8'>
                <div className='logo mb-4'>
                  <Link to={"/"} className='flex items-center'>
                    <MeeTimeLogoIcon className="h-20 w-20 ms-5" />
                    <MeeTimeTextIcon className="h-10 w-32 mx-5" />
                  </Link>
                </div>
                <p className='text-sm lg:text-base'>{t(condition?.footerText ?? "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.")}</p>
              </div>
              <div className='ps-3 xl:ps-8'>
                <h4 className='text-lg md:text-xl lg:text-2xl font-semibold'>{t("comman_text.welcome_text")}</h4>
                <p className='py-3 text-sm lg:text-base'>{t('front_footer.subscribe_dec')}</p>
                <div className=''>
                  <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                      <InputWrap groupClassName="mb-4" className="bg-white/30 max-w-[295px] rounded-xl h-[49px] text-white placeholder:text-white placeholder:font-normal" placeholder={t('input_filed_placeholder.enter_email')} errorType={Boolean(touched.email && errors.email) && "error"} {...getFieldProps("email")} errorData={touched.email && errors.email} />
                      <ButtonPrimary size="default" className="btn-theme w-full max-w-[295px]" htmltype="submit">
                        {t('button_label.subscribe_now')}
                      </ButtonPrimary>
                    </form>
                  </FormikProvider>
                </div>
              </div>
              <div className=' lg:mx-auto ps-3 xl:ps-8'>
                <h4 className='text-lg md:text-xl lg:text-2xl font-semibold'>{t('front_footer.more_info')}</h4>
                <ul className='[&>li]:underline [&>li]:text-primary1 [&>li]:py-1'>
                  <li>
                    <Link to={"/about-us"}>{t('comman_text.about_me_time')}</Link>
                  </li>
                  <li>
                    <Link to={"/prices"}>{t('comman_text.prices')}</Link>
                  </li>
                  <li>
                    <Link to={"/contact-us"}>{t('comman_text.contact_us')}</Link>
                  </li>
                  <li>
                    <Link to={"/faq"}>{t('comman_text.faq')}</Link>
                  </li>
                  <li>
                    <Link to={"/privacy-policy"}>{t('comman_text.privacy_policy')}</Link>
                  </li>
                  <li>
                    <Link to={"/terms-of-use"}>{t('comman_text.terms_of_use')}</Link>
                  </li>
                </ul>
              </div>
              <div className='ps-3 xl:ps-10'>
                <div className=''>
                  <h4 className='text-lg md:text-xl lg:text-2xl font-semibold'>{t('front_footer.social_media')}</h4>
                  <div className='flex gap-2 py-3'>
                    <a href={condition?.tiktok ?? '/'} target="_blank" className='h-[30px] w-[30px] flex items-center justify-center bg-success-950 pt-0 rounded'><TikTokIcon className="w-4 h-4" /></a>
                    <a href={condition?.facebook ?? '/'} target="_blank" className='h-[30px] w-[30px] flex items-center justify-center bg-success-950 pt-0 rounded'><FaceBookIcon className="w-4 h-4" /></a>
                    <a href={condition?.instagram ?? '/'} target="_blank" className='h-[30px] w-[30px] flex items-center justify-center bg-success-950 pt-0 rounded'><InstaGramIcon className="w-4 h-4" /></a>
                  </div>
                </div>
                {
                  paymentmethod ?
                    <div className=''>
                      <h4 className='text-lg md:text-xl lg:text-2xl font-semibold'>Payment Methods</h4>
                      <div className="bg-white p-1.5 rounded max-w-[265px] mt-3">
                        <img src={require("../../../assets/images/payment_icon1.png")} alt="" />
                      </div>
                    </div>
                    : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn('text-center text-sm pb-2 pt-2 bg-success-700', bottomBgColor)}>
        <p>© {new Date().getFullYear()} {t('front_footer.all_rights_Reserved')} - CVR:36738782</p>
      </div>
    </footer>
  )
}

export default withTranslation()(FrontFooter)
