import React from 'react'
import IconShop2 from '../icons/IconShop2'
import WorkHoursList from './WorkHoursList'
import LocationPin3 from '../icons/LocationPin3'
import { cn } from '../../../../lib/utils'

const WorkHoursDetails = ({ colored, salonData, className, titleClassName, itemClassName, textDayClassName, textTimeClassName, itemGroupClassName, titleIconClassName }) => {
    return (
        <div className={cn(`border-[1.3px] border-success-100 rounded-[10px] h-full py-2 px-3 ${colored && "bg-light-800"}`)}>
            <div className={`grid grid-cols-1 ${((salonData?.service_location == 1 || salonData?.service_location == 3) && (salonData?.service_location == 2 || salonData?.service_location == 3)) ? 'xsm:grid-cols-2' : ''} w-full gap-6 xsm:[&>div:not(:last-child)]:relative xsm:[&>div:not(:last-child)]:after:border-e xsm:[&>div:not(:last-child)]:after:border-success-100 xsm:[&>div:not(:last-child)]:after:absolute xsm:[&>div:not(:last-child)]:after:top-0 xsm:[&>div:not(:last-child)]:after:h-full xsm:[&>div:not(:last-child)]:after:-end-3`}>
                {(salonData?.service_location == 1 || salonData?.service_location == 3) && (
                    <WorkHoursList
                        listHeading={"Salon"}
                        hours={salonData?.availability?.salon}
                        listHeadingIcon={<IconShop2 className={cn("w-5 h-5", titleIconClassName)} />}
                        className={className}
                        titleClassName={titleClassName}
                        itemClassName={itemClassName}
                        textDayClassName={textDayClassName}
                        textTimeClassName={textTimeClassName}
                        itemGroupClassName={itemGroupClassName}
                    />
                )}
                {(salonData?.service_location == 2 || salonData?.service_location == 3) && (
                    <WorkHoursList
                        listHeadingIcon={<LocationPin3 className={cn("w-5 h-5", titleIconClassName)} />}
                        listHeading={"On The Go"}
                        hours={salonData?.availability?.onthego}
                        className={className}
                        titleClassName={titleClassName}
                        itemClassName={itemClassName}
                        textDayClassName={textDayClassName}
                        textTimeClassName={textTimeClassName}
                        itemGroupClassName={itemGroupClassName}
                    />
                )}
            </div>
        </div>
    )
}

export default WorkHoursDetails