import React from 'react'

const StripLogo = (props) => {
    return (
        <svg {...props} viewBox="0 0 41 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_887_1448)">
                <path fillRule="evenodd" clipRule="evenodd" d="M40.8558 8.78358C40.8558 5.87835 39.4486 3.58594 36.759 3.58594C34.058 3.58594 32.4238 5.87835 32.4238 8.76088C32.4238 12.1768 34.3531 13.9018 37.1221 13.9018C38.4726 13.9018 39.494 13.5954 40.2657 13.1641V10.8944C39.494 11.2803 38.6088 11.5186 37.4853 11.5186C36.3845 11.5186 35.4085 11.1327 35.2837 9.7936H40.8331C40.8331 9.64607 40.8558 9.05594 40.8558 8.78358ZM35.2496 7.70546C35.2496 6.42308 36.0327 5.88969 36.7476 5.88969C37.4399 5.88969 38.1775 6.42308 38.1775 7.70546H35.2496Z" fill="#635BFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M28.0424 3.58594C26.9302 3.58594 26.2152 4.10797 25.818 4.47113L25.6705 3.76751H23.1738V16.9999L26.011 16.3985L26.0223 13.1868C26.4309 13.4819 27.0323 13.9018 28.031 13.9018C30.0624 13.9018 31.9122 12.2676 31.9122 8.67009C31.9009 5.37901 30.0284 3.58594 28.0424 3.58594ZM27.3614 11.4051C26.6919 11.4051 26.2947 11.1668 26.0223 10.8717L26.011 6.6614C26.306 6.33229 26.7146 6.10532 27.3614 6.10532C28.3942 6.10532 29.1091 7.26287 29.1091 8.74953C29.1091 10.2702 28.4055 11.4051 27.3614 11.4051Z" fill="#635BFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.2695 2.91658L22.118 2.30376V0L19.2695 0.601474V2.91658Z" fill="#635BFF" />
                <path d="M22.118 3.78125H19.2695V13.7112H22.118V3.78125Z" fill="#635BFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.2168 4.61335L16.0353 3.77356H13.584V13.7035H16.4211V6.97385C17.0907 6.10001 18.2255 6.25889 18.5773 6.38372V3.77356C18.2142 3.63737 16.8864 3.3877 16.2168 4.61335Z" fill="#635BFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5421 1.3125L7.77307 1.90263L7.76172 10.9928C7.76172 12.6724 9.02141 13.9094 10.701 13.9094C11.6316 13.9094 12.3125 13.7392 12.687 13.5349V11.2311C12.3238 11.3787 10.5308 11.9007 10.5308 10.2211V6.19238H12.687V3.77514H10.5308L10.5421 1.3125Z" fill="#635BFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.87119 6.66164C2.87119 6.21905 3.23434 6.04882 3.83581 6.04882C4.6983 6.04882 5.78776 6.30984 6.65025 6.77513V4.10822C5.70832 3.73371 4.77774 3.58618 3.83581 3.58618C1.53206 3.58618 0 4.78913 0 6.79782C0 9.93003 4.31245 9.43069 4.31245 10.7812C4.31245 11.3032 3.85851 11.4734 3.22299 11.4734C2.28106 11.4734 1.07811 11.0876 0.124834 10.5655V13.2665C1.18025 13.7204 2.24701 13.9134 3.22299 13.9134C5.58349 13.9134 7.20633 12.7445 7.20633 10.7131C7.19499 7.33121 2.87119 7.93268 2.87119 6.66164Z" fill="#635BFF" />
            </g>
            <defs>
                <clipPath id="clip0_887_1448">
                    <rect width={41} height={17} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default StripLogo