import React from 'react'
import MailBoxIcon from '../../uiComponents/icons/MailBoxIcon'

const CheckMailResetPass = () => {
    return (
        <div className="bg-white p-5 rounded-md">
            <div className="mb-4 text-center">
                {/* {ThemeIcons.icn_congratulations} */}
                <MailBoxIcon className="w-48 h-48" />
            </div>
            <h6 className="text-2xl font-semibold text-black/80 mb-4 text-center">
                Check your mail inbox
            </h6>
            <p className='text-base text-center px-10 mb-4 font-medium text-black/40'>We have sent you an email, on how to reset your password.</p>
            {/* <p className="text-base font-semibold   text-center mb-4">
                  {t('otp_verification_module.account_successfully_created')}
                </p> */}
        </div >
    )
}

export default CheckMailResetPass