import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./components/contexts/AuthProvider";
import ClientProvider from "./components/react-query";
import SnackProvider from "./components/contexts/SnackbarProvider";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackProvider>
      <ClientProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ClientProvider>
    </SnackProvider>
  </React.StrictMode>
);
