import React, { useEffect, useState } from 'react'
import { cn } from '../../../lib/utils';

const RowWrap = ({ children, className, ...props }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
  }
  
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [windowWidth])

  return (
    props.xl ?
      <div className={cn(className, props.xl === 3 ? windowWidth >= 1280 ? `
          [&>div:nth-child(3n-1)]:relative
            [&>div:nth-child(3n-1)]:after:border-e
            [&>div:nth-child(3n-1)]:after:border-success-100
            [&>div:nth-child(3n-1)]:after:absolute
            [&>div:nth-child(3n-1)]:after:top-0
            lg:[&>div:nth-child(3n-1)]:after:-end-4
            [&>div:nth-child(3n-1)]:after:-end-3
            [&>div:nth-child(3n-1)]:after:h-full

            [&>div:nth-child(3n-2)]:relative
            [&>div:nth-child(3n-2)]:after:border-e
            [&>div:nth-child(3n-2)]:after:border-success-100
            [&>div:nth-child(3n-2)]:after:absolute
            [&>div:nth-child(3n-2)]:after:top-0
            lg:[&>div:nth-child(3n-2)]:after:-end-4
            [&>div:nth-child(3n-2)]:after:-end-3
            [&>div:nth-child(3n-2)]:after:h-full`
        // : windowWidth >= 768 ? `[&>div:nth-child(odd)]:relative
        //     [&>div:nth-child(odd)]:after:border-e
        //     [&>div:nth-child(odd)]:after:border-success-100
        //     [&>div:nth-child(odd)]:after:absolute
        //     [&>div:nth-child(odd)]:after:top-0
        //     [&>div:nth-child(odd)]:after:-end-3
        //     [&>div:nth-child(odd)]:after:h-full`
          : "[&>div:nth-child(odd)]:after:hidden"
        : props.xl === 2 ? windowWidth >= 1280 ? `[&>div:nth-child(odd)]:relative
          [&>div:nth-child(odd)]:after:border-e
          [&>div:nth-child(odd)]:after:border-success-100
          [&>div:nth-child(odd)]:after:absolute
          [&>div:nth-child(odd)]:after:top-0
          lg:[&>div:nth-child(odd)]:after:-end-4
          [&>div:nth-child(odd)]:after:-end-3
          [&>div:nth-child(odd)]:after:h-full`
          : "[&>div:nth-child(odd)]:after:hidden" : "")}>{children}</div>
      : props.lg ?
        <div className={cn(className, props.lg === 3 ? windowWidth >= 992 ? `
              [&>div:nth-child(3n-1)]:relative
              [&>div:nth-child(3n-1)]:after:border-e
              [&>div:nth-child(3n-1)]:after:border-success-100
              [&>div:nth-child(3n-1)]:after:absolute
              [&>div:nth-child(3n-1)]:after:top-0
              lg:[&>div:nth-child(3n-1)]:after:-end-4
              [&>div:nth-child(3n-1)]:after:-end-3
              [&>div:nth-child(3n-1)]:after:h-full
          
              [&>div:nth-child(3n-2)]:relative
              [&>div:nth-child(3n-2)]:after:border-e
              [&>div:nth-child(3n-2)]:after:border-success-100
              [&>div:nth-child(3n-2)]:after:absolute
              [&>div:nth-child(3n-2)]:after:top-0
              lg:[&>div:nth-child(3n-2)]:after:-end-4
              [&>div:nth-child(3n-2)]:after:-end-3
              [&>div:nth-child(3n-2)]:after:h-full`
          // : windowWidth >= 768 ? `[&>div:nth-child(odd)]:relative
          //     [&>div:nth-child(odd)]:after:border-e
          //     [&>div:nth-child(odd)]:after:border-success-100
          //     [&>div:nth-child(odd)]:after:absolute
          //     [&>div:nth-child(odd)]:after:top-0
          //     lg:[&>div:nth-child(odd)]:after:-end-4
          //     [&>div:nth-child(odd)]:after:-end-3
          //     [&>div:nth-child(odd)]:after:h-full`
            : "[&>div:nth-child(odd)]:after:hidden" :
          props.lg === 2 ? windowWidth >= 992 ? `[&>div:nth-child(odd)]:relative
          [&>div:nth-child(odd)]:after:border-e
          [&>div:nth-child(odd)]:after:border-success-100
          [&>div:nth-child(odd)]:after:absolute
          [&>div:nth-child(odd)]:after:top-0
          lg:[&>div:nth-child(odd)]:after:-end-4
          [&>div:nth-child(odd)]:after:-end-3
          [&>div:nth-child(odd)]:after:h-full`
            : "[&>div:nth-child(odd)]:after:hidden" : "")
        }> {children}</div >
        : props.md ?
          <div className={cn(className, props.md === 3 ? windowWidth >= 768 ? `
              [&>div:nth-child(3n-1)]:relative
              [&>div:nth-child(3n-1)]:after:border-e
              [&>div:nth-child(3n-1)]:after:border-success-100
              [&>div:nth-child(3n-1)]:after:absolute
              [&>div:nth-child(3n-1)]:after:top-0
              lg:[&>div:nth-child(3n-1)]:after:-end-4
              [&>div:nth-child(3n-1)]:after:-end-3
              [&>div:nth-child(3n-1)]:after:h-full
          
              [&>div:nth-child(3n-2)]:relative
              [&>div:nth-child(3n-2)]:after:border-e
              [&>div:nth-child(3n-2)]:after:border-success-100
              [&>div:nth-child(3n-2)]:after:absolute
              [&>div:nth-child(3n-2)]:after:top-0
              lg:[&>div:nth-child(3n-2)]:after:-end-4
              [&>div:nth-child(3n-2)]:after:-end-3
              [&>div:nth-child(3n-2)]:after:h-full`
            // : windowWidth >= 576 ? `[&>div:nth-child(odd)]:relative
            //   [&>div:nth-child(odd)]:after:border-e
            //   [&>div:nth-child(odd)]:after:border-success-100
            //   [&>div:nth-child(odd)]:after:absolute
            //   [&>div:nth-child(odd)]:after:top-0
            //   lg:[&>div:nth-child(odd)]:after:-end-4
            //   [&>div:nth-child(odd)]:after:-end-3
            //   [&>div:nth-child(odd)]:after:h-full`
              : "[&>div:nth-child(odd)]:after:hidden"
            : props.md === 2 ? windowWidth >= 768 ? `[&>div:nth-child(odd)]:relative
              [&>div:nth-child(odd)]:after:border-e
              [&>div:nth-child(odd)]:after:border-success-100
              [&>div:nth-child(odd)]:after:absolute
              [&>div:nth-child(odd)]:after:top-0
              lg:[&>div:nth-child(odd)]:after:-end-4
              [&>div:nth-child(odd)]:after:-end-3
              [&>div:nth-child(odd)]:after:h-full`
              : "[&>div:nth-child(odd)]:after:hidden" : "")}>{children}</div>
          : props.sm ?
            <div className={cn(className, props.sm === 3 ? windowWidth >= 576 ? `
              [&>div:nth-child(3n-1)]:relative
              [&>div:nth-child(3n-1)]:after:border-e
              [&>div:nth-child(3n-1)]:after:border-success-100
              [&>div:nth-child(3n-1)]:after:absolute
              [&>div:nth-child(3n-1)]:after:top-0
              lg:[&>div:nth-child(3n-1)]:after:-end-4
              [&>div:nth-child(3n-1)]:after:-end-3
              [&>div:nth-child(3n-1)]:after:h-full
          
              [&>div:nth-child(3n-2)]:relative
              [&>div:nth-child(3n-2)]:after:border-e
              [&>div:nth-child(3n-2)]:after:border-success-100
              [&>div:nth-child(3n-2)]:after:absolute
              [&>div:nth-child(3n-2)]:after:top-0
              lg:[&>div:nth-child(3n-2)]:after:-end-4
              [&>div:nth-child(3n-2)]:after:-end-3
              [&>div:nth-child(3n-2)]:after:h-full`
              // : windowWidth >= 576 ? `[&>div:nth-child(odd)]:relative
              // [&>div:nth-child(odd)]:after:border-e
              // [&>div:nth-child(odd)]:after:border-success-100
              // [&>div:nth-child(odd)]:after:absolute
              // [&>div:nth-child(odd)]:after:top-0
              // lg:[&>div:nth-child(odd)]:after:-end-4
              // [&>div:nth-child(odd)]:after:-end-3
              // [&>div:nth-child(odd)]:after:h-full`
                : "[&>div:nth-child(odd)]:after:hidden"
              : props.sm === 2 ? windowWidth >= 576 ? `[&>div:nth-child(odd)]:relative
                [&>div:nth-child(odd)]:after:border-e
                [&>div:nth-child(odd)]:after:border-success-100
                [&>div:nth-child(odd)]:after:absolute
                [&>div:nth-child(odd)]:after:top-0
                lg:[&>div:nth-child(odd)]:after:-end-4
                [&>div:nth-child(odd)]:after:-end-3
                [&>div:nth-child(odd)]:after:h-full`
                : "[&>div:nth-child(odd)]:after:hidden" : "")}>{children}</div>
            : <div className={cn(className)}>{children}</div>
  )
}
export default RowWrap;
