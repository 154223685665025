import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import RowWrap from '../uiComponents/RowWrap';
import CategorySelectDropdown from '../uiComponents/inputs/CategorySelectDropdown';
import { withTranslation } from 'react-i18next';
import BarberIcon from '../uiComponents/icons/service-icon/BarberIcon';
import BodyTreatmentIcon from '../uiComponents/icons/service-icon/BodyTreatmentIcon';
import FacialTreatmentIcon from '../uiComponents/icons/service-icon/FacialTreatmentIcon';
import HairdresserIcon from '../uiComponents/icons/service-icon/HairdresserIcon';
import HairRemovalIcon from '../uiComponents/icons/service-icon/HairRemovalIcon';
import HairstylistIcon from '../uiComponents/icons/service-icon/HairstylistIcon';
import LashesBrowsIcon from '../uiComponents/icons/service-icon/LashesBrowsIcon';
import MakeUpIcon from '../uiComponents/icons/service-icon/MakeUpIcon';
import ManicurePedicureIcon from '../uiComponents/icons/service-icon/ManicurePedicureIcon';
import MasseuseIcon from '../uiComponents/icons/service-icon/MasseuseIcon';
import PhysiotherapistIcon from '../uiComponents/icons/service-icon/PhysiotherapistIcon';
import SpaWellnessIcon from '../uiComponents/icons/service-icon/SpaWellnessIcon';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';


const ServiceFilterCategoryModal = ({ t, categorySelectItems, setCategorySelectItems, modalOpen, setModalOpen }) => {

    const serviceCategory = [
        {
            id: 1,
            name: "Barber",
            icon: <BarberIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 11, label: 'Color' },
                { id: 12, label: 'Balayage' },
            ]
        },
        {
            id: 2,
            name: "Body Treatment",
            icon: <BodyTreatmentIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 13, label: 'Service 11' },
                { id: 14, label: 'Color 22' },
            ]
        },
        {
            id: 3,
            name: "Facial Treatment",
            icon: <FacialTreatmentIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 15, label: 'Service 13' },
                { id: 16, label: 'Service 14' },
            ]
        },
        {
            id: 4,
            name: "Hairdresser",
            icon: <HairdresserIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 17, label: 'Service 15' },
                { id: 18, label: 'Service 16' },
            ]
        },
        {
            id: 5,
            name: "Hair Removal",
            icon: <HairRemovalIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 19, label: 'Service 17' },
                { id: 110, label: 'Service 18' },
            ]
        },
        {
            id: 6,
            name: "Hairstylist",
            icon: <HairstylistIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 111, label: 'Service 19' },
                { id: 112, label: 'Service 20' },
            ]
        },
        {
            id: 7,
            name: "Lashes & Brows",
            icon: <LashesBrowsIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 113, label: 'Service 21' },
                { id: 114, label: 'Service 22' },
            ]
        },
        {
            id: 8,
            name: "MakeUp",
            icon: <MakeUpIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 115, label: 'Service 23' },
                { id: 116, label: 'Service 24' },
            ]
        },
        {
            id: 9,
            name: "Manicure Pedicure",
            icon: <ManicurePedicureIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 117, label: 'Service 25' },
                { id: 118, label: 'Service 26' },
            ]
        },
        {
            id: 10,
            name: "Masseuse",
            icon: <MasseuseIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 119, label: 'Service 27' },
                { id: 120, label: 'Service 28' },
            ]
        },
        {
            id: 11,
            name: "Physiotherapist",
            icon: <PhysiotherapistIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 121, label: 'Service 29' },
                { id: 122, label: 'Service 30' },
            ]
        },
        {
            id: 12,
            name: "Spa & Wellness",
            icon: <SpaWellnessIcon className="h-8 w-8 sm:h-9 sm:w-9" />,
            categories: [
                { id: 123, label: 'Service 31' },
                { id: 124, label: 'Service 32' },
            ]
        },
    ]


    const handleSelectServiceIds = (categoryId) => {
        setCategorySelectItems(categoryId)
    };


    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[864px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">{t("services_filter_modal.select_service")}</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center">
                                    <div className="gap-5 w-full">
                                        <RowWrap className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-5">
                                            {
                                                serviceCategory.map((category, index) => (
                                                    <div key={index}>

                                                    <CategorySelectDropdown selcetAll={true} categorySelectItems={categorySelectItems} handleSelectChange={handleSelectServiceIds} children={category.categories} prefixIcon={category.icon} buttonText={category.name} />
                                                    </div>
                                                ))
                                            }
                                        </RowWrap>
                                    </div>

                                    <ButtonPrimary size="sm" className="w-full mt-5" onClick={() => setModalOpen(false)}>{t('button.choose')}</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(ServiceFilterCategoryModal)