import React from 'react'
import { cn } from '../../../lib/utils'
import EmptyFileIcon from './icons/EmptyFileIcon'

const DataNotFound = ({className, textClassName, iconClassName, ...props}) => {
  return (
    <div className={cn("border-[1.5px] border-success-100 rounded-lg p-5 flex flex-col gap-4 justify-center items-center h-[182px]", className)}>
        <p className={cn("text-base font-normal text-blue-950" ,textClassName)}>No Services added Yet, but that can change with only a few clicks!</p>
        <div className={cn(iconClassName)}><EmptyFileIcon className="w-24 h-24"/></div>
    </div>
  )
}

export default DataNotFound