import React, { useEffect } from 'react'
import Card from '../../../../uiComponents/Card'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import { withTranslation } from 'react-i18next'
import RightArrowIcon from '../../../../uiComponents/icons/RightArrowIcon'
import PayoutIcon from '../../../../uiComponents/icons/financial-icon/PayoutIcon'
import ReportsIcon from '../../../../uiComponents/icons/financial-icon/ReportsIcon'
import RecieptsIcon from '../../../../uiComponents/icons/financial-icon/RecieptsIcon'
import AddMoreIcon from '../../../../uiComponents/icons/financial-icon/AddMoreIcon'
import { useNavigate } from 'react-router-dom'
import StripLogo from '../../../../uiComponents/icons/financial-icon/StripLogo'
import { enqueueSnackbar } from 'notistack'
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios'
import { PATH_PROVIDER } from '../../../../../routes/path'
import { useMutation } from 'react-query'
import Http from '../../../../../../Http'
import { authStore } from '../../../../../contexts/AuthProvider'

const Finance = ({ t }) => {
    const IsAccountRegister = true
    const navigate = useNavigate();
    const salon_id = authStore((state) => state?.salonDataId)
    const userType = authStore((state) => state?.profileType)
    const updateSalonProfileStep = authStore((state) => state?.updateSalonProfileStep)
    const salonStep = authStore((state) => state?.salonProfileStep)
    const is_profile_complete = authStore((state) => state?.is_profile_complete)

    const { mutateAsync: financeStep } = useMutation(
        async (dataas) => {
            try {
                const { data } = await Http.post(PROFILE_COMPLETE_URL.paymentStepStore, dataas);
                if (data) {
                    updateSalonProfileStep(5);
                    navigate(PATH_PROVIDER.congratulations);
                }
            } catch (error) {
                enqueueSnackbar("Something", {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    );
    
    const handleSkip = async () => {
        try {
            await financeStep({ salon_id: salon_id })
        } catch (error) {

        }
    }

    useEffect(() => {
        if (Number(salonStep) >= Number(5) && userType !== 4) {
            navigate(PATH_PROVIDER.congratulations);
        }
    }, [])

    return (
        <>
            <Card className={"p-3 lg:p-5"}>
                <div className={`relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full min-h-[200px] lg:min-h-[200px] xl:min-h-[220px] xxl:min-h-[300px] bg-no-repeat bg-cover bg-center items-center overflow-hidden rounded-xl lg:rounded-[20px] py-[18px] pb-10 px-3 sm:px-4 xl:px-10 z-[1]`}
                >
                    <img src="../../../../../../../../assets/images/financial-background.jpg" className='h-full w-full object-cover absolute top-0 start-0 -z-[1]' alt="" />
                    <div className="flex justify-center items-center gap-2 absolute bottom-2.5 start-1/2 -translate-x-1/2 w-full"><p className="text-xs font-medium text-white">In collaboration with</p><p><StripLogo className="w-11" /></p></div>
                    <div className="w-full hidden lg:block"></div>
                    <div className="">
                        <div className="flex flex-col gap-2 items-center justify-center max-w-[350px] mx-auto">
                            <Card className={"p-3 sm:px-5 sm:py-3.5 w-full text-center"}>
                                {!IsAccountRegister ?
                                    <>
                                        <div className='mb-3 flex flex-col gap-2'>
                                            <h5 className='text-lg !leading-none font-medium text-blue-950'>Account Deposit</h5>
                                            <h6 className='text-sm !leading-none font-medium text-blue-950'>0 Dkk.</h6>
                                        </div>
                                        <ButtonPrimary className={"w-full lg:text-lg"} size="sm"><RightArrowIcon className="w-6 h-6" /><span className='w-full'>Manage</span></ButtonPrimary>
                                    </>
                                    : <>
                                        <p className='mb-2 text-[15px] text-blue-950'>Register your banking information,
                                            in order to receive your Payouts</p>
                                        <ButtonPrimary className={"w-full lg:text-lg"} size="sm">Connect</ButtonPrimary>
                                    </>}
                            </Card>
                        </div>
                    </div>
                    <div className="max-w-[220px] mx-auto md:me-0 md:ms-auto">
                        <ul className='flex h-full flex-col justify-center gap-2 '>
                            <li className='flex gap-2.5 md:gap-4 items-center justify-start text-white cursor-default'>
                                <PayoutIcon className="md:w-9 md:h-9 w-7 h-7 flex-shrink-0" />
                                <span className='block text-[15px] font-semibold'>Payout</span>
                            </li>
                            <li className='flex gap-2.5 md:gap-4 items-center justify-start text-white cursor-default'>
                                <ReportsIcon className="md:w-9 md:h-9 w-7 h-7 flex-shrink-0" />
                                <span className='block text-[15px] font-semibold'>Reports & Statastics</span>
                            </li>
                            <li className='flex gap-2.5 md:gap-4 items-center justify-start text-white cursor-default'>
                                <RecieptsIcon className="md:w-9 md:h-9 w-7 h-7 flex-shrink-0" />
                                <span className='block text-[15px] font-semibold'>Reciepts</span>
                            </li>
                            <li className='flex gap-2.5 md:gap-4 items-center justify-start text-white cursor-default'>
                                <AddMoreIcon className="md:w-9 md:h-9 w-7 h-7 flex-shrink-0" />
                                <span className='block text-[15px] font-semibold'>Add More</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </Card>
            {!is_profile_complete ? <Card className={"mt-2.5 p-3 sm:py-3"}>
                <ButtonPrimary variant={"outline"} className={"w-full"} size="sm" type="button"
                    onClick={() => handleSkip()}>{t("button.skip")}</ButtonPrimary>
            </Card> : <></>}
        </>
    )
}

export default withTranslation()(Finance)